import React, { useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'

function NavigationSide() {
  const location = useLocation()
  return (
    <div
      // className='fixed z-10 flex flex-row items-end justify-around w-full gap-2 px-12 py-4 ml-6 overflow-auto overflow-x-hidden bg-white md:items-center lg:items-center lg:gap-8 md:gap-8 md:top-24 md:px-4 md:justify-start md:flex-col md:w-32 h:20 md:h-screen justify-items-center grid-col-1 rounded-xl'
      className='fixed z-10 bottom-0 md:top-24 w-full md:w-auto flex flex-row md:flex-col Zgap-16 md:gap-8 justify-evenly items-center md:justify-start mx-0 md:mx-6 md:px-8 py-2 md:py-4 md:h-fit Zmd:max-h-[500px] rounded-xl bg-white Zoverflow-y-auto'
      style={{
        border: '2px solid #E6E6E6',
        // height:'78%'
      }}
    >
      <div className='flex flex-col items-center justify-center'>
        <a
          className={`flex items-center justify-center p-1 transition-all rounded-full shrink-0 hover:bg-gray-200 w-14 h-14`}
          style={location.pathname === '/home' ? { background: '#00FBC8' } : {}}
          href='/home'
        >
          <img src='/images/home-outline.svg' alt='chart' className='w-8 h-8' />
        </a>
        <div className='text-xs font-semibold'>Inicio</div>
      </div>
      <div className='flex flex-col items-center justify-center'>
        <a
          className={`flex items-center justify-center p-1 transition-all rounded-full shrink-0 hover:bg-gray-200 w-14 h-14`}
          style={
            location.pathname.startsWith('/producto')
              ? { background: '#00FBC8' }
              : {}
          }
          href={'/productos'}
        >
          <img
            src='/images/delivery-outline.svg'
            alt='product'
            className='w-8 h-8'
          />
        </a>
        <div className='text-xs font-semibold'>Productos</div>
      </div>
      <div className='flex flex-col items-center justify-center'>
        <a
          className={`flex items-center justify-center p-1 transition-all rounded-full shrink-0 hover:bg-gray-200 w-14 h-14`}
          style={
            location.pathname.startsWith('/venta')
              ? { background: '#00FBC8' }
              : {}
          }
          href='/ventas'
        >
          <img
            src='/images/cart-outline.svg'
            alt='ventas'
            className='w-8 h-8'
          />
        </a>
        <div className='text-xs font-semibold'>Ventas</div>
      </div>
      <div className='flex flex-col items-center justify-center'>
        <a
          className={`flex items-center justify-center p-1 transition-all rounded-full shrink-0 hover:bg-gray-200 w-14 h-14 `}
          style={
            location.pathname.startsWith('/delivery')
              ? { background: '#00FBC8' }
              : {}
          }
          href='/delivery'
        >
          <img
            src='/images/fast-delivery-outline.svg'
            alt='ventas'
            className='w-8 h-8'
          />
        </a>
        <div className='text-xs font-semibold'>Delivery</div>
      </div>
      <div className='flex flex-col items-center justify-center'>
        <a
          className={`flex items-center justify-center p-1 transition-all rounded-full shrink-0 hover:bg-gray-200 w-14 h-14`}
          style={
            location.pathname === '/tienda' ? { background: '#00FBC8' } : {}
          }
          href='/tienda'
        >
          <img src='/images/bag-outline.svg' alt='tienda' className='w-8 h-8' />
        </a>
        <div className='text-xs font-semibold'>Tienda</div>
      </div>
      <div className='flex flex-col items-center justify-center'>
        <a
          className={`flex items-center justify-center p-1 transition-all rounded-full shrink-0 hover:bg-gray-200 w-14 h-14`}
          style={
            location.pathname === '/payments' ? { background: '#00FBC8' } : {}
          }
          href='/payments'
        >
          <img
            src={'/images/subscription.svg'}
            alt='subscripcion'
            className='w-8 h-8'
          />
        </a>
        <div className='text-xs font-semibold'>Mi suscripcion</div>
      </div>
      <div className='flex flex-col items-center justify-center'>
        <a
          className={`flex items-center justify-center p-1 transition-all rounded-full shrink-0 hover:bg-gray-200 w-14 h-14`}
          style={
            location.pathname === '/payments' ? { background: '#00FBC8' } : {}
          }
          href='/payments'
        >
          <img src='/images/payments.svg' alt='tienda' className='w-8 h-8' />
        </a>
        <div className='text-xs font-semibold'>Pagos</div>
      </div>
    </div>
  )
}

export default NavigationSide
