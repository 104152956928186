import React, {
  Component,
  useState,
  useRef,
  useContext,
  useEffect,
} from 'react'
import axios from 'axios'
import UIBackofficeVentaTableCell from './UIBackofficeVentaTableCell'
import { UserContext } from '../context/UserContext'
import { currencyFromStrapi } from '../utils/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'

function UIBackofficeVentaTable({ ishome }) {
  const [loader, setLoader] = useState(true)
  const [ventas, setVentas] = useState([])
  const [page, setPage] = useState(0)
  const { user, setUser } = useContext(UserContext)

  const limit = ishome ? 4 : 10

  useEffect(() => {
    setLoader(true)
    let id = user
    let config = {
      method: 'get',
      url: `https://ventafazil-strapi-production.up.railway.app/ventas?cliente=${id}&_sort=id:DESC&_start=${page}&_limit=${limit}&estado=aceptado`,
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${process.env.REACT_APP_KEY_STRAPI}`,
      },
    }
    axios(config)
      .then((response) => {
        //console.log('PRO111', response.data)
        let vent = response.data
        vent.map((v, i) => {
          let price = String(v.total)
          v.total = currencyFromStrapi(price)
          return v
        })
        //console.log('VENTTTT', vent)
        setVentas(vent)
        setLoader(false)
      })
      .catch((error) => {
        //console.log(error)
      })
  }, [page])
  return (
    <div>
      <div className='flex flex-col gap-4 '>
        <div className='w-full h-auto '>
          {!ishome && (
            <div className='w-full flex justify-between items-center'>
              <div
                className='w-auto h-auto cursor-pointer'
                onClick={() => setPage(page - 10)}
              >
                {page !== 0 && (
                  <FontAwesomeIcon
                    icon={faArrowLeft}
                    className='p-2 text-2xl'
                  />
                )}
              </div>
              <div
                className='w-auto h-auto cursor-pointer'
                onClick={() => setPage(page + 10)}
              >
                {ventas.length === limit || ventas.length === 0 ? (
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    className='p-2 text-2xl'
                  />
                ) : (
                  ''
                )}
              </div>
            </div>
          )}
          {loader ? (
            <div>Cargando...</div>
          ) : (
            <div className='w-full h-auto gap-y-4'>
              {ventas.length > 0 ? (
                ventas.map((venta, index) => {
                  return (
                    <UIBackofficeVentaTableCell
                      key={index}
                      id={venta.id}
                      fecha={venta.fecha}
                      total={venta.total}
                      createdAt={venta.createdAt}
                      productos={venta.productos}
                      estado={venta.estado} //falta crear en el strapi [Cancelado, Pendiente, Aceptado, Enviado]
                      clientefinal={venta.clientefinal}
                    />
                  )
                })
              ) : (
                <div>Ya no hay mas ventas por ver</div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
export default UIBackofficeVentaTable
