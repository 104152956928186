import React from 'react'
import InputLabel from '../components/InputLabel'

const MethodPeru = ({ onChange, dataKeychain, disabled }) => {
  return (
    <>
      <p className='font-bold'>
        Aqui coloca los keys de izipay que se encuentran en tu cuenta “Mi Cuenta
        web”
      </p>
      <div className='lg:min-w-[500px] lg:w-5/12 flex flex-col gap-12'>
        <div className='flex flex-col md:flex-row gap-2 md:gap-5'>
          <InputLabel
            labelName={'Número Identificador de la tienda'}
            name='userapirest'
            value={dataKeychain.userapirest}
            onChange={onChange}
            disabled={disabled}
          />
        </div>
        <div className='flex flex-col gap-5'>
          <div className='flex flex-col md:flex-row gap-2 md:gap-5'>
            <InputLabel
              labelName={'Key Test Password'}
              name='passwordtestapirest'
              value={dataKeychain.passwordtestapirest}
              onChange={onChange}
              disabled={disabled}
            />
            <InputLabel
              labelName={'Key Produccion Password'}
              name='passwordproductionapirest'
              value={dataKeychain.passwordproductionapirest}
              onChange={onChange}
              disabled={disabled}
            />
          </div>
          <div className='flex flex-col md:flex-row gap-2 md:gap-5'>
            <InputLabel
              labelName={'Key Test Javascript'}
              name='publickeytestjavascript'
              value={dataKeychain.publickeytestjavascript}
              onChange={onChange}
              disabled={disabled}
            />
            <InputLabel
              labelName={'Key Producción Javascript'}
              name='publickeyproductionjavascript'
              value={dataKeychain.publickeyproductionjavascript}
              onChange={onChange}
              disabled={disabled}
            />
          </div>
          <div className='flex flex-col md:flex-row gap-2 md:gap-5'>
            <InputLabel
              labelName={'Key Test krhash'}
              name='keytestkrhash'
              value={dataKeychain.keytestkrhash}
              onChange={onChange}
              disabled={disabled}
            />
            <InputLabel
              labelName={'Key producción krhash'}
              name='keyproductionkrhash'
              value={dataKeychain.keyproductionkrhash}
              onChange={onChange}
              disabled={disabled}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default MethodPeru
