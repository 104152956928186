import axios from 'axios'

export const PutCuponAPI = async (idcupon, body = putcupon) => {
  try {
    const { data } = await axios.put(
      `https://ventafazil-strapi-production.up.railway.app/cupons/${idcupon}`,
      body
    )
    if (data) {
      return data
    } else {
      return null
    }
  } catch (error) {
    //console.log(error);
  }
}

export const putcupon = {
  benefit: 'discount',
  name: 'discount-minimal',
  value: 10,
  stock: 10,
  cliente: {
    _id: '627a770b2c35fb0017422cdd',
  },
}
