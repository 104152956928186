import axios from 'axios'

export const GetTermsConditionsAPI = async (
  idcliente = '627a770b2c35fb0017422cdd'
) => {
  try {
    const { data } = await axios.get(
      `https://ventafazil-strapi-production.up.railway.app/tycstores?cliente=${idcliente}`
    )
    if (data) {
      return data
    } else {
      return null
    }
  } catch (error) {
    //console.log(error);
  }
}

export const PutTermsConditionsAPI = async (idterms, body) => {
  try {
    const { data } = await axios.put(
      `https://ventafazil-strapi-production.up.railway.app/tycstores/${idterms}`,
      body
    )
    if (data) {
      return data
    } else {
      return null
    }
  } catch (error) {
    //console.log(error);
  }
}

export const PostTermsConditionsAPI = async () => {
  try {
    const { data } = await axios.post(
      `https://ventafazil-strapi-production.up.railway.app/tycstores`,
      {
        texto: 'Nuevo texto de prueba',
      },
      {
        headers: {
          'content-type': 'text/json',
        },
      }
    )
    if (data) {
      return data
    } else {
      return null
    }
  } catch (error) {
    //console.log(error);
  }
}
