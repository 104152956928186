import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import React, { useState, useEffect, useContext } from 'react'
import NavigationSide from '../components/NavigationSide'
import NavigationTop from '../components/NavigationTop'
import UIinput from '../components/UIinput'
import UIinputLabeled from '../components/UIinputLabeled'
import ImageUploader from '../components/ImageUploader'
import ModalMessage from '../components/ModalMessage'
import VaritionsUpdater from '../components/VariationsUpdater'
import ExtrasUpdater from '../components/ExtrasUpdater'
import { Link, useParams } from 'react-router-dom'
import Select from 'react-select'
import ModalImageCrop from '../components/ModalImageCrop'
import VariationsUpdater1 from '../components/VariationsUpdater1'
import VariantsTable from '../components/VariantsTable'
import combinate from 'combinate'
import { CurrencyContext } from '../context/CurrencyContext'

function Producto() {
  const { productid } = useParams()
  const [combinaciones1, setCombinaciones1] = useState([])

  const [producto, setProducto] = useState({
    imagenproducto: '',
    nombreproducto: '',
    categoriaproducto: '',
    precio: '',
    codigoproducto: '',
    variations: [],
    extras: [],
    stock: '',
    detalladoproducto: '',
    published_at: '',
    createdAt: '',
    updatedAt: '',
    categorias: [],
    image: [],
    stock: '',
    imagenprueba: '',
    id: '',
    cliente: {
      nombrecomercio: '',
      published_at: '',
      createdAt: '',
      updatedAt: '',
      __v: 0,
      Nombre: '',
      apelido: '',
      ciudad: '',
      confirmed: true,
      correoelectronico: '',
      direccionfiscal: '',
      numerodedocumento: '',
      region: '',
      telefono: '',
      tipodedocumento: '',
      id: '',
    },
  })
  const [optionsCategory, setOptionsCategory] = useState([])
  const [viewModal, setViewModal] = useState(false)
  const [contentModal, setContentModal] = useState({
    title: '',
    text: '',
    linkgo: '',
  })

  const [isProductStock, setisProductStock] = useState(producto?.isProductStock)
  const [imageList, setImageList] = useState([])
  const [changesValid, setChangesValid] = useState(false)
  const [loader, setLoader] = useState(true)
  const [productsVariants, setProductsVariants] = useState([])
  const [errors, setErrors] = useState({})
  const [errorsVariations, setErrorsVariations] = useState({})
  const [errorsExtra, setErrorsExtra] = useState({})
  const [errorsTable, setErrorsTable] = useState({})
  const [hasVariants, setHasVariants] = useState(false)
  const currencyValue = useContext(CurrencyContext)
  const currency = currencyValue.currency

  const [minLengths, setMinLengths] = useState({
    nombreproducto: 6,
    detalladoproducto: 3,
    codigoproducto: 3,
    precio: 1,
    stock: 1,
    variations: 3,
    productvariations: 1,
    variationsName: 1,
    variationsValue: 1,
    extras: 3,
    extrasName: 3,
    extrasPrice: 1,
  })

  const [imageCrop, setImageCrop] = useState(null)

  const [showModalCropImage, setShowModalCropImage] = useState(false)

  const resolution = {
    width: 500,
    height: 500,
  }
  const aspectRatio = 16 / 9

  const changeListImage = (val) => {
    let copy = Object.assign({}, producto)
    copy.image = val
    setImageList(val)
    setProducto(copy)
    setChangesValid(true)
  }

  const customStyles = {
    input: (styles) => ({ ...styles, height: '2.6rem' }),
  }

  const getListCategorys = async () => {
    let user = JSON.parse(localStorage.getItem('user'))
    let config = {
      method: 'get',
      url: `https://ventafazil-strapi-production.up.railway.app/categorias/?cliente=${user._id}`,
    }
    await axios(config)
      .then((response) => {
        let categories = response.data.map((el) => {
          let body = { value: el.id, label: el.nombrecategoria }
          return body
        })
        setOptionsCategory(categories)
      })
      .catch((error) => {
        //console.log(error)
      })
  }

  useEffect(() => {
    getListCategorys()
    let config = {
      method: 'get',
      url: `https://ventafazil-strapi-production.up.railway.app/productos/${productid}`,
      headers: {},
    }
    axios(config)
      .then(async (response) => {
        let producto = response.data
        let listaCate = producto.categorias.map((el) => {
          let body = { value: el.id, label: el.nombrecategoria }
          return body
        })
        producto.categorias = await listaCate
        // console.log('producto', producto)
        producto.variations.length > 0 && setHasVariants(true)
        setProducto(producto)
        setImageList(producto.image)
        setLoader(false)
        // console.log(response.data)
        combinates(response.data)
      })
      .catch((error) => {
        //console.log(error)
      })
  }, [])

  const changeCategories = (listUserCategory) => {
    // console.log(producto.categorias)

    let stado = producto
    stado.categorias = listUserCategory
    setProducto(stado)
    setChangesValid(true)
  }

  const changeInputDecimal = (event) => {
    let start = 0
    let input = ''
    start = event.target.selectionStart
    let name = event.target.name
    let val = event.target.value
    val = val.replace(/([^0-9.]+)/, '')
    val = val.replace(/^(0|\.)/, '')
    const match = /(\d{0,7})[^.]*((?:\.\d{0,2})?)/g.exec(val)
    const value = match[1] + match[2]
    input = value
    if (val.length > 0) {
      event.target.value = Number(value).toFixed(2)
      event.target.setSelectionRange(start, start)
      input = Number(value).toFixed(2)
      // newstore[event.target.name] = input
      return input
    }
  }

  const onChange = (event) => {
    let name = event.target.name
    let value = event.target.value
    let newstore = Object.assign({}, producto)

    if (name == 'precio') {
      newstore[event.target.name] = changeInputDecimal(event)
    } else {
      newstore[event.target.name] = event.target.value
    }
    setProducto(newstore)
    setChangesValid(true)
    // console.log("errors", errors)
    // console.log("errorsVariations", errorsVariations)
    // console.log("errorsExtra", errorsExtra)
    // setErrors(validation(name, value, newstore))
  }

  const onBlur = (event) => {
    onChange(event)
    setErrors(validation())
    // //console.log("ERRORS==", errors)
  }

  const validation = (name, value, newstore) => {
    let product = { ...producto }
    let minLength = minLengths
    let errors = {}

    let regexName = /^[A-Za-z0-9ÑñÁáÉéÍíÓóÚúÜü!:/()\s]+$/
    let regexSpecialLetters =
      /^[a-zA-Z0-9ÑñÁáÉéÍíÓóÚúÜü!"#$%&'()*+,.:;=^_`~¨?@|{}/\[\]\\\-\s¡\p{Emoji_Presentation}]+$/u

    let regexLettersNumbers = /^[A-Za-z0-9ÑñÁáÉéÍíÓóÚúÜü\s]+$/
    let regexNumber = /^[0-9]+$/
    let regexDecimal = /^[0-9.]+$/
    let regexVariations = /^[A-Za-z0-9ÑñÁáÉéÍíÓóÚúÜü~\s]+$/
    let regexExtras =
      /^[a-zA-Z0-9ÑñÁáÉéÍíÓóÚúÜü!"#$%&'()*+,.:;=^_`~¨@{}/\[\]\\\-\s]+$/

    if (!product.nombreproducto?.trim()) {
      errors.nombreproducto = "El campo 'Producto' es requerido"
    } else if (!regexName.test(product.nombreproducto.trim())) {
      errors.nombreproducto =
        "El campo 'Producto' solo acepta letras, números, espacios en blanco y estos caraceteres ! : / ( )"
    } else if (product.nombreproducto.length < minLength.nombreproducto) {
      errors.nombreproducto =
        "El campo 'Producto' debe tener mínimo " +
        minLength.nombreproducto +
        ' caracteres'
    }
    // este omm
    if (!product.detalladoproducto.trim()) {
      // errors.detalladoproducto = "El campo 'INFORMACION GENERAL' es requerido"
    }
    //  else if (!regexSpecialLetters.test(product.detalladoproducto.trim())) {
    //   errors.detalladoproducto =
    //     "El campo 'INFORMACION GENERAL' solo acepta letras, números, espacios en blanco y estos caraceteres " +
    //     '! " # $ % & \' ( ) * + , - . / : ; = ? @ [ \\ ] ^ _ ` { | } ~ ¨ '
    else if (product.detalladoproducto.length < minLength.detalladoproducto) {
      errors.detalladoproducto =
        "El campo 'INFORMACION GENERAL' debe tener mínimo " +
        minLength.detalladoproducto +
        ' caracteres'
    }
    if (!product.codigoproducto.trim()) {
      // errors.codigoproducto = "El campo 'Código de producto' es requerido"
    } else if (!regexLettersNumbers.test(product.codigoproducto.trim())) {
      errors.codigoproducto =
        "El campo 'Código de producto' solo acepta letras, números y espacios en blanco"
    } else if (product.codigoproducto.length < minLength.codigoproducto) {
      errors.codigoproducto =
        "El campo 'Código de producto' debe tener mínimo " +
        minLength.codigoproducto +
        ' caracteres'
    }
    if (!product.precio?.trim()) {
      if (hasVariants) {
        // errors.precio = ""
        delete errors.precio
      } else {
        errors.precio = "El campo 'Precio' es requerido"
      }
    } else if (!regexDecimal.test(product.precio?.trim())) {
      errors.precio = "El campo 'Precio' solo acepta números"
    } else if (parseFloat(product.precio) < minLength.precio) {
      errors.precio =
        "El campo 'Precio' debe ser igual o mayor a " +
        currency +
        minLength.precio
    }
    if (!product.stock.trim()) {
      if (isProductStock || hasVariants) {
        // errors.stock = ""
        delete errors.stock
      } else {
        errors.stock = "El campo 'Stock' es requerido"
      }
    } else if (!regexNumber.test(product.stock.trim())) {
      errors.stock = "El campo 'Stock' solo acepta números enteros"
    } else if (product.stock.length < minLength.stock) {
      errors.stock =
        "El campo 'Stock' debe tener mínimo " + minLength.stock + ' caracteres'
    }

    return errors
  }

  const updateProductData = (dataProduct) => {
    // //console.log(Object.keys(errors).length === 0, " errors", errors)
    // //console.log(Object.values(errorsVariations), " errorsVariations", errorsVariations)
    // //console.log(Object.keys(errorsExtra).length === 0, " errorsExtra", errorsExtra)
    // //console.log("ERRORS TABLE", errorsTable)
    setErrors(validation())
    let allError = Object.assign({}, errorsTable)
    combinaciones1.forEach((elem, i) => {
      let newError = {}
      if (elem.precio === 0) elem.precio = ''
      if (elem.stock === '') Number((elem.stock = 0))
      let indexPrecio = Object.keys(elem).findIndex((el) => el == 'precio')
      let _nameP = Object.keys(elem)[indexPrecio]
      let _valueP = elem.precio
      let indexStock = Object.keys(elem).findIndex((el) => el == 'stock')
      let _nameS = Object.keys(elem)[indexStock]
      let _valueS = elem.stock
      // //console.log(i, "newError", newError)
      setErrorsTable(validateTable(null, _nameP, _valueP, i))
      setErrorsTable(validateTable(null, _nameS, _valueS, i))
    })
    // console.log('OUT FOREACH', allError)
    let noError =
      Object.keys(errors).length === 0 &&
      Object.keys(errorsVariations).length === 0 &&
      Object.keys(errorsExtra).length === 0 &&
      Object.keys(errorsTable).length === 0
    let noEmpy
    if (hasVariants) {
      noEmpy = producto.nombreproducto.trim().length > 0
    } else if (isProductStock) {
      noEmpy =
        producto.nombreproducto.trim().length > 0 &&
        producto.precio.trim().length > 0
    } else {
      noEmpy =
        producto.nombreproducto.trim().length > 0 &&
        producto.precio.trim().length > 0 &&
        producto.stock.trim().length > 0
    }
    if (noError && noEmpy) {
      let categorias = dataProduct.categorias.map((el) => el.value)
      // console.log('dataprod', dataProduct)
      // console.log(
      //   ' dataProduct.productvariations',
      //   dataProduct.productvariations
      // )
      // console.log('MIS COMBINACIONES', combinaciones1)
      let config = {
        method: 'put',
        url: `https://ventafazil-strapi-production.up.railway.app/productos/${productid}`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_KEY_STRAPI}`,
        },
        data: {
          nombreproducto: dataProduct.nombreproducto,
          precio: dataProduct.precio,
          // dataProduct?.variations.length > 0
          //   ? dataProduct.variations[0].price
          //   : dataProduct.precio,
          detalladoproducto: dataProduct.detalladoproducto,
          imagenproducto:
            imageList && imageList.length > 0
              ? dataProduct.imagenproducto !== ''
                ? dataProduct.imagenproducto
                : imageList[0].url
              : '',
          categorias: categorias,
          image: imageList,
          codigoproducto: dataProduct.codigoproducto,
          stock: dataProduct.stock,
          variations: dataProduct.variations,
          extras: dataProduct.extras,
          productvariations: combinaciones1,
          isproductstock: dataProduct.isproductstock,
          isnewproduct: true,
        },
      }
      axios(config)
        .then((response) => {
          // console.log(response.data)
          let bodyModal = {
            title: 'Producto editado',
            text: 'La información del producto ha sido modificado con éxito.',
            linkgo: '/productos/',
          }
          setContentModal(bodyModal)
          setViewModal(true)
        })
        .catch((error) => {
          console.log(error)
        })
    } else {
      console.log('HAY ERRORES NO SE PUEDE GUARDAR')
    }
  }

  const changeImgURL = (val) => {
    // //console.log(this.state)
    let newbody = {
      ...producto,
      imagenproducto: val,
    }
    setProducto(newbody)
    setChangesValid(true)
  }

  const closeModal = (val) => {
    setViewModal(val)
  }

  const changeVariations = (val, i) => {
    let prod = producto
    prod.variations = val
    setProducto(prod)
    setChangesValid(true)
    combinates(producto)

    if (val.length > 0) {
      setHasVariants(true)
      setProducto({ ...producto, precio: '', stock: '' })
      delete errors.precio
      delete errors.stock
    } else {
      setHasVariants(false)
    }
    if (i > -1) {
      setErrorsVariations(validateVariations(i))
    } else {
      // //console.log("ErrorVariations", errorsVariations)
      prod.variations.forEach((e, index) => {
        setErrorsVariations(validateVariations(index))
      })
    }
  }

  const combinates = (p) => {
    // console.log('MI productos 000', p)
    const combinates = {}

    p.variations.forEach((p) => {
      combinates[p.name] = p.values
    })
    // console.log('combinates----1', combinates)
    const options = combinate(combinates)
    // console.log('probando conbinacion---', options)
    const prOptions = []

    options.forEach((option, i) => {
      // console.log('mi option', option)
      // console.log('OPCONESSS', p?.productvariations[i])
      prOptions.push({
        options: option,
        precio:
          p?.productvariations[i]?.precio || combinaciones1[i]?.precio || 0,
        isStockIlimit:
          p?.productvariations[i]?.isStockIlimit ||
          combinaciones1[i]?.isStockIlimit ||
          false,
        stock: p?.productvariations[i]?.stock || combinaciones1[i]?.stock | 0,
      })
    })
    // console.log('prOptions----', prOptions)
    // setCombinaciones1(prOptions)
    setCombinaciones1([...prOptions])
    // console.log('COMBINACIONESFFFF', [...prOptions])
    // console.log(producto)
    setProducto({ ...p, productvariations: combinaciones1 })

    // console.log(producto)

    // console.log('secom ', prOptions)
    // setCombinaciones1(combinate({ options }))
  }

  const validateVariations = (i) => {
    let errors = { ...errorsVariations }
    let minLength = minLengths
    let variations = producto.variations
    let name = variations[i]?.name
    let values = variations[i]?.values
    let regexVariations = /^[A-Za-z0-9ÑñÁáÉéÍíÓóÚúÜü~\s]+$/

    if (!name?.trim()) {
      // errors.variations = "El campo 'Opción' es requerido"
      errors['variationsName' + i] = ''
    } else if (!regexVariations.test(name.trim())) {
      errors['variationsName' + i] =
        "El campo 'Opción' solo acepta letras, números, espacios en blanco y estos caraceteres ~"
    } else if (name.length < minLength.variationsName) {
      errors['variationsName' + i] =
        "El campo 'Opción' debe tener mínimo " +
        minLength.variationsName +
        ' caracteres'
    } else {
      errors['variationsName' + i] = ''
    }

    if (errors['variationsName' + i] == '') {
      delete errors['variationsName' + i]
    }

    return errors
  }

  const changeExtras = (val, i) => {
    let prod = producto
    prod.extras = val
    setProducto(prod)
    setChangesValid(true)
    if (i > -1) {
      setErrorsExtra(validateExtra(val, i))
    } else {
      prod.extras.forEach((e, index) => {
        setErrorsExtra(validateExtra(val, index))
      })
    }
  }

  const validateExtra = (val, i) => {
    let errors = { ...errorsExtra }
    let minLength = minLengths
    let extras = producto.extras
    let name = extras[i]?.name
    let price = extras[i]?.price
    let regexExtras =
      /^[a-zA-Z0-9ÑñÁáÉéÍíÓóÚúÜü!"#$%&'()*+,.:;=^_`~¨@{}/\[\]\\\-\s]+$/
    let regexDecimal = /^[0-9.]+$/
    // //console.log("Index ", i, " name", name, " price", price)
    if (!name?.trim()) {
      // errors["extrasName" + i] = "El campo 'Extra' es requerido"
      errors['extrasName' + i] = ''
    } else if (!regexExtras.test(name.trim())) {
      errors['extrasName' + i] =
        "El campo 'Extra' solo acepta letras, números, espacios en blanco y estos caraceteres " +
        '! " # $ % & \' ( ) * + , - . / : ; = @ [ \\ ] ^ _ ` { } ~ ¨ '
    } else if (name.length < minLength.extrasName) {
      errors['extrasName' + i] =
        "El campo 'Extra' debe tener mínimo " +
        minLength.extrasName +
        ' caracteres'
    } else {
      errors['extrasName' + i] = ''
    }
    if (!price?.trim()) {
      // errors["extrasPrice" + i] = "El campo 'Precio' es requerido"
      errors['extrasPrice' + i] = ''
    } else if (!regexDecimal.test(price?.trim())) {
      errors['extrasPrice' + i] = "El campo 'Precio' solo acepta números"
    } else if (parseFloat(price) < minLength.extrasPrice) {
      errors['extrasPrice' + i] =
        "El campo 'Precio' debe ser igual o mayor a " +
        currency +
        minLength.extrasPrice
    } else {
      errors['extrasPrice' + i] = ''
    }
    if (errors['extrasName' + i] == '') {
      delete errors['extrasName' + i]
    }
    if (errors['extrasPrice' + i] == '') {
      delete errors['extrasPrice' + i]
    }

    return errors
  }
  // console.log('PROBANDO', producto.isProductStock)

  // console.log('Ddd', isProductStock)
  let changeStockProduct = (e) => {
    // console.log('vvv', isProductStock)
    setisProductStock(!isProductStock)
    producto.isproductstock = !producto.isproductstock
    producto.stock = ''
    delete errors.stock
    // console.log('despyes', producto.isproductstock)
  }
  useEffect(() => {}, [])

  const onChangeVariations = (e, i) => {
    const { name, value } = e.target
    // console.log('gol')
    // console.log(name, value)
    // console.log('index', i)

    const newproductsVariants = [...productsVariants]
    // console.log(newproductsVariants[i][name].push(value))
    // console.log(productsVariants)
    // setProductsVariants(newproductsVariants)

    // console.log(productsVariants)
  }

  const validateTable = (e, _name, _value, i) => {
    // //console.log("VALIDATE TABLE", e, i)
    let errors = { ...errorsTable }
    let minLength = minLengths
    let name = _name //e.target.name
    let value = _value //e.target.value
    let regexDecimal = /^[0-9.]+$/
    let regexNumber = /^[0-9]+$/
    // console.log("Index ", i, " name", name, " price", price)
    // console.log("Name",_name,"VALUE",_value, "TYPE",typeof(_value))
    // if (typeof (value) == 'number') {
    //   toString(value)
    // }
    // console.log("Name 2",_name,"VALUE 2",_value,"TYPE 2",typeof(_value))
    if (name == 'precio') {
      if (!value?.trim()) {
        errors['tablePrice' + i] = 'El Precio es requerido'
        // errors['tablePrice' + i] = ''
      } else if (!regexDecimal.test(value?.trim())) {
        errors['tablePrice' + i] = "El campo 'Precio' solo acepta números"
      } else if (parseFloat(value) < minLength.precio) {
        errors['tablePrice' + i] =
          'El Precio debe ser igual o mayor a ' + currency + minLength.precio
      } else {
        errors['tablePrice' + i] = ''
      }
    }
    if (name == 'stock') {
      // console.log('MI VALUEEEE', value)
      if (!toString(value).trim()) {
        errors['tableStock' + i] = 'El stock es requerido'
        // errors['tablePrice' + i] = ''
      } else if (!regexNumber.test(Number(value))) {
        errors['tableStock' + i] = "El campo 'Stock' solo acepta números"
      } else if (Number(value) < minLength.stock) {
        errors['tableStock' + i] =
          'El Precio debe ser igual o mayor a ' + currency + minLength.stock
      } else {
        errors['tableStock' + i] = ''
      }
    }
    if (errors['tablePrice' + i] == '') {
      delete errors['tablePrice' + i]
    }
    if (name === 'isStockIlimit' || errors['tableStock' + i] == '') {
      delete errors['tableStock' + i]
    }
    // //console.log("ERROR TABLE", errorsTable)
    return errors
  }

  return (
    <div className='w-fit md:min-w-full'>
      {viewModal && (
        <ModalMessage
          title={contentModal.title}
          text={contentModal.text}
          deniedBtn={false}
          textButton='Aceptar'
          linkgo={contentModal.linkgo}
          closeModal={(e) => closeModal(e)}
        />
      )}
      {showModalCropImage && (
        <ModalImageCrop
          resolution={resolution}
          aspectRatio={aspectRatio}
          type={'product'}
          index={''}
          setShowModalCropImage={setShowModalCropImage}
          image={''}
        />
      )}
      <NavigationTop />
      <NavigationSide />
      {loader ? (
        <div>Cargando...</div>
      ) : (
        <section className='p-6 pb-24  md:ml-40'>
          <div className='flex items-center gap-3'>
            <a href='/productos/' className='flex-grow'>
              <FontAwesomeIcon
                icon={faArrowLeft}
                className='font-semibold cursor-pointer hover:text-yellow-400 '
              />{' '}
              back
            </a>
            <a href='/productos/'>Cancelar</a>
            {changesValid ? (
              <div
                onClick={() => updateProductData(producto)}
                // className='p-2 px-4 font-semibold bg-yellow-400 rounded cursor-pointer'
                className={`p-2 px-4 text-white font-semibold rounded-xl cursor-pointer ${
                  Object.keys(errors).length === 0 &&
                  Object.keys(errorsVariations).length === 0 &&
                  Object.keys(errorsExtra).length === 0 &&
                  Object.keys(errorsTable).length === 0 &&
                  Object.keys(errorsTable).length === 0
                    ? ' bg-yellow-400'
                    : ' bg-gray-400'
                }`}
                style={{ background: '#8163FF' }}
              >
                Guardar
              </div>
            ) : (
              <div
                className='p-2 px-4 text-white font-semibold rounded-xl cursor-pointer'
                style={{ background: '#8163FF' }}
              >
                Guardar
              </div>
            )}
          </div>
          <label className='flex flex-col gap-1 mb-1 text-sm font-semibold my-2 max-w-5xl [54rem]  w-full tracking-tight text-gray-700'>
            <div className=''>Nombre del Producto</div>
            <input
              className='px-3 py-3 text-base border border-gray-400 rounded-lg appearance-none focus:outline-none focus:border-yellow-500'
              type='text'
              placeholder='Nombre de producto'
              value={producto.nombreproducto}
              onChange={(e) => onChange(e)}
              name='nombreproducto'
              onBlur={onBlur}
            />
          </label>
          {errors?.nombreproducto && (
            <p className='p-1 text-sm text-red-500'>{errors?.nombreproducto}</p>
          )}
          <section className='w-full flex flex-col mt-4 gap-4 md:flex-row md:justify-between'>
            <div className='flex flex-col gap-6 grow'>
              <div>
                <div className='mb-3 text-xs font-semibold text-gray-500 uppercase'>
                  informacion general
                </div>

                <textarea
                  className='form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none'
                  id='exampleFormControlTextarea1'
                  rows='3'
                  placeholder={producto.detalladoproducto}
                  value={producto.detalladoproducto}
                  name='detalladoproducto'
                  onChange={(e) => onChange(e)}
                  onBlur={onBlur}
                ></textarea>
                {errors?.detalladoproducto && (
                  <p className='p-1 text-sm text-red-500'>
                    {errors?.detalladoproducto}
                  </p>
                )}
              </div>
              <div className='p-4 bg-gray-200 rounded-xl'>
                <div className='flex flex-wrap items-start gap-2'>
                  <label className='flex flex-col mb-1 text-sm font-semibold tracking-tight text-gray-700 w-44 grow'>
                    Asignar categoria
                    <Select
                      placeholder='Categorias'
                      className='w-full mt-1'
                      closeMenuOnSelect={false}
                      styles={customStyles}
                      isMulti
                      onChange={(e) => changeCategories(e)}
                      options={optionsCategory}
                    />
                  </label>
                  <div className='self-stretch hidden mx-3 border border-l-gray-300 md:flex'></div>
                  <label className='flex flex-col gap-1 mb-1 text-sm font-semibold tracking-tight text-gray-700'>
                    <div className=''>Código de producto</div>
                    <input
                      className='px-3 py-3 text-base border border-gray-400 rounded-lg appearance-none focus:outline-none focus:border-yellow-500'
                      type='text'
                      placeholder='Nombre de categoria'
                      value={producto.codigoproducto}
                      onChange={(e) => onChange(e)}
                      name='codigoproducto'
                      onBlur={onBlur}
                    />
                    {errors?.codigoproducto && (
                      <p className='p-1 text-sm text-red-500'>
                        {errors?.codigoproducto}
                      </p>
                    )}
                  </label>
                  <UIinput
                    type='text'
                    label='Precio del producto'
                    className='w-2'
                    suffix={currency}
                    placeholder='precio'
                    value={producto.precio}
                    changePrice={(e) => onChange(e)}
                    nameInput='precio'
                    onBlur={onBlur}
                    // maxLength={5}
                    disabled={hasVariants}
                  />
                  {errors?.precio && (
                    <p className='p-1 text-sm text-red-500'>{errors?.precio}</p>
                  )}
                </div>
              </div>
              <div className='p-4 bg-gray-100 rounded-xl'>
                <div className='flex flex-row gap-2'>
                  <label className='flex flex-col w-full md:mr-10 md:w-96'>
                    <div>
                      <input
                        className='mr-2'
                        type='checkbox'
                        name='isProductStock'
                        checked={producto.isproductstock}
                        onChange={(e) => changeStockProduct(e)}
                        // disabled={hasVariants}
                      />
                      Este producto tiene stocks limitados
                    </div>

                    <input
                      className={`px-3 py-3 text-base border border-gray-400 rounded-lg appearance-none focus:outline-none focus:border-yellow-500 ${
                        isProductStock && 'opacity-40'
                      }`}
                      type='text'
                      placeholder='Stock'
                      value={producto.stock}
                      onChange={(e) => onChange(e)}
                      disabled={producto.isproductstock === true || hasVariants}
                      name='stock'
                      onBlur={onBlur}
                    />
                    {errors?.stock && (
                      <p className='p-1 text-sm text-red-500'>
                        {errors?.stock}
                      </p>
                    )}
                  </label>
                </div>
              </div>
              <div className='flex flex-row my-3 border border-gray-200'></div>
              {/* <VaritionsUpdater
                changeVariations={(e) => changeVariations(e)}
                variations={producto.variations}
              /> */}
              <VariationsUpdater1
                changeVariations={(e, i) => changeVariations(e, i)}
                variations={producto.variations}
                productvariations={producto.productvariations}
                errors={errorsVariations}
                setErrors={setErrorsVariations}
                minLengths={minLengths}
                hasVariants={hasVariants}
                combinaciones1={combinaciones1}
                setProducto={setProducto}
                producto={producto}
                truer={true}
              />
              <ExtrasUpdater
                changeExtras={(e, i) => changeExtras(e, i)}
                changeInputDecimal={(e, i) => changeInputDecimal(e, i)}
                extras={producto.extras}
                errors={errorsExtra}
                currency={currency}
              />
              {/* pr */}
              <VariantsTable
                changeInputDecimal={(e, i) => changeInputDecimal(e, i)}
                errors={errorsTable}
                setErrors={setErrorsTable}
                validateTable={validateTable}
                combinaciones1={combinaciones1}
                onChangeVariations={onChangeVariations}
                producto={producto}
                setProducto={setProducto}
                productVar={producto.productvariations}
                z
                truer
              />
            </div>
            <div className='flex flex-col items-start gap-3 grow'>
              <div className='min-w-max max-w-xs w-full'>
                <div className='mb-3 text-xs font-semibold text-gray-500 uppercase'>
                  informacion general
                </div>
                <div className='w-full max-w-xs overflow-hidden bg-gray-200 rounded-xl'>
                  <ImageUploader
                    imagesProduct={producto.image ? producto.image : []}
                    setShowModalCropImage={setShowModalCropImage}
                    setImageList={(el) => changeListImage(el)}
                    nameFile='imagenproducto'
                    image={producto.imagenproducto}
                    changeImgURL={changeImgURL}
                  />
                </div>
              </div>
            </div>
          </section>
        </section>
      )}
    </div>
  )
}
export default Producto
