import Swal from 'sweetalert2'

export const Alert = ({ text }) => {
  // ddd
  Swal.fire({
    title: 'Credenciales invalidas',
    text,
    icon: 'error',

    confirmButtonText: 'Aceptar',
    confirmButtonColor: '#FFF544',

    width: '400px',
    timer: 10000,
    timerProgressBar: true,
  })
}
