import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrashAlt, faTrash } from "@fortawesome/free-solid-svg-icons"
import DatetimeRange from './DatetimeRange'
import InputLabel from './InputLabel'
import InputChecked from './InputChecked'
import { benefit, applyto } from '../../../../utils/constants/couponData'
import ButtonPurple from './ButtonPurple'

function NewCoupon({
    toListCoupon = () => { },
    isVisibleModal = () => { },
    nameByApplyTo = '',
    onChange = () => { },
    onChangeDate = () => { },
    dataCoupon = {},
    datetimePicker = {},
    deleteItem = () => { },
    saveCoupon = () => { } }) {
    return (
        <div className='w-full flex flex-col gap-10 px-3 md:px-1'>
            <div className='flex gap-5 justify-end'>
                <button className='py-2 px-4 rounded-2xl border-2 text-red-600' onClick={() => toListCoupon(true)}>Cancelar</button>
                <button className='py-2 px-4 rounded-2xl' style={{ background: '#00FBC8' }} onClick={() => saveCoupon()}>Guardar cambios</button>
            </div>
            <div className='w-full flex gap-10'>
                <div className='w-1/2 flex flex-col gap-10'>
                    <InputLabel
                        labelName={'Nombre'}
                        name='name'
                        value={dataCoupon.name}
                        onChange={onChange}
                    />
                    <InputChecked
                        label='Tipo de cupón'
                        arrayData={benefit}
                        value={dataCoupon.benefit}
                        onChange={onChange}
                    />
                    <InputLabel
                        labelName={'Monto del cupón'}
                        name='value'
                        value={dataCoupon.value}
                        onChange={onChange}
                        disabled={dataCoupon.benefit === 'freeshipping'}
                    />
                    <InputLabel
                        labelName={'Cantidad Disponible'}
                        name='stock'
                        value={dataCoupon.stock}
                        onChange={onChange}
                    />
                    <DatetimeRange
                        onChange={onChange}
                        onChangeDate={onChangeDate}
                        datetimePicker={datetimePicker}
                    />
                </div>
                <div className='w-1/2 flex flex-col gap-10'>
                    <InputChecked
                        label='Aplica a'
                        arrayData={applyto}
                        type='radio'
                        onChange={onChange}
                        value={dataCoupon.applyto}
                    />
                    {(dataCoupon.applyto === 'specificproduct' || dataCoupon.applyto === 'specificcategory') &&
                        <>
                            <div className='min-w-max w-full pl-16'>
                                <ButtonPurple
                                    text={dataCoupon.applyto === 'specificproduct' ? 'Agregar productos específicos'
                                        : dataCoupon.applyto === 'specificcategory' ? 'Agregar categorías específicas'
                                            : ''}
                                    onClick={isVisibleModal}
                                />
                            </div>
                            <div className='max-w-[450px] flex flex-col'>
                                <h3 className='p-4 rounded-xl' style={{ background: '#F0F0F0' }}> Todos los productos ({dataCoupon[nameByApplyTo].length})</h3>
                                <div className="w-full flex flex-col gap-8 py-2 px-4 h-[450px] overflow-x-auto">
                                    {
                                        dataCoupon[nameByApplyTo]?.map((item, index) =>
                                            <div className="flex justify-between items-center gap-5" key={index + 'product' + item.id}>
                                                <div className="flex items-center gap-5">
                                                    {
                                                        dataCoupon.applyto === 'specificproduct' &&
                                                        < img
                                                            className="w-20 h-auto"
                                                            src={item.imagenproducto}
                                                            alt={item.nombreproducto}
                                                        />
                                                    }
                                                    <label className="text-lg font-semibold">
                                                        {dataCoupon.applyto === 'specificproduct'
                                                            ? item.nombreproducto
                                                            : item.nombrecategoria}
                                                    </label>
                                                </div>
                                                <div >
                                                    <FontAwesomeIcon
                                                        className='hover:text-red-500 px-2 cursor-pointer'
                                                        onClick={() => deleteItem(item, nameByApplyTo)}
                                                        icon={faTrashAlt}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
        </div >
    )
}

export default NewCoupon