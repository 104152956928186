const Country = (props) => {
   return (
      <div className='flex flex-col w-full md:w-3/6 Zbg-yellow-400'>
         <div className='font-bold'>{props.country.name}</div>
         <div className='flex flex-col gap-4 p-5 Zpr-10 h-96 overflow-auto bg-gray-200 rounded-lg'>
            {
               props.listOneDivision?.map((elOne, iOne) => {
                  return (
                     <div className='flex flex-col gap-5 Zbg-green-300' key={iOne + '_Div' + elOne.name}>
                        <div className='flex gap-2 px-3 py-2 bg-white rounded-lg' key={iOne + '_' + elOne.name}>
                           {
                              (props.openOneDivision['open' + elOne.name] && props.country.nroDivision == 2) && (
                                 <input
                                    className='bg-yellow'
                                    type='checkbox'
                                    name={'chk1' + elOne.name.trim() + '_ID' + elOne._id}
                                    value={props.chkOneDivision['chk1' + elOne.name.trim() + '_ID' + elOne._id]}
                                    checked={props.chkOneDivision['chk1' + elOne.name.trim() + '_ID' + elOne._id]}
                                    onChange={(e) =>
                                       props.changeCheckOneDivision('chk1' + elOne.name.trim() + '_ID' + elOne._id, elOne.id, props.listTwoDivision, e)
                                    }
                                 />
                              )
                           }
                           <button
                              className='font-bold'
                              onClick={() =>
                                 props.seeTwoDivision('open' + elOne.name)
                              }
                           >
                              {elOne.name} {props.openOneDivision['open' + elOne.name] ? '-' : '+'}
                           </button>
                        </div>
                        {
                           props.openOneDivision['open' + elOne.name] && (
                              <div className='flex flex-col gap-2 px-3 Zbg-yellow-300' key={iOne + '_OpenTwo' + elOne.name}>
                                 {
                                    props.listTwoDivision?.map((elTwo, iTwo) => {
                                       if (elOne.id == elTwo.onedivision.id) {
                                          return (
                                             <div className='flex flex-col' key={iTwo + '_Div' + elTwo.name}>
                                                <div className={`flex gap-2 px-3 py-2 bg-white rounded-lg ${props.isOnlyPrice ? ''
                                                   : props.isDisabled['isDisabled' + elTwo._id.trim()] && 'opacity-50'}`}
                                                   key={iTwo + '_' + elTwo.name}
                                                >
                                                   {
                                                      (props.country.nroDivision == 2 || props.openTwoDivision['open' + elTwo.name]) && (
                                                         <input
                                                            className='bg-yellow'
                                                            type='checkbox'
                                                            name={'chk2' + elTwo.name.trim() + '_ID' + elTwo._id}
                                                            value={
                                                               props.isOnlyPrice ?
                                                                  props.chkTwoDivision['chk2' + elTwo.name.trim() + '_ID' + elTwo._id]
                                                                  : !props.isDisabled['isDisabled' + elTwo._id.trim()] && props.chkTwoDivision['chk2' + elTwo.name.trim() + '_ID' + elTwo._id]
                                                            }
                                                            checked={
                                                               props.isOnlyPrice ?
                                                                  props.chkTwoDivision['chk2' + elTwo.name.trim() + '_ID' + elTwo._id]
                                                                  : !props.isDisabled['isDisabled' + elTwo._id.trim()] && props.chkTwoDivision['chk2' + elTwo.name.trim() + '_ID' + elTwo._id]
                                                            }
                                                            onChange={(e) => {
                                                               props.changeCheckTwoDivision('chk2' + elTwo.name.trim() + '_ID' + elTwo._id, elTwo.id, props.listThreeDivision, e)
                                                            }}
                                                            disabled={props.isOnlyPrice ? false
                                                               : props.isDisabled['isDisabled' + elTwo._id.trim()]}
                                                         />
                                                      )
                                                   }
                                                   {
                                                      props.country.nroDivision == 2 ? (
                                                         <div className='Zfont-semibold'>{elTwo.name}</div>
                                                      ) : props.country.nroDivision == 3 ? (
                                                         <button
                                                            className='font-semibold'
                                                            onClick={() =>
                                                               props.seeThreeDivision('open' + elTwo.name)}
                                                         >
                                                            {elTwo.name} {
                                                               props.openTwoDivision['open' + elTwo.name] && props.listThreeDivision.length > 0 ? '-' :
                                                                  !props.openTwoDivision['open' + elTwo.name] && props.listThreeDivision.length > 0 ? '+' :
                                                                     ''
                                                            }
                                                         </button>
                                                      ) : null
                                                   }
                                                </div>
                                                {
                                                   props.openTwoDivision['open' + elTwo.name] && (
                                                      <div className='flex flex-col Zbg-blue-300' key={iTwo + '_OpenThree' + elTwo.name}>
                                                         {
                                                            props.listThreeDivision?.map((elThree, iThree) => {
                                                               if (elTwo.id == elThree.twodivision.id) {
                                                                  return (
                                                                     <div
                                                                        className={`flex gap-2 px-3 py-0.5 bg-white ${props.isOnlyPrice ? ''
                                                                           : props.isDisabled['isDisabled' + elThree._id.trim()] && 'opacity-50'}`}
                                                                        key={iThree + '_' + elThree.name}>
                                                                        <input
                                                                           className='bg-yellow'
                                                                           type='checkbox'
                                                                           name={'chk3' + elThree.name.trim() + '_ID' + elThree._id}
                                                                           value={props.isOnlyPrice ?
                                                                              props.chkThreeDivision['chk3' + elThree.name.trim() + '_ID' + elThree._id]
                                                                              : !props.isDisabled['isDisabled' + elThree._id.trim()] && props.chkThreeDivision['chk3' + elThree.name.trim() + '_ID' + elThree._id]
                                                                           }
                                                                           checked={props.isOnlyPrice ?
                                                                              props.chkThreeDivision['chk3' + elThree.name.trim() + '_ID' + elThree._id]
                                                                              : !props.isDisabled['isDisabled' + elThree._id.trim()] && props.chkThreeDivision['chk3' + elThree.name.trim() + '_ID' + elThree._id]
                                                                           }
                                                                           onChange={() => { props.changeCheckThreeDivision('chk3' + elThree.name.trim() + '_ID' + elThree._id) }}
                                                                           disabled={props.isOnlyPrice ? false
                                                                              : props.isDisabled['isDisabled' + elThree._id.trim()]}
                                                                        />
                                                                        <div className='Zfont-semibold'>{elThree.name}</div>
                                                                     </div>
                                                                  )
                                                               }
                                                            })
                                                         }
                                                      </div>
                                                   )
                                                }
                                             </div>
                                          )
                                       }
                                    })
                                 }
                              </div>
                           )
                        }
                     </div>
                  )
               })
            }
         </div>
      </div>
   )
}

export default Country