import React from 'react'
import { NavLink } from 'react-router-dom'
import axios from 'axios'
import { UserContext } from '../context/UserContext'

export default class NavigationCategories extends React.Component {
  static contextType = UserContext

  constructor(props) {
    super(props)
    this.state = {
      categories: [],
    }
  }

  componentDidMount() {
    const user = this.context.user

    let config = {
      method: 'get',
      url:
        `https://ventafazil-strapi-production.up.railway.app/categorias/?cliente=` +
        user,
      headers: {},
    }
    axios(config)
      .then((response) => {
        //console.log(response.data)
        this.setState({ categories: response.data })
      })
      .catch((error) => {
        //console.log(error)
      })
  }

  render() {
    return (
      <div className='flex flex-row items-start justify-start no-scrollbar w-full gap-12 py-4 px-10 overflow-x-auto text-sm font-semibold rounded-lg '>
        <a
          exact
          // activeClassName='ext-black border-b-2 border-yellow-400 font-bold '
          href={`/productos/`}
          className='text-gray-400 capitalize transition shrink-0 hover:text-yellow-500'
        >
          {' '}
          Ver Todas{' '}
        </a>
        {this.state.categories.map((category, index) => {
          return (
            <a
              exact
              activeClassName='text-black border-b-2 border-yellow-400 font-bold '
              key={index}
              href={`/productos/${category.id}`}
              className='py-1 tracking-tighter text-gray-400 capitalize transition shrink-0 hover:text-yellow-500'
            >
              {category.nombrecategoria}{' '}
            </a>
          )
        })}
      </div>
    )
  }
}
