import TimePickerContainer from './TimePickerContainer';
import DatePickerContainer from './DatePickerContainer';

function DatetimeRange({ onChange = () => { }, onChangeDate = () => { }, datetimePicker = {} }) {
    return (
        <div className='flex flex-col gap-16'>
            <div className='flex flex-col gap-8'>
                <p>Rango de Fecha disponible</p>
                <DatePickerContainer
                    startDate={datetimePicker.startDate}
                    endDate={datetimePicker.endDate}
                    onChangeDate={onChangeDate}
                />
            </div>
            <div className='flex flex-col gap-4'>
                <p>Horario</p>
                <div className='flex flex-col gap-3 px-2'>
                    <p className='font-semibold'>Formato de 12 horas</p>
                    <TimePickerContainer
                        datePeriod='Inicio'
                        nameHours='startHours'
                        nameMinutes='startMinutes'
                        namePeriod='startPeriod'
                        valueHours={datetimePicker.startHours}
                        valueMinutes={datetimePicker.startMinutes}
                        valuePeriod={datetimePicker.startPeriod}
                        labelNameHours='Horas'
                        labelNameMinutos='Minutos'
                        onChange={onChange}
                    />
                    <TimePickerContainer
                        datePeriod='Fin'
                        nameHours='endHours'
                        nameMinutes='endMinutes'
                        namePeriod='endPeriod'
                        valueHours={datetimePicker.endHours}
                        valueMinutes={datetimePicker.endMinutes}
                        valuePeriod={datetimePicker.endPeriod}
                        labelNameHours='Horas'
                        labelNameMinutos='Minutos'
                        onChange={onChange}
                    />
                </div>
            </div>
        </div>
    )
}

export default DatetimeRange