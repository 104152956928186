export const tableHeaders = [
    'Nombre',
    'Monto',
    'Tipo de cupón',
    'Aplica a',
    'Activo/Inactivo',
    'Stock',
    '',
    ''
]

export const benefit = [
    {
        id: 1,
        text: 'Porcentaje',
        name: 'benefit',
        value: 'percentage'
    },
    {
        id: 2,
        text: 'Monto fijo',
        name: 'benefit',
        value: 'discount'
    },
    {
        id: 3,
        text: 'Free Shipping',
        name: 'benefit',
        value: 'freeshipping'
    }
]

export const applyto = [
    {
        id: 1,
        text: 'Todo el carrito ',
        name: 'applyto',
        value: 'wholecart'
    },
    {
        id: 2,
        text: 'Productos en específicos',
        name: 'applyto',
        value: 'specificproduct'
    },
    {
        id: 3,
        text: 'Categorias en  específicos',
        name: 'applyto',
        value: 'specificcategory'
    }
]

export const selectHours = [
    '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'
];

export const selectMinutes = [
    '00', '15', '30', '45'
];

export const selectPeriod = [
    'AM', 'PM'
];