import axios from 'axios'
import { useState } from 'react'
import { getProductAPI } from './GetProduct'
import Swal from 'sweetalert2'

export const verifyStock = async (productosVendidos) => {
  const stock = productosVendidos
  // const cuponId = stock.id
  // const cuponStock = stock.amount
  let data = []

  for (let i = 0; i < stock.length; i++) {
    const x = stock[i]

    // stock.forEach(async (x, i) => {
    const productid = x.id

    const res = await getProductAPI(productid)

    // getProductAPI(productid).then((res) => {
    //console.log('esto es x', x)
    //console.log(x.max)
    //console.log(x.amount)
    //console.log(res.stock)
    //console.log(x.variation)
    if (x.variation === null || x.variation === undefined) {
      //console.log('MAX', x.max)
      //console.log('AMOUNT', x.amount)

      if (parseInt(res.stock) < x.amount) {
        //console.log('UPS HUBO')
        data.push(x.nombreproducto)
      }
    } else if (Object.entries(x.variation).length === 0) {
      //console.log(Object.entries(x.variation))

      if (parseInt(res.stock) < x.amount) {
        //console.log('UPS HUBO UN PROBLEMA111', x.nombreproducto)
        data.push(x.nombreproducto)
        //console.log('data', data)
      }
    } else {
      const resp = res.productvariations.map((item, index) => {
        //console.log('ITEM ANTER', item)
        if (JSON.stringify(item.options) === JSON.stringify(x.variation)) {
          //console.log('MU ITEM', item)
          //console.log('XXX', x)
          //console.log('entramos aqui1233')

          const newNombre =
            x.nombreproducto + ' ' + Object.values(x.variation).join('/')
          let newStock = x.max - x.amount
          //console.log(newNombre)

          if (parseInt(x.max) < x.amount) {
            //console.log('UPS HUBO FF')
            //console.log(newNombre)

            data.push(newNombre)
          }
        } else {
          return item
        }
      })
    }
  }

  // await new Promise((resolve) => setTimeout(resolve, 2500))

  return data
}

// .finally(() => {
//   //console.log('stock', stock.length)
//   //console.log('III', i)
//   //console.log(x.nombreproducto)

//   //console.log('SUCCESS', data)
// })
//
