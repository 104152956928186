import React from 'react'

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts'
const Bar3D = ({ data, x, y, startDate, startDate1 }) => {
  return (
    <ResponsiveContainer width='98%' height={300}>
      <BarChart
        data={data}
        margin={{
          top: 50,
        }}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey={x} />
        <YAxis allowDecimals={false} />
        <Tooltip />
        {/* <Legend /> */}

        <Bar dataKey={y} fill='#A38DFF' barSize={40} />
      </BarChart>
    </ResponsiveContainer>
  )
}

export default Bar3D
