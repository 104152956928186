import React from 'react'
import { faArrowLeft, faPrint } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import { Link } from 'react-router-dom'
import NavigationSide from '../components/NavigationSide'
import NavigationTop from '../components/NavigationTop'
import UIBackofficeProductCell from '../components/UIBackofficeProductCell'
import { currencyFromStrapi } from '../utils/utils'
import Select from 'react-select'
import {
  estadoPago,
  estadoPago1,
  estadoventa1,
  estadoPago2,
} from '../utils/data'
import { PutVentasAPI } from '../utils/PutVentas'
import Swal from 'sweetalert2'
import { useHistory } from 'react-router-dom'
import { PostHistoriaAPI } from '../utils/PostHistorials'
import Modal from '../components/Modal'
import Reembolsar from './Reembolsar'
import { CurrencyContext } from '../context/CurrencyContext'

class Venta extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      venta: [
        {
          estado: 'aceptado',
          productos: [
            {
              _id: '62323174f106470016227617',
              imagenproducto:
                'https://images.pexels.com/photos/302896/pexels-photo-302896.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
              nombreproducto: '2Specialty Coffee Yana Muru House Blend ',
              categoriaproducto: 'Cafe Molido',
              precio: '22.90',
              codigoproducto: 'SCYMHB',
              detalladoproducto:
                'Cafe de Especialidad Notas a Cascara de Mandarina, con fondo a Chocolate Acidez Suave | Cuerpo Medio Puntaje en Taza: 81 pts. Origen: Cajamarca, San Martin, Ayacucho. Variedad: Catimor, Catuai. Elevación: 1200 msnm. Secado: FitoToldo. Control de Calidad y Catado por:  Gracia Maria Ampuero Estremadoyro Lic Q-Grader.',
              published_at: '2022-03-16T18:50:28.448Z',
              createdAt: '2022-03-16T18:50:28.452Z',
              updatedAt: '2022-03-29T05:03:16.431Z',
              __v: 0,
              stock: '5',
              variables: 'café villarica',
              cliente: null,
              id: '62323174f106470016227617',
            },
          ],
          _id: '624f90177627bb00166b4a30',
          fecha: '07/04/22',
          balance: '50.00',
          clientefinal: 'clientepreuba',
          published_at: '2022-04-08T01:30:10.183Z',
          createdAt: '2022-04-08T01:29:59.601Z',
          updatedAt: '2022-04-08T01:51:34.560Z',
          __v: 0,
          cliente: {
            _id: '624733539b4ad700169c7e4b',
            Nombre: 'SIlke Yanetttt',
            apelido: 'HUAMANI URQUIZO',
            Ntorazonsocial: 'SILKE SAC',
            direccionfiscal: 'calle los higos 163',
            region: 'LIMA',
            telefono: '981349877',
            numerodedocumento: '74323606',
            ciudad: 'LIMA',
            correoelectronico: 'atencion.alcliente.izipay@gmail.com',
            tipodedocumento: 'DNI',
            confirmed: true,
            published_at: '2022-04-01T17:16:03.024Z',
            createdAt: '2022-04-01T17:16:03.031Z',
            updatedAt: '2022-04-08T00:32:14.826Z',
            __v: 0,
            culqicard: 'crd_live_1rgkYdKvLJCqOOyu',
            culqiclient: 'cus_live_UuB6oTWGkcMMBaA0',
            culqiplan: 'pln_live_t6IYGKhROml1W00l',
            culqisub: 'sub_live_1Z0oCMAwgj4vbYdf',
            id: '624733539b4ad700169c7e4b',
          },
          nombredirecciontelefono: [
            {
              nombre: 'Sergio Santana',
              direccion: 'SJM',
              telefono: '986614957',
            },
          ],
          id: '624f90177627bb00166b4a30',
        },
      ],
      metadata: {},
      productos: [],
      loader: true,
      // currency: JSON.parse(localStorage.user).currencyvalue.split(',')[0],
      selectEstadoVenta: '',
      comment: [],
      isreembolsado: false,
      reembolsado: {},
      isModal: false,
    }
  }

  componentDidMount() {
    let config = {
      method: 'get',
      url: `https://ventafazil-strapi-production.up.railway.app/ventas/${this.props.match.params.ventaid}`,
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_KEY_STRAPI}`,
      },
    }
    axios(config)
      .then((response) => {
        //console.log(response.data)
        this.setState({ venta: response.data })
        this.setState({ metadata: response.data.metadata[0] })
        this.setState({ productos: response.data.productos })
        this.setState({ loader: false })
        this.setState({ estadoventa: response.data.estadoventa })
        this.setState({ estado: response.data.estado })

        this.setState({
          comment: response.data.comment ? response.data.comment : [],
        })
        this.setState({ isreembolsado: response.data.isreembolsado })

        this.setState({ reembolsado: response.data.reembolsado })
        this.setState({ isModal: false })
      })
      .catch((error) => {
        //console.log(error)
      })
  }
  dateSale(date) {
    let newdate = new Date(date)
    let a =
      newdate.getDate() +
      '/' +
      (newdate.getMonth() + 1) +
      '/' +
      newdate.getFullYear()
    return a
  }

  handleChange(event, index) {
    const commentarios = this.state.comment

    commentarios[index].name = event.target.value
    this.setState({ commentarios })
  }

  render() {
    //console.log('PRU ESTADO', this.state.estado)
    const handleSelectChange = (e) => {
      //console.log('event', e.value)
      this.setState({ estadoventa: e.value })

      // setSelectEstadoVenta(e.value)
    }
    const handleSelectChange1 = (e) => {
      //console.log('event', e.value)
      if (e.value === 'aceptado') {
        this.setState({ reembolsado: {} })
        this.setState({ estado: e.value })
      }

      // setSelectEstadoVenta(e.value)
    }
    const isModalFalse = (e) => {
      this.setState({ isModal: false })
    }

    const guardarPedido = async () => {
      if (this.state.estado === 'aceptado') {
        //console.log('entramos')
        this.setState({ isreembolsado: false }, () => {
          ApiPutVenta()
        })
      } else {
        ApiPutVenta()
      }
    }

    const ApiPutVenta = async () => {
      await PutVentasAPI(
        this.props.match.params.ventaid,
        this.state.estadoventa,
        this.state.comment,
        this.state.isreembolsado,
        this.state.reembolsado,
        this.state.estado
      )
      let user = JSON.parse(localStorage.user)

      let datasET = {}
      datasET.description = 'Se edito un pedido'
      datasET.estado = this.state.estadoventa
      datasET.estadopdo = this.state.estado
      datasET.idpedido = this.props.match.params.ventaid
      datasET.usuario = JSON.parse(
        localStorage.getItem('user')
      ).correoelectronico

      //console.log(datasET)
      PostHistoriaAPI(datasET)

      Swal.fire('Se edito la venta  ', '', 'success')
      this.props.history.push({
        pathname: `/ventas`,
      })
    }

    const customStyles = {
      control: (base, state) => ({
        ...base,
        background: '#EAB308',
        // match with the menu
        borderRadius: state.isFocused ? '3px 3px 0 0' : 3,
        // Overwrittes the different states of border
        borderColor: state.isFocused ? 'yellow' : 'green',
        // Removes weird border around container
        boxShadow: state.isFocused ? null : null,
        '&:hover': {
          // Overwrittes the different states of border
          borderColor: state.isFocused ? 'red' : 'blue',
        },
      }),
      menu: (base) => ({
        ...base,
        // override border radius to match the box
        borderRadius: 0,
        // kill the gap
        marginTop: 0,
      }),
      menuList: (base) => ({
        ...base,
        // kill the white space on first and last option
        padding: 0,
      }),
    }
    // const history = useHistory()
    const sumTotal = () => {
      const maprr = this.state.metadata.detailProducts.map((x) => {
        const amount = x.amount || 1
        return x.precio * amount
      })

      return Number(maprr.reduce((a, b) => Number(a) + Number(b), 0))
    }

    const addComent = () => {
      var defaultComment = {
        name: '',
      }
      //console.log([...this.state.comment, defaultComment])
      this.setState({ comment: [...this.state.comment, defaultComment] })
    }

    const deleteProduct = (index) => {
      const updatedItems = [...this.state.comment]
      updatedItems.splice(index, 1)

      this.setState({
        comment: updatedItems,
      })

      // setItems(updatedItems)
    }
    let user = JSON.parse(localStorage.getItem('user'))

    return (
      <div className='w-fit lg:w-full pb-28'>
        <NavigationTop />
        <NavigationSide />
        <section className='p-4 pb-24 md:px-20 md:py-6 md:ml-28 '>
          <div className='flex justify-between mb-2'>
            <a href={'/ventas/'} className='flex items-center gap-3'>
              <FontAwesomeIcon
                icon={faArrowLeft}
                className='font-semibold cursor-pointer hover:text-yellow-400 '
              />
              Detalle del pedido
            </a>
            <button
              className='p-2 px-10 text-sm font-bold text-center bg-yellow-400 rounded-md '
              onClick={guardarPedido}
            >
              Guardar Pedido
            </button>
          </div>

          {this.state.loader ? (
            <></>
          ) : (
            <>
              <section className='flex gap-8 md:gap-12 md:mx-16 '>
                <div className=' w-3/5  border border-gray-300 py-4 px-2 rounded-md'>
                  <div className='text-center '>
                    <Select
                      className='w-44  m-auto py-2 focus:border-yellow-500 '
                      closeMenuOnSelect={true}
                      options={estadoPago1}
                      onChange={handleSelectChange1}
                      styles={customStyles}
                      value={{
                        value: this.state.estado,
                        label: this.state.estado,
                      }}
                    />

                    <h1>Orden #{this.state.venta._id} </h1>
                    <p>
                      {' '}
                      {new Date(this.state.venta.createdAt).toLocaleDateString(
                        'ES'
                      )}{' '}
                      {new Date(this.state.venta.createdAt).toLocaleTimeString(
                        'en-US',
                        {
                          hour: 'numeric',
                          minute: 'numeric',
                        }
                      )}{' '}
                    </p>
                  </div>
                  <div>
                    <h2>Detalle del pedido </h2>
                    {this.state.metadata.detailProducts.map(
                      (producto, index) => {
                        return (
                          <UIBackofficeProductCell
                            amount={producto.amount}
                            key={index}
                            index={index}
                            nombre={producto.nombreproducto}
                            id={producto.id}
                            img={producto.imagenproducto}
                            precio={producto.precio}
                            codigoproducto={producto.codigoproducto}
                            createdAt={producto.createdAt}
                            stock={producto.stock}
                            extras={producto.extras}
                            variation={producto.variation}
                            currency={this.state.currency}
                          />
                        )
                      }
                    )}
                    {this.state.metadata?.coupon?.benefit ? (
                      <div className='flex flex-col items-center justify-start gap-3 p-4 text-sm text-red-600 border-b border-gray-300 md:flex-row '>
                        <div className='flex flex-col '>
                          <div className='font-semibold'>
                            DESCUENTO APLICADO{''}
                            {this.state.metadata?.coupon?.benefit ===
                              'percentage' && (
                              <div className=''>
                                Porcentaje {this.state.metadata?.coupon?.value}{' '}
                                %
                              </div>
                            )}
                          </div>
                        </div>

                        {this.state.metadata?.coupon?.benefit !==
                        'freeshipping' ? (
                          <div className='flex justify-end flex-grow gap-1 text-2xl text-red-600 tracking-tighter text-right'>
                            {Number(
                              currencyFromStrapi(String(this.state.venta.total))
                            ) -
                              (sumTotal() +
                                Number(this.state.metadata?.delivery?.price))}
                            {/* {this.state.metadata.delivery.price} */}
                          </div>
                        ) : (
                          <div className='flex justify-end flex-grow gap-1 text-2xl text-red-600 tracking-tighter text-right'>
                            DELIVERY GRATIS
                          </div>
                        )}
                      </div>
                    ) : (
                      ''
                    )}

                    {this.state.metadata?.delivery?.price ? (
                      <div className='flex flex-col items-center justify-start gap-3 p-4 text-sm text-gray-600 border-b border-gray-300 md:flex-row '>
                        <div className='flex flex-col '>
                          <div className='font-semibold'>
                            Costo por delivery
                          </div>
                        </div>
                        <div className='flex justify-end flex-grow gap-1 text-2xl tracking-tighter text-right'>
                          <div className='text-sm'>{this.state.currency} </div>
                          {this.state.metadata?.delivery?.price}
                        </div>
                      </div>
                    ) : (
                      ''
                    )}

                    <div className='flex flex-row p-4'>
                      <div className='flex text-3xl font-bold tracking-tifght grow'>
                        Total
                      </div>
                      <div className='flex flex-row gap-2 text-5xl font-semibold tracking-tighter'>
                        <div className='text-lg'>{this.state.currency} </div>
                        <div className='text-4xl'>
                          {currencyFromStrapi(String(this.state.venta.total))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='text-center '>
                    {/* <button className='bg-yellow-500 p-1 rounded-md w-44 text-center'>
                      Por atender1
                    </button> */}
                    <Select
                      className='w-44  m-auto py-2 focus:border-yellow-500 '
                      closeMenuOnSelect={true}
                      options={estadoventa1}
                      onChange={handleSelectChange}
                      styles={customStyles}
                      value={{
                        value: this.state.estadoventa,
                        label: this.state.estadoventa,
                      }}
                    />
                  </div>
                </div>

                <div className=' w-2/5 flex flex-col gap-3 px-2 md:max-w-sm'>
                  <div className='border-gray-300 border h-2/4 rounded-md Ztext-center pt-5 flex flex-col items-center justify-center px-2'>
                    <h1 className='font-bold'>Información del cliente</h1>
                    <div className='text-sm px-10 md:px-0'>
                      <div className=''>
                        {this.state.metadata.name} {this.state.metadata.surname}
                      </div>
                      <div className=''> {this.state.metadata.email}</div>

                      <div className=''> {this.state.metadata.mobile}</div>

                      <div className=''>
                        Direccion:{' '}
                        {this.state.metadata.metodoEntrega === 'Delivery'
                          ? ` ${this.state.metadata?.addres} - ${this.state.metadata?.delivery.label} `
                          : `${this.state.metadata?.puntoRecojo?.direccion} - ${this.state.metadata?.puntoRecojo.place}`}
                      </div>
                      <div>
                        {this.state.metadata.metodoEntrega !== 'Delivery' &&
                          `Punto Recojo ${this.state.metadata.name}`}
                      </div>
                      <div>
                        {this.state.metadata.metodoEntrega === 'Delivery' &&
                          `Codigo Postal ${this.state.metadata.postalCode}`}
                      </div>
                    </div>
                  </div>
                  <div className='border-gray-300 border h-1/4 rounded-md flex items-center justify-center px-2'>
                    <div>
                      <h1 className='font-bold'>Metodo de Entrega</h1>
                      <div>{this.state.metadata.metodoEntrega}</div>
                    </div>
                  </div>
                  <div className='border-gray-300 border h-1/4 rounded-md flex items-center justify-center px-2'>
                    <div>
                      {' '}
                      <h1 className='font-bold '>Metodo de Pago</h1>
                      <div>Tarjeta de credito</div>
                    </div>
                  </div>
                  <a href={`/historial/${this.props.match.params.ventaid}`}>
                    <h1 className='p-2 px-10 text-sm font-bold text-center bg-yellow-400 rounded-md '>
                      Ver Historial
                    </h1>
                  </a>
                  <div>
                    {!this.state.isreembolsado && (
                      <Reembolsar
                        idPedido={this.props.match.params.ventaid}
                        estado={this.state.estadoventa}
                        estadopdo={this.state.estado}
                        usuario={
                          JSON.parse(localStorage.getItem('user'))
                            .correoelectronico
                        }
                        total={currencyFromStrapi(
                          String(this.state.venta.total)
                        )}
                        open={false}
                      />
                    )}
                    {/* {this.state.isModal && (
                      <Reembolsar
                        idPedido={this.props.match.params.ventaid}
                        estado={this.state.estadoventa}
                        estadopdo={this.state.estado}
                        usuario={
                          JSON.parse(localStorage.getItem('user'))
                            .correoelectronico
                        }
                        open={true}
                        isModalFalse={isModalFalse}
                      />
                    )} */}
                  </div>
                </div>
              </section>
              <section className='flex gap-12 md:mx-16 mt-4'>
                <section className='w-3/5 border border-gray-300 py-4 px-2 rounded-md'>
                  <div className=' flex justify-end '>
                    <p onClick={addComent} className='cursor-pointer'>
                      {' '}
                      AGREGAR COMENTARIO +
                    </p>
                  </div>
                  {this.state.comment &&
                    this.state.comment.map((comentario, i) => {
                      return (
                        <div
                          className='p-2 border border-blue-200 mb-2 flex '
                          key={i}
                        >
                          <input
                            type='text'
                            value={comentario.name}
                            className=' w-[calc(100%_-_1rem)] outline-none '
                            onChange={(event) => this.handleChange(event, i)}
                          />
                          <p
                            className='text-red-600 cursor-pointer'
                            onClick={() => deleteProduct(i)}
                          >
                            X
                          </p>
                        </div>
                      )
                    })}
                </section>
              </section>
            </>
          )}
        </section>
      </div>
    )
  }
}

export default Venta
