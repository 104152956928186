import TableCheckBox from "./TableCheckBox"
import TableActions from "./TableActions"
import { tableHeaders } from "../../../../utils/constants/couponData"
import ButtonPurple from "./ButtonPurple"

function Table({ toNewCoupon, tableBody, activateCoupon, actionUpdate, actionDelete }) {
    return (
        <div className="container mx-auto p-4 min-w-full lg:min-w-[750px] ">
            <div className="overflow-x-auto min-h-[300px] max-h-[500px]">
                <table className="min-w-full bg-white ">
                    <thead style={{ background: '#E6E6E6' }}>
                        <tr>
                            {tableHeaders.map((header, index) =>
                                <th
                                    className={`py-3 px-4 font-normal 
                                ${index === 0 ? 'rounded-l-2xl' : index === tableHeaders.length - 1 ? 'rounded-r-2xl' : ''}`}
                                    key={index + '_' + header}
                                >{header}</th>
                            )}
                        </tr>
                    </thead>
                    <tbody >
                        {
                            tableBody?.map((body, index) => {
                                return (
                                    <tr className='h-[90px]' key={index + '_' + body.name}>
                                        <td className="py-2 px-4 text-center">{body.name}</td>
                                        <td className="py-2 px-4 text-center">{body.value}</td>
                                        <td className="py-2 px-4 text-center">{body.benefit}</td>
                                        <td className="py-2 px-4 text-center">{body.applyto}</td>
                                        <td className="py-2 px-4 text-center">
                                            <TableCheckBox
                                                coupon={body}
                                                name={'chk' + body.name}
                                                value={body.isactive}
                                                activateCoupon={() => activateCoupon(body)}
                                            />
                                        </td>
                                        <td className="py-2 px-4 text-center">{body.stock}</td>
                                        <TableActions
                                            actionUpdate={() => actionUpdate(body)}
                                            actionDelete={() => actionDelete(body)}
                                        />
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
            <div className="flex justify-end mt-10">
                <ButtonPurple
                    text="Crear nuevo cupon"
                    onClick={toNewCoupon}
                />
            </div>
        </div>
    )
}

export default Table