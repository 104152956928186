import React, { useState, useEffect, useRef } from 'react'
import '@toast-ui/editor/dist/toastui-editor.css'

import { Editor } from '@toast-ui/react-editor'
import {
  GetTermsConditionsAPI,
  PutTermsConditionsAPI,
  PostTermsConditionsAPI,
} from '../../../utils/GetTermsConditions'
import Button1 from '../components/Button1'

export default function EditorTexto({ setLoading = () => { }, toastFire = () => { } }) {
  // get cliente from local storage as a variable
  const user = JSON.parse(localStorage.getItem('user'))
  const idcliente = user?.id

  const [markup, setMarkup] = useState('')
  const [markdownvalue, setMarkdownvalue] = useState('')
  const editorRef = useRef()
  const [id, setId] = useState('')

  useEffect(() => {
    GetTermsConditionsAPI(idcliente).then((data) => {
      if (data.length > 0) {
        setMarkup(data[0].texto)
        setId(data[0].id)
      } else {
        //console.log("inicia creación")
        PostTermsConditionsAPI().then((res) => {
          if (res) {
            PutTermsConditionsAPI(res.id, {
              texto: 'Nuevo texto de prueba',
              cliente: idcliente,
            }).then((data) => {
              //console.log("EDITO", data);
              setMarkup(data.texto)
              setId(data.id)
            })
          }
        })
      }
    })
  }, [id])

  function handleChange(e) {
    setMarkdownvalue(e)
    //console.log(markdownvalue);
  }

  function SubmitValue() {
    setLoading(true)
    var body = { texto: editorRef.current.getInstance().getMarkdown() }
    PutTermsConditionsAPI(id, body)
      .then((data) => {
        toastFire()
        // console.log(data);
      })
      .finally(() => {
        setLoading(false)
      })
  }

  function CreateNew(idcliente) {
    PostTermsConditionsAPI()
    var body = { texto: idcliente }
    setLoading(true)
      .then((data) => {
        toastFire()
        // console.log(data);
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <div className='flex flex-col gap-4' style={{}}>
      {markup !== '' ? (
        <Editor
          ref={editorRef}
          initialValue={markup}
          height='600px'
          value={markdownvalue}
          events={{ change: handleChange }}
          onChange={(e) => handleChange(e)}
          autofocus={false}
        />
      ) : (
        <div onClick={() => CreateNew}> Crear </div>
      )}
      <Button1
        textColor='#ffffff'
        background='#8163FF'
        text='Guardar términos y condiciones'
        onClick={SubmitValue}
      />
    </div>
  )
}