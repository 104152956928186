import NavigationTop from '../components/NavigationTop'
import NavigationSide from '../components/NavigationSide'
import { useContext, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { UserContext } from '../context/UserContext'
import Swal from 'sweetalert2'

const FormasEntrega = () => {
  const { user } = useContext(UserContext)

  const [listCountries, setListCountries] = useState([])
  const [country, setCountry] = useState({})
  const [chkCountry, setChkCountry] = useState({
    isPeru: false,
    isMexico: false
  })
  const [chkDeliveryMethod, setChkDeliveryMethod] = useState({
    isHomeDelivery: false,
    isPickUpInStore: false,
  })
  const [chkShippingPolicy, setChkShippingPolicy] = useState({
    isPriceOnly: false,
    isPricePerZone: false,
  })

  const changeCountry = (e) => {
    let _checked = e.target.checked
    let _name = e.target.name
    if (_name === 'Peru') {
      if (chkCountry.isPeru) return
      setChkCountry({ ...chkCountry, isPeru: _checked, isMexico: !_checked })
    } else if (_name == 'Mexico') {
      if (chkCountry.isMexico) return
      setChkCountry({ ...chkCountry, isMexico: _checked, isPeru: !_checked })
    }
    if (e.target.checked) {
      let _country = listCountries.filter((el) => el.name == _name)
      setCountry(_country[0])
    }
  }

  const changeDeliveryMethod = (e) => {
    let _checked = e.target.checked
    if (e.target.name === 'homeDelivery') {
      setChkDeliveryMethod({ ...chkDeliveryMethod, isHomeDelivery: _checked })
      if (!_checked) {
        setChkShippingPolicy({ isPriceOnly: false, isPricePerZone: false })
      }
    } else if (e.target.name == 'isPickUpStore') {
      setChkDeliveryMethod({ ...chkDeliveryMethod, isPickUpInStore: _checked })
    }
    if (!chkDeliveryMethod.isHomeDelivery) {
      setChkShippingPolicy({ isPriceOnly: false, isPricePerZone: false })
    }
  }

  const changeShippingPolicy = (e) => {
    let _checked = e.target.checked
    if (e.target.name === 'priceOnly') {
      if (chkShippingPolicy.isPriceOnly) return
      setChkShippingPolicy({
        ...chkShippingPolicy,
        isPriceOnly: _checked,
        isPricePerZone: !_checked,
      })
    } else if (e.target.name === 'pricePerZone') {
      if (chkShippingPolicy.isPricePerZone) return
      setChkShippingPolicy({
        ...chkShippingPolicy,
        isPricePerZone: _checked,
        isPriceOnly: !_checked,
      })
    }
  }

  const saveDelivery = () => {
    let config = {
      method: 'put',
      url:
        `https://ventafazil-strapi-production.up.railway.app/clientes/` + user,
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_KEY_STRAPI}`,
      },
      data: {
        paises: { _id: country._id },
        ishomedelivery: chkDeliveryMethod.isHomeDelivery,
        ispickupstore: chkDeliveryMethod.isPickUpInStore,
        isonlyprice: chkShippingPolicy.isPriceOnly,
        iszoneprice: chkShippingPolicy.isPricePerZone,
      },
    }

    axios(config)
      .then((response) => {
        if (response.status == 200) {
          localStorage.setItem('user', JSON.stringify(response.data))
          Swal.fire('Se guardo la forma de entrega ', '', 'success')
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getCountries = async () => {
    const url = 'https://ventafazil-strapi-production.up.railway.app/Countries'
    try {
      const res = await axios.get(url)
      const data = res.data
      if (res.status == 200) {
        setListCountries(data)
        fillCountries(data)
        setValuesCheckbox(data)
      }
    } catch (error) {
      console.log(error.response)
    }
  }

  const fillCountries = async (data) => {
    let _chkCountries = {}
    await data.forEach((el) => {
      _chkCountries['is' + el.name] = false
    })
    setChkCountry(_chkCountries)
  }

  const setValuesCheckbox = async (data) => {
    let _user = await JSON.parse(localStorage.getItem('user'))
    let _country = data.filter((el) => el.name == _user.paises.name)
    setCountry(_country[0])
    setChkCountry({ ...chkCountry, ['is' + _user.paises.name]: true })
    setChkDeliveryMethod({
      ...chkDeliveryMethod,
      isHomeDelivery: _user.ishomedelivery,
      isPickUpInStore: _user.ispickupstore,
    })
    setChkShippingPolicy({
      ...chkShippingPolicy,
      isPriceOnly: _user.isonlyprice,
      isPricePerZone: _user.iszoneprice,
    })
  }

  useEffect(() => {
    getCountries()
  }, [])

  return (
    <div className='pb-24 bg-gray-50'>
      <NavigationTop />
      <NavigationSide />
      <div className='Zbg-yellow-400 container py-8 2xl:px-28'>
        <div className='Zbg-green-300 container flex justify-between py-4 2xl:pr-20'>
          <h1 className='text-3xl font-bold tracking-tighter'>
            Formas de entrega
          </h1>
          <button
            className='py-2 px-10 font-bold text-center bg-yellow-400 rounded-md'
            onClick={saveDelivery}
          >
            Guardar
          </button>
        </div>
        <div id='formasEntrega' className='Zbg-red-400 container py-4'>
          <div className='flex gap-14 2xl:gap-20'>
            <div>
              <div className='mb-5'>
                1.<strong className='ml-3'>Elige tu País</strong>
              </div>
              <div className='flex flex-col gap-5 ml-5 py-6 w-56 bg-gray-200 rounded-lg'>
                {listCountries.map((el, i) => {
                  if (el.name === 'Mexico') {
                    return (
                      <label
                        className={`flex items-center ml-3 mr-5 w-48 h-8 bg-white rounded-lg ${chkCountry['is' + el.name] && 'bg-yellow-300'
                          }`}
                        key={'_' + i}
                      >
                        <input
                          className='ml-2 mr-8'
                          type='checkbox'
                          name={el.name}
                          value={chkCountry['is' + el.name]}
                          checked={chkCountry['is' + el.name]}
                          onChange={(e) => changeCountry(e)}
                        />
                        {el.name}
                      </label>
                    )
                  }
                })}
              </div>
            </div>
            <div>
              <div className='mb-5'>
                2.<strong className='ml-3'>Forma de entrega</strong>
              </div>
              <div className='flex flex-col gap-5 pl-5'>
                <div className='py-6 w-64 bg-gray-200 rounded-lg'>
                  <label
                    className={`flex items-center ml-3 mr-5 w-56 h-8 bg-white rounded-lg ${chkDeliveryMethod.isHomeDelivery && 'bg-yellow-300'
                      }`}
                  >
                    <input
                      className='ml-2 mr-8'
                      type='checkbox'
                      name='homeDelivery'
                      value={chkDeliveryMethod.isHomeDelivery}
                      checked={chkDeliveryMethod.isHomeDelivery}
                      onChange={(e) => changeDeliveryMethod(e)}
                    />
                    Envío a domicilio
                  </label>
                </div>
                <div className=' py-6 w-64 bg-gray-200 rounded-lg'>
                  <label
                    className={`flex items-center ml-3 mr-5 w-56 h-8 bg-white rounded-lg ${chkDeliveryMethod.isPickUpInStore && 'bg-yellow-300'
                      }`}
                  >
                    <input
                      className='ml-2 mr-8'
                      type='checkbox'
                      name='isPickUpStore'
                      value={chkDeliveryMethod.isPickUpInStore}
                      checked={chkDeliveryMethod.isPickUpInStore}
                      onChange={(e) => changeDeliveryMethod(e)}
                    />
                    Recojo en tienda
                  </label>
                </div>
              </div>
            </div>
            {chkDeliveryMethod.isHomeDelivery && (
              <div
                className={`flex flex-col gap-6 ${!chkDeliveryMethod.isHomeDelivery && 'font-sans'
                  }`}
              >
                <div className='flex flex-col'>
                  <div className=''>
                    2.1.<strong className='ml-3'>Políticas de envío</strong>
                  </div>
                  <div className='flex gap-10 py-4'>
                    <div className='p-4 w-60 rounded-lg bg-gray-200 '>
                      <label
                        className={`flex justify-center items-center px-4 h-8 bg-white rounded-lg ${chkShippingPolicy.isPriceOnly && 'bg-yellow-300'
                          } `}
                      >
                        <input
                          type={'checkbox'}
                          name='priceOnly'
                          value={chkShippingPolicy.isPriceOnly}
                          checked={chkShippingPolicy.isPriceOnly}
                          onChange={(e) => changeShippingPolicy(e)}
                          hidden
                        />
                        Precio único
                      </label>
                    </div>
                    <div className='p-4 w-60 rounded-lg bg-gray-200 '>
                      <label
                        className={`flex justify-center items-center px-4 h-8 bg-white rounded-lg ${chkShippingPolicy.isPricePerZone && 'bg-yellow-300'
                          }`}
                      >
                        <input
                          type={'checkbox'}
                          name='pricePerZone'
                          value={chkShippingPolicy.isPricePerZone}
                          checked={chkShippingPolicy.isPricePerZone}
                          onChange={(e) => changeShippingPolicy(e)}
                          hidden
                        />
                        Precio por zona
                      </label>
                    </div>
                  </div>
                </div>
                <div
                  className={`p-3 h-20 rounded-lg ${chkShippingPolicy.isPriceOnly ||
                    chkShippingPolicy.isPricePerZone
                    ? 'bg-gray-200'
                    : null
                    }`}
                >
                  <a href={'/delivery/politica-envio'}>
                    {chkShippingPolicy.isPriceOnly ? (
                      <button className='h-full w-full font-semibold rounded-lg bg-white hover:bg-yellow-200'>
                        {'>> '}Puedes administrar el precio único aquí{' <<'}
                      </button>
                    ) : chkShippingPolicy.isPricePerZone ? (
                      <button className='h-full w-full font-semibold rounded-lg bg-white hover:bg-yellow-200'>
                        {'>> '}Puedes administrar el precio por zona aquí{' <<'}
                      </button>
                    ) : null}
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
export default FormasEntrega
