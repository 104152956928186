import React, { useContext, useState } from 'react'
import actions from '../../../assets/images/actions.svg'
import Checkbox1 from './Checkbox1';
import { Link } from 'react-router-dom';
import { CurrencyContext } from '../../../context/CurrencyContext';

function List({ isPickupPoint, pathnameEdit, allDeliveryList, element, onChange, deleteElement }) {
   const { currency } = useContext(CurrencyContext)
   const [showOptions, setShowOptions] = useState(false);

   const openActions = () => {
      setShowOptions(!showOptions);
   }

   return (
      <div
         className='flex items-center justify-evenly gap-7 w-full my-5 py-5 px-10 rounded-xl text-sm'
         style={{ border: '1px solid #AAAAAA' }}
      >
         <div
            className=''
            style={!isPickupPoint ? { width: '50%' } : { width: '65%' }}
         >
            {!isPickupPoint ? element.ratename : element.name}
         </div>
         <div
            className='flex justify-center '
            style={{ width: '15%' }}
         >
            <button onClick={() => onChange(element)}>
               <Checkbox1
                  name={!isPickupPoint ? element.ratename : element.name}
                  value={element.isactive}
               // onChange={onChange}
               />
            </button>
         </div>
         {
            !isPickupPoint && (
               <div
                  className='flex justify-center '
                  style={{ width: '15%' }}
               >
                  {currency + ' ' + element.price}
               </div>
            )
         }
         <div
            className='flex justify-center '
            style={{ width: '10%', color: '#8163FF' }}
         >
            <button>
               <Link
                  to={{
                     pathname: pathnameEdit,
                     state: { id: element._id, isEdit: true, allDeliveryList: allDeliveryList }
                  }}
               >
                  Editar
               </Link>
            </button>
         </div>
         <div
            className='relative flex justify-center '
            style={{ width: '10%' }}
         >
            <button
               className='flex justify-center items-center'
               onClick={openActions}>
               <img src={actions} alt='actions' />
            </button>
            {showOptions && (
               <div className='absolute top-0 right-0 mt-6'>
                  <ul className='bg-white border zborder-gray-300 rounded-md py-1'>
                     <li className='px-4 py-2 hover:bg-gray-200 cursor-pointer' style={{ color: '#FF0000' }}
                        onClick={() => {
                           deleteElement(element.id)
                        }}>
                        Eliminar
                     </li>
                  </ul>
               </div>
            )}
         </div>
      </div>
   )
}

export default List