import React, { useState, useContext, useEffect } from 'react'
import NavigationSide from '../components/NavigationSide'
import NavigationTop from '../components/NavigationTop'
import ModalMessage from '../components/ModalMessage'
import InfoMessage from '../components/InfoMessage'
import axios from 'axios'
import Select from 'react-select'
import { districts, provinces } from '../utils/data'

import { UserContext } from '../context/UserContext'
import { CurrencyContext } from '../context/CurrencyContext'

function Delivery() {
  const { user } = useContext(UserContext)
  const [viewModal, setViewModal] = useState(false)
  const [viewInfoMessage, setViewInfoMessage] = useState({
    viewProvince: false,
    viewDistrict: false,
  })
  const [viewMessageProvince, setViewMessageProvince] = useState(false)
  const [viewMessageDistrict, setViewMessageDistrict] = useState(false)
  const [contentModal, setContentModal] = useState({
    title: '',
    text: '',
    linkgo: '',
  })
  const [contentMessage, setContentMessage] = useState({
    province: {
      title: 'Provincia agregada',
      text: '',
    },
    district: {
      title: 'Distrito agregado',
      text: '',
    },
  })

  const [listDistricts, setListDistricts] = useState(districts)
  const [districtsSelectes, setDistrictsSelectes] = useState([])
  const [priceToDistrict, setPriceToDistrict] = useState('')
  const [listPriceDelivery, setListPriceDelivery] = useState([])

  const [deliveryProvinces, setDeliveryProvinces] = useState(false)

  const [listProvinces, setListProvinces] = useState(provinces)
  const [priceToProvince, setPriceToProvince] = useState('')
  const [provincesSelected, setProvincesSelected] = useState([])
  const [listPriceDeliveryProvinces, setListPriceDeliveryProvinces] = useState(
    []
  )

  const [unicPrice, setUnicPrice] = useState({
    isUnic: false,
    price: '',
  })
  const [city, setCity] = useState('')
  const [loader, setLoader] = useState(true)
  const currencyValue = useContext(CurrencyContext)
  const currency = currencyValue.currency
  // deliveryprovincia
  // deliveryprecio
  // preciodeliveryunico
  let closeModal = (val) => {
    setViewModal(val)
  }

  useEffect(() => {
    axios
      .get(
        `https://ventafazil-strapi-production.up.railway.app/clientes/${user}`
      )
      .then(async (response) => {
        let userDistricts = response.data.deliveryoptions
          ? response.data.deliveryoptions
          : []
        let deliveryProvinces = response.data.deliveryprovincia
          ? response.data.deliveryprovincia
          : []
        //console.log(response.data)
        let diferenceDistricts = []
        listDistricts.map((district) => {
          userDistricts.map((el) => {
            if (district.value === el.value) {
              diferenceDistricts.push(district)
            }
          })
        })
        let differenceDistricts = listDistricts.filter(
          (district) => !diferenceDistricts.includes(district)
        )

        let diferenceProvinces = []
        listProvinces.map((province) => {
          deliveryProvinces.map((el) => {
            if (province.value === el.value) {
              diferenceProvinces.push(province)
            }
          })
        })
        let differenceProv = listProvinces.filter(
          (province) => !diferenceProvinces.includes(province)
        )
        setCity(response.data.ciudad.toUpperCase())
        setListDistricts(differenceDistricts)
        setListProvinces(differenceProv)
        setListPriceDelivery(userDistricts)
        setListPriceDeliveryProvinces(deliveryProvinces)
        let isDeliveryProvince = response.data.deliveryprovince
          ? response.data.deliveryprovince
          : false
        setDeliveryProvinces(isDeliveryProvince)
        setUnicPrice({
          isUnic: response.data.preciodeliveryunico,
          price: response.data.deliveryprecio,
        })
        setLoader(false)
      })
      .catch((err) => {
        //console.log(err)
      })
  }, [])
  let changeDataDelivery = (event) => {
    let newObj = Object.assign({}, unicPrice)

    newObj[event.target.name] =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value
    //console.log(newObj)
    if (newObj.isUnic) {
      setListPriceDelivery([])
      setUnicPrice(newObj)
    } else {
      newObj.price = ''
      setUnicPrice(newObj)
    }
  }
  let changeDistricts = (districts) => {
    setDistrictsSelectes(districts)
    // let newArr = this.state.producto;
    // districtsSelectes = districts;
    // this.setState({ producto: newArr });
  }
  let addPriceToDelivery = (type) => {
    let pricedelivery = [...districtsSelectes]
    let newlistDistrictDelivery = [...listPriceDelivery]

    let priceProvinces = [...provincesSelected]
    let newlistProvincesDelivery = [...listPriceDeliveryProvinces]

    let bodyModal = {
      title: '',
      text: '',
    }
    if (!priceProvinces.length > 0 || !pricedelivery.length > 0) {
      if (type === 'province') {
        priceProvinces.map((el) => {
          el.price = priceToProvince
          newlistProvincesDelivery.push(el)
        })

        let differenceProvinces = listProvinces.filter(
          (province) => !provincesSelected.includes(province)
        )
        setListProvinces(differenceProvinces)
        setProvincesSelected([])
        setListPriceDeliveryProvinces(newlistProvincesDelivery)

        setViewMessageProvince(true)
        setTimeout(() => {
          setViewMessageProvince(false)
        }, 2000)

        bodyModal.title = 'Nueva provincia agregada'
        bodyModal.text = 'Se agrego ' + priceProvinces[0]?.label + ' a la lista'
      } else {
        pricedelivery.map((el) => {
          el.price = priceToDistrict
          newlistDistrictDelivery.push(el)
        })
        let differenceDistricts = listDistricts.filter(
          (district) => !districtsSelectes.includes(district)
        )
        setListDistricts(differenceDistricts)
        setDistrictsSelectes([])
        setListPriceDelivery(newlistDistrictDelivery)

        setViewMessageDistrict(true)
        setTimeout(() => {
          setViewMessageDistrict(false)
        }, 2000)

        bodyModal.title = 'Nuevo distrito agregado'
        bodyModal.text = 'Se agrego ' + pricedelivery[0]?.label + ' a la lista'
      }
      // let bodyMessage = {
      //   title: _title,
      //   text: _text + _type.toLowerCase()
      // }
      // setContentMessage(bodyMessage)

      // setContentModal(bodyModal)
      // setViewModal(true)
    }
  }
  let changeDeliveryProvinces = (e) => {
    if (e.target.checked === false) {
      setListPriceDeliveryProvinces([])
    }
    setDeliveryProvinces(e.target.checked)
  }
  let deleteDistrict = (index, type) => {
    let newlist =
      type === 'province'
        ? [...listPriceDeliveryProvinces]
        : [...listPriceDelivery]
    // let listProvinceDelivery = [...listPriceDeliveryProvinces];

    let elem = newlist[index]
    newlist.splice(index, 1)

    let newlistDistricts =
      type === 'province' ? [...listDistricts] : [...listDistricts]

    newlistDistricts.push(elem)
    newlistDistricts.sort((a, b) => {
      const nameA = a.label.toUpperCase()
      const nameB = b.label.toUpperCase()
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
      return 0
    })
    let _type = ''
    let _title = ''
    let _text = ''
    if (type === 'province') {
      setListProvinces(newlistDistricts)
      setListPriceDeliveryProvinces(newlist)
      _type = 'Provincia'
      _title = _type + ' eliminada'
      _text = 'Se ha eliminado la '
    } else {
      setListDistricts(newlistDistricts)
      setListPriceDelivery(newlist)
      _type = 'Distrito'
      _title = _type + ' eliminado'
      _text = 'Se ha eliminado el '
    }
    let bodyModal = {
      title: _title,
      text: _text + _type.toLowerCase() + ' ' + elem.label,
    }
    setViewModal(true)
    setContentModal(bodyModal)
    //console.log(listPriceDeliveryProvinces)
  }
  let changeDataUser = () => {
    let config = {
      method: 'put',
      url:
        `https://ventafazil-strapi-production.up.railway.app/clientes/` + user,
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_KEY_STRAPI}`,
      },
      data: {
        deliveryoptions: listPriceDelivery,
        deliveryprovincia: listPriceDeliveryProvinces,
        preciodeliveryunico: unicPrice.isUnic,
        deliveryprecio: unicPrice.price,
        deliveryprovince: deliveryProvinces,
      },
    }
    axios(config)
      .then((response) => {
        //console.log(response.data)
      })
      .catch((error) => {
        //console.log(error)
      })
  }
  return (
    <div className='pb-24 bg-gray-50'>
      {viewModal && (
        <ModalMessage
          title={contentModal.title}
          text={contentModal.text}
          deniedBtn={false}
          textButton='Aceptar'
          linkgo={contentModal.linkgo}
          acceptModal={() => closeModal()}
        />
      )}
      <NavigationTop />
      <NavigationSide />

      <div className='py-6 bg-white '>
        <div className='container py-4 mx-auto'>
          <div className='container flex items-start content-start flex-grow gap-3'>
            <h2 className='flex flex-grow text-3xl font-bold tracking-tighter'>
              Precios Delivery
            </h2>
          </div>
        </div>
      </div>
      <div id='cuenta' className='container px-6 md:ml-24'>
        <div className='p-4 bg-gray-200 rounded-lg  mb-10'>
          <p className='font-bold text-gray-500'>Precio delivery</p>
          <p className='text-gray-600'>Precio unico para todos los distritos</p>
          {city === 'LIMA' ? (
            <div className='flex flex-col gap-2 pt-4 md:flex-row'>
              <label className='flex text-xs items-center font-bold text-gray-700 md:w-6/12'>
                <input
                  className='mr-2 inline'
                  type='checkbox'
                  name='isUnic'
                  checked={unicPrice.isUnic}
                  onChange={(e) => changeDataDelivery(e)}
                />
                Asignar precio único a todo Lima
              </label>
              <label className='flex flex-col text-xs font-bold text-gray-700 md:w-6/12'>
                Precio
                <input
                  type='text'
                  className='h-12 p-2 rounded-md'
                  name='price'
                  disabled={!unicPrice.isUnic}
                  placeholder='00.00'
                  value={unicPrice.price}
                  onChange={(e) => changeDataDelivery(e)}
                />
              </label>
            </div>
          ) : (
            <div className='flex flex-col gap-2 pt-4 md:flex-row'>
              <label className='flex flex-col text-xs font-bold text-gray-700 md:w-6/12'>
                Precio único a todo {city}
                <input
                  type='text'
                  className='h-12 p-2 rounded-md'
                  name='price'
                  placeholder='00.00'
                  value={unicPrice.price}
                  onChange={(e) => changeDataDelivery(e)}
                />
              </label>
            </div>
          )}
        </div>
        {viewMessageProvince && (
          <InfoMessage
            title={contentMessage.province.title}
            text={contentMessage.province.text}
          />
        )}
        <div className='p-4 bg-gray-200 rounded-lg mb-10'>
          <p className='font-bold text-gray-500'>Provincias</p>
          <div className='flex flex-col gap-2 pt-4 md:flex-row'>
            <label className='flex text-xs items-center font-bold text-gray-700 md:w-6/12'>
              <input
                className='mr-2 inline'
                type='checkbox'
                name='isUnic'
                checked={deliveryProvinces}
                disabled={false}
                onChange={(e) => changeDeliveryProvinces(e)}
              />
              Realizo envio a provincias
            </label>
          </div>
          {deliveryProvinces ? (
            <div>
              <div className='flex flex-col gap-2 pt-4 md:flex-row items-center'>
                <label className='flex flex-col mb-1 text-sm font-semibold tracking-tight text-gray-700 md:w-8/12'>
                  <div className=''>Seleccionar Provincias</div>
                  <Select
                    className='w-full py-2 focus:border-yellow-500 '
                    closeMenuOnSelect={false}
                    isMulti
                    onChange={(e) => setProvincesSelected(e)}
                    value={provincesSelected}
                    options={listProvinces}
                  />
                </label>
                <label className='flex flex-col text-xs font-bold text-gray-700 md:w-2/12'>
                  Precio
                  <input
                    type='text'
                    className='h-10 p-2 rounded-md'
                    name='instagram'
                    value={priceToProvince}
                    onChange={(e) => setPriceToProvince(e.target.value)}
                  />
                </label>
                <div
                  className={`w-full h-10 p-2 mt-4 px-4 text-sm font-bold text-center bg-yellow-400 rounded-md cursor-pointer md:w-2/12`}
                  onClick={() => addPriceToDelivery('province')}
                >
                  Agregar precios
                </div>
              </div>
              <div className='flex flex-col gap-2 pt-4'>
                <div className='flex flex-row justify-between pt-4 font-semibold text-gray-700'>
                  <div>Provincia</div>
                  <div>Precio</div>
                </div>
                {loader ? (
                  <div className='flex flex-col'>Cargando precios...</div>
                ) : (
                  listPriceDeliveryProvinces.map((el, i) => {
                    return (
                      <div
                        key={el.value}
                        className='flex flex-row justify-between'
                      >
                        <div>{el.label}</div>
                        <div className='flex flex-row'>
                          {currency + ' ' + el.price}{' '}
                          <div
                            onClick={() => deleteDistrict(i, 'province')}
                            className='ml-2 font-bold cursor-pointer px-1 hover:text-gray-400'
                          >
                            X
                          </div>
                        </div>
                      </div>
                    )
                  })
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        {city && city !== '' && city.toUpperCase() === 'LIMA' ? (
          <>
            {viewMessageDistrict && (
              <InfoMessage
                title={contentMessage.district.title}
                text={contentMessage.district.text}
              />
            )}
            <div
              className={`p-4 ${
                unicPrice.isUnic ? 'bg-gray-300 ' : 'bg-gray-200 '
              } rounded-lg`}
            >
              {/* <p className='font text-xl text-red-900'>Habrá un precio unico para todo Lima</p> */}
              <p className='font-bold text-gray-500'>Distritos</p>
              <div className='flex flex-col gap-2 pt-4 md:flex-row items-center'>
                <label className='flex flex-col mb-1 text-sm font-semibold tracking-tight text-gray-700 md:w-8/12'>
                  <div className=''>Seleccionar Distritos</div>
                  {unicPrice.isUnic ? (
                    <select
                      disabled={true}
                      placeholder='Distritos'
                      className='w-full py-2 focus:border-yellow-500 '
                    ></select>
                  ) : (
                    <Select
                      className='w-full py-2 focus:border-yellow-500 '
                      closeMenuOnSelect={false}
                      isMulti
                      placeholder='Distritos'
                      onChange={(e) => changeDistricts(e)}
                      value={districtsSelectes}
                      options={listDistricts}
                    />
                  )}
                </label>
                <label className='flex flex-col text-xs font-bold text-gray-700 md:w-2/12'>
                  Precio
                  <input
                    type='text'
                    className='h-10 p-2 rounded-md'
                    name='instagram'
                    disabled={unicPrice.isUnic}
                    value={priceToDistrict}
                    onChange={(e) => setPriceToDistrict(e.target.value)}
                  />
                </label>
                {unicPrice.isUnic ? (
                  <div className='w-full h-10 p-2 mt-4 px-4 text-sm text-slate-500 font-bold text-center bg-yellow-200 rounded-md cursor-pointer md:w-2/12'>
                    Agregar precio
                  </div>
                ) : (
                  <div
                    className='w-full h-10 p-2 mt-4 px-4 text-sm font-bold text-center bg-yellow-400 rounded-md cursor-pointer md:w-2/12'
                    onClick={() => addPriceToDelivery('district')}
                  >
                    Agregar precio
                  </div>
                )}
              </div>
              <div className='flex flex-col gap-2 pt-4'>
                <div className='flex flex-row justify-between pt-4 font-semibold text-gray-700'>
                  <div>Distrito</div>
                  <div>Precio</div>
                </div>
                {loader ? (
                  <div className='flex flex-col'>Cargando precios...</div>
                ) : (
                  listPriceDelivery.map((el, i) => {
                    return (
                      <div
                        key={el.value}
                        className='flex flex-row justify-between'
                      >
                        <div>{el.label}</div>
                        <div className='flex flex-row'>
                          {currency + ' ' + el.price}{' '}
                          <div
                            onClick={() => deleteDistrict(i, 'district')}
                            className='ml-2 font-bold cursor-pointer px-1 hover:text-gray-400'
                          >
                            X
                          </div>
                        </div>
                      </div>
                    )
                  })
                )}
              </div>
            </div>
          </>
        ) : (
          ''
        )}

        <div className='flex flex-row items-start content-start justify-center flex-grow mt-6'>
          <div
            onClick={() => changeDataUser()}
            className='w-full p-2 px-4 text-sm font-bold text-center bg-yellow-400 rounded-md cursor-pointer md:w-1/4'
          >
            Guardar cambios
          </div>
        </div>
      </div>
    </div>
  )
}

export default Delivery
