import React, { useState, useEffect } from 'react'
import UIinput from './UIinput'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import TagsInput from './TagsInput'

const VariationsUpdater1 = (props) => {
  const [variations, setVariations] = useState([])
  useEffect(() => {
    if (props.variations && props.variations.length > 0) {
      setVariations(props.variations)
    }
  }, [])

  function editVariation(e, i) {
    let name = e.target.name
    let value = e.target.value
    const newVariations = [...variations]
    let index = i
    let errors = { ...props.errors }
    let regexVariations = /^[A-Za-z0-9ÑñÁáÉéÍíÓóÚúÜü~\s]+$/
    let val = e.target.value

    if (name === 'name') {
      newVariations[i][name] = value
      setVariations(newVariations)
      // // props.changeVariations(newVariations)
      // console.log(variations)
      props.changeVariations(newVariations, index) //
    }

    if (name === 'values') {
      if (!val.trim()) {
        // errors.variations = "El campo 'Variantes' es requerido"
        errors['variationsValues' + i] = ''
      } else if (!regexVariations.test(val.trim())) {
        errors['variationsValues' + i] =
          "El campo 'Variantes' solo acepta letras, números, espacios en blanco y estos caraceteres ~"
      } else if (val.length < props.minLengths.variationsValue) {
        errors['variationsValues' + i] =
          "El campo 'Variantes' debe tener mínimo " +
          props.minLengths.variationsValue +
          ' caracteres'
      } else {
        errors['variationsValues' + i] = ''
      }
      if (errors['variationsValues' + i] == '') {
        delete errors['variationsValues' + i]
      }
      props.setErrors(errors)
      // //console.log("PROPS ERRORS", props.errors)
      if (e.key !== 'Enter') return

      const value = e.target.value
      if (!value.trim()) return

      if (!props.errors['variationsValues' + i]) {
        newVariations[i][name].push(value)
        setVariations(newVariations)
        // //console.log('NUEVAS VARIACIONESFFF', newVariations)
        props.changeVariations(newVariations, index)
        e.target.value = ''
      } else {
        // //console.log("NO ENTRO")
      }
    }

    // setVariations(newVariations)
    //   setTagPr([tagPr,..,])

    // props.changeVariations(newVariations)
  }

  function RemoveVariation(e) {
    const newVariations = [...variations]
    // //console.log("props.errors1",props.errors)
    delete props.errors['variationsName' + e]
    delete props.errors['variationsValues' + e]
    // //console.log("props.errors2",props.errors)
    newVariations.splice(e, 1)
    setVariations(newVariations)
    props.changeVariations(newVariations)
  }

  function AddVariation(e) {
    // console.log('VARRR', variations)
    const newVariations = [...variations]
    newVariations.push({
      name: '',
      type: 'product-option',
      values: [],
    })

    setVariations(newVariations)
    // props.changeVariations(newVariations)
    props.changeVariations({ ...newVariations })
  }

  //  TAGS

  const removeTags = (indexRemove, i) => {
    const values = variations[i].values.filter((t, i) => i !== indexRemove)

    const newState = variations.map((obj, index) => {
      // 👇️ if id equals 2, update country property

      if (index === i) {
        return {
          ...obj,
          values: variations[i].values.filter((t, i) => i !== indexRemove),
        }
      }

      // 👇️ otherwise return object as is
      RemoveVariation(i)
      // console.log('entramos')

      return obj
    })
    // props.setProducto({
    //   ...props.producto,
    //   productvariations: props.combinaciones1,
    // })

    setVariations(newState)
    props.changeVariations(newState)

    // //console.log('NUEVAS ELIM', newState)
  }

  useEffect(() => {}, [variations])

  return (
    <div className='bg-gray-100 rounded-xl p-4'>
      <div className='mb-3 text-xs font-semibold text-gray-500 uppercase'>
        variaciones
      </div>
      {variations &&
        variations?.map((variation, i) => {
          return (
            <div key={i + 'a'} className='flex justify-between gap-2'>
              <div className='w-1/4'>
                <UIinput
                  type='text'
                  label={`opcion ` + (i + 1)}
                  nameInput='name'
                  changePrice={(e) => editVariation(e, i)}
                  value={variation.name}
                />
                {props.errors && props.errors['variationsName' + i] && (
                  <p className='p-1 text-sm text-red-500'>
                    {props.errors['variationsName' + i]}
                  </p>
                )}
              </div>

              <div className='w-1/2'>
                {/* variations[i].values.filter( (t, i) => i !== indexRemove ), */}
                {variation.values && (
                  <TagsInput
                    changePrice={(e) => editVariation(e, i)}
                    tags={variations[i]}
                    nameInput='values'
                    removeTags={(indexRemove) => {
                      removeTags(indexRemove, i)
                    }}
                    // disabled={props?.errors["variationsName" + i]}
                  />
                )}
                {props.errors && props.errors['variationsValues' + i] && (
                  <p className='p-1 text-sm text-red-500'>
                    {props.errors['variationsValues' + i]}
                  </p>
                )}
              </div>
              <div
                className='flex flex-col justify-end'
                onClick={() => RemoveVariation(i)}
              >
                <div className='flex items-center justify-center w-6 h-6 mb-6 text-white bg-black rounded-full cursor-pointer hover:bg-red-600 '>
                  <FontAwesomeIcon icon={faTimes} />{' '}
                </div>
              </div>
            </div>
          )
        })}
      <div
        onClick={() => AddVariation()}
        className='text-sm font-bold tracking-tighter text-blue-600 opacity-50 cursor-pointer hover:opacity-100'
      >
        + Agregar una variación
      </div>
    </div>
  )
}

export default VariationsUpdater1
