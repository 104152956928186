import React, { useState, useEffect } from 'react'
import NavigationSide from '../components/NavigationSide'
import NavigationTop from '../components/NavigationTop'
import UIBackofficeHeader from '../components/UIBackofficeHeader'
import ModalMessage from '../components/ModalMessage'
import InfoMessage from '../components/InfoMessage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTrash,
  faFloppyDisk,
  faPencil,
  faXmark,
  faArrowLeft,
} from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'

export default function ListaCategorias(props) {
  let basecategory = {
    nombrecategoria: '',
    cliente: {
      _id: '',
    },
    thumbnail: '',
    isspecial: false,
    isEdit: false,
  }
  const [errors, setErrors] = useState({})
  const [viewModal, setViewModal] = useState(false)
  const [viewInfoMessage, setViewInfoMessage] = useState(false)
  const [contentModal, setContentModal] = useState({
    title: '',
    text: '',
    linkgo: '',
  })
  const [contentMessage, setContentMessage] = useState({
    title: '',
    text: '',
  })
  const [userListCategorys, setUserListCategorys] = useState([])
  const [dataCategory, setDataCategory] = useState(basecategory)
  useEffect(() => {
    getListCategorys()
  }, [])

  let addNewCategory = () => {
    let e = dataCategory.nombrecategoria
    setErrors(validateName(e))
    let user = JSON.parse(localStorage.getItem('user'))
    let config = {
      method: 'post',
      url: `https://ventafazil-strapi-production.up.railway.app/categorias`,
      data: {
        ...dataCategory,
        cliente: {
          _id: user.id,
        },
      },
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_KEY_STRAPI}`,
        'Content-Type': 'application/json',
      },
    }
    // //console.log(" dataCategory.nombrecategoria", dataCategory.nombrecategoria,  dataCategory.nombrecategoria.length)
    if (
      Object.entries(errors).length == 0 &&
      dataCategory.nombrecategoria?.trim().length > 1
    ) {
      axios(config)
        .then((response) => {
          // //console.log("RESPONSE NEW", response)
          let newCate = response.data.nombrecategoria
          if (response.status == 200) {
            let bodyMessage = {
              title: 'Nueva categoria creada',
              text: 'Se ha agregado ' + newCate + ' a su lista de categorías', //response.statusText
            }
            getListCategorys()
            setViewInfoMessage(true)
            setContentMessage(bodyMessage)
          }
          let bodyparse = {
            nombrecategoria: '',
            cliente: {
              _id: '',
            },
            thumbnail: '',
            isspecial: false,
            isEdit: false,
          }
          // //console.log(bodyparse)
          setDataCategory(bodyparse)
        })
        .finally(() => {
          setTimeout(() => {
            setViewInfoMessage(false)
          }, 3000)
        })
        .catch((error) => {
          //console.log(error)
        })
    } else {
      //console.log('NO SE PUEDE GUARDAR HAY ERRORES')
    }
  }
  let deleteCategory = (id, index) => {
    let config = {
      method: 'delete',
      url: `https://ventafazil-strapi-production.up.railway.app/categorias/${id}`,
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_KEY_STRAPI}`,
      },
    }
    axios(config)
      .then((response) => {
        if (response.status == 200) {
          let bodyModal = {
            title: 'Categoria eliminada',
            text: 'Se ha eliminada la categoria', //response.statusText
          }
          getListCategorys()
          setViewModal(true)
          setContentModal(bodyModal)
        }
      })
      .catch((error) => {
        //console.log(error)
      })
  }
  let getListCategorys = () => {
    let user = JSON.parse(localStorage.getItem('user'))
    let config = {
      method: 'get',
      url: `https://ventafazil-strapi-production.up.railway.app/categorias/?cliente=${user._id}`,
    }
    axios(config)
      .then((response) => {
        let userCategories = response.data.map((el) => {
          el.isEdit = false
          el.isspecial = el.isspecial ? el.isspecial : false
          return el
        })
        setUserListCategorys(userCategories)
      })
      .catch((error) => {
        //console.log(error)
      })
  }
  let uploadImgCloudinary = async (data) => {
    let file = data.target.files[0]
    let formdata = new FormData()
    formdata.append('file', file)
    formdata.append('upload_preset', 'VentaFacil')
    let resObject
    await fetch(
      'https://vf-express-production.up.railway.app/api/images/upload',
      {
        method: 'POST',
        body: formdata,
      }
    )
      .then((response) => response.json())
      .then(async (response) => {
        resObject = await response
      })
      .catch((err) => console.log(err))
    return resObject
  }
  let updateCategory = (cat, index) => {
    let config = {
      method: 'put',
      url: `https://ventafazil-strapi-production.up.railway.app/categorias/${cat._id}`,
      data: cat,
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_KEY_STRAPI}`,
        'Content-Type': 'application/json',
      },
    }
    axios(config)
      .then((response) => {
        // //console.log("RESPONSE EDIT",response)
        if (response.status == 200) {
          let bodyModal = {
            title: 'Categoria editada',
            text: 'Se ha editado la categoria', //response.statusText
          }
          getListCategorys()
          setViewModal(true)
          setContentModal(bodyModal)
        }
      })
      .catch((error) => {
        //console.log(error)
      })
  }
  let uploadImgNewCategory = async (file) => {
    let res = await uploadImgCloudinary(file)
    let newdata = Object.assign({}, dataCategory)
    newdata.thumbnail = res.secure_url
    setDataCategory(newdata)
  }
  let changeImgListCategory = async (file, index) => {
    let res = await uploadImgCloudinary(file)
    let newListCategory = [...userListCategorys]
    newListCategory[index].thumbnail = res.secure_url
    setUserListCategorys(newListCategory)
  }
  let editCategory = (event, index) => {
    let newlist = [...userListCategorys]
    newlist[index][event.target.name] =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value
    setUserListCategorys(newlist)
  }

  let changeEditMode = (index, val) => {
    let newarr = [...userListCategorys]
    newarr[index].isEdit = !val
    setUserListCategorys(newarr)
  }
  let validate = () => {
    let list = userListCategorys.filter((el) => el.isspecial === true)
    let res
    if (list.length > 0) {
      res = true
    } else {
      res = false
    }
    return res
  }
  let validateName = (e) => {
    // //console.log("dataCategory.nombrecategoria", dataCategory.nombrecategoria)
    let errors = {}
    let nombrecategoria = e //.target.value
    // let nombrecategoria = dataCategory.nombrecategoria
    let regexName = /^[A-Za-z\s]+$/
    // let regexNamez = /^[A-Za-z0-9ÑñÁáÉéÍíÓóÚúÜü!:/()\s]+$/;
    if (!nombrecategoria.trim()) {
      errors.nombrecategoria = 'El nombre de categoría es requerido'
    } else if (nombrecategoria.length < 2) {
      errors.nombrecategoria =
        'El nombre de categoría debe tener mínimo 2 caracteres'
    }
    // else if(!regexName.test(nombrecategoria)){
    // errors.nombrecategoria = "El nombre de categoría solo acepta estos caracteres"
    // }

    return errors
  }

  const closeModal = (val) => {
    setViewModal(val)
  }

  return (
    <div className='w-fit min-w-full'>
      {viewModal && (
        <ModalMessage
          title={contentModal.title}
          text={contentModal.text}
          deniedBtn={false}
          textButton='Aceptar'
          linkgo={contentModal.linkgo}
          acceptModal={() => closeModal()}
        />
      )}
      <NavigationTop />
      <NavigationSide />
      <div className='md:ml-20'>
        <UIBackofficeHeader title='Mis Categorias' activeReturn={true} />
        <div className='py-6 mb-44 md:ml-28 mx-2'>
          {viewInfoMessage && (
            <InfoMessage
              title={contentMessage.title}
              text={contentMessage.text}
            />
          )}
          {/* <p>Crear Categoria</p> */}
          <div className='flex flex-col md:flex-row justify-between items-center gap-4 p-4 bg-gray-200 rounded-lg w-full md:w-9/12 mb-6 md:min-w-max'>
            <div className='flex flex-col md:w-4/12 md:min-w-max gap-6'>
              <div className='flex flex-col gap-6 pt-4 md:flex-row w-full mr-8'>
                <label className='flex flex-col flex-grow text-xs h-12 font-bold text-gray-700'>
                  Nombre de categoria:
                  <input
                    type='text'
                    value={dataCategory.nombrecategoria}
                    onChange={(e) => {
                      setDataCategory({
                        ...dataCategory,
                        nombrecategoria: e.target.value,
                      })
                      let nombreCategoria = e.target.value
                      setErrors(validateName(nombreCategoria))
                    }}
                    className='flex-grow h-12 p-2 text-sm bg-gray-100 w-full rounded-md'
                    name='name'
                    placeholder='Ingrese su texto'
                  />
                  {errors.nombrecategoria && (
                    <p className='text-red-600'>{errors.nombrecategoria}</p>
                  )}
                </label>
              </div>
              {/* <div className='flex flex-row gap-6 pt-4 w-full'>
                <label className='flex flex-col w-full py-2 text-xs font-bold text-gray-700'>
                  Imagen de categoria:
                  <input
                    type='file'
                    className='h-12 p-2 pt-3 bg-gray-100 rounded-md'
                    placeholder='Nombre de la tienda'
                    onChange={(e) => uploadImgNewCategory(e)}
                  />
                </label>
              </div> */}
              <div className='flex flex-col'>
                {/* <div className='form-check form-switch py-2'>
                  <input
                    className='form-check-input appearance-none w-9 -ml-10 rounded-full float-left h-5 align-top bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm'
                    type='checkbox'
                    role='switch'
                    disabled={validate()}
                    checked={dataCategory.isspecial}
                    onChange={(e) =>
                      setDataCategory({
                        ...dataCategory,
                        isspecial: e.target.checked,
                      })
                    }
                  />
                  <label className='form-check-label inline-block text-gray-800'>
                    Categoria especial
                  </label>
                </div> */}
                <p className='text-xs font-semibold'>
                  *Esta categoria aparecerá en la vista principal
                </p>
              </div>
            </div>
            <div className='pt-4 w-4/12'>
              {dataCategory.thumbnail !== '' ? (
                <img
                  className='w-full h-40'
                  src={dataCategory.thumbnail}
                  alt=''
                />
              ) : (
                ''
              )}
            </div>
            <div className='md:w-4/12 flex items-center justify-center text-lg font-semibold '>
              <div
                onClick={addNewCategory}
                // className='w-52 text-center font-semibold text-lg bg-yellow-400 rounded-full cursor-pointer p-2 px-2'
                className={`w-52 text-center font-semibold text-lg pointer-events-none text-white 
                ${
                  !errors.nombrecategoria && 'pointer-events-auto'
                } rounded-full cursor-pointer p-2 px-2`}
                style={{ background: '#8163FF' }}
              >
                Agregar categoria
              </div>
            </div>
          </div>
          <p>Lista de categorias</p>
          {userListCategorys.map((category, i) => {
            return (
              <div
                key={category._id}
                className='flex flex-col md:flex-row justify-between items-center gap-4 p-4 bg-gray-200 rounded-lg w-full md:w-8/12 mb-6'
              >
                <div
                  className='py-2 px-4 rounded-full font-semibold text-white'
                  style={{ background: '#8163FF' }}
                >
                  {i + 1}
                </div>
                <div className='flex flex-col gap-6 md:w-8/12'>
                  {category.isEdit ? (
                    <>
                      <div className='flex flex-col gap-6 pt-4 md:flex-row w-full mr-8'>
                        <label className='flex flex-col flex-grow text-xs h-12 font-bold text-gray-700'>
                          Nombre de categoria:
                          <input
                            type='text'
                            value={category.nombrecategoria}
                            onChange={(e) => editCategory(e, i)}
                            className='flex-grow h-12 p-2 text-sm bg-gray-100 w-full rounded-md'
                            name='nombrecategoria'
                            placeholder='Ingrese su texto'
                          />
                        </label>
                      </div>

                      <div className='flex flex-col'>
                        <p className='text-xs font-semibold'>
                          *Esta categoria aparecerá en la vista principal
                        </p>
                        {/* <p className='text-xs font-semibold'>
                          *Solo se puede seleccionar una categoria especial
                        </p> */}
                      </div>
                    </>
                  ) : (
                    <div>
                      <div>{category.nombrecategoria}</div>
                      <div className='font-bold'>
                        {category.isspecial ? 'Especial' : ''}
                      </div>
                    </div>
                  )}
                </div>
                <div className='pt-4'>
                  {category.thumbnail && category.thumbnail !== '' ? (
                    <img
                      className='w-full h-40'
                      src={category.thumbnail ? category.thumbnail : ''}
                      alt=''
                    />
                  ) : (
                    ''
                  )}
                </div>
                <div className='flex items-center p-4 text-lg font-bold'>
                  {category.isEdit ? (
                    <>
                      <FontAwesomeIcon
                        className='hover:text-red-500 p-2 cursor-pointer'
                        icon={faXmark}
                        onClick={() => changeEditMode(i, category.isEdit)}
                      />
                      <FontAwesomeIcon
                        className='hover:text-green-500 p-4 cursor-pointer'
                        icon={faFloppyDisk}
                        onClick={() => updateCategory(category)}
                      />
                    </>
                  ) : (
                    ''
                  )}
                  {!category.isEdit ? (
                    <>
                      <FontAwesomeIcon
                        className='hover:text-amber-500 p-2 cursor-pointer'
                        onClick={() => changeEditMode(i, category.isEdit)}
                        icon={faPencil}
                      />
                      <FontAwesomeIcon
                        className='hover:text-red-600 p-2 cursor-pointer'
                        onClick={() => deleteCategory(category._id, i)}
                        icon={faTrash}
                      />
                    </>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
