import axios from 'axios'
import React, { useContext, useEffect, useState, useRef } from 'react'
import FilterOrder from '../components/FilterOrder'
import NavigationOrder from '../components/NavigationOrder'
import NavigationSide from '../components/NavigationSide'
import NavigationTop from '../components/NavigationTop'
import UIBackofficeHeader from '../components/UIBackofficeHeader'
import UIBackofficeVentaTable from '../components/UIBackofficeVentaTable'
import VentTable from '../components/VentTable'
import { UserContext } from '../context/UserContext'
import { currencyFromStrapi, parseDate } from '../utils/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { useHistory } from 'react-router-dom'
import { CurrencyContext } from '../context/CurrencyContext'
import filter from '../assets/images/filter.svg'
import fileExport from '../assets/images/export.svg'
import { estadoventa, estadoPago } from '../utils/data'

const csvHeader = [
  { label: 'Orden ID', key: 'ordenid' },
  { label: 'Fecha y Hora', key: 'fechayhora' },
  // { label: 'Cliente', key: 'cliente' },
  { label: 'Nombre', key: 'customerdetails.name' },
  { label: 'Apellido', key: 'customerdetails.lastname' },
  { label: 'Correo', key: 'customerdetails.email' },
  { label: 'Teléfono', key: 'customerdetails.phone' },
  { label: 'Dirección', key: 'customerdetails.address' },
  { label: 'Punto Recojo', key: 'customerdetails.pickuppoint' },
  { label: 'Código Postal', key: 'customerdetails.postalcode' },
  { label: 'Total', key: 'total' },
  { label: 'Items', key: 'items' },
  { label: 'Estado del Pago', key: 'estadodelpago' },
  { label: 'Estado del Pedido', key: 'estadodelpedido' },
  { label: 'Metodo de Entrega', key: 'metododeentrega' },
  // { label: 'Metodo de Pago', key: 'metododepago' },
]

export default function Ventas() {
  const history = useHistory()
  const [loader, setLoader] = useState(true)
  const [ventas, setVentas] = useState([])
  const [page, setPage] = useState(0)
  const { user, setUser } = useContext(UserContext)
  const debounceRef = useRef()
  const [query, setquery] = useState()
  const currencyValue = useContext(CurrencyContext)
  const currency = currencyValue.currency

  const [selectEstadoVenta, setSelectEstadoVenta] = useState()
  const [selectEstadoPago, setselectEstadoPago] = useState()
  const [csvData, setCsvData] = useState([])
  const csvDownloadRef = useRef(null)
  const [loading, setLoading] = useState(false)

  const onQuerChanged = (e) => {
    e.persist()
    if (debounceRef.current) {
      clearTimeout(debounceRef.current)
    }
    debounceRef.current = setTimeout(() => {
      //consulta
      //console.log('debounce valuce', e.target.value)

      debounceRef.current = e.target.value
      setquery(e.target.value)
      consultarApi(e.target.value)
    }, 500)
  }

  const handleSelectChange = (e) => {
    //console.log('event', e.value)
    setSelectEstadoVenta(e.value)
  }
  const handleSelectChange1 = (e) => {
    //console.log('event', e.value)
    setselectEstadoPago(e.value)
  }
  const consultarApi = (query) => {
    setLoader(true)
    let config
    let id = user
    //console.log(debounceRef.current)
    if (debounceRef.current) {
      //console.log('HAY UNA QUETY')
      setPage(0)

      let urlApi = `https://ventafazil-strapi-production.up.railway.app/ventas?cliente=${id}&_sort=id:DESC&_limit=-1&estado_ne=pendiente`
      if (selectEstadoVenta) urlApi += `&estadoventa=${selectEstadoVenta}`
      if (selectEstadoPago) urlApi += `&estado=${selectEstadoPago}`

      config = {
        method: 'get',
        url: urlApi,
        headers: {
          Accept: '*/*',
          Authorization: `Bearer ${process.env.REACT_APP_KEY_STRAPI}`,
        },
      }

      axios(config)
        .then((response) => {
          //console.log('PRO111', response.data)
          let vent = response.data
          vent.map((v, i) => {
            let price = String(v.total)
            v.total = currencyFromStrapi(price)
            return v
          })

          const resultadoBusqueda = vent.filter((elemento) => {
            //console.log('FFF1', elemento._id)
            //console.log('FFF2', query)

            if (
              elemento._id
                .toString()
                .toLowerCase()
                .includes(debounceRef.current.toLowerCase()) ||
              elemento.clientefinal
                .toString()
                .toLowerCase()
                .includes(debounceRef.current.toLowerCase())
            ) {
              return elemento
            }
          })

          setVentas(resultadoBusqueda)

          setLoader(false)
        })
        .catch((error) => {
          //console.log(error)
        })
    } else {
      //console.log('no HAY UNA QUETY')
      //console.log('query', query)
      let urlApi = `https://ventafazil-strapi-production.up.railway.app/ventas?cliente=${id}&_sort=id:DESC&_start=${page}&_limit=10&estado_ne=pendiente`
      if (selectEstadoVenta) urlApi += `&estadoventa=${selectEstadoVenta}`
      if (selectEstadoPago) urlApi += `&estado=${selectEstadoPago}`

      config = {
        method: 'get',
        url: urlApi,
        headers: {
          Accept: '*/*',
          Authorization: `Bearer ${process.env.REACT_APP_KEY_STRAPI}`,
        },
      }

      axios(config)
        .then((response) => {
          //console.log('PRO111', response.data)
          let vent = response.data
          vent.map((v, i) => {
            let price = String(v.total)
            v.total = currencyFromStrapi(price)
            return v
          })
          setVentas(vent)

          setLoader(false)
        })
        .catch((erroPr) => {
          //console.log(erroPr)
        })
    }
  }

  const getVentasToCSV = async () => {
    setLoading(true)
    let url = `https://ventafazil-strapi-production.up.railway.app/ventas?cliente=${user}&_sort=id:DESC&_limit=-1&estado_ne=pendiente`
    if (selectEstadoVenta) url += `&estadoventa=${selectEstadoVenta}`
    if (selectEstadoPago) url += `&estado=${selectEstadoPago}`
    const config = {
      url: url,
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${process.env.REACT_APP_KEY_STRAPI}`,
      },
    }
    try {
      const response = await axios(config)
      const data = response.data
      if (response.status === 200) {
        let _data = []
        // { new Date(fecha).toLocaleDateString('ES') } { ' ' }
        // {
        //   new Date(fecha).toLocaleTimeString('en-US', {
        //     hour: 'numeric',
        //     minute: 'numeric',
        //   })
        // }
        data.forEach(el => {
          _data.push({
            ordenid: el.id,
            fechayhora: parseDate(el.createdAt),
            // cliente: el.clientefinal,
            customerdetails: {
              name: el.metadata[0].name,
              lastname: el.metadata[0].surname,
              email: el.metadata[0].email,
              phone: el.metadata[0].mobile,
              address: el.metadata[0].metodoEntrega === 'Delivery'
                ? el.metadata[0].addres
                : el.metadata[0]?.puntoRecojo?.direccion + ' - ' + el.metadata[0]?.puntoRecojo?.place,
              pickuppoint: el.metadata[0].metodoEntrega !== 'Delivery'
                ? el.metadata[0].puntoRecojo.name
                : '-',
              postalcode: el.metadata[0].metodoEntrega === 'Delivery'
                ? el.metadata[0].postalCode
                : '-'
            },
            total: currencyFromStrapi(String(el.total)),
            items: el.metadata.length,
            estadodelpago: el.estado,
            estadodelpedido: el.estadoventa,
            metododeentrega: el.metadata[0].metodoEntrega,
            // metododepago: el.metadata[0].metodoEntrega
          })
        });
        setCsvData(_data)
        csvDownloadRef.current.link.click()
      }
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }

  const Loading = () => {
    return (
      <div className='flex justify-center items-center w-full lg:w-screen h-full lg:h-screen fixed z-30 bg-gray-100 opacity-60 top-0 left-0'>
        <div className="border-t-transparent w-28 h-28 border-4 border-blue-200 rounded-full animate-spin" ></div>
      </div>
    )
  }

  useEffect(() => {
    consultarApi()
  }, [page, selectEstadoVenta, selectEstadoPago])

  return (
    <div className='w-fit lg:w-full pb-24 overflow-x-hidden'>
      {loading && <Loading />}
      <NavigationTop />
      <NavigationSide />
      <div className='md:ml-28 md:mt-5'
      // style={{ width: '90%' }}
      >
        <UIBackofficeHeader
          title='Mis Ventas'
          label='+ Crear Orden'
          link='/venta/nuevo'
        />
        {/* <NavigationOrder /> */}
        <div className='w-auto md:ml-20 lg:ml-28 px-5 md:pl-0 md:pr-5'>
          <FilterOrder
            handleChange={onQuerChanged}
            handleSelectChange={handleSelectChange}
            handleSelectChange1={handleSelectChange1}
            placeHolder='Busca por Orden ID, cliente'
            img1={filter}
            img2={fileExport}
            text1='Filtro'
            text2='Exportar'
            options1={estadoventa}
            options2={estadoPago}
            csvVisible={true}
            csvHeader={csvHeader}
            csvData={csvData}
            csvDownloadRef={csvDownloadRef}
            getVentasToCSV={getVentasToCSV}
          />
          <div className='container py-3 md:ml-20 '></div>
          {loader ? (
            <div className='w-full'>Cargando...</div>
          ) : (
            <VentTable ventas={ventas} currency={currency} />
          )}
          <div
            className='flex justify-end items-center gap-3 px-12 mb-28 pt-4'
            style={{ borderTop: '4px solid #F2F2F2' }}
          >
            {!query && ventas.length >= 1 && (
              <>
                <div
                  className='w-auto h-auto cursor-pointer'
                  onClick={() => setPage(page - 10)}
                >
                  {page !== 0 && (
                    <button
                      className='rounded-xl px-3 py-1'
                      style={{ border: '1px solid #909090' }}
                    >
                      Anterior
                    </button>
                    // <FontAwesomeIcon
                    //   icon={faArrowLeft}
                    //   className='p-2 text-2xl'
                    // />
                  )}
                </div>

                <div
                  className='w-auto h-auto cursor-pointer'
                  onClick={() => setPage(page + 10)}
                >
                  {ventas.length === 10 || ventas.length === 0 ? (
                    <button
                      className='rounded-xl px-3 py-1'
                      style={{ border: '1px solid #909090' }}
                    >
                      Siguiente
                    </button>
                  ) : (
                    // <FontAwesomeIcon
                    //   icon={faArrowRight}
                    //   className='p-2 text-2xl'
                    // />
                    ''
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
