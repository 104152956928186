import React, { useContext, useEffect, useState, useCallback } from 'react'
import {
  Route,
  BrowserRouter as Router,
  Switch,
  useHistory,
} from 'react-router-dom'
import Home from '../views/Home'
import Ventas from '../views/Ventas'
import Productos from '../views/Productos'
import Cuenta from '../views/Cuenta'
import Producto from '../views/Producto'
import Suscripcion from '../views/Suscripcion'
import Delivery0 from '../views/Delivery0'
import Login from '../views/Login'
import { UserContext } from '../context/UserContext'
import Venta from '../views/Venta'
import ProductoNuevo from '../views/ProductoNuevo'
import ListaCategorias from '../views/ListaCategorias'
import Categorias from '../views/Categorias'
import { Redirect } from 'react-router-dom'
import VentaNuevo from '../views/VentaNuevo'
import Historials from '../views/Historials'
import FormasEntrega from '../views/FormasEntrega'
import PoliticaEnvio from '../views/PoliticaEnvio'
import TiendaRecojo from '../views/TiendaRecojo'
import NewShop from '../views/NewShop'
import PricePerZone from '../views/PricePerZone'
import Delivery from '../views/Delivery'
import Store from '../views/Store'
import NeedHelp from '../components/NeedHelp'
import Payments from '../views/Payments'
import Help from '../components/help/Help'

function AppNavigation() {
  const { user } = useContext(UserContext)
  const [usuario, setUsuario] = useState(
    JSON.parse(localStorage.getItem('user'))
  )

  useEffect(() => {
    if (usuario) {
      setUsuario(usuario)
    } else {
      localStorage.setItem('user', JSON.stringify(usuario))
    }
  }, [])

  return (
    <Router>
      {user != null && <NeedHelp />}
      <Switch>
        <Route exact path='/' component={user == null ? Login : Home} />
        {user == null && <Redirect to="/" />}
        <Route exact path='/home' component={Home} />
        <Route exact path='/productos' component={Productos} />
        <Route exact path='/ventas' component={Ventas} />
        <Route exact path='/delivery' component={Delivery} />
        <Route exact path='/tienda' component={Store} />
        <Route exact path='/suscripcion' component={Suscripcion} />
        <Route exact path='/producto/nuevo' component={ProductoNuevo} />
        <Route exact path='/venta/nuevo' component={VentaNuevo} />
        <Route exact path='/cuenta' component={Cuenta} />
        <Route exact path='/payments' component={Payments} />
        <Route
          exact
          path='/ayuda'
          component={Help}
        />
        {/* <Route
                exact
                path='/delivery/formas-entrega'
                component={FormasEntrega}
              />
              <Route
                exact
                path='/delivery/politica-envio'
                component={PoliticaEnvio}
              /> */}
        <Route
          exact
          path='/delivery/precio-zona/nuevo'
          component={PricePerZone}
        />
        <Route
          exact
          path='/delivery/precio-zona/editar'
          component={PricePerZone}
        />
        <Route
          exact
          path='/delivery/tienda-recojo'
          component={TiendaRecojo}
        />
        <Route
          exact
          path='/delivery/tienda-recojo/nuevo'
          component={NewShop}
        />
        <Route
          exact
          path='/delivery/tienda-recojo/edit'
          component={NewShop}
        />
        <Route
          exact
          path='/historial/:ventaid'
          render={(props) => <Historials />}
        />
        <Route exact path='/categorias' component={ListaCategorias} />
        <Route
          exact
          path='/producto/:productid'
          render={(props) => <Producto {...props} />}
        />
        <Route
          exact
          path='/productos/:categoryid'
          render={(props) => <Categorias {...props} />}
        />
        <Route
          exact
          path='/venta/:ventaid'
          render={(props) => <Venta {...props} />}
        />

        {/* <Route exact path='/:userid' render={(props) => <Home {...props} />} /> */}
        <Redirect to="/" />
      </Switch>
    </Router>
  )
}

export default AppNavigation
