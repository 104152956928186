import React from 'react'

const TableChart = ({ data }) => {
  //console.log(data)
  return (
    <div className='w-full'>
      <h4 className='font-bold text-center'>Producto más Vendidos</h4>
      {data.map((product) => {
        return (
          <div className='flex justify-between items-center   mb-2'>
            <img
              className='w-16  h-16'
              src={product.image && product.image}
              alt=''
            />

            <p>{product.label}</p>

            <p className='font-bold'>{product.amount} ventas</p>
          </div>
        )
      })}
    </div>
  )
}

export default TableChart
