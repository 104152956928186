import Swal from "sweetalert2"

function toastAlert(msgError = '') {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    if (msgError) {
        Toast.fire({
            icon: 'error',
            title: msgError,
        })
    }
}

function toastModal(title = 'Se guardó con éxito', icon = 'success') {
    Swal.fire({
        title: title,
        icon: icon,
        timer: 3000
    })
}

export { toastAlert, toastModal }