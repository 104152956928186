import React from 'react'
import { Link } from 'react-router-dom'

function ModalMessage({
  title,
  text,
  deniedBtn,
  textButton,
  cancelModal,
  acceptModal,
  linkgo,
}) {
  return (
    <div>
      <div className='w-full h-full bg-stone-900 z-50 fixed opacity-40'></div>
      <div
        style={{ transform: 'translate(-50%,-50%)' }}
        className='w-96 h-48 bg-white rounded-md p-6 fixed z-50 left-1/2 top-1/2 shadow-xl flex flex-col justify-between'
      >
        <div>
          <div className='font-bold text-xl'>{title}</div>
          <div className='font-medium text-base'>{text}</div>
        </div>
        <div className='flex justify-end items-center'>
          {deniedBtn ? (
            <div onClick={cancelModal} className='cursor-pointer'>
              Cancelar
            </div>
          ) : (
            ''
          )}
          {linkgo ? (
            <a href={linkgo}>
              <div
                onClick={() => acceptModal(false)}
                className='bg-teal-400 py-2 px-6 rounded-md ml-8 text-white font-medium cursor-pointer'
              >
                {textButton}
              </div>
            </a>
          ) : (
            <div
              onClick={() => acceptModal(false)}
              className='bg-teal-400 py-2 px-6 rounded-md ml-8 text-white font-medium cursor-pointer'
            >
              {textButton}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ModalMessage
