import { useState } from 'react'
import NavigationTop from '../NavigationTop'
import NavigationSide from '../NavigationSide'
import HelpIndex from './components/HelpIndex'
import HelpBody from './components/HelpBody'
import { indicePE } from '../../utils/constants/helpDataPE'
import { indiceMX } from '../../utils/constants/helpDataMX'

let indice = []
let storageUser = JSON.parse(localStorage.getItem('user'))
let paises = storageUser?.paises

if (paises?.name === 'Peru') indice = indicePE
else if (paises?.name === 'Mexico') indice = indiceMX
else indice = indicePE

const initialValues = {
    index: 'Preguntas Frecuentes',
    subIndex: 'Productos',
    frequentQuestions: { frequentQuestions: true },
    listItem: indice[0].subIndex[0].products
}

function Help() {
    const [selectedMainIndex, setSelectedMainIndex] = useState(initialValues.index)
    const [selectedSubIndex, setSelectedSubIndex] = useState(initialValues.subIndex)
    const [isVisibleSubIndex, setIsVisibleSubIndex] = useState(initialValues.frequentQuestions)
    const [listItem, setListItem] = useState(initialValues.listItem)
    const [originalListItem, setOriginalListItem] = useState(initialValues.listItem)

    /**
     * Checks if the isVisibleSubIndex variable is false to set a new value.
     * Checks if the index parameter has sub index and if there are none 
     * sends two parameters, first send the array of index and second sends true
     * but sends three parameters, first sends the array of the first position of the sub index, 
     * second sends false and third sends the first position of sub index.
     * @param {{}} index
     */
    const viewSubIndex = (index = {}) => {
        if (!isVisibleSubIndex[index.nameIndex]) setIsVisibleSubIndex({ [index.nameIndex]: !isVisibleSubIndex[index.nameIndex] })
        setSelectedMainIndex(index.text)

        if (index.subIndex === undefined) {
            selectIndex(index[index.nameIndex], true)
        } else selectIndex(index.subIndex[0][index.defaultSubIndex], false, index.subIndex[0])
    }

    /*
    *   Search by word
    */
    const handleSearchWord = (e) => {
        const { value } = e.target

        setListItem(() => originalListItem.filter(item =>
            item.title.toLowerCase().includes(value.toLowerCase()))
        )
    }

    /**
     * The first parameter must be an array to set in the setListItem and setOriginalListItem states
     * If the noSubIndex parameter is true then set '' to setSelectedSubIndex state
     * otherwise set subIndex.text to setSelectedSubIndex state.
     * @param {[]} arraySubIndex 
     * @param {boolean} noSubIndex 
     * @param {{}} subIndex
     */
    const selectIndex = (arraySubIndex, noSubIndex = false, subIndex) => {
        if (noSubIndex) setSelectedSubIndex('')
        else setSelectedSubIndex(subIndex.text)

        setListItem(arraySubIndex)
        setOriginalListItem(arraySubIndex)
    }

    return (
        <div className='pb-24 min-w-fit w-full lg:w-full zoverflow-hidden'>
            <NavigationTop />
            <NavigationSide />
            <div className='px-2 md:pl-28 lg:px-28 mt-5 md:mt-10 pb-32 md:pb-0 flex gap-10'>
                <HelpBody
                    handleSearchWord={handleSearchWord}
                    listItem={listItem}
                    selectedMainIndex={selectedMainIndex}
                    selectedSubIndex={selectedSubIndex}
                />
                <HelpIndex
                    indice={indice}
                    isVisibleSubIndex={isVisibleSubIndex}
                    viewSubIndex={viewSubIndex}
                    selectIndex={selectIndex}
                    selectedSubIndex={selectedSubIndex}
                />
            </div>
        </div>
    )
}

export default Help