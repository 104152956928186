import React, { createContext, useState, useMemo, useEffect } from 'react'

const UserContext = createContext({
  user: null,
  setUser: () => {},
  name: 'cliente',
  setName: () => {},
})
let datauser = JSON.parse(localStorage.getItem('user'))
const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState(datauser ? datauser.id : null) //* AQUI HAY QUE PONER EL USER ID*/
  const value = useMemo(() => ({ user, setUser }), [user])
  const [urlPage, setUrlPage] = useState(datauser ? datauser.urlpage : null)

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

const UserContextConsumer = UserContext.Consumer
export { UserContext, UserContextProvider, UserContextConsumer }
