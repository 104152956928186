import esPE from '../utils/languages/es-PE.json'
import esMX from '../utils/languages/es-MX.json'

function loadTranslations(locale) {
  switch (locale) {
    case 'Peru':
      return esPE
    case 'Mexico':
      return esMX
    // Agrega más casos según los idiomas admitidos
    default:
      throw new Error(`Unsupported locale: ${locale}`)
  }
}

export default loadTranslations
