import React from 'react'
import { Link, useNavigate, useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

function UIBackofficeHeader(props) {
  const history = useHistory()
  return (
    <div className='bg-white '>
      <div className='w-full py-6 lg:pl-28 md:pl-20 '>
        <div className='flex flex-col items-center justify-between w-full gap-4 md:flex-row md:pr-5'>
          <div className='flex items-center w-auto gap-x-6'>
            {props.activeReturn && (
              <div className='cursor-pointer' onClick={() => history.goBack()}>
                <FontAwesomeIcon className='text-xl ' icon={faArrowLeft} />
              </div>
            )}{' '}
            <h2 className='flex text-3xl font-bold tracking-tighter md:text-center '>
              {props.title}
            </h2>
          </div>
          <div className='flex w-auto gap-x-6'>
            {props.nameButton ?
              <button
                onClick={props.onClick}
                className='p-2 px-10 text-sm font-semibold text-center rounded-3xl'
                style={{ background: '#00FBC8' }}
              >
                {props.nameButton}
              </button>
              : props.label ? (
                <a
                  href={props.link}
                  className='p-2 px-10 text-sm font-semibold text-center rounded-3xl'
                  style={{ background: '#00FBC8' }}
                >
                  {props.label}
                </a>
              ) : null}
            {props.isProduct && (
              <a
                href='/categorias'
                className='p-2 px-10 text-sm font-semibold text-center text-white rounded-3xl'
                style={{ background: '#8163FF' }}
              >
                Ver categorias
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default UIBackofficeHeader
