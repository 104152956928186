import React, { useContext, useEffect, useState } from 'react'
import Input1 from '../components/Input1'
import axios from 'axios'
import { UserContext } from '../../../context/UserContext'
import Button1 from '../components/Button1'
import Swal from 'sweetalert2'
import Loading from '../../Loading'

const listSocialNetworks = [
  {
    id: 1,
    label: 'Facebook',
    name: 'facebook'
  }
]

const socialNetworksBody = {
  socialmedia: {
    facebook: '',
    twitter: '',
    youtube: '',
    instagram: ''
  }
}

function SocialNetworks() {
  const { user } = useContext(UserContext)
  const [storeData, setStoreData] = useState(socialNetworksBody)
  const [loading, setLoading] = useState(true)

  const onChange = (e) => {
    let { name, value } = e.target
    let _storeData = Object.assign({}, storeData)
    _storeData.socialmedia[name] = value
    setStoreData(_storeData)
  }

  const getCustomer = async () => {
    const url = `https://ventafazil-strapi-production.up.railway.app/clientes/${user}`
    try {
      const response = await axios.get(url)
      const data = response.data
      if (response.status === 200) {
        let _data = {
          socialmedia: data.socialmedia
            ? data.socialmedia
            : {
              facebook: '',
              twitter: '',
              youtube: '',
              instagram: '',
            }
        }
        setStoreData(_data)
      }
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }

  const saveCustomer = async () => {
    setLoading(true)
    const url = `https://ventafazil-strapi-production.up.railway.app/clientes/` + user
    const data = {
      socialmedia: storeData.socialmedia,
    }
    const config = {
      headers: {
        'Authorization': `Bearer ${process.env.REACT_APP_KEY_STRAPI}`,
        'Content-Type': 'application/json'
      }
    }
    try {
      const response = await axios.put(url, data, config)
      if (response.status === 200) {
        setLoading(false)
        Swal.fire('Se guardo las redes sociales', '', 'success')
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getCustomer()
  }, [])

  return (
    <div className='w-full flex flex-col gap-10 mt-5 px-5 py-10 md:mx-0'>
      {loading && <Loading />}
      <div className='flex flex-col gap-3'>
        <h2 className='font-semibold'>Redes sociales</h2>
        <p className='' style={{ color: '#787878' }}>Redes sociales de tu tienda.</p>
      </div>
      <div className='flex flex-col gap-4'>
        <div className='flex gap-10 w-9/12'>
          <Input1
            label='Facebook'
            className='w-full'
            name='facebook'
            placeholder='https://www.facebook.com/mitienda'
            value={storeData.socialmedia.facebook}
            onChange={onChange}
          />
          <Input1
            label='Instagram'
            className='w-full'
            name='instagram'
            placeholder='https://www.instagram.com/mitienda'
            value={storeData.socialmedia.instagram}
            onChange={onChange}
          />
        </div>
        <div className='flex gap-10 w-9/12'>
          <Input1
            label='Tiktok'
            className='w-full'
            name='twitter'
            placeholder='https://www.tiktok.com/mitienda'
            value={storeData.socialmedia.twitter}
            onChange={onChange}
          />
          <Input1
            label='Youtube'
            className='w-full'
            name='youtube'
            placeholder='https://www.youtube.com/mitienda'
            value={storeData.socialmedia.youtube}
            onChange={onChange}
          />
        </div>
      </div>
      <div className='flex justify-end zmx-20 lg:mx-0'>
        <Button1
          textColor='#ffffff'
          background='#8163FF'
          text='Guardar'
          onClick={saveCustomer}
        />
      </div>
    </div>
  )
}

export default SocialNetworks