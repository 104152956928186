import DatePicker from 'react-datepicker'
import { registerLocale, setDefaultLocale } from 'react-datepicker'
import es from 'date-fns/locale/es'
import 'react-datepicker/dist/react-datepicker.css'
registerLocale('es', es)

function DatePickerContainer({ startDate = '', endDate = '', onChangeDate = () => { } }) {
    return (
        <div className='flex gap-5 flex-wrap'>
            <div className='flex flex-col gap-2'>
                <p className='text-center'>Inicio</p>
                <DatePicker
                    className='text-center py-1 border-gray-400 border-2 rounded-lg'
                    placeholderText='Seleccione una fecha'
                    selected={startDate}
                    name={'startDate'}
                    onChange={(e) => onChangeDate(e, 'startDate')}
                    locale={"es"}
                    dateFormat='dd-MM-yyyy'
                />
            </div>
            <div className='flex flex-col gap-2'>
                <p className='text-center'>Fin</p>
                <DatePicker
                    className='text-center py-1 border-gray-400 border-2 rounded-lg'
                    placeholderText='Seleccione una fecha'
                    selected={endDate}
                    name={'endDate'}
                    onChange={(e) => onChangeDate(e, 'endDate')}
                    locale={"es"}
                    dateFormat='dd-MM-yyyy'
                />
            </div>
        </div>
    )
}

export default DatePickerContainer