function TextArea1({ labelName, className, name, placeholder, value, onChange }) {
    return (
        <div className='flex flex-col gap-2 ' style={{ width: '90%' }}>
            <label className='font-bold' style={{ color: '#8C8C8C' }}>
                {labelName}
            </label>
            <textarea
                className={`px-4 py-3 rounded-xl resize-none ${className && className}`}
                style={{ border: '2px solid #D6D6D6' }}
                name={name}
                placeholder={placeholder}
                value={value}
                onChange={(e) => onChange(e)}
            />
        </div>
    )
}

export default TextArea1