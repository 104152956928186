import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  Component,
} from 'react'
import axios from 'axios'
import { UserContext } from '../context/UserContext'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import ReCAPTCHA from 'react-google-recaptcha'

import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import { loginValidator } from '../utils/formValidation'
import { Alert } from './Alert'
import { CurrencyContext } from '../context/CurrencyContext'

function VentaFazilLoginForm(props) {
  let history = useHistory()
  let recaptchaRef = useRef()

  const validationSchema = loginValidator

  const { user, setUser } = useContext(UserContext)
  const currency = useContext(CurrencyContext)
  // const [user, setUser] = useState([])
  const [toggle, setToggle] = useState('password')
  const [restorePassword, setRestorePass] = useState(false)
  const [emailforRestore, setEmailRestore] = useState('')
  const [loaderEmail, setLoaderEmail] = useState(false)
  const [advert, setAdvert] = useState(false)
  const [emailInvalid, setEmailInvalid] = useState(false)
  const initialValues = {
    email: '',
    password: '',
  }

  // const changeUserData = (event) => {
  //   //console.log(event)
  //   let newdataUser = Object.assign({}, userLogin)
  //   newdataUser[event.target.name] = event.target.value
  //   setAdvert(false)
  //   setUserLogin(newdataUser)
  // }
  const sendRestorePass = () => {
    // https://vf-express-production.up.railway.app/restorepassmail
    axios
      .post(`https://vf-express-production.up.railway.app/restorepassmail`, {
        email: emailforRestore,
      })
      .then((response) => {
        if (response.status === 200) {
          setLoaderEmail(true)
          //console.log(response)
        } else {
          setEmailInvalid(true)
          //console.log('error')
        }
      })
      .catch((error) => setEmailInvalid(true))
  }
  const togglePassword = (e) => {
    setToggle(toggle === 'password' ? 'text' : 'password')
  }
  const onChange = (value) => {
    //console.log('Captcha value:', value)
  }
  const onSubmit = async (user) => {
    await axios
      .post(
        `https://ventafazil-strapi-production.up.railway.app/clientes/login`,
        {
          correoelectronico: user.email,
          contrasenha: user.password,
        }
      )
      .then(async (response) => {
        props.setLogged(response.data.user)
        if (response.data.access) {
          setUser(response.data.user.id)
          localStorage.setItem('user', JSON.stringify(response.data.user))
          let _currency = JSON.parse(
            localStorage.getItem('user')
          )?.currencyvalue
          currency.changeCurrency(_currency)

          history.push('/home')
          // this.props.history.push({
          //   pathname: `/`,
          // });
        } else {
          // Alert('Credenciales invalidas')
          setAdvert(true)
        }
      })
      .catch((error) => {
        //console.log(error)
      })
  }

  const formik = useFormik({ initialValues, validationSchema, onSubmit })
  const { handleSubmit, handleChange, values, touched, handleBlur, errors } =
    formik

  return (
    <div>
      {!restorePassword && (
        <form onSubmit={handleSubmit}>
          <div className='grid grid-cols-1 justify-items-center'>
            <div className='w-3/4 mb-6 text-3xl font-semibold tracking-tighter text-center'>
              Iniciar Sesión
            </div>

            <div className='grid w-3/4 grid-cols-1 gap-4 justify-items-center'>
              <input
                value={values.email}
                onChange={handleChange}
                name='email'
                placeholder='Correo electrónico'
                className={`w-full px-6 py-2 text-lg border-2 rounded-lg  ${
                  errors.email && touched.email ? 'border-2 border-red-400	' : ''
                } `}
              ></input>
              {errors.email && touched.email && (
                <div className='text-sm text-red-400'>{errors.email}</div>
              )}

              <div
                className={`w-full flex flex-row items-center border-2 border-gray-200 rounded-lg
               ${
                 errors.password && touched.password
                   ? 'border-2 border-red-400	'
                   : ''
               }
              `}
              >
                <input
                  type={toggle}
                  value={values.password}
                  name='password'
                  onChange={handleChange}
                  placeholder='Contraseña'
                  className={`w-full px-6 py-2 text-lg`}
                ></input>
                {toggle === 'password' ? (
                  <FontAwesomeIcon
                    className='p-2 cursor-pointer hover:bg-primary'
                    icon={faEye}
                    onClick={() => togglePassword()}
                  />
                ) : (
                  <FontAwesomeIcon
                    className='p-2 cursor-pointer hover:bg-primary'
                    icon={faEyeSlash}
                    onClick={() => togglePassword()}
                  />
                )}
              </div>

              {errors.password && touched.password && (
                <div className='text-sm text-red-400'>{errors.password}</div>
              )}
              {advert ? (
                <div className='text-sm text-red-400'>
                  Credenciales invalidas
                </div>
              ) : (
                ''
              )}
              <ReCAPTCHA
                sitekey='6Lc-NYAiAAAAAOwYZa03jlSUfNx8rMz22ocMY4JT'
                onChange={(e) => onChange(e)}
              />
              <button
                type='submit'
                className='px-12 py-2 mt-6 text-lg font-semibold text-center transition-all transform rounded-lg cursor-pointer opacity-80 hover:opacity-100 hover:-translate-y-1 hover:shadow-2xl '
                style={{ background: 'rgb(129, 99, 255)' }}
              >
                Iniciar Ahora
              </button>

              <div
                onClick={() => {
                  setRestorePass(true)
                  setLoaderEmail(false)
                }}
                className='text-lg font-semibold cursor-pointer text-secondary'
              >
                Olvide mi contraseña
              </div>
            </div>
            <div className='flex flex-row gap-2 mt-20'>
              <div className='text-lg'>No tienes una cuenta </div>
              <a href='/'>
                {' '}
                <a
                  href='https://www.powerbizz.com/'
                  target='_blank'
                  className='text-lg font-semibold text-secondary'
                >
                  Registrate
                </a>
              </a>
            </div>
          </div>
        </form>
      )}

      {restorePassword && (
        <div className='grid grid-cols-1 justify-items-center'>
          {loaderEmail === false ? (
            <>
              <div className='w-3/4 mb-6 text-3xl font-semibold tracking-tighter text-center'>
                Recuperar Contraseña
              </div>
              <div className='grid w-3/4 grid-cols-1 gap-4 justify-items-center'>
                <input
                  type='mail'
                  value={emailforRestore}
                  onChange={(e) => setEmailRestore(e.target.value)}
                  name='email'
                  placeholder='Correo electrónico'
                  className='w-full px-6 py-2 text-lg border-2 rounded-lg'
                ></input>
                <label>Se le enviará un mensaje a su bandeja de correo.</label>
                <ReCAPTCHA
                  sitekey='6Lc-NYAiAAAAAOwYZa03jlSUfNx8rMz22ocMY4JT'
                  onChange={(e) => onChange(e)}
                />
                <div
                  onClick={() => sendRestorePass()}
                  className='px-12 py-2 mt-6 text-lg font-semibold text-center transition-all transform rounded-lg cursor-pointer opacity-80 hover:opacity-100 hover:-translate-y-1 hover:shadow-2xl bg-primary'
                >
                  Recuperar contraseña
                </div>
              </div>
            </>
          ) : (
            <>
              <div className='w-3/4 mb-6 font-semibold tracking-tighter text-center text-md'>
                Se ha enviado un mensaje a su correo electrónico, siga los pasos
                para recuperar su cuenta.
              </div>
              <div
                onClick={() => setRestorePass(false)}
                className='px-12 py-2 mt-6 text-lg font-semibold text-center transition-all transform rounded-lg cursor-pointer opacity-80 hover:opacity-100 hover:-translate-y-1 hover:shadow-2xl bg-primary'
              >
                Regresar al login
              </div>
            </>
          )}

          {restorePassword && emailInvalid && (
            <>
              <div className='w-3/4 mb-6 font-semibold tracking-tighter text-center text-md'>
                Este email no existe
              </div>
            </>
          )}
        </div>
      )}
    </div>
  )
}

// class VentaFazilLoginForm extends Component {
//   static contextType = UserContext;
//   constructor(props) {
//     super(props);
//     this.state = {
//       user: [],
//       toggle: 'password',
//       restorePassword: false,
//       emailforRestore: "",
//       loaderEmail: false,
//       advert:false,
//       userLogin: {
//         email: "",
//         password: "",
//       },
//     };
//     this.changeUserData = this.changeUserData.bind(this);
//   }
//   changeUserData = (event) => {
//     let newdataUser = Object.assign({}, this.state.userLogin);
//     newdataUser[event.target.name] = event.target.value;
//     this.setState({advert: false})
//     this.setState({ userLogin: newdataUser });
//   };
//   sendRestorePass = () =>{
//     axios.post(
//         `https://vf-express-production.up.railway.app/restorepassmail`,
//         {
//           email: this.state.emailforRestore,
//         }
//       )
//       .then((response) => {
//         //console.log(response);
//         this.setState({loaderEmail: true})

//       })
//       .catch((error) => {
//         //console.log(error);
//       });
//   }
//   togglePassword = (e) =>{
//     this.setState({toggle: this.state.toggle === 'password' ? 'text' : 'password'})
//   }
//   loginUser = async (user) => {
//     await axios
//       .post(
//         `https://ventafazil-strapi-production.up.railway.app/clientes/login`,
//         {
//           correoelectronico: user.email,
//           contrasenha: user.password,
//         }
//       )
//       .then(async (response) => {
//         //console.log(response.data);
//         this.props.setLogged(response.data.user);
//         if (response.data.access) {
//           this.context.setUser(response.data.user.id);
//           localStorage.setItem("user", JSON.stringify(response.data.user));
//           this.props.history.push({
//             pathname: `/`,
//           });
//         }else{
//           this.setState({advert: true})
//         }
//       })
//       .catch((error) => {
//         //console.log(error);
//       });
//   };
//   render() {
//     return (
//       <div>
//         {
//         this.state.restorePassword === false ?
//         <div className='grid grid-cols-1 justify-items-center'>
//           <div className='w-3/4 mb-6 text-3xl font-semibold tracking-tighter text-center'>
//             Iniciar Sesión
//           </div>
//           <div className='grid w-3/4 grid-cols-1 gap-4 justify-items-center'>
//             <input
//               type='mail'
//               value={this.state.userLogin.email}
//               onChange={this.changeUserData}
//               name='email'
//               placeholder='Correo electrónico'
//               className='w-full px-6 py-2 text-lg border-2 rounded-lg'
//             ></input>
//             <div className='flex flex-row items-center w-full border-2 border-gray-200 rounded-lg'>
//               <input
//                 type={this.state.toggle}
//                 value={this.state.userLogin.password}
//                 name='password'
//                 onChange={this.changeUserData}
//                 placeholder='Contraseña'
//                 className='w-full px-6 py-2 text-lg'
//               ></input>
//               {this.state.toggle === 'password' ?
//               <FontAwesomeIcon
//                 className='p-2 cursor-pointer hover:text-red-500'
//                 icon={faEye}
//                 onClick={()=>this.togglePassword()}
//               /> :
//               <FontAwesomeIcon
//                 className='p-2 cursor-pointer hover:text-red-500'
//                 icon={faEyeSlash}
//                 onClick={()=>this.togglePassword()}
//               />}
//             </div>

//             {this.state.advert ? <div className='text-lg text-red-400'>La contraseña es incorrecta</div> :""}
//             <div
//               onClick={() => this.loginUser(this.state.userLogin)}
//               className='px-12 py-2 mt-6 text-lg font-semibold text-center transition-all transform rounded-lg cursor-pointer opacity-80 hover:opacity-100 hover:-translate-y-1 hover:shadow-2xl bg-primary'
//             >
//               Iniciar Ahora
//             </div>

//             <div onClick={() => this.setState({restorePassword:true, loaderEmail: false})}className='text-lg font-semibold cursor-pointer text-secondary'>
//               Olvide mi contraseña
//             </div>
//           </div>
//           <div className='flex flex-row gap-2 mt-20'>
//             <div className='text-lg'>No tienes una cuenta </div>
//             <a href='/'>
//               {" "}
//               <a href="https://www.ventafacil.app/registro" target="_blank" className='text-lg font-semibold text-secondary'>
//                 Registrate
//               </a>
//             </a>
//           </div>
//         </div>
//         :
//         <div className='grid grid-cols-1 justify-items-center'>
//           {
//             this.state.loaderEmail === false ?
//           <>
//             <div className='w-3/4 mb-6 text-3xl font-semibold tracking-tighter text-center'>
//               Recuperar Contraseña
//             </div>
//             <div className='grid w-3/4 grid-cols-1 gap-4 justify-items-center'>
//               <input
//                 type='mail'
//                 value={this.state.emailforRestore}
//                 onChange={(e)=>this.setState({emailforRestore: e.target.value})}
//                 name='email'
//                 placeholder='Correo electrónico'
//                 className='w-full px-6 py-2 text-lg border-2 rounded-lg'
//               ></input>
//               <label>Se le enviará un mensaje a su bandeja de correo.</label>
//               <div
//                 onClick={() => this.sendRestorePass()}
//                 className='px-12 py-2 mt-6 text-lg font-semibold text-center transition-all transform rounded-lg cursor-pointer opacity-80 hover:opacity-100 hover:-translate-y-1 hover:shadow-2xl bg-primary'
//               >
//                 Recuperar contraseña
//               </div>

//             </div>
//           </>
//           :
//           <>
//             <div className='w-3/4 mb-6 font-semibold tracking-tighter text-center text-md'>
//                 Se ha enviado un mensaje a su correo electrónico, siga los pasos para recuperar su cuenta.
//             </div>
//             <div
//                 onClick={() => this.setState({restorePassword: false})}
//                 className='px-12 py-2 mt-6 text-lg font-semibold text-center transition-all transform rounded-lg cursor-pointer opacity-80 hover:opacity-100 hover:-translate-y-1 hover:shadow-2xl bg-primary'
//               >
//                 Regresar al login
//               </div>
//           </>
//           }
//         </div>
//         }
//       </div>
//     );
//   }
// }

export default VentaFazilLoginForm
