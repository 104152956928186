import React from 'react'

function Button1({ text, onClick, textColor, background, border, className }) {
   return (
      <button
         className={`w-full py-3 px-5 font-semibold text-center rounded-full ${className}`}
         style={{ color: textColor, background: background, border: border }}
         onClick={onClick}
      >
         {text}
      </button>
   )
}

export default Button1