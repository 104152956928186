function Button1({ width, textColor, background, border, className, text, onClick }) {
    return (
        <button
            className={`w-fit py-3 px-5 font-semibold text-center rounded-full ${className}`}
            style={{ width: width, color: textColor, background: background, border: border }}
            onClick={onClick}
        >
            {text}
        </button>
    )
}

export default Button1