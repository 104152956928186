import NavigationTop from '../../NavigationTop'
import NavigationSide from '../../NavigationSide'
import UIBackofficeHeader from '../../UIBackofficeHeader'
import InputLabel from '../components/InputLabel'
import { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../../context/UserContext'
import axios from 'axios'
import MethodPeru from './MethodPeru'
import MethodMexicoClip from './MethodMexicoClip'
import Swal from 'sweetalert2'
import { Alert } from '../../Alert'

function PaymentMethods() {
  const { user } = useContext(UserContext)

  const [dataKeychain, setDataKeychain] = useState({
    _id: '',
    userapirest: '',
    endpoint: '',
    passwordtestapirest: '',
    passwordproductionapirest: '',
    publickeytestjavascript: '',
    publickeyproductionjavascript: '',
    keytestkrhash: '',
    keyproductionkrhash: '',
  })

  const onChange = (e) => {
    let { name, value } = e.target
    setDataKeychain({ ...dataKeychain, [name]: value })
  }

  const savePaymentMethods = async () => {
    const url = `https://ventafazil-strapi-production.up.railway.app/Keychains/${dataKeychain._id}`
    const config = {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_KEY_STRAPI}`,
        'Content-Type': 'application/json',
      },
    }
    const data = {
      userapirest: dataKeychain.userapirest,
      endpoint: dataKeychain.endpoint,
      passwordtestapirest: dataKeychain.passwordtestapirest,
      passwordproductionapirest: dataKeychain.passwordproductionapirest,
      publickeytestjavascript: dataKeychain.publickeytestjavascript,
      publickeyproductionjavascript: dataKeychain.publickeyproductionjavascript,
      keytestkrhash: dataKeychain.keytestkrhash,
      keyproductionkrhash: dataKeychain.keyproductionkrhash,
    }

    const url1 =
      `https://ventafazil-strapi-production.up.railway.app/clientes/` + user

    try {
      if (boolPagos.activeizi || boolPagos.activewhatsapp) {
        const response = await axios.put(url, data, config)
        console.log('boolpagos', boolPagos)
        const response1 = await axios.put(url1, boolPagos, config)

        if (response.status === 200) {
          Swal.fire('Se guardó correctamente los cambios', '', 'success')
        }
      } else {
        const toastAlert = (title) => {
          const Toast = Swal.mixin({
            toast: true,
            position: 'top-right',
            iconColor: 'white',
            customClass: {
              popup: 'colored-toast',
            },
            showConfirmButton: false,
            timer: 2500,
            timerProgressBar: true,
          })
          Toast.fire({
            icon: 'error',
            title: title,
          })
        }
        toastAlert('Debe estar seleccionado al menos un metodo de pago')
      }

      //   }
    } catch (error) {
      console.log(error)
    }
  }

  const getKeychain = async () => {
    console.log('user', user)
    const url = `https://ventafazil-strapi-production.up.railway.app/Keychains?cliente=${user}`
    const config = {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_KEY_STRAPI}`,
        'Content-Type': 'application/json',
      },
    }

    try {
      const response = await axios.get(url, config)
      console.log('GET KEYCHAIN', response.data)
      if (response.status === 200) {
        const data = response.data[0]
        setDataKeychain({
          _id: data._id,
          userapirest: data.userapirest,
          endpoint: data.endpoint,
          passwordtestapirest: data.passwordtestapirest,
          passwordproductionapirest: data.passwordproductionapirest,
          publickeytestjavascript: data.publickeytestjavascript,
          publickeyproductionjavascript: data.publickeyproductionjavascript,
          keytestkrhash: data.keytestkrhash,
          keyproductionkrhash: data.keyproductionkrhash,
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const [boolPagos, setboolPagos] = useState({})
  const getCustomer = async () => {
    const url = `https://ventafazil-strapi-production.up.railway.app/clientes/${user}`
    try {
      const response = await axios.get(url)
      const data = response.data
      if (response.status === 200) {
        console.log('accounttt', data)

        let _data = {
          activeizi: data.activeizi,
          activewhatsapp: data.activewhatsapp,
        }

        setboolPagos(_data)
        console.log('fff', _data)
      }
    } catch (error) {
      console.log(error)
    }
    // setLoading(false)
  }

  useEffect(() => {
    getKeychain()
    getCustomer()
  }, [])

  const handleOpcion1Change = () => {
    setboolPagos({
      activeizi: !boolPagos.activeizi,
      activewhatsapp: boolPagos.activewhatsapp,
    })
  }

  const handleOpcion2Change = () => {
    setboolPagos({
      activeizi: boolPagos.activeizi,
      activewhatsapp: !boolPagos.activewhatsapp,
    })
  }
  const _localstorage = localStorage.getItem('user')

  const pais = JSON.parse(_localstorage).paises.name
  console.log('pais: ', pais)

  const pasarella = () => {
    switch (pais) {
      case 'Mexico':
        return (
          <MethodMexicoClip
            onChange={onChange}
            dataKeychain={dataKeychain}
            disabled={!boolPagos.activeizi}
          />
        )
        break
      case 'Peru':
        return (
          <MethodPeru
            onChange={onChange}
            dataKeychain={dataKeychain}
            disabled={!boolPagos.activeizi}
          />
        )

      default:
        break
    }
  }

  return (
    <div className='pb-24 w-fit lg:w-full min-w-full Zoverflow-hidden'>
      <NavigationTop />
      <NavigationSide />
      <div className='md:ml-28 md:mr-10 md:mt-5'>
        <UIBackofficeHeader
          title='Metodos de pago'
          nameButton='Guardar Cambios'
          onClick={savePaymentMethods}
        />

        <div className='Zw-fit lg:w-auto flex flex-col gap-12 md:ml-20 lg:ml-28 px-5 md:px-0'>
          <div>
            <label>
              <input
                type='checkbox'
                checked={boolPagos.activeizi}
                onChange={handleOpcion1Change}
              />
              <span className='ml-4'>Activar Pasarela</span>
            </label>
            <br />
            {/* dd */}
            {pasarella()}
            <label className=''>
              <input
                className='mt-8'
                type='checkbox'
                checked={boolPagos.activewhatsapp}
                onChange={handleOpcion2Change}
              />
              <span className='ml-4'> Activar Pidelo por Whatsapp</span>
            </label>
            <form>
              <p>El numero de wsp es colocado en tienda</p>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PaymentMethods
