import axios from 'axios'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import CustomCheckbox from '../components/CustomCheckbox'
import NavigationSide from '../components/NavigationSide'
import NavigationTop from '../components/NavigationTop'
import Swal from 'sweetalert2'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPencil } from '@fortawesome/free-solid-svg-icons'
import { useContext } from 'react'

const TiendasRecojo = () => {
  const [data, setData] = useState([])
  const clientID = JSON.parse(localStorage.getItem('user')).id

  // let pais = JSON.parse(localStorage.getItem('user')).paises
  // let country = pais.id
  let pais = JSON.parse(localStorage.getItem('user')).paises || null

  const [loading, setLoading] = useState(true)

  const consultarData = async () => {
    const url = `https://ventafazil-strapi-production.up.railway.app/Pickpoints/?cliente=${clientID}&country=${pais?.id}`
    try {
      const response = await axios.get(url)
      setData(response.data)
      console.log(data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    consultarData()
  }, [])

  const handleCheckboxChange = (tienda) => {
    const updatedItems = data.map((item, i) => {
      if (item._id === tienda._id) {
        return { ...item, isactive: !item.isactive }
      }
      return item
    })

    try {
      putPickPoints(tienda)
      setData(updatedItems)
    } catch (error) {}
  }
  
  const putPickPoints = async (tienda) => {
    let changeState = {
      isactive: !tienda.isactive,
    }
    try {
      console.log(data)
      await axios.put(
        `https://ventafazil-strapi-production.up.railway.app/Pickpoints/${tienda._id}`,
        changeState,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_KEY_STRAPI}`,

            'Content-Type': 'application/json',
          },
        }
      )
      // Swal.fire('Se Edito un punto de recojo con exito ', '', 'success')

      const Toast = Swal.mixin({
        toast: true,
        position: 'top-right',
        iconColor: 'white',
        customClass: {
          popup: 'colored-toast',
        },
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
      })
      Toast.fire({
        icon: 'success',
        title: 'Se edito un punto de recojo con exito',
      })

      consultarData()
    } catch (error) {
      console.log(error)
    }
  }

  const deletePickPoint = (id, index) => {
    let config = {
      method: 'delete',
      url: `https://ventafazil-strapi-production.up.railway.app/Pickpoints/${id}`,
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_KEY_STRAPI}`,
      },
    }
    axios(config)
      .then((response) => {
        if (response.status == 200) {
          let bodyModal = {
            title: 'Categoria eliminada',
            text: 'Se ha eliminada la categoria', //response.statusText
          }
          consultarData()
          // setViewModal(true)
          // setContentModal(bodyModal)

          // Swal.fire({
          //   text: 'Se elemingoun producto con exito',
          //   target: '#custom-target',
          //   customClass: {
          //     container: 'position-absolute',
          //   },
          //   toast: true,
          //   position: 'top-right',
          // })

          const Toast = Swal.mixin({
            toast: true,
            position: 'top-right',
            iconColor: 'white',
            customClass: {
              popup: 'colored-toast',
            },
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
          })
          Toast.fire({
            icon: 'success',
            title: 'Se elimino un producto con exito',
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  if (loading) {
    return (
      <div className=''>
        <NavigationTop />
        <NavigationSide />
        <div className='pb-32 bg-white md:ml-40 md:p-6'>CARGANDO</div>
      </div>
    )
  }

  if (!pais) {
    return (
      <div className=''>
        <NavigationTop />
        <NavigationSide />
        <div className='pb-32 bg-white md:ml-40 md:p-6'>
          <div className='flex justify-between'>
            <h1 className='font-bold text-2xl'>Puntos de Recojo</h1>
          </div>

          <div>Aun no tienes tu punto de recojo configurado :</div>
        </div>
      </div>
    )
  }

  return (
    <div className=''>
      <NavigationTop />
      <NavigationSide />
      <div className='pb-32 bg-white md:ml-40 md:p-6'>
        <div className='flex justify-between'>
          <h1 className='font-bold text-2xl'>Puntos de Recojo</h1>
          <a
            href='/delivery/tienda-recojo/nuevo'
            className='p-2 px-10 text-sm font-bold text-center bg-yellow-400 rounded-md '
            // onClick={crearPedido}
          >
            Agregar
          </a>
        </div>

        {data.length < 1 && (
          <div>Aun no has creado ningun punto de recojo :</div>
        )}
        <div className=''>
          {data.length >= 1 && (
            <div>
              <h1 className='font-bold'>Tarifas</h1>
              <div className='grid gap-3'>
                {data.map((tienda, i) => {
                  return (
                    <>
                      <div
                        className='bg-slate-100 w-5/5 p-2 flex   '
                        key={tienda._id}
                      >
                        <div className='w-11/12 flex items-center '>
                          {' '}
                          <CustomCheckbox
                            checked={tienda.isactive}
                            onChange={() => handleCheckboxChange(tienda)}
                          />
                          {tienda.name}
                        </div>

                        <div className='text-right   justify-items-end'>
                          <Link
                            to={{
                              pathname: '/delivery/tienda-recojo/edit',
                              state: { id: tienda._id, isEdit: true },
                            }}
                          >
                            <FontAwesomeIcon
                              className='hover:text-amber-500 p-2 cursor-pointer'
                              // onClick={() => changeEditMode(i, category.isEdit)}
                              icon={faPencil}
                            />
                          </Link>

                          <FontAwesomeIcon
                            className='hover:text-red-600 p-2 cursor-pointer'
                            onClick={() => deletePickPoint(tienda._id, i)}
                            icon={faTrash}
                          />
                        </div>
                      </div>
                    </>
                  )
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default TiendasRecojo
