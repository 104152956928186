import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import React, { useState, useEffect, useContext } from 'react'
import NavigationSide from '../components/NavigationSide'
import NavigationTop from '../components/NavigationTop'
import UIinput from '../components/UIinput'
import UIinputLabeled from '../components/UIinputLabeled'
import ImageUploader from '../components/ImageUploader'
import { UserContext } from '../context/UserContext'
import ModalMessage from '../components/ModalMessage'
import Select, { components } from 'react-select'
import combinate from 'combinate'

import { Link } from 'react-router-dom'
import VaritionsUpdater from '../components/VariationsUpdater'
import ExtrasUpdater from '../components/ExtrasUpdater'

import ModalImageCrop from '../components/ModalImageCrop'
import VariationsUpdater1 from '../components/VariationsUpdater1'
import VariantsTable from '../components/VariantsTable'
import { CurrencyContext } from '../context/CurrencyContext'
import { number } from 'yup'

const customStyles = {
  input: (styles) => ({ ...styles, height: '2.6rem' }),
  // placeholder: (styles) => ({ ...styles, fontSize:'1.5rem' }),
  // option: (styles) => ({...styles, fontSize: '1.5rem'}),
  // singleValue: (styles) => ({ ...styles, fontSize:'1.5rem' }),
}

function ProductoNuevo() {
  const { user, setUser } = useContext(UserContext)
  //COMBIBANCIONES
  const [combinaciones1, setCombinaciones1] = useState()

  const [productsVariants, setProductsVariants] = useState([])

  const [isProductStock, setisProductStock] = useState(false)
  const [IsProductStockVar, setIsProductStockVar] = useState([])
  const [errors, setErrors] = useState({})
  const [errorsVariations, setErrorsVariations] = useState({})
  const [errorsExtra, setErrorsExtra] = useState({})
  const [errorsTable, setErrorsTable] = useState({})
  const [hasVariants, setHasVariants] = useState(false)
  const currencyValue = useContext(CurrencyContext)
  const currency = currencyValue.currency
  const [minLengths, setMinLengths] = useState({
    nombreproducto: 6,
    detalladoproducto: 3,
    codigoproducto: 3,
    precio: 1,
    stock: 1,
    variations: 3,
    productvariations: 1,
    variationsName: 1,
    variationsValue: 1,
    extras: 3,
    extrasName: 3,
    extrasPrice: 1,
  })

  // NUM 11
  const [producto, setProducto] = useState({
    imagenproducto: '',
    nombreproducto: '',
    precio: '',
    codigoproducto: '',
    detalladoproducto: '',
    categorias: [],
    image: [],
    stock: '',
    isproductstock: true,
    istrending: false,
    cliente: {
      _id: '',
    },
    extras: [],
    variations: [],
    productvariations: [],
    isnewproduct: true,
  })
  const [optionsCategory, setOptionsCategory] = useState([])
  const [listUserCategory, setListUserCategory] = useState([])
  const [viewModal, setViewModal] = useState(false)
  const [contentModal, setContentModal] = useState({
    title: '',
    text: '',
    linkgo: '',
  })
  const [imageList, setImageList] = useState([])
  const [variaciones, setVariaciones] = useState(false)
  const [loader, setLoader] = useState(true)

  const [showModalCropImage, setShowModalCropImage] = useState(false)
  const resolution = {
    width: 500,
    height: 500,
  }
  const aspectRatio = 16 / 9
  const changeListImage = (val) => {
    setImageList(val)
  }
  const getListCategorys = () => {
    let user = JSON.parse(localStorage.getItem('user'))
    let config = {
      method: 'get',
      url: `https://ventafazil-strapi-production.up.railway.app/categorias/?cliente=${user._id}`,
    }
    axios(config)
      .then((response) => {
        ////console.log(response)
        let categories = response.data.map((el) => {
          let body = { value: el.id, label: el.nombrecategoria }
          return body
        })
        setOptionsCategory(categories)
      })
      .catch((error) => {
        //console.log(error)
      })
  }
  useEffect(() => {
    getListCategorys()
  }, [])
  const changeInputDecimal = (event) => {
    let start = 0
    let input = ''
    start = event.target.selectionStart
    let name = event.target.name
    let val = event.target.value
    val = val.replace(/([^0-9.]+)/, '')
    val = val.replace(/^(0|\.)/, '')
    const match = /(\d{0,7})[^.]*((?:\.\d{0,2})?)/g.exec(val)
    const value = match[1] + match[2]
    input = value
    if (val.length > 0) {
      event.target.value = Number(value).toFixed(2)
      event.target.setSelectionRange(start, start)
      input = Number(value).toFixed(2)
      // newstore[event.target.name] = input
      return input
    }
  }
  const changeInputProduct = (event) => {
    // //console.log("selectionStart=========", event.target.selectionStart)
    let name = event.target.name
    let value = event.target.value
    let newstore = Object.assign({}, producto)

    if (name == 'precio') {
      newstore[event.target.name] = changeInputDecimal(event)
      // } else if (name == 'stock') {
      //   if (value == 0) {
      //     newstore[event.target.name] = 1
      //   } else newstore[event.target.name] = event.target.value
    } else {
      newstore[event.target.name] = event.target.value
    }
    setProducto(newstore)
    // //console.log("errors",errors)
    // //console.log("errorsVariations",errorsVariations)
    // //console.log("errorsExtra",errorsExtra)
  }
  const onBlur = (event) => {
    changeInputProduct(event)
    setErrors(validation())
    // //console.log("ERRORS==", errors)
  }
  const validation = () => {
    let product = producto
    let minLength = minLengths
    let errors = {}

    let regexName = /^[A-Za-z0-9ÑñÁáÉéÍíÓóÚúÜü!:/()\s]+$/
    let regexSpecialLetters =
      /^[a-zA-Z0-9ÑñÁáÉéÍíÓóÚúÜü!"#$%&'()*+,.:;=^_`~¨?@|{}/\[\]\\\-\s¡\p{Emoji_Presentation}]+$/u

    let regexLettersNumbers = /^[A-Za-z0-9ÑñÁáÉéÍíÓóÚúÜü\s]+$/
    let regexNumber = /^[0-9]+$/
    let regexDecimal = /^[0-9.]+$/
    let regexVariations = /^[A-Za-z0-9ÑñÁáÉéÍíÓóÚúÜü~\s]+$/
    let regexExtras =
      /^[a-zA-Z0-9ÑñÁáÉéÍíÓóÚúÜü!"#$%&'()*+,.:;=^_`~¨@{}/\[\]\\\-\s]+$/

    if (!product.nombreproducto.trim()) {
      errors.nombreproducto = "El campo 'Producto' es requerido"
    } else if (!regexName.test(product.nombreproducto.trim())) {
      errors.nombreproducto =
        "El campo 'Producto' solo acepta letras, números, espacios en blanco y estos caraceteres ! : / ( )"
    } else if (product.nombreproducto.length < minLength.nombreproducto) {
      errors.nombreproducto =
        "El campo 'Producto' debe tener mínimo " +
        minLength.nombreproducto +
        ' caracteres'
    }
    if (!product.detalladoproducto.trim()) {
      // errors.detalladoproducto = "El campo 'INFORMACION GENERAL' es requerido"
    }

    // else if (!regexSpecialLetters.test(product.detalladoproducto.trim())) {
    //   errors.detalladoproducto =
    //     "El campo 'INFORMACION GENERAL' solo acepta letras, números, espacios en blanco y estos caraceteres " +
    //     '! " # $ % & \' ( ) * + , - . / : ; = ? @ [ \\ ] ^ _ ` { | } ~ ¨ '
    // }
    else if (product.detalladoproducto.length < minLength.detalladoproducto) {
      errors.detalladoproducto =
        "El campo 'INFORMACION GENERAL' debe tener mínimo " +
        minLength.detalladoproducto +
        ' caracteres'
    }
    if (!product.codigoproducto.trim()) {
      // errors.codigoproducto = "El campo 'Código de producto' es requerido"
    } else if (!regexLettersNumbers.test(product.codigoproducto.trim())) {
      errors.codigoproducto =
        "El campo 'Código de producto' solo acepta letras, números y espacios en blanco"
    } else if (product.codigoproducto.length < minLength.codigoproducto) {
      errors.codigoproducto =
        "El campo 'Código de producto' debe tener mínimo " +
        minLength.codigoproducto +
        ' caracteres'
    }
    if (!product.precio?.trim()) {
      if (hasVariants) {
        // errors.precio = ""
        delete errors.precio
      } else {
        errors.precio = "El campo 'Precio' es requerido"
      }
    } else if (!regexDecimal.test(product.precio?.trim())) {
      errors.precio = "El campo 'Precio' solo acepta números"
    } else if (parseFloat(product.precio) < minLength.precio) {
      errors.precio =
        "El campo 'Precio' debe ser igual o mayor a " +
        currency +
        minLength.precio
    }
    // //console.log("STOCK",typeof(product.stock),product.stock)
    if (!product.stock.trim()) {
      if (isProductStock || hasVariants) {
        // errors.stock = ""
        delete errors.stock
      } else {
        errors.stock = "El campo 'Stock' es requerido"
      }
    } else if (!regexNumber.test(product.stock.trim())) {
      errors.stock = "El campo 'Stock' solo acepta números enteros"
    } else if (product.stock.length < minLength.stock) {
      errors.stock =
        "El campo 'Stock' debe tener mínimo " + minLength.stock + ' caracteres'
    }

    return errors
  }
  const changeSwitch = (event) => {
    let newProduct = {
      ...producto,
      istrending: !producto.istrending,
    }
    setProducto(newProduct)
  }

  const createProduct = () => {
    // //console.log("ERRORS", errors)
    // //console.log("ERRORS VARIATIONS", errorsVariations)
    // //console.log("ERRORS EXTRA", errorsExtra)
    setErrors(validation())

    let arrayError = []
    if (combinaciones1?.length > 0) {
      // for (let i = 0; i < combinaciones1.length; i++) {
      //   let _errors = {}
      //   let elem = combinaciones1[i]
      //   if (elem.precio === 0) elem.precio = ""
      //   if (elem.stock === '') Number(elem.stock = 0)
      //   // //console.log(i, " ELEMENTO", elem)
      //   let indexPrecio = Object.keys(elem).findIndex(el => el == 'precio')
      //   let _nameP = Object.keys(elem)[indexPrecio]
      //   let _valueP = elem.precio
      //   // //console.log(_nameP, typeof (_valueP), _valueP)
      //   arrayError.push = validateTable(null, _nameP, _valueP, i)
      //   //console.log("1===ERRORS", arrayError)
      //   // setErrorsTable(validateTable(null, _nameP, _valueP, i))

      //   let indexStock = Object.keys(elem).findIndex(el => el == 'stock')
      //   let _nameS = Object.keys(elem)[indexStock]
      //   let _valueS = elem.stock
      //   // //console.log(_nameS, typeof (_valueS), _valueS)
      //   arrayError.push = validateTable(null, _nameS, _valueS, i)
      //   //console.log("2===ERRORS", arrayError)
      //   // setErrorsTable(validateTable(null, _nameS, _valueS, i))

      //   // setErrorsTable(_errorsTable)
      // }
      let allError = Object.assign({}, errorsTable)
      combinaciones1.forEach((elem, i) => {
        let newError = {}
        if (elem.precio === 0) elem.precio = ''
        if (elem.stock === '') Number((elem.stock = 0))
        let indexPrecio = Object.keys(elem).findIndex((el) => el == 'precio')
        let _nameP = Object.keys(elem)[indexPrecio]
        let _valueP = elem.precio
        let indexStock = Object.keys(elem).findIndex((el) => el == 'stock')
        let _nameS = Object.keys(elem)[indexStock]
        let _valueS = elem.stock
        // //console.log(i, "newError", newError)
        setErrorsTable(validateTable(null, _nameP, _valueP, i))
        setErrorsTable(validateTable(null, _nameS, _valueS, i))
      })
      console.log('OUT FOREACH', allError)
    }
    let noError =
      Object.keys(errors).length === 0 &&
      Object.keys(errorsVariations).length === 0 &&
      Object.keys(errorsExtra).length === 0 &&
      Object.keys(errorsTable).length === 0
    let noEmpy
    if (hasVariants) {
      noEmpy = producto.nombreproducto.trim().length > 0
    } else if (isProductStock) {
      noEmpy =
        producto.nombreproducto.trim().length > 0 &&
        producto.precio.trim().length > 0
    } else {
      noEmpy =
        producto.nombreproducto.trim().length > 0 &&
        producto.precio.trim().length > 0 &&
        producto.stock.trim().length > 0
    }
    if (noError && noEmpy) {
      let copyprod = Object.assign(producto)
      // let copyprod = Object.assign(
      //   setProducto({ ...producto, productvariations: combinaciones1 })
      // )

      copyprod.categorias = copyprod.categorias.map((el) => el.value)
      copyprod.cliente._id = user
      if (copyprod.imagenproducto === '') {
        copyprod.imagenproducto =
          imageList && imageList.length > 0 ? imageList[0].url : ''
      }
      copyprod.image = imageList
      copyprod.productvariations = combinaciones1
      //console.log(' copyprod.productvariations', copyprod.productvariations)
      //console.log(' combinaciones1', combinaciones1)

      let config = {
        method: 'post',
        url: `https://ventafazil-strapi-production.up.railway.app/productos`,
        data: copyprod,
      }
      axios(config)
        .then((response) => {
          let bodyModal = {
            title: 'Producto creado',
            text: 'Acaba de crear un producto nuevo',
            linkgo: '/productos/',
          }
          setViewModal(true)
          setContentModal(bodyModal)
        })
        .catch((error) => {
          //console.log(error)
        })
    } else {
      //console.log('HAY ERRORES NO SE PUEDE GUARDAR')
    }
  }

  const changeImgURL = (val) => {
    let newbody = {
      ...producto,
      imagenproducto: val,
    }
    setProducto(newbody)
  }

  const changeCategories = (listUserCategory) => {
    // stado.categorias = listUserCategory;
    setProducto({
      ...producto,
      categorias: listUserCategory,
    })
  }

  const closeModal = (val) => {
    setViewModal(val)
  }

  const changeContentModal = (val) => {
    setContentModal(val)
  }

  const changeVariations = (val, i) => {
    // //console.log("ERRORS", errors)
    // //console.log("ERRORS VARIATIONS", errorsVariations)
    // //console.log("ERRORS EXTRA", errorsExtra)
    let prod = producto
    prod.variations = val
    combinates(producto)
    if (val.length > 0) {
      setHasVariants(true)
      setProducto({ ...producto, precio: '', stock: '' })

      delete errors.precio
      delete errors.stock
    } else {
      setHasVariants(false)
    }
    if (i > -1) {
      setErrorsVariations(validateVariations(i))
    } else {
      // //console.log("ErrorVariations", errorsVariations)
      prod.variations.forEach((e, index) => {
        setErrorsVariations(validateVariations(index))
      })
    }
    // //console.log("val", val)
  }

  const combinates = (p) => {
    //console.log('p', p)
    //console.log('combinaciones111', combinaciones1)
    const combinates = {}
    // //console.log('MI P', p)

    //console.log('PR VACC', producto.variations)
    //console.log('combinaciones112', combinaciones1)

    producto.variations.length >= 1 &&
      producto.variations?.forEach((p) => {
        combinates[p.name] = p.values
      })
    //console.log('combinates----1', combinates)
    const options = combinate(combinates)
    //console.log('combinaciones113', combinaciones1)

    // //console.log('probando conbinacion---', options)
    const prOptions = []
    //console.log('MIS OPCIONES', options)
    options.forEach((option, i) => {
      // prOptions.push({
      //   options: option,
      //   precio: producto?.productvariations[i]?.precio || 0,
      //   isStockIlimit: producto?.productvariations[i]?.isStockIlimit || false,
      //   stock: producto?.productvariations[i]?.stock || '',
      // })
      //console.log('OPTION111111', option)
      //console.log('combinaciones114', combinaciones1)
      prOptions.push({
        options: option,
        precio: combinaciones1[i]?.precio || 0,
        isStockIlimit: combinaciones1[i]?.isStockIlimit || false,
        stock: combinaciones1[i]?.stock || '',
      })
      //console.log('combinaciones115', combinaciones1)

      //console.log('PRBAD')
    })

    setCombinaciones1(prOptions)

    //estaes
    // setCombinaciones1([...prOptions])
    // setProducto({ ...producto, productvariations: combinaciones1 })

    // //console.log('secom ', prOptions)
    // setCombinaciones1(combinate({ options }))
  }

  const validateVariations = (i) => {
    let errors = { ...errorsVariations }
    let minLength = minLengths
    let variations = producto.variations
    let name = variations[i]?.name
    let values = variations[i]?.values
    let regexVariations = /^[A-Za-z0-9ÑñÁáÉéÍíÓóÚúÜü~\s]+$/

    if (!name?.trim()) {
      // errors.variations = "El campo 'Opción' es requerido"
      errors['variationsName' + i] = ''
    } else if (!regexVariations.test(name.trim())) {
      errors['variationsName' + i] =
        "El campo 'Opción' solo acepta letras, números, espacios en blanco y estos caraceteres ~"
    } else if (name.length < minLength.variationsName) {
      errors['variationsName' + i] =
        "El campo 'Opción' debe tener mínimo " +
        minLength.variationsName +
        ' caracteres'
    } else {
      errors['variationsName' + i] = ''
    }
    if (errors['variationsName' + i] == '') {
      delete errors['variationsName' + i]
    }

    return errors
  }

  const changeExtras = (val, i) => {
    let prod = producto
    prod.extras = val
    setProducto(prod)
    if (i > -1) {
      setErrorsExtra(validateExtra(val, i))
    } else {
      prod.extras.forEach((e, index) => {
        setErrorsExtra(validateExtra(val, index))
      })
    }
    // //console.log("ERRORS EXTRA", errorsExtra)
  }

  const validateExtra = (val, i) => {
    let errors = { ...errorsExtra }
    let minLength = minLengths
    let extras = producto.extras
    let name = extras[i]?.name
    let price = extras[i]?.price
    let regexExtras =
      /^[a-zA-Z0-9ÑñÁáÉéÍíÓóÚúÜü!"#$%&'()*+,.:;=^_`~¨@{}/\[\]\\\-\s]+$/
    let regexDecimal = /^[0-9.]+$/
    // //console.log("Index ", i, " name", name, " price", price)
    if (!name?.trim()) {
      // errors["extrasName" + i] = "El campo 'Extra' es requerido"
      errors['extrasName' + i] = ''
    } else if (!regexExtras.test(name.trim())) {
      errors['extrasName' + i] =
        "El campo 'Extra' solo acepta letras, números, espacios en blanco y estos caraceteres " +
        '! " # $ % & \' ( ) * + , - . / : ; = @ [ \\ ] ^ _ ` { } ~ ¨ '
    } else if (name.length < minLength.extrasName) {
      errors['extrasName' + i] =
        "El campo 'Extra' debe tener mínimo " +
        minLength.extrasName +
        ' caracteres'
    } else {
      errors['extrasName' + i] = ''
    }
    if (!price?.trim()) {
      // errors["extrasPrice" + i] = "El campo 'Precio' es requerido"
      errors['extrasPrice' + i] = ''
    } else if (!regexDecimal.test(price?.trim())) {
      errors['extrasPrice' + i] = "El campo 'Precio' solo acepta números"
    } else if (parseFloat(price) < minLength.extrasPrice) {
      errors['extrasPrice' + i] =
        "El campo 'Precio' debe ser igual o mayor a " +
        currency +
        minLength.extrasPrice
    } else {
      errors['extrasPrice' + i] = ''
    }
    if (errors['extrasName' + i] == '') {
      delete errors['extrasName' + i]
    }
    if (errors['extrasPrice' + i] == '') {
      delete errors['extrasPrice' + i]
    }

    return errors
  }

  const onChangeVariations = (e, i) => {
    const { name, value } = e.target
    //console.log('gol')
    //console.log(name, value)
    //console.log('index', i)

    const newproductsVariants = [...productsVariants]
    // //console.log(newproductsVariants[i][name].push(value))
    //console.log(productsVariants)
    // setProductsVariants(newproductsVariants)

    //console.log(productsVariants)
  }

  const validateTable = (e, _name, _value, i) => {
    // //console.log(i, "VALIDATE TABLE", _name, _value)
    let errors = { ...errorsTable }
    let minLength = minLengths
    let name = _name //e.target.name
    let value = _value //e.target.value
    let regexDecimal = /^[0-9.]+$/
    let regexNumber = /^[0-9]+$/
    // //console.log("Index ", i, " name", name, " price", price)
    // debugger
    if (typeof value == 'number') {
      toString(value)
    }
    if (name == 'precio') {
      if (!value?.trim()) {
        errors['tablePrice' + i] = 'El Precio es requerido'
        // errors['tablePrice' + i] = ''
      } else if (!regexDecimal.test(value?.trim())) {
        errors['tablePrice' + i] = "El campo 'Precio' solo acepta números"
      } else if (parseFloat(value) < minLength.precio) {
        errors['tablePrice' + i] =
          'El Precio debe ser igual o mayor a ' + currency + minLength.precio
      } else {
        errors['tablePrice' + i] = ''
      }
    }
    if (name == 'stock') {
      // if (!value?.trim()) {
      if (value == '') {
        errors['tableStock' + i] = 'El Stock es requerido'
        // errors['tablePrice' + i] = ''
      } else if (!regexNumber.test(value)) {
        errors['tableStock' + i] = "El campo 'Stock' solo acepta números"
      } else if (parseInt(value) < minLength.stock) {
        errors['tableStock' + i] =
          'El Precio debe ser igual o mayor a ' + currency + minLength.stock
      } else {
        errors['tableStock' + i] = ''
      }
    }
    if (errors['tablePrice' + i] == '') {
      delete errors['tablePrice' + i]
    }
    if (name === 'isStockIlimit' || errors['tableStock' + i] == '') {
      delete errors['tableStock' + i]
    }
    // debugger
    // //console.log("ERRORS", errors)
    // //console.log("ERROR TABLE", errorsTable)
    return errors
  }

  useEffect(() => {}, [combinaciones1])

  useEffect(() => {
    // //console.log('PRODUCT VARIANTSSSS11')
  }, [producto.variations])
  useEffect(() => {
    // //console.log('PRODUCT VARIANTSSSS11')
  }, [producto])

  let changeStockProduct = (e) => {
    setisProductStock(!isProductStock)
    producto.isproductstock = isProductStock
    producto.stock = ''
    delete errors.stock
  }

  useEffect(() => {}, [producto])

  return (
    <div className='w-fit lg:w-full flex flex-col'>
      {viewModal && (
        <ModalMessage
          title={contentModal.title}
          text={contentModal.text}
          deniedBtn={false}
          textButton='Aceptar'
          linkgo={contentModal.linkgo}
          acceptModal={() => closeModal()}
        />
      )}
      {showModalCropImage && (
        <ModalImageCrop
          resolution={resolution}
          aspectRatio={aspectRatio}
          type={'product'}
          index={''}
          setShowModalCropImage={setShowModalCropImage}
          image={''}
        />
      )}

      <NavigationTop />
      <NavigationSide />

      <section className='p-6 mb-44 md:ml-40'>
        <div className='flex items-center gap-3 mb-6'>
          <a href={'/productos/'} className='flex-grow'>
            <FontAwesomeIcon
              icon={faArrowLeft}
              className='font-semibold cursor-pointer hover:text-yellow-400 '
            />{' '}
            back
          </a>
          <a href='/productos'>Cancelar</a>
          <div
            onClick={() => createProduct()}
            // className='p-2 px-4 font-semibold bg-yellow-400 rounded cursor-pointer'
            className={`p-2 px-4 text-white font-semibold rounded-xl cursor-pointer ${
              Object.keys(errors).length === 0 &&
              Object.keys(errorsVariations).length === 0 &&
              Object.keys(errorsExtra).length === 0 &&
              Object.keys(errorsTable).length === 0
                ? ' bg-yellow-400'
                : ' bg-gray-400'
            }`}
            style={{ background: '#8163FF' }}
            disabled={
              Object.keys(errors).length === 0 &&
              Object.keys(errorsVariations).length === 0 &&
              Object.keys(errorsExtra).length === 0 &&
              Object.keys(errorsTable).length === 0
            }
          >
            Guardar
          </div>
        </div>
        <section className='flex flex-col gap-4 md:flex-row'>
          <div className='flex flex-col gap-4 grow'>
            <UIinputLabeled
              label='Nombre del Producto'
              placeholder={producto.nombreproducto}
              nameInput='nombreproducto'
              changeFunction={(e) => changeInputProduct(e)}
              value={producto.nombreproducto}
              onBlur={onBlur}
              // onKeyUp={onBlur}
            />
            {errors?.nombreproducto && (
              <p className='p-1 text-sm text-red-500'>
                {errors?.nombreproducto}
              </p>
            )}
            <div>
              <div className='mb-3 text-xs font-semibold text-gray-500 uppercase'>
                {' '}
                informacion general
              </div>
              <textarea
                className='form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700bg-white bg-clip-padding border border-solid border-gray-400 rounded-lg h-44 transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none'
                id='exampleFormControlTextarea1'
                rows='3'
                placeholder={producto.detalladoproducto}
                value={producto.detalladoproducto}
                name='detalladoproducto'
                onChange={(e) => changeInputProduct(e)}
                onBlur={onBlur}
              ></textarea>
              {errors?.detalladoproducto && (
                <p className='p-1 text-sm text-red-500'>
                  {errors?.detalladoproducto}
                </p>
              )}
            </div>
            <div className='p-3 bg-gray-200  rounded-xl'>
              <div className='flex flex-wrap items-start gap-2'>
                <label className='flex flex-col mb-1 text-sm font-semibold tracking-tight text-gray-700 w-44 grow'>
                  <div className=''>Asignar categoria</div>
                  <Select
                    placeholder='Categorias'
                    className='w-full py-2 focus:border-yellow-500 '
                    closeMenuOnSelect={false}
                    styles={customStyles}
                    isMulti
                    onChange={(e) => changeCategories(e)}
                    value={producto.categorias}
                    options={optionsCategory}
                  />
                </label>
                <div className='self-stretch mx-3 border border-l-gray-300'></div>
                <label className='flex flex-col gap-1 mb-1 text-sm font-semibold tracking-tight text-gray-700'>
                  <div className=''>Código de producto</div>
                  <input
                    className='px-3 py-3 text-base border border-gray-400 rounded-lg appearance-none focus:outline-none focus:border-yellow-500'
                    type='text'
                    placeholder='Código de producto'
                    value={producto.codigoproducto}
                    onChange={(e) => changeInputProduct(e)}
                    name='codigoproducto'
                    onBlur={onBlur}
                  />
                  {errors?.codigoproducto && (
                    <p className='p-1 text-sm text-red-500'>
                      {errors?.codigoproducto}
                    </p>
                  )}
                </label>
                <div className='flex flex-col gap-1 mb-1'>
                  <UIinput
                    type='text'
                    // step='10.01'
                    // min='1'
                    // max='9999999'
                    label='Precio del producto'
                    suffix={currency}
                    placeholder='precio'
                    value={producto.precio}
                    changePrice={(e) => changeInputProduct(e)}
                    nameInput='precio'
                    onBlur={onBlur}
                    // maxLength={9}
                    disabled={hasVariants}
                  />
                  {errors?.precio && (
                    <p className='p-1 text-sm text-red-500'>{errors?.precio}</p>
                  )}
                </div>
              </div>
            </div>
            <div className='p-4 bg-gray-100 rounded-xl'>
              <div className='flex flex-row gap-2'>
                <label className='flex flex-col mr-10 w-96'>
                  <div>
                    <input
                      className='mr-2'
                      type='checkbox'
                      name='district'
                      // checked={acountData.doyouhaveavirtualstore}
                      // value={isProductStock}
                      onChange={(e) => changeStockProduct(e)}
                    />
                    Este producto tiene stocks ilimitados
                  </div>
                  <input
                    className={`px-3 py-3 text-base border border-gray-400 rounded-lg appearance-none focus:outline-none focus:border-yellow-500 ${
                      isProductStock && 'opacity-40'
                    }`}
                    type='number'
                    min='1'
                    max='999'
                    step='10'
                    placeholder='Stock'
                    value={producto.stock}
                    onChange={(e) => changeInputProduct(e)}
                    disabled={isProductStock === true || hasVariants}
                    name='stock'
                    onBlur={onBlur}
                  />
                  {errors?.stock && (
                    <p className='p-1 text-sm text-red-500'>{errors?.stock}</p>
                  )}
                </label>

                <div className='   mt-9'>
                  <label className='  mr-10 w-96'>Producto Destacado</label>

                  <div class='  relative inline-block w-14 mr-2 align-middle select-none transition duration-200 ease-in'>
                    <input
                      type='checkbox'
                      // name='istrending'
                      onChange={changeSwitch}
                      id='toggle'
                      className='toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer'
                    />
                    <label
                      for='toggle'
                      className='toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer'
                    ></label>
                  </div>
                </div>
              </div>
            </div>
            <div className='flex flex-row my-3 border border-gray-200'></div>
            <VariationsUpdater1
              changeVariations={(e, i) => changeVariations(e, i)}
              variations={producto.variations}
              // productvariations={producto.productvariations}
              errors={errorsVariations}
              setErrors={setErrorsVariations}
              minLengths={minLengths}
              hasVariants={hasVariants}
            />
            <ExtrasUpdater
              changeExtras={(e, i) => changeExtras(e, i)}
              changeInputDecimal={(e, i) => changeInputDecimal(e, i)}
              extras={producto.extras}
              errors={errorsExtra}
            />
            {combinaciones1?.length >= 1 && (
              <VariantsTable
                changeInputDecimal={(e, i) => changeInputDecimal(e, i)}
                errors={errorsTable}
                setErrors={setErrorsTable}
                validateTable={validateTable}
                combinaciones1={combinaciones1}
                onChangeVariations={onChangeVariations}
                producto={producto}
                setProducto={setProducto}
              />
            )}
          </div>
          <div className='flex flex-col gap-1 grow'>
            <div className='text-sm font-semibold tracking-tight text-gray-700 '>
              Galería de medios
            </div>

            <div className='w-full min-w-max max-w-xs p-3 overflow-hidden bg-gray-200 rounded-xl'>
              <ImageUploader
                imageList={imageList}
                setImageList={(el) => changeListImage(el)}
                nameFile='imagenproducto'
                changeImgURL={changeImgURL}
                contentModal={contentModal}
                closeModal={(e) => closeModal(e)}
                changeContentModal={(e) => changeContentModal(e)}
              />
            </div>
          </div>
        </section>
      </section>
    </div>
  )
}

export default ProductoNuevo
