function ButtonPurple({ text = '', onClick = () => { } }) {
    return (
        <button
            className="py-2 px-4 text-white rounded-2xl"
            style={{ background: '#8163FF' }}
            onClick={() => onClick()}
        >{text}</button>
    )
}

export default ButtonPurple