export const TITLE = Object.freeze({
    SAVE: 'Se guardó correctamente',
    UPDATE: 'Se actualizó correctamente',
    DELETE: 'Se eliminó correctamente',
    ERROR: 'Ups!, algo salió mal'
})

export const ICON = Object.freeze({
    SUCCESS: 'success',
    ERROR: 'error',
    INFO: 'info'
})