import React from 'react'
import Button1 from '../components/Button1'
import List from '../components/List'
import { Link } from 'react-router-dom'

const PATHNAME = Object.freeze({
    NEW: '/delivery/precio-zona/nuevo',
    EDIT: '/delivery/precio-zona/editar'
})

const headers = [
    {
        id: 1,
        name: 'Zona',
        className: '',
        style: { width: '50%' }
    },
    {
        id: 2,
        name: 'Activo / Inactivo',
        className: 'text-center ',
        style: { width: '15%' }
    },
    {
        id: 3,
        name: 'Precio',
        className: 'text-center ',
        style: { width: '15%' }
    },
    {
        id: 4,
        name: '',
        className: 'text-center',
        style: { width: '10%' }
    },
    {
        id: 5,
        name: '',
        className: 'text-center',
        style: { width: '10%' }
    },
]

function PricePerZoneData({ pricePerZoneData, allDeliveryList, changeActiveZone, deleteZone }) {
    return (
        <div className='flex flex-col justify-center gap-6'>
            <div className='flex justify-between items-center'>
                <p style={{ color: '#787878' }}>
                    {
                        pricePerZoneData.length > 0
                            ? 'Puedes crear zonas de reparto y darles sus respectivos precios.'
                            : 'Agrega una zona , recuerda puedes tener multiples precios por cada zona.'
                    }
                </p>
                <div className='w-fit'>
                    <Link
                        to={{
                            pathname: PATHNAME.NEW,
                            state: { allDeliveryList: allDeliveryList }
                        }}
                    >
                        <Button1
                            textColor='#000000'
                            background='#00FBC8'
                            text='Crear Zona'
                        />
                    </Link>
                </div>
            </div>
            {
                pricePerZoneData.length > 0 && (
                    <div className='w-full' >
                        <div
                            className="flex items-center justify-evenly gap-7 w-full py-2 px-10 rounded-xl text-sm"
                            style={{ background: '#E6E6E6' }}
                        >
                            {
                                headers.map(el =>
                                    <div className={el.className} key={el.id + el.name} style={el.style}>{el.name}</div>
                                )
                            }
                        </div>
                        {
                            pricePerZoneData.map((element, index) => {
                                return (
                                    <div key={index + element.ratename}>
                                        <List
                                            pathnameEdit={PATHNAME.EDIT}
                                            allDeliveryList={allDeliveryList}
                                            element={element}
                                            onChange={changeActiveZone}
                                            deleteElement={deleteZone}
                                        />
                                    </div>
                                )
                            })
                        }
                    </div>
                )
            }
        </div>
    )
}

export default PricePerZoneData