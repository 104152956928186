function HelpIndex({ indice = [], isVisibleSubIndex = {}, viewSubIndex = () => { }, selectIndex = () => { }, selectedSubIndex = '' }) {
    return (
        <div className='w-/12 flex flex-col Zbg-red-600'>
            <div className='flex flex-col gap-4 p-6 rounded-xl' style={{ background: '#F9F3F9' }}>
                <h2 className='text-xl font-bold py-4' style={{ color: '#323232', borderBottom: '#B7B7B7 solid 1px' }}>Índice</h2>
                <ul className='flex flex-col gap-5'>
                    {indice.map(elem =>
                        <li className='text-[18px] font-semibold' style={isVisibleSubIndex[elem.nameIndex] ? { color: '#8163FF' } : { color: '#323232' }} key={elem.id + '_mainIndex'}>
                            <button className='text-left' onClick={() => viewSubIndex(elem)}>{elem.text}</button>
                            {(isVisibleSubIndex[elem.nameIndex] && elem.subIndex) &&
                                <ul className='ml-8 pt-1 list-disc'>
                                    {elem.subIndex?.map(elem2 =>
                                        <li className='text-base py-[5px]' key={elem2.id + '_subIndex' + elem2.text}>
                                            <button
                                                style={{ color: elem2.text === selectedSubIndex ? '#8163FF' : '#646464', textAlign: 'left' }}
                                                onClick={() => selectIndex(elem2[elem2.nameIndex], false, elem2)}>{elem2.text}</button>
                                        </li>
                                    )}
                                </ul>
                            }
                        </li>
                    )}
                </ul>
            </div>
        </div >
    )
}

export default HelpIndex