import React, { useEffect, useState } from 'react'

const VariantsTable = ({
  changeInputDecimal,
  errors,
  setErrors,
  validateTable,
  combinaciones1,
  onChangeVariations,
  setProducto,
  producto,
  productVar,
  truer,
}) => {
  const [productVariations, setProductVariations] = useState([])

  // console.log('MIS COMBINACIONES001', combinaciones1)

  // //console.log('producto.productvariations', producto.productvariations)

  // []
  // const renderDefault =
  //   combinaciones1.length >= 1 ? combinaciones1 : producto.productvariations

  // //console.log(producto.productvariations)
  // //console.log('miscombinaciones1', combinaciones1)
  // //console.log('renderDefault', renderDefault)

  function editVariation(event, i) {
    let name = event.target.name
    let value = event.target.value
    // console.log("Name",name,"VALUE",value, "TYPE",typeof(value))
    // if (typeof (value) == 'number') {
    //   toString(value)
    // }
    // console.log("2=======Name",name,"VALUE",value, "TYPE",typeof(value))
    setErrors(validateTable(event, name, value, i))
    // //console.log(combinaciones1)
    // //console.log(renderDefault)
    const newVariations = [...combinaciones1]
    if (name === 'isStockIlimit') {
      newVariations[i]['isStockIlimit'] = !newVariations[i]['isStockIlimit']
      newVariations[i]['stock'] = ''
    } else if (name == 'precio') {
      // let amount = value
      // if (!amount || amount.match(/^\d{1,7}(\.\d{0,2})?$/)) {
      //   newVariations[i][name] = amount//changeInputDecimal(event, i)
      // }
      newVariations[i][name] = changeInputDecimal(event, i)
    } else {
      newVariations[i][name] = value
    }
    // //console.log('combinaciones1 UPD', combinaciones1)
    setProductVariations({ ...newVariations })
    // onChangeVariations(newVariations)

    // hace ALGO=?==

    setProducto({ ...producto, productvariations: combinaciones1 })
  }
  useEffect(() => {
    const newVariations = [...combinaciones1]
  }, [producto.productvariations, combinaciones1])

  return (
    <div className='bg-gray-100 rounded-xl p-4'>
      <div className='mb-3 text-xs font-semibold text-gray-500 uppercase'>
        Variaciones de productos
        <div class='flex flex-col'>
          <div class='overflow-x-auto sm:-mx-6 lg:-mx-8'>
            <div class='py-2 inline-block min-w-full sm:px-6 lg:px-8'>
              <div class='overflow-hidden'>
                <table class='min-w-full'>
                  <thead class='border-b'>
                    <tr>
                      <th
                        scope='col'
                        class='text-sm font-medium text-gray-900 px-6 py-4 text-left'
                      >
                        Variantes
                      </th>
                      <th
                        scope='col'
                        class='text-sm font-medium text-gray-900 px-6 py-4 text-left'
                      >
                        Precio
                      </th>

                      <th
                        scope='col'
                        class='text-sm font-medium text-gray-900 px-6 py-4 text-left'
                      >
                        Stock Ilimitado
                      </th>
                      <th
                        scope='col'
                        class='text-sm font-medium text-gray-900 px-6 py-4 text-left'
                      >
                        Stock
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {combinaciones1?.map((t, i) => {
                      return (
                        <tr class='border-b' key={i}>
                          <td class='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900'>
                            {/* {Object.values(t).join('/')} */}
                            {Object.values(t.options).join('/')}
                          </td>
                          <td class='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap'>
                            <input
                              placeholder='precio'
                              value={producto?.productvariations[i]?.precio}
                              type='text'
                              name='precio'
                              onChange={(e) => {
                                editVariation(e, i)
                              }}
                            />
                            {errors['tablePrice' + i] && (
                              <p className='mt-1 text-xs text-red-600'>
                                {errors['tablePrice' + i]}
                              </p>
                            )}
                          </td>
                          <td className='flex justify-center py-4'>
                            {
                              <input
                                type='checkbox'
                                name='isStockIlimit'
                                checked={
                                  producto?.productvariations[i]?.isStockIlimit
                                }
                                onChange={(e) => {
                                  editVariation(e, i)
                                }}
                              />
                            }
                          </td>
                          <td class='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap'>
                            <input
                              type='text'
                              // step='1'
                              // min='1'
                              placeholder='stock'
                              name='stock'
                              value={combinaciones1[i]?.stock}
                              className={`${producto?.productvariations[i]?.isStockIlimit} && 'opacity-20'
                              }`}
                              disabled={
                                combinaciones1[i]?.isStockIlimit === true
                              }
                              onChange={(e) => {
                                editVariation(e, i)
                              }}
                            />
                            {errors['tableStock' + i] && (
                              <p className='mt-1 text-xs text-red-600'>
                                {errors['tableStock' + i]}
                              </p>
                            )}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VariantsTable
