import axios from 'axios'

export const getProductAPI = async (id) => {
  try {
    const { data } = await axios.get(
      `https://ventafazil-strapi-production.up.railway.app/productos/${id}`
    )
    ////console.log("productapi", data)
    return data
  } catch (error) {
    //console.log(error)
  }
}
