import React  from "react";

function DatosNegocioRegistro({storedata,handleChange,handleSubmit,listDocuments}) {
    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div className="flex flex-col gap-4 px-6 py-8 bg-gray-200 rounded-xl">
                    <div className="text-xl text-gray-500 ">Datos Del Comercio</div>
                    <div className="flex flex-col ">
                        <div className="text-sm font-bold">Nombre del Comercio</div>
                        <input type="text" placeholder="Mi tienda online" className={`w-full px-6 py-2 text-lg border-2 rounded-lg ${storedata.nombre ===''?'border-2 border-rose-600':''} `}
                            value={storedata.nombre} name="nombre" onChange={handleChange}></input>
                    </div>
                    <div className="flex flex-col gap-4 md:flex-row">
                        <div className="grow">
                            <div className="text-sm font-bold">Tipo de Documento</div>
                            <select value={storedata.tipodedocumento} name="tipodedocumento" onChange={handleChange}  className={`w-full px-6 py-2 text-lg border-2 rounded-lg `}>
                            <option value="" >Seleccionar documento...</option>
                            {
                                listDocuments.map((bank, i) => {
                                    return <option value={bank} key={i} >{bank}</option>
                                })
                            }
                        </select>
                        </div>

                        <div className="grow">
                            <div className="text-sm font-bold">Número de Documento</div>
                            <input type="text" placeholder="75668942" className={`w-full px-6 py-2 text-lg border-2 rounded-lg ${storedata.nombre==''?'border-2 border-rose-600':''}`}
                                value={storedata.numerodedocumento} name="numerodedocumento" onChange={handleChange}></input>
                        </div>
                    </div>

                    <div className="grid grid-cols-1 ">
                        <div className="text-lg font-bold">Nombre del Titular o Razón Social</div>
                        <input type="text" placeholder="Mi tienda S.A.C." className={`w-full px-6 py-2 text-lg border-2 rounded-lg ${storedata.Ntorazonsocial==''?'border-2 border-rose-600':''}`}
                            value={storedata.Ntorazonsocial} name="Ntorazonsocial" onChange={handleChange}></input>
                    </div>

                    {/* <div className="grid grid-cols-2 gap-10"> */}
                    <div className="flex flex-col justify-start lg:flex-row">
                        <div className="w-full lg:w-6/12">
                            <div className="text-lg font-bold">Dirección Fiscal (como aparece en su ficha RUC)</div>
                            <input type="text" placeholder="Dirección Fiscal" className="w-full px-6 py-2 text-lg border-2 rounded-lg "
                                value={storedata.direccionfiscal} name="direccionfiscal" onChange={handleChange}></input>
                        </div>

                        <div className="w-full mt-6 lg:w-6/12 lg:mt-4 lg:pl-20">
                            <div>No necesito un domicilio </div>
                            <div className="flex flex-row">
                                <input className="float-left w-4 h-4 mt-1 mr-2 align-top transition duration-200 bg-white bg-center bg-no-repeat bg-contain border border-gray-300 rounded-sm appearance-none cursor-pointer form-check-input checked:bg-blue-600 checked:border-blue-600 focus:outline-none"
                                    type="checkbox" id="flexCheckDefault"
                                    value={storedata.esnegocio} name="esnegocio" onChange={handleChange}
                                />
                                <label className="inline-block font-bold text-gray-800 form-check-label" htmlFor="flexCheckDefault">
                                    Soy un negocio digital
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col justify-start gap-4 lg:flex-row">
                        <div className="w-full lg:w-6/12">
                            <div className="text-lg font-bold">Región</div>
                            <input type="text" placeholder="Lima" className="w-full px-6 py-2 text-lg border-2 rounded-lg "
                                value={storedata.region} name="region" onChange={handleChange}></input>
                        </div>

                        <div className="w-full lg:w-6/12">
                            <div className="text-lg font-bold">Ciudad</div>
                            <input type="text" placeholder="Lima" className="w-full px-6 py-2 text-lg border-2 rounded-lg "
                                value={storedata.ciudad} name="ciudad" onChange={handleChange}></input>
                        </div>
                    </div>

                    <div className="flex flex-col justify-start gap-4 lg:flex-row">
                        <div className="w-full lg:w-6/12">
                            <div className="text-lg font-bold">Correo electrónico</div>
                            <input type="email" placeholder="usuario@mitienda.com" className={`w-full px-6 py-2 text-lg border-2 rounded-lg ${storedata.correoelectronico==''?'border-2 border-rose-600':''}`}
                                value={storedata.correoelectronico} name="correoelectronico" onChange={handleChange}></input>
                        </div>

                        <div className="w-full lg:w-6/12">
                            <div className="text-lg font-bold">Teléfono</div>
                            <input type="text" placeholder="+51 75896433" className="w-full px-6 py-2 text-lg border-2 rounded-lg "
                                value={storedata.telefono} name="telefono" onChange={handleChange}></input>
                        </div>
                    </div>

                    <div className="flex flex-col justify-start gap-4 lg:flex-row">
                        <div className="w-full lg:w-6/12">
                            <div className="text-lg font-bold">Contraseña</div>
                            <input type="password" placeholder="Contraseña" className={`w-full px-6 py-2 text-lg border-2 rounded-lg ${storedata.contrasena==''?'border-2 border-rose-600':''}`}
                                value={storedata.contrasena} name="contrasena" onChange={handleChange}></input>
                        </div>

                        <div className="w-full lg:w-6/12">
                            <div className="text-lg font-bold">Confirmar Contraseña</div>
                            <input type="password" placeholder="Repetir contraseña" className={`w-full px-6 py-2 text-lg border-2 rounded-lg ${storedata.confirm_contrasena==''?'border-2 border-rose-600':''}`}
                                value={storedata.confirm_contrasena} name="confirm_contrasena" onChange={handleChange}></input>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default DatosNegocioRegistro