import React, { useState, useEffect, useContext } from 'react'
import UIBackofficeTableCell from './UIBackofficeTableCell'
import axios from 'axios'
import { UserContext } from '../context/UserContext'

function UIBackofficeTable({ ishome }) {
  const { user, setUser } = useContext(UserContext)
  const [api, setApi] = useState([])
  const [products, setProducts] = useState([])
  const [loader, setLoader] = useState(true)

  useEffect(() => {
    let config = {
      method: 'get',
      url:
        `https://ventafazil-strapi-production.up.railway.app/productos/?cliente=` +
        user,
      headers: {},
    }
    axios(config)
      .then((response) => {
        setProducts(response.data)
        setLoader(false)
      })
      .catch((error) => {
        //console.log(error)
      })
  }, [])
  const categoriesProd = (cat) => {
    let newcat =
      cat && cat.length > 0 ? cat.map((el) => el.nombrecategoria) : []
    return newcat.join(', ')
  }
  return (
    <div className='flex flex-col items-start w-full gap-2'>
      {loader ? (
        <div>Cargando datos...</div>
      ) : (
        products &&
        products.slice(0, 4).map((product, index) => {
          return (
            <UIBackofficeTableCell
              category={categoriesProd(product.categorias)}
              id={product.id}
              codigo={product.codigoproducto}
              nombre={product.nombreproducto}
              image={product.imagenproducto}
              description={product.detalladoproducto}
              variables={product.variables}
              price={product.precio}
              extras={product.extras}
              stock={product.stock}
              listImages={product.image}
              key={index}
              ishome={ishome}
            />
          )
        })
      )}
    </div>
  )
}

export default UIBackofficeTable
