function TimePicker({ options = [], name = '', value = '', labelName = '', onChange = () => { } }) {
    return (
        <div className='flex flex-col'>
            <select
                name={name}
                className={`${labelName === '' ? 'text-sm px-1' : 'text-4xl'} bg-transparent appearance-none outline-none text-center border-b-2`}
                style={{ borderColor: '#8163FF' }}
                value={value}
                onChange={(e) => onChange(e, null, true)}
            >
                {options.map(elem =>
                    <option className='text-xl' value={elem} key={name + '_' + elem}>{elem}</option>
                )}
            </select>
            {labelName && <label className='text-xs px-2' >{labelName}</label>}
        </div>
    )
}

export default TimePicker