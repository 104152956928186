import React from 'react'

const TagsInput = ({ changePrice, tags, removeTags, nameInput, disabled }) => {
  return (
    <div className='border-2  border-black p-5 rounded-md w-100 flex items-center flex-wrap gap-2'>
      {/* <div className='inline-block  p-0.5 rounded-md bg-slate-400'>
        <span className='text'>gello</span>
        <span className='h-5 w-5  bg-slate-800  rounded-md  mt-1  inline-flex text-white  justify-center items-center ml-1 2x '>
          x
        </span>
      </div> */}

      {tags.values.map((tag, index) => (
        <div
          className='inline-block  p-0.5 rounded-md bg-slate-400'
          key={index + tag}
        >
          {/* <span className='text'>{tag.name}</span> */}
          <span className='text'>{tag}</span>

          <span
            onClick={() => removeTags(index)}
            className='h-5 w-5  bg-slate-800  rounded-md  mt-1  inline-flex text-white  justify-center items-center ml-1 2x '
          >
            x
          </span>
        </div>
      ))}

      <input
        type='text'
        onKeyDown={changePrice}
        onChange={changePrice}
        placeholder='tags-input '
        className='border-none  outline-none  flex-grow-1 '
        name={nameInput}
        disabled={disabled}
      />
    </div>
  )
}

export default TagsInput
