import React from 'react'
import NavigationSide from '../components/NavigationSide'
import NavigationTop from '../components/NavigationTop'
import UIBackofficeCategoriesFilter from '../components/UIBackofficeCategoriesFilter'
import UIBackofficeHeader from '../components/UIBackofficeHeader'

export default function Categorias(props) {
  var category = props.match.params.categoryid
  //console.log(category)

  return (
    <div className='bg-gray-100 '>
      <NavigationTop />
      <NavigationSide />
      <div className='md:ml-10 lg:ml-0'>
      <UIBackofficeHeader
        title='Mis Productos'
        label='Nuevo Producto'
        link='/producto/nuevo'
      />
      </div>
      <div className='min-h-screen overflow-x-scroll px-6'>
        <div className='container py-3 md:ml-20 '>
          <UIBackofficeCategoriesFilter category={category} />
        </div>
      </div>
    </div>
  )
}
