import React from 'react'

function CustomCheckbox(props) {
  return (
    <label className='flex items-center'>
      <input
        type='checkbox'
        className='hidden'
        checked={props.checked}
        onChange={props.onChange}
      />
      <div
        className={`w-5 h-5 border rounded-full flex items-center justify-center cursor-pointer ${
          props.checked ? 'bg-yellow-400' : 'border-gray-400'
        }`}
      >
        {props.checked && (
          <svg className='w-3 h-3 text-white fill-current' viewBox='0 0 20 20'>
            <path d='M0 11l2-2 5 5L18 3l2 2L7 18z' />
          </svg>
        )}
      </div>
      <span className='ml-2'>{props.label}</span>
    </label>
  )
}

export default CustomCheckbox
