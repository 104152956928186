import Cropper from 'react-cropper'
import './cropper.css'
// import 'react-cropper/dist/react-cropper.css'
import { useState, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfo } from '@fortawesome/free-solid-svg-icons'

function ModalImageCrop({
  type,
  resolution,
  aspectRatio,
  setShowModalCropImage,
  cutbanner,
  cutimage,
  index,
  image,
  data,
  removeListBanner,
  setLoading,
}) {
  const [cropper, setCropper] = useState()

  const getCropData = () => {
    console.log('prubbb')
    if (typeof cropper !== 'undefined') {
      setShowModalCropImage(false)
      if (type === 'banners') {
        cutbanner(cropper.getCroppedCanvas().toDataURL(), index)
      } else {
        cutimage(cropper.getCroppedCanvas().toDataURL())
      }
    }
  }
  const cancelcropper = () => {
    setCropper('')
    // setLoading(false)
    setShowModalCropImage(false)
    data[index].link = ''
    removeListBanner(index)
  }

  return (
    <div className='fixed flex justify-center items-center w-full h-screen top-0 left-0 z-20 bg-[#00000080]'>
      <div className='absolute  rounded-xl w-3/4 h-3/4 bg-white'>
        <div className='w-full h-full flex flex-col justify-between'>
          <div className='flex mt-6 px-6 justify-between items-top w-full h-auto text-right'>
            <div></div>
            <p className='font-semibold'>Cargar Imagen</p>
            <p
              onClick={cancelcropper}
              className=' mb-6 px-4 text-xl text-gray-500 rounded-full p-2 cursor-pointer bg-gray-300 inline'
            >
              X
            </p>
          </div>
          <div className='relative  w-full h-full '>
            {/* <Cropper
              // style={{ height: 'auto', width: '100%', maxHeight: '60%' }}
              initialAspectRatio={2}
              // preview='.img-preview'
              src={image}
              guides={0}
              zoomable={0} // Desactivar la capacidad de zoom
              scalable={0} // Desactivar la capacidad de escalado
              viewMode={0}
              minCropBoxHeight={10}
              minCropBoxWidth={10}
              background={false}
              responsive={false}
              autoCropArea={1}
              checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
              onInitialized={(instance) => {
                setCropper(instance)
              }}
            /> */}

            <Cropper
              zoomOnTouch={false}
              style={{ height: 'auto', width: '100%', maxHeight: '60%' }}
              zoomOnWheel={false}
              zoomable={false}
              preview='.img-preview'
              src={image}
              viewMode={1}
              minCropBoxWidth={1080}
              minCropBoxHeight={400}
              background={false}
              responsive={true}
              checkOrientation={false}
              onInitialized={(instance) => {
                console.log('instance', instance)
                setCropper(instance)
              }}
              guides={true}
              cropBoxResizable={false}
            />
          </div>
          <div className='w-full mt-4 mb-6'>
            <div className='flex items-center gap-x-2 px-6'>
              <div className='flex'>
                <FontAwesomeIcon
                  className='text-slate-700 rounded-full p-2 px-3 bg-gray-400'
                  icon={faInfo}
                />
              </div>
              <div className='text-xs'>
                <p>
                  La imagen debe tener dimensiones de {resolution.width} x{' '}
                  {resolution.height} pixeles.
                </p>
                <p>Los formatos aceptables son .png, jpg y jpeg.</p>
              </div>
            </div>
            <hr className='my-4' />
            <div className='flex justify-end  px-6'>
              <button className='font-semibold' onClick={() => cancelcropper()}>
                Cancelar
              </button>
              <button
                className='bg-primary py-2 px-4 rounded-lg font-semibold ml-6'
                onClick={() => getCropData()}
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalImageCrop

//!new
// import React, { useState, useRef } from 'react'
// import { Cropper, ImageRestriction } from 'react-advanced-cropper'
// import 'react-advanced-cropper/dist/style.css'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faInfo, faLariSign } from '@fortawesome/free-solid-svg-icons'
// import { BackgroundWrapperWithNotifications } from './BackgroundWrapperWithNotifications'

// function ModalImageCrop({
//   type,
//   resolution,
//   aspectRatio,
//   setShowModalCropImage,
//   cutbanner,
//   cutimage,
//   index,
//   image,
//   data,
//   removeListBanner,
//   setLoading,
// }) {
//   const cropperRef = useRef(null)

//   const getCropData = () => {
//     console.log('prubbb')
//     const cropper = cropperRef.current
//     console.log(cropper)
//     if (cropper) {
//       setShowModalCropImage(false)
//       if (type === 'banners') {
//         cutbanner(cropper.getCanvas().toDataURL(), index)
//       } else {
//         cutimage(cropper.getCanvas().toDataURL())
//       }
//     }
//   }

//   const cancelcropper = () => {
//     // setLoading(false);
//     setShowModalCropImage(false)
//     data[index].link = ''
//     removeListBanner(index)
//   }
//   console.log('image', image)
//   return (
//     <div className='fixed flex justify-center items-center w-full h-screen top-0 left-0 z-20 bg-[#00000080]'>
//       <div className='absolute rounded-xl w-3/4 h-3/4 bg-white'>
//         <div className='w-full h-full flex flex-col justify-between'>
//           <div className='flex mt-6 px-6 justify-between items-top w-full h-auto text-right'>
//             <div></div>
//             <p className='font-semibold'>Cargar Imagen</p>
//             <p
//               onClick={cancelcropper}
//               className='mb-6 px-4 text-xl text-gray-500 rounded-full p-2 cursor-pointer bg-gray-300 inline'
//             >
//               X
//             </p>
//           </div>
//           <div className='relative w-full h-full'>
//             {/* <Cropper
//               ref={cropperRef}
//               src={image}
//               className='cropper'
//               cropRatio={aspectRatio}
//               onCoordinatesChange={(coordinates) => console.log(coordinates)}
//               onCanvasChange={(canvas) => console.log(canvas)}
//               resizable={false}
//             /> */}

//             <Cropper
//               className={'cropper-scroll-example'}
//               stencilProps={{
//                 movable: false,
//                 resizable: false,
//                 handlers: {},
//                 lines: {},
//               }}
//               // stencilSize={{
//               //   width: 300,
//               //   height: 300,
//               // }}
//               transformImage={{
//                 adjustStencil: false,
//               }}
//               imageRestriction={ImageRestriction.stencil}
//               backgroundWrapperComponent={BackgroundWrapperWithNotifications}
//               src={image}
//               ref={cropperRef}
//             />
//           </div>
//           <div className='w-full mt-4 mb-6'>
//             <div className='flex items-center gap-x-2 px-6'>
//               <div className='flex'>
//                 <FontAwesomeIcon
//                   className='text-slate-700 rounded-full p-2 px-3 bg-gray-400'
//                   icon={faInfo}
//                 />
//               </div>
//               <div className='text-xs'>
//                 <p>
//                   La imagen debe tener dimensiones de {resolution.width} x{' '}
//                   {resolution.height} pixeles.
//                 </p>
//                 <p>Los formatos aceptables son .png, jpg y jpeg.</p>
//               </div>
//             </div>
//             <hr className='my-4' />
//             <div className='flex justify-end px-6'>
//               <button className='font-semibold' onClick={cancelcropper}>
//                 Cancelar
//               </button>
//               <button
//                 className='bg-primary py-2 px-4 rounded-lg font-semibold ml-6'
//                 onClick={getCropData}
//               >
//                 Guardar
//               </button>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }

// export default ModalImageCrop
