import { useEffect, useState } from 'react'
import CouponApi from '../../../utils/services/CouponApi'
import ProductApi from '../../../utils/services/ProductApi'
import CategoryApi from '../../../utils/services/CategoryApi'
import Table from './components/Table'
import NewCoupon from './components/NewCoupon'
import Loading from '../../Loading'
import Modal from './components/Modal'
import CuponsModule from './CuponsModule'
import { toastAlert, toastModal } from '../../../utils/toast/Toast'
import { TITLE, ICON } from '../../../utils/constants/toastData'
import { isEmpty, minPrice, minAmount, minSelected } from '../../../utils/Validation'

const initialCoupon = {
   id: '',
   name: '',
   benefit: '',
   value: 0.0,
   stock: 0,
   applyto: '',
   isactive: true,
   startdate: '',
   enddate: '',
   cliente: '',
   productos: [],
   categorias: [],
}

const initialTimePicker = {
   startDate: new Date(),
   endDate: new Date(),
   startHours: '1',
   startMinutes: '00',
   startPeriod: 'AM',
   endHours: '1',
   endMinutes: '00',
   endPeriod: 'AM',
}

function Coupon() {
   //COMEND
   const user = JSON.parse(localStorage.getItem('user'))

   const [loading, setLoading] = useState(true)
   const [viewNewCoupon, setViewNewCoupon] = useState(false)
   const [isOpenModal, setIsOpenModal] = useState(false)
   const [isEdit, setIsEdit] = useState(false)
   const [listCoupon, setListCoupon] = useState([])
   const [originalListItem, setOriginalListItem] = useState([])
   const [listItem, setListItem] = useState([])
   const [selectedItems, setSelectedItems] = useState([])
   const [dataCoupon, setDataCoupon] = useState(initialCoupon)
   const [datetimePicker, setDatetimePicker] = useState(initialTimePicker)

   const isViewNewCoupon = (isCancel = false) => {
      if (isCancel) {
         setDataCoupon(initialCoupon)
         setDatetimePicker(initialTimePicker)
         setIsEdit(false)
      }
      setViewNewCoupon(!viewNewCoupon)
   }

   const isVisibleModal = () => {
      const { applyto } = { ...dataCoupon }
      setIsOpenModal(!isOpenModal)
      if (!isOpenModal) {
         if (applyto === 'specificproduct') getProducts()
         else if (applyto === 'specificcategory') getCategories()
      }
      setSelectedItems([])
   }

   const onChangeDate = (date, name) => {
      setDatetimePicker({ ...datetimePicker, [name]: date });
   }

   const onChange = (e, chkValue, isTimePicker = false) => {
      let { type, name, value, checked } = e.target
      if (isTimePicker) setDatetimePicker({ ...datetimePicker, [name]: value })
      if (type === 'checkbox' || type === 'radio') {
         console.log(chkValue)
         if (checked) {
            if (chkValue == 'freeshipping') setDataCoupon({ ...dataCoupon, [name]: chkValue, value: 0 })
            else setDataCoupon({ ...dataCoupon, [name]: chkValue })
         } else setDataCoupon({ ...dataCoupon, [name]: '' })
      } else if (name === 'value') {
         if (!value || value.match(/^\d{1,7}(\.\d{0,2})?$/)) setDataCoupon({ ...dataCoupon, [name]: value })
      } else if (name === 'stock') {
         if (!value || value.match(/^\d{1,7}?$/)) setDataCoupon({ ...dataCoupon, [name]: value })
      } else if (!isTimePicker) setDataCoupon({ ...dataCoupon, [name]: value })
   }

   const validate = () => {
      const { name, value, stock, benefit, applyto } = { ...dataCoupon }
      let error = {}
      const _minPrice = 0
      const _minAmount = 1

      error['name'] = isEmpty(name, 'nombre del cupón')
      if (benefit !== 'freeshipping') error['price'] = minPrice(value, _minPrice, ' del cupón')
      error['stock'] = minAmount(stock, _minAmount, 'cupones')
      error['benefit'] = minSelected(benefit, 'tipo de cupón')
      error['applyto'] = minSelected(applyto, 'aplica a')

      Object.keys(error).forEach(elem => {
         if (error[elem] === "") delete error[elem]
      })

      const firstError = Object.values(error).find((errorMessage) => errorMessage !== '');

      return firstError
   }

   const formatListCoupon = (list) => {
      const newList = list.map(elem => {
         if (elem.benefit === 'discount') elem.benefit = 'Descuento'
         if (elem.benefit === 'percentage') elem.benefit = 'Porcentaje'
         if (elem.benefit === 'freeshipping') elem.benefit = 'Free Shipping'
         if (elem.applyto === 'wholecart') elem.applyto = 'Todo el carrito'
         if (elem.applyto === 'specificproduct') elem.applyto = 'Productos en específicos'
         if (elem.applyto === 'specificcategory') elem.applyto = 'Categorias en específicos'
         return elem
      })

      setListCoupon(newList)
   }

   const getCoupon = async () => {
      try {
         const _listCoupon = await CouponApi.Get(user.id)
         formatListCoupon(_listCoupon)
      } catch (error) {
         if (error?.response?.status === 400) {
            console.error("Error en el cliente:", error.response.data.data.errors);
         }
         console.error("Error en la solicitud:", error.message);
      }
      setLoading(false)
   }

   const activateCoupon = async (coupon) => {
      setLoading(true)
      const data = { isactive: !coupon.isactive }
      try {
         const response = await CouponApi.Put(coupon.id, data)
         if (response.status === 200) {
            getCoupon()
         }
      } catch (error) {
         if (error?.response?.status === 400) {
            console.error("Error en el cliente:", error.response.data.data.errors);
         }
         console.error("Error en la solicitud:", error.message);
      }
   }

   const getCouponById = async (coupon) => {
      setLoading(true)
      try {
         const response = await CouponApi.Get(user.id, coupon.id)
         const data = response[0]
         setDataCoupon({
            id: data.id,
            name: data.name,
            benefit: data.benefit,
            value: parseFloat(data.value),
            stock: parseInt(data.stock),
            applyto: data.applyto,
            isactive: data.isactive,
            startdate: data.startdate,
            enddate: data.enddate,
            cliente: data.cliente?.id,
            productos: data.productos,
            categorias: data.categorias
         })
         preparingDatetimePicker(data.startdate, data.enddate)
      } catch (error) {
         if (error?.response?.status === 400) {
            console.error("Error en el cliente:", error.response.data.data.errors);
         }
         console.error("Error en la solicitud:", error.message);
      }
      setIsEdit(true)
      isViewNewCoupon()
      setLoading(false)
   }

   const deleteCoupon = async (coupon) => {
      setLoading(true)
      try {
         const response = await CouponApi.Delete(coupon.id)
         if (response.status === 200) {
            getCoupon()
            toastModal(TITLE.DELETE, ICON.SUCCESS)
         }
      } catch (error) {
         if (error?.response?.status === 400) {
            console.error("Error en el cliente:", error.response.data.data.errors);
         }
         console.error("Error en la solicitud:", error.message);
      }
   }

   const saveCoupon = async () => {
      let errorMessage = validate()
      const { data, idCoupon } = prepareCouponData()
      let response = {}

      if (errorMessage == undefined) {
         setLoading(true)
         try {
            if (!isEdit) response = await CouponApi.Post(data)
            else response = await CouponApi.Put(idCoupon, data)

            if (response.status === 200) {
               getCoupon()
               toastModal(TITLE.SAVE, ICON.SUCCESS)
               setViewNewCoupon(false)
               setDataCoupon(initialCoupon)
               setIsEdit(false)
            }
         } catch (error) {
            if (error?.response?.status === 400) {
               console.error("Error en el cliente:", error.response.data.data.errors);
               toastModal(TITLE.ERROR, ICON.ERROR)
            }
            console.error("Error en la solicitud:", error.message);
            setLoading(false)
         }
      } else {
         toastAlert(errorMessage)
      }
   }

   const getProducts = async () => {
      setLoading(true)
      try {
         const _listProduct = await ProductApi.Get(user.id)
         filtredListItem(_listProduct)
      } catch (error) {
         if (error?.response?.status === 400) {
            console.error("Error en el cliente:", error.response.data.data.errors);
         }
         console.error("Error en la solicitud:", error.message);
      }
      setLoading(false)
   }

   const getCategories = async () => {
      setLoading(true)
      try {
         const _listCategory = await CategoryApi.Get(user.id)
         filtredListItem(_listCategory)
      } catch (error) {
         if (error?.response?.status === 400) {
            console.error("Error en el cliente:", error.response.data.data.errors);
         }
         console.error("Error en la solicitud:", error.message);
      }
      setLoading(false)
   }

   const pad = (value) => {
      return String(value).padStart(2, '0');
   }

   const parseDateTimeByUTC = (dateTime) => {
      const UTC = 5
      let fechaHora = new Date(dateTime);//"2023-06-12T03:00:00.000Z";
      fechaHora.setUTCHours(fechaHora.getUTCHours() - UTC);

      let year = fechaHora.getUTCFullYear();
      let month = fechaHora.getUTCMonth() + 1;
      let day = fechaHora.getUTCDate();
      let hours = fechaHora.getUTCHours();
      let minutes = fechaHora.getUTCMinutes();
      let seconds = fechaHora.getUTCSeconds();

      const newDateTime = year + "-" + pad(month) + "-" + pad(day) + "T" + pad(hours) + ":" + pad(minutes) + ":" + pad(seconds) + ".000Z";
      console.log("newDateTime", newDateTime);
      return newDateTime
   }

   /*
   * Transforms a date string to a date object
   */
   const parseStringDateToDate = (_dateString) => {
      const dateString = _dateString;
      const dateParts = dateString.split('-');
      const year = parseInt(dateParts[0]);
      const month = parseInt(dateParts[1]) - 1;
      const day = parseInt(dateParts[2]);

      const date = new Date(year, month, day);
      return date
   }

   /*
   * Preparing the new data for datetimePicker object
   */
   const preparingNewDatetimePicker = (hourPeriod = {}) => {
      let { date, hour, period, minute } = hourPeriod
      if (parseInt(hour) === 0) hour = '12'
      else if (hour.startsWith('0') && parseInt(hour) > 0) hour = hour.split('0')[1]
      else if (parseInt(hour) > 12) {
         hour = (hour - 12).toString()
         period = 'PM'
      } else if (parseInt(hour) === 12) period = 'PM'

      return {
         date: parseStringDateToDate(date),
         hour: hour,
         period: period,
         minute: minute
      }
   }

   /*
   * Preparing datetime("2023-06-13T02:30:00.000Z") of Get services from the service coupon 
   * for the new datetimePicker object
   */
   const preparingDatetimePicker = (_startDatetime, _endDatetime) => {
      const startDate = _startDatetime.split('T')[0]//2023-06-13
      const endDate = _endDatetime.split('T')[0]
      const startTime = _startDatetime.split('T')[1]//02:30:00
      const endTime = _endDatetime.split('T')[1]
      const startDatetime = {
         date: startDate,
         hour: startTime.split(':')[0],
         period: 'AM',
         minute: startTime.split(':')[1]
      }
      const endDatetime = {
         date: endDate,
         hour: endTime.split(':')[0],
         period: 'AM',
         minute: endTime.split(':')[1]
      }

      const newStartDatetime = preparingNewDatetimePicker(startDatetime)
      const newEndDatetime = preparingNewDatetimePicker(endDatetime)

      const newDatetimePicker = {
         startDate: newStartDatetime.date,
         startHours: newStartDatetime.hour,
         startMinutes: newStartDatetime.minute,
         startPeriod: newStartDatetime.period,
         endDate: newEndDatetime.date,
         endHours: newEndDatetime.hour,
         endMinutes: newEndDatetime.minute,
         endPeriod: newEndDatetime.period
      }
      setDatetimePicker(newDatetimePicker)
   }

   /*
   * Transforms a date object to a date string
   */
   const formatDateToString = (_date) => {
      const date = new Date(_date)
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')
      const formatDate = `${year}-${month}-${day}`

      return formatDate
   }

   /*
   * Prepare datetime for strapi("2023-06-12T00:45:00.000Z")
   */
   const prepareStringDatetime = (dateTime = {}) => {
      let { date, period, hour, minute } = dateTime
      if (period === 'AM' && hour.length === 1) hour = pad(hour)
      else if (period === 'AM' && parseInt(hour) === 12) hour = '00'
      else if (period === 'PM' && parseInt(hour) < 12) hour = parseInt(hour) + 12
      const newDateTime = date + 'T' + hour + ':' + minute + ':00.000Z'

      return newDateTime
   }

   /*
   * Prepare data of dataCoupon object for services(post/put)
   */
   const prepareCouponData = () => {
      const startDatetime = {
         date: formatDateToString(datetimePicker.startDate),
         period: datetimePicker.startPeriod,
         hour: datetimePicker.startHours,
         minute: datetimePicker.startMinutes
      }
      const endDatetime = {
         date: formatDateToString(datetimePicker.endDate),
         period: datetimePicker.endPeriod,
         hour: datetimePicker.endHours,
         minute: datetimePicker.endMinutes
      }

      const newStartDatetime = prepareStringDatetime(startDatetime)
      const newEndDatetime = prepareStringDatetime(endDatetime)

      let data = { ...dataCoupon }
      data.cliente = user.id
      data.startdate = newStartDatetime
      data.enddate = newEndDatetime
      const idCoupon = data.id
      delete data.id

      // console.log("DATA", data)
      return {
         data: data,
         idCoupon: idCoupon
      }
   }

   /*
   * Delete item by applyto
   */
   const deleteItem = (item = {}, name = '') => {
      const { applyto, productos, categorias } = { ...dataCoupon }
      let _listItem = []

      if (applyto === 'specificproduct') _listItem = productos
      else if (applyto === 'specificcategory') _listItem = categorias

      setDataCoupon(preveDataCoupon => ({
         ...preveDataCoupon, [name]: _listItem.filter(elem => elem.id !== item.id)
      }))
   }

   /*
   * Filtred response.data of service GET
   */
   const filtredListItem = (dataList = []) => {
      let newDataList = dataList.map(el => {
         return {
            ...el,
            isChecked: false
         }
      })

      const { applyto, productos, categorias } = { ...dataCoupon }
      let _listItem = []

      if (applyto === 'specificproduct') _listItem = productos
      else if (applyto === 'specificcategory') _listItem = categorias

      const idListItem = _listItem.map(elem => elem.id)

      setListItem(() => newDataList.filter(elem => !idListItem.includes(elem.id)))
      setOriginalListItem(() => newDataList.filter(elem => !idListItem.includes(elem.id)))
   }

   /*
   * Search item by word
   */
   const handleSearchWord = (e) => {
      const { value } = e.target
      let name = ''

      if (dataCoupon.applyto === 'specificproduct') name = 'nombreproducto'
      else if (dataCoupon.applyto === 'specificcategory') name = 'nombrecategoria'

      setListItem(() => originalListItem.filter(item =>
         item[name].toLowerCase().includes(value.toLowerCase()))
      )
   }

   /*
   * Adds value to the prop isChecked of item of the list selectedItems
   * Adds the product to the selectedItems if it is checked or
   * remove the product from the selectedItems if it is not checked
   */
   const handleCheckedProduct = (e, item) => {
      const { checked } = e.target
      item.isChecked = checked
      if (checked) {
         setSelectedItems(prevSelectedProducts => [...prevSelectedProducts, item])
      }
      else {
         setSelectedItems(prevSelectedProducts => prevSelectedProducts.filter((elem) => elem.id !== item.id))
      }
   }

   /*
   * Creates a new list of selectedItems but delete the prop isChecked of item of the list selectedItems
   * and adds the new list created to the dataCoupon object and performs filtering on the item lists.
   */
   const addProducts = () => {
      const newSelectedItems = selectedItems.map(({ isChecked, ...rest }) => rest);
      const idSelectedItems = selectedItems.map(elem => elem.id)
      let name = ''

      if (dataCoupon.applyto === 'specificproduct') name = 'productos'
      else if (dataCoupon.applyto === 'specificcategory') name = 'categorias'

      setDataCoupon(prevDataCoupon => ({
         ...prevDataCoupon,
         [name]: [...dataCoupon[name], ...newSelectedItems]
      }));

      setListItem(prevListItem =>
         prevListItem.filter(item => !idSelectedItems.includes(item.id))
      )

      setOriginalListItem(prevListItem =>
         prevListItem.filter(item => !idSelectedItems.includes(item.id))
      )

      setSelectedItems([])
   }

   useEffect(() => {
      getCoupon()
   }, [])

   return (
      <div className='w-full mt-5 pb-24 md:pb-10'>
         {loading && <Loading />}
         {/* <div className='flex flex-col gap-3'>
                <h2 className='font-semibold'>Cupón</h2>
                <p className='' style={{ color: '#787878' }}>Cupones de tu tienda.</p>
            </div> */}
         {/* <CuponsModule /> */}
         {
            isOpenModal &&
            <Modal
               onClose={isVisibleModal}
               applyto={dataCoupon.applyto}
               handleSearchWord={handleSearchWord}
               listItem={listItem}
               handleCheckedProduct={handleCheckedProduct}
               addProducts={addProducts}
            />
         }
         {viewNewCoupon ?
            <NewCoupon
               isVisibleModal={isVisibleModal}
               toListCoupon={isViewNewCoupon}
               nameByApplyTo={dataCoupon.applyto === 'specificproduct' ? 'productos' : 'categorias'}
               onChange={onChange}
               onChangeDate={onChangeDate}
               dataCoupon={dataCoupon}
               datetimePicker={datetimePicker}
               deleteItem={deleteItem}
               saveCoupon={saveCoupon}
            />
            :
            <Table
               toNewCoupon={isViewNewCoupon}
               tableBody={listCoupon}
               activateCoupon={activateCoupon}
               actionUpdate={getCouponById}
               actionDelete={deleteCoupon}
            />
         }
      </div>
   )
}

export default Coupon