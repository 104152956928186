import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ButtonPurple from "./ButtonPurple"

const Modal = ({
   onClose = () => { },
   applyto = '',
   handleSearchWord = () => { },
   listItem = [],
   handleCheckedProduct = () => { },
   addProducts = () => { }
}) => {
   return (
      <div className="fixed inset-0 z-20 bg-gray-300 bg-opacity-60">
         <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center">
            <div className="w-[85%] md:w-[45%] h-[95%] px-8 rounded-2xl bg-white">
               <div className="flex justify-end">
                  <button
                     className="text-red-500 font-semibold py-1 px-3 rounded-xl my-4 border-gray-300 border-[1px]"
                     onClick={onClose}
                  >
                     Cerrar
                  </button>
               </div>
               <div className="flex flex-col items-center gap-4 h-[88%]">
                  <h2 className="font-semibold text-xl">Agrega tus productos</h2>
                  <div className='w-full flex justify-between items-center gap-5'>
                     <div
                        className='w-full flex justify-center items-center border rounded-lg py-1 px-2'
                        style={{ borderColor: '#A0A0A0' }}
                     >
                        <FontAwesomeIcon className='text-gray-400 ' icon={faMagnifyingGlass} />
                        <input
                           type='text'
                           placeholder='Busca tus productos por nombre.'
                           className='w-full outline-none p-2'
                           onChange={(e) => handleSearchWord(e)}
                        />
                     </div>
                  </div>
                  <div className="w-full flex flex-col gap-10 py-2 overflow-x-auto">
                     {
                        listItem?.map((item, index) =>
                           <div className="flex items-center gap-5" key={index + '_product' + item.id}>
                              <input
                                 className="w-5 h-5"
                                 type="checkbox"
                                 name={item.id}
                                 value={item.isChecked}
                                 checked={item.isChecked}
                                 onChange={(e) => handleCheckedProduct(e, item)}
                              />
                              {
                                 applyto === 'specificproduct' &&
                                 <img
                                    className="w-20 h-auto"
                                    src={item.imagenproducto}
                                    alt={item.nombreproducto}
                                 />
                              }
                              <label className="text-lg font-semibold">
                                 {applyto === 'specificproduct'
                                    ? item.nombreproducto
                                    : item.nombrecategoria}
                              </label>
                           </div>
                        )
                     }
                  </div>
                  <ButtonPurple
                     text="Agregar"
                     onClick={() => addProducts()}
                  />
               </div>
            </div>
         </div>
      </div>
   )
}

export default Modal