export const decimalNumber = (e) => {
    console.log("e.key", e.target.value)
    let newNumber = 0
    let start = e.target.selectionStart;
    let val = e.target.value;
    val = val.replace(/([^0-9.]+)/, "");
    val = val.replace(/^(0|\.)/, "");
    const match = /(\d{0,7})[^.]*((?:\.\d{0,2})?)/g.exec(val);
    const value = match[1] + match[2];
    e.target.value = value;
    newNumber = value
    if (val.length >= 0) {
        e.target.value = Number(value).toFixed(2);
        e.target.setSelectionRange(start, start);
        newNumber = Number(value).toFixed(2);
    }
    return newNumber
}