import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState, useRef, useEffect } from 'react'
import axios from 'axios'

import ModalImageCrop from '../components/ModalImageCrop'

const ImageUploader = (props) => {
  const [imageName, setImageName] = useState(null)
  const [image, setImage] = useState(null)
  const [loading, setLoading] = useState(false)
  const [gallery, setGallery] = useState([])
  const [featured, setFeatured] = useState(null)

  const [showModalCropImage, setShowModalCropImage] = useState(false)
  const resolution = {
    width: 500,
    height: 500,
  }
  const aspectRatio = 1 / 1
  const [imageCrop, setImageCrop] = useState(null)
  // const [imageList, setImageList] = useState([]);
  // imageList
  // setImageList
  const CloudinaryBtn = useRef(null)
  useEffect(() => {
    setFeatured(props.image)
  }, [])
  useEffect(() => {
    if (props.imagesProduct && props.imagesProduct.length > 0) {
      setGallery(props.imagesProduct)
    } else {
      setFeatured('')
    }
  }, [props.imagesProduct])

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], filename, { type: mime })
  }
  const cropImage = (e) => {
    let files
    if (e.dataTransfer) {
      files = e.dataTransfer.files
    } else if (e.target) {
      files = e.target.files
    }
    //console.log('entramos')
    if (files.length > 0) {
      setImageName(files[0].name)
      const reader = new FileReader()
      reader.onload = () => {
        setImageCrop(reader.result)
      }
      reader.readAsDataURL(files[0])
      setShowModalCropImage(true)
      //console.log('entramos1')

      setLoading(true)
    }
  }
  const UploadImge = async (image) => {
    let imgtrasnform = dataURLtoFile(image, imageName)
    //console.log(imgtrasnform, 'imgtrasnform')
    let data = new FormData()
    data.append('file', imgtrasnform)
    data.append('upload_preset', 'VentaFacil')
    let file
    if (data) {
      let config = {
        method: 'post',
        url: 'https://vf-express-production.up.railway.app/api/images/upload',
        data: data,
      }
      await axios(config)
        .then((response) => {
          //console.log(response)
          //console.log('ENTRAMOS2')
          file = response.data
          //console.log(file)
          if (file.secure_url) {
            //console.log('ENTRAMOS3')

            addToGallery({ url: file.secure_url, id: file.public_id })
            setImage(file.secure_url)
            setLoading(false)
          } else {
            //console.log('ENTRAMOS4')

            setLoading(false)
            let bodyModal
            props.closeModal(true)
            if (file.message === 'File too large') {
              bodyModal = {
                title: 'Imagen muy pesada',
                text: 'Se acepta como máximo imagenes de 1.2Mb.',
              }
              props.changeContentModal(bodyModal)
            } else if (file.message === 'Fail extends') {
              bodyModal = {
                title: 'Formato no aceptado',
                text: 'Solo aceptamos imagenes en formato jpg o png.',
              }
              props.changeContentModal(bodyModal)
            }
          }
        })
        .catch((err) => console.log(err))
    } else {
      //console.log('ENTRAMOS5')

      setLoading(false)
    }

    // if(file.secure_url){
    //     setImage(file.secure_url);
    //     setLoading(false);
    //     addToGallery({url:file.secure_url, id: file.public_id});
    // }else{
    //     let bodyModal
    //     props.closeModal(true)
    //     if(file.message === "File too large"){
    //         bodyModal = {
    //             title: "Imagen muy pesada",
    //             text: "Se acepta como máximo imagenes de 1.2Mb.",
    //         };
    //         props.changeContentModal(bodyModal)
    //     }else if(file.message === "Fail extends"){
    //         bodyModal = {
    //             title: "Formato no aceptado",
    //             text: "Solo aceptamos imagenes en formato jpg o png.",
    //         };
    //         props.changeContentModal(bodyModal)
    //     }
    // }
  }
  function addToGallery(e) {
    let body = { url: e.url, public_id: e.id }
    let newGallery = [...gallery]
    newGallery.push(body)
    //console.log(newGallery)
    setGallery(newGallery)
    props.setImageList(newGallery)
  }

  async function removeFromGallery(e) {
    const newGallery = [...gallery]
    let config
    if (newGallery[e].public_id) {
      config = {
        method: 'post',
        url: `https://vf-express-production.up.railway.app/api/images/delete`,
        data: {
          public_id: newGallery[e].public_id,
        },
      }
    } else {
      var reg =
        /^((http[s]?|ftp):\/)?\/?([^:\/\s]+)((\/\w+)*\/)([\w\-\.]+[^#?\s]+)(.*)?(#[\w\-]+)?$/
      let str = newGallery[e].url
      let attributes = reg.exec(str)
      let image = attributes[6]
      let index = image.indexOf('.')
      let publicID = image.substring(0, index)
      config = {
        method: 'post',
        url: `https://vf-express-production.up.railway.app/api/images/delete`,
        data: {
          public_id: 'VentaFacil/' + publicID,
        },
      }
    }
    await axios(config)
      .then((response) => {
        //console.log(response)
      })
      .catch((error) => {
        //console.log(error)
      })

    newGallery.splice(e, 1)
    setGallery(newGallery)
    props.setImageList(newGallery)
    let imgs = newGallery.map((el) => el.url)
    let newImage = newGallery.length > 0 ? newGallery[0].url : ''
    if (!imgs.includes(featured)) {
      setFeatured(newImage)
      props.changeImgURL(newImage)
      //console.log(newImage)
    }
  }

  let selectPrincipal = (i, img) => {
    setFeatured(img.url)
    props.changeImgURL(img.url)
  }

  return (
    <div className='p-4'>
      {showModalCropImage && (
        <ModalImageCrop
          cutimage={(e) => UploadImge(e)}
          resolution={resolution}
          aspectRatio={aspectRatio}
          type={'product'}
          index={''}
          setShowModalCropImage={setShowModalCropImage}
          image={imageCrop}
          setLoading={setLoading}
        />
      )}

      <div className='mb-2 text-xs font-semibold text-gray-500 uppercase'>
        imagen destacada 500 x 500
      </div>
      <input
        onChange={(e) => cropImage(e)}
        ref={CloudinaryBtn}
        type='file'
        name={props.nameFile}
        className='hidden'
        placeholder='Upload image here'
      />

      <div className='flex p-2 mb-4 bg-gray-300 border border-gray-800 border-dashed grow border-3 rounded-3xl'>
        <div
          className='flex items-center justify-center h-64 font-bold tracking-tight text-center text-gray-600 bg-gray-400 bg-center bg-cover border-dashed grow border-3 rounded-2xl'
          style={{ backgroundImage: `url(${featured})` }}
        >
          {' '}
          {featured === null ? 'elige una imagen' : null}
        </div>
      </div>

      <div className='grid grid-cols-3 gap-3 mb-4'>
        {gallery.map((img, i) => (
          <div
            key={i}
            style={{ backgroundImage: `url(${img.url})` }}
            className='relative h-20 overflow-hidden bg-center bg-cover border border-gray-300 rounded-lg '
          >
            <div
              onClick={() => selectPrincipal(i, img)}
              className='absolute top-0 bottom-0 left-0 right-0 flex transition-all bg-opacity-0 cursor-pointer hover:bg-white hover:bg-opacity-25'
            ></div>
            <div
              onClick={() => removeFromGallery(i)}
              className='absolute flex items-center justify-center w-6 h-6 text-white bg-black rounded-full cursor-pointer top-1 right-1 hover:bg-red-600 '
            >
              <FontAwesomeIcon icon={faTimes} />{' '}
            </div>
          </div>
        ))}
      </div>

      <div>
        {loading ? (
          <div className='mb-2 text-center transition'>Loading...</div>
        ) : (
          <div></div>
        )}
      </div>

      <div
        className='p-4 font-semibold text-center rounded-lg cursor-pointer text-white'
        style={{ background: '#8163FF' }}
        onClick={() => {
          CloudinaryBtn.current.click()
        }}
      >
        agregar a galeria
      </div>
    </div>
  )
}

export default ImageUploader
