import React, { useState, useEffect } from 'react'
import Checkbox1 from '../components/Checkbox1'
import axios from 'axios'
import PricePerZoneData from './PricePerZoneData'
import Swal from 'sweetalert2'
import { useContext } from 'react'
import { UserContext } from '../../../context/UserContext'
import Loading from '../components/Loading'

const TOAST_TITLE = Object.freeze({
  ENABLED: 'Se activo el precio por zona',
  DISABLED: 'Se desactivo el precio por zona',
  EDIT: 'Se edito un precio por zona con exito',
  DELETE: 'Se elimino un precio por zona con exito'
})

const config = {
  headers: {
    'Authorization': `Bearer ${process.env.REACT_APP_KEY_STRAPI}`,
    'Content-Type': 'application/json'
  }
}

function PriceType() {
  const { user } = useContext(UserContext)
  const _user = JSON.parse(localStorage.getItem('user'))
  const idCustomer = _user._id
  const idCountry = _user.paises.id

  const [isPricePerZone, setIsPricePerZone] = useState(false)
  const [pricePerZoneData, setPricePerZoneData] = useState([])
  const [allDeliveryList, setAllDeliveryList] = useState([])
  const [loading, setLoading] = useState(true)

  const toastAlert = (title) => {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-right',
      iconColor: 'white',
      customClass: {
        popup: 'colored-toast',
      },
      showConfirmButton: false,
      timer: 1500,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })
    Toast.fire({
      icon: 'success',
      title: title
    })
  }

  const getPricePerZoneBycustomer = async (getStorage) => {
    const userStorage = JSON.parse(localStorage.getItem('user'))
    if (getStorage) setIsPricePerZone(userStorage.iszoneprice)
    else setIsPricePerZone(_user.iszoneprice)
    const url = `https://ventafazil-strapi-production.up.railway.app/Priceperzones?cliente=${idCustomer}&country=${idCountry}`
    if (_user.ishomedelivery && (_user.iszoneprice || getStorage)) {
      try {
        const response = await axios.get(url, config)
        const data = response.data
        if (response.status == 200) {
          setPricePerZoneData(response.data)
          getAllDeliveryList(data)
        }
      } catch (error) {
        console.log(error)
      }
    }
    setLoading(false)
  }

  const getAllDeliveryList = (data) => {
    let _allDeliveryList = []
    if (data.length > 0) {
      data.forEach(el => {
        _allDeliveryList.push(
          {
            idRate: el._id,
            allDeliveryList: el.deliverylist
          }
        )
      })
      setAllDeliveryList(_allDeliveryList)
    }
  }

  const changeActiveZone = async (item) => {
    setLoading(true)
    const url = `https://ventafazil-strapi-production.up.railway.app/Priceperzones/${item._id}`
    const data = {
      isactive: !item.isactive,
    }
    try {
      const response = await axios.put(url, data, config)
      if (response.status === 200) {
        getPricePerZoneBycustomer()
        toastAlert(TOAST_TITLE.EDIT)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const deleteZone = async (id) => {
    setLoading(true)
    const url = `https://ventafazil-strapi-production.up.railway.app/Priceperzones/${id}`
    try {
      const response = await axios.delete(url, config)
      if (response.status === 200) {
        getPricePerZoneBycustomer()
        toastAlert(TOAST_TITLE.DELETE)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getPricePerZoneBycustomer()
  }, [])

  return (
    <div className='w-full md:w-10/12 flex flex-col gap-10 py-10'>
      {
        loading && <Loading />
      }
      {
        isPricePerZone ? (
          <PricePerZoneData
            pricePerZoneData={pricePerZoneData}
            allDeliveryList={allDeliveryList}
            changeActiveZone={changeActiveZone}
            deleteZone={deleteZone}
          />
        ) : (
          <p className='font-semibold'>Para editar esta sección activalo desde la pestaña delivery.</p>
        )
      }
    </div>
  )
}

export default PriceType