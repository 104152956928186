import React from 'react'

function UIinput(props) {
  return (
    <label
      className={`${props.className} font-semibold flex grow mb-3 flex-col gap-1`}
    >
      <div className='text-sm font-semibold tracking-tight text-gray-700 '>
        {props.label}
      </div>
      <div className='flex flex-row items-center rounded-lg '>
        {props.suffix && (
          <div className='flex items-center self-stretch p-2 text-xs font-semibold bg-gray-300 border border-r-0 border-gray-400 rounded-l-lg'>
            {props.suffix}
          </div>
        )}
        <input
          className={`w-full px-3 py-3 border border-gray-400 ${
            props.suffix ? 'rounded-l-none' : 'rounded-l-lg'
          } rounded-lg appearance-none focus:outline-none focus:border-yellow-500`}
          type={props.type}
          step={props.step}
          max={props.max}
          min={props.min}
          placeholder={props.placeholder}
          value={props.value}
          onChange={props.changePrice}
          name={props.nameInput}
          maxLength={props.maxLength}
          onBlur={props.onBlur}
          disabled={props.disabled}
        />
      </div>
    </label>
  )
}

export default UIinput
