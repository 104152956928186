import React, { useContext, useEffect } from 'react'
import NavigationSide from '../components/NavigationSide'
import NavigationTop from '../components/NavigationTop'
import UIBackofficeHeader from '../components/UIBackofficeHeader'
import UIBackofficeTableFilter from '../components/UIBackofficeTableFilter'
import { UserContext } from '../context/UserContext'

export default function Productos(props) {
  var category = props.match.params.categoryid
  const { user } = useContext(UserContext)

  // useEffect(() => {
  //   //console.log('user', user)
  //   //console.log('REDIRECT')
  //   if (user == null) {
  //     window.location.href = '/'
  //   }
  // }, [user, window.location])

  return (
    <div className='min-w-full w-fit pb-10 md:pb-24'>
      <NavigationTop />
      <NavigationSide />
      <div className='md:ml-24 lg:ml-24 md:mt-5'>
        <UIBackofficeHeader
          title='Mis Productos'
          isProduct={true}
          label='+ Agrega Producto'
          link='/producto/nuevo'
        />
        <div className='md:pl-20 lg:pl-28 px-5 zmd:pl-0'>
          <UIBackofficeTableFilter />
        </div>
      </div>
    </div>
  )
}
