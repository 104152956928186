import { useContext, useState, useEffect } from 'react'
import { UserContext } from '../../../context/UserContext'
import axios from 'axios'
import Swal from 'sweetalert2'
import Button1 from '../components/Button1'
import TextArea1 from '../components/TextArea1'
import Loading from '../../Loading'

const seoBody = Object.freeze({
  metapixelid: '',
  GTM: '',
})

function Seo() {
  const { user } = useContext(UserContext)
  const [loading, setLoading] = useState(true)
  const [storeData, setStoreData] = useState(seoBody)

  const onChange = (e) => {
    let { name, value } = e.target
    let _storeData = Object.assign({}, storeData)
    _storeData[name] = value
    setStoreData(_storeData)
  }

  const getCustomer = async () => {
    const url = `https://ventafazil-strapi-production.up.railway.app/clientes/${user}`
    try {
      const response = await axios.get(url)
      const data = response.data
      if (response.status === 200) {
        let _data = {
          metapixelid: data.metapixelid,
          GTM: data.GTM,
        }
        setStoreData(_data)
        setLoading(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const saveCustomer = async () => {
    setLoading(true)
    const url =
      `https://ventafazil-strapi-production.up.railway.app/clientes/` + user
    const data = {
      metapixelid: storeData.metapixelid,
      GTM: storeData.GTM,
    }
    const config = {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_KEY_STRAPI}`,
        'Content-Type': 'application/json',
      },
    }
    try {
      const response = await axios.put(url, data, config)
      if (response.status === 200) {
        Swal.fire('Se guardó el seo', '', 'success')
        setLoading(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getCustomer()
  }, [])

  return (
    <div className='w-full flex flex-col gap-10 mt-5 px-5 py-10 md:mx-0'>
      {loading && <Loading />}
      <div className='flex flex-col gap-3'>
        <h2 className='font-semibold'>SEO</h2>
        <p className='' style={{ color: '#787878' }}>
          Seo de tu tienda.
        </p>
      </div>
      <TextArea1
        labelName='Pixel Meta'
        className='h-32'
        name='metapixelid'
        placeholder='Insertar el pixel de meta'
        value={storeData.metapixelid}
        onChange={(e) => onChange(e)}
      />
      <TextArea1
        labelName='Pixel Google'
        className='h-32'
        name='GTM'
        placeholder='Insertar el pixel de meta'
        value={storeData.GTM}
        onChange={(e) => onChange(e)}
      />
      <div className='flex justify-end'>
        <Button1
          textColor='#ffffff'
          background='#8163FF'
          text='Guardar'
          onClick={saveCustomer}
        />
      </div>
    </div>
  )
}

export default Seo
