import axios from 'axios'
import React, { useEffect } from 'react'
import { useContext } from 'react'
import { useState } from 'react'
import { UserContext } from '../context/UserContext'
import { currencyFromStrapi } from '../utils/utils'
import { Pie3D, Column3D, Bar3D, Area3D, TableChart } from './charts'
import { format, formatDistance, formatRelative, subDays } from 'date-fns'
import { faTimes, faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import DatePicker from 'react-datepicker'

import { registerLocale, setDefaultLocale } from 'react-datepicker'
import es from 'date-fns/locale/es'
import 'react-datepicker/dist/react-datepicker.css'

import SimpleLineChart from './charts/SimpleChart'
import Indicador from './charts/Indicador'
import Modal from './Modal'
import { date } from 'yup/lib/locale'
import { MyCharts } from './charts/MyCharts'
import { PromedioPedido } from './charts/PromedioPedido'
import { red } from '@cloudinary/url-gen/actions/adjust'
import { CurrencyContext } from '../context/CurrencyContext'
console.log('DEUS', process.env)

const MESES = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
]

const getWeek = function ($date) {
  var onejan = new Date($date.getFullYear(), 0, 1)
  return Math.ceil((($date - onejan) / 86400000 + onejan.getDay() + 1) / 7)
}

const HomeGraphics = () => {
  const [openModal, setOpenModal] = useState(false)
  const [startDate, setStartDate] = useState('')
  const [startDate1, setStartDate1] = useState()
  // const [startDate, setStartDate] = useState('')
  // const [startDate1, setStartDate1] = useState('')
  const [endDate, setEndDate] = useState('')
  const [loader, setLoader] = useState(false)
  const [ventas, setVentas] = useState([])
  const [cities, setCities] = useState([])
  const [categories, setCategories] = useState([])
  const [products, setProducts] = useState([])
  const [maxBoletas, setmaxBoletas] = useState([])
  const [VentasRealizadas, setVentasRealizadas] = useState(0)
  const [productosVendidos, setproductosVendidos] = useState(0)
  const [dineroGenerado, setdineroGenerado] = useState(0)
  const { user, setUser } = useContext(UserContext)
  const [alert, setAlert] = useState(false)
  const [dateX, setDateX] = useState([])
  const [dateY, setDateY] = useState([])
  const [dateYReembolso, setdateYReembolso] = useState([])
  const [dateYPromedio, setdateYPromedio] = useState([])
  const currencyValue = useContext(CurrencyContext)
  const currency = currencyValue.currency
  registerLocale('es', es)

  //console.log('MYuser', user)
  const onChangeFech1 = (fecha) => {
    //console.log('MY FECHAAA')

    setStartDate(fecha)
  }
  const onChangeFech2 = (fecha) => {
    setStartDate1(fecha)

    // //console.log(startDate1.toISOString())
    // // //console.log(startDate1.toISOString() + 1)
  }

  const changeFechas = () => {
    if (!startDate || !startDate1) {
      //console.log('Rellenar los CAMPOS')

      setAlert(true)
      setTimeout(() => {
        setAlert(false)
      }, 2000)
      return
    }
    consultarApi()

    setOpenModal(false)
    // setTimeout(() => {
    //   setStartDate('')
    //   setStartDate1('')
    // }, 1000)
  }
  const onCancelar = () => {
    setStartDate('')
    setStartDate1('')
    setOpenModal(false)
  }

  const getMonthDifference = (startDate, endDate) => {
    const diferencia =
      endDate.getMonth() -
      startDate.getMonth() +
      12 * (endDate.getFullYear() - startDate.getFullYear())
    const date = new Date()
    //console.log('ddd', diferencia)
    let month = []
    for (let i = 0; i < diferencia + 1; i++) {
      month.push(MESES[startDate.getMonth() + i])
    }

    return month
  }

  const getYearDifference = (startDate, endDate) => {
    // const diferencia =
    //   endDate.getMonth() -
    //   startDate.getMonth() +
    //   12 * (endDate.getFullYear() - startDate.getFullYear())
    //   //console.log('ddd', diferencia)
    // let month = []
    // for (let i = 0; i < diferencia + 1; i++) {
    //   month.push(MESES[startDate.getMonth() + i])
    // }

    // return month

    const diferencia = endDate.getFullYear() - startDate.getFullYear()
    let years = []
    for (let i = 0; i < diferencia + 1; i++) {
      years.push(startDate.getFullYear() + i)
    }
    //console.log('YEARS', years)
    return years
  }

  const consultarApi = () => {
    setLoader(true)
    let id = user
    let enDate
    if (startDate1) {
      enDate = new Date(startDate1)
      enDate.setHours(23, 59, 59, 999)

      setEndDate(enDate)
    }
    // PROBANDO111 2022-12-24T04:59:59.999Z
    // let url = `https://ventafazil-strapi-production.up.railway.app/ventas?_limit=-1&cliente=${id}&_sort=id:DESC&estado=aceptado`

    let url = `https://ventafazil-strapi-production.up.railway.app/ventas?_limit=-1&cliente=${id}&_sort=id:DESC&estado_in=reembolsoparcial&estado_in=aceptado
`

    let url1 = `https://ventafazil-strapi-production.up.railway.app/ventas?_limit=-1&cliente=${id}&_sort=id:DESC&estado_in=reembolsoparcial&estado_in=reembolso`

    // reembolsoparcial
    // if (consultarFecha)
    //   // url += `&createdAt_gte=${startDate.toISOString()} &createdAt_lte=${startDate1.toISOString()}`

    console.log('DEUS', process.env.REACT_APP_KEY_STRAPI)
    if (startDate) url += `&createdAt_gte=${startDate.toISOString()}`
    if (startDate1) url += `&createdAt_lte=${enDate.toISOString()}`
    if (startDate) url1 += `&createdAt_gte=${startDate.toISOString()}`
    if (startDate1) url1 += `&createdAt_lte=${enDate.toISOString()}`
    let config = {
      method: 'get',
      url: url,
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${process.env.REACT_APP_KEY_STRAPI}`,
      },
    }

    let config1 = {
      method: 'get',
      url: url1,
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${process.env.REACT_APP_KEY_STRAPI}`,
      },
    }
    // ------------REMEBOLSO

    axios(config1)
      .then((response) => {
        let reembolso = response.data
        reembolso.map((v, i) => {
          // //console.log('fff', v.createdAt.slice(0, 4))
          let price = String(v.total)
          v.total = currencyFromStrapi(price)
          return v
        })
        const dataX = [
          ...new Set(
            response.data.map((v) => {
              return new Date(v.createdAt).getFullYear()
            })
          ),
        ].sort((a, b) => {
          return a - b
        })
        //console.log('MI DATA ', dataX)

        setDateX(dataX)
        let dateReembolso = []

        for (let i = 0; i < dataX.length; i++) {
          const dataAnioReem = reembolso.filter((venta) => {
            // //console.log('ff', dataX[i])
            // //console.log(venta.createdAt.slice(0, 4))
            return venta.createdAt.slice(0, 4) == dataX[i]
          })

          let reduceReembolso = dataAnioReem.reduce((acumulador, actual) => {
            if (actual.estado == 'reembolsoparcial') {
              //console.log('monto', actual.reembolsado.monto)
              return (
                acumulador +
                (parseFloat(actual.total) - actual.reembolsado.monto)
              )
            } else {
              return acumulador + parseFloat(actual.total)
            }
          }, 0)
          dateReembolso.push(reduceReembolso)
        }
        setdateYReembolso(dateReembolso)
        reembolsoTotal(reembolso)
      })
      .catch((error) => {})
    // --------------
    axios(config)
      .then((response) => {
        let vent = response.data
        vent.map((v, i) => {
          // //console.log('fff', v.createdAt.slice(0, 4))
          let price = String(v.total)
          v.total = currencyFromStrapi(price)
          return v
        })
        // //console.log('vent', ventas)
        const dataX = [
          ...new Set(
            response.data.map((v) => {
              return new Date(v.createdAt).getFullYear()
            })
          ),
        ].sort((a, b) => {
          return a - b
        })
        //console.log('MI DATA ', dataX)

        setDateX(dataX)
        let dateVe = []
        let dateVe1 = []

        for (let i = 0; i < dataX.length; i++) {
          const dataAnio = response.data.filter((venta) => {
            // //console.log('ff', dataX[i])
            // //console.log(venta.createdAt.slice(0, 4))
            return venta.createdAt.slice(0, 4) == dataX[i]
          })

          // let reduce = dataAnio.reduce(
          //   (acumulador, actual) => acumulador + parseFloat(actual.total),
          //   0
          // )
          let reduce = dataAnio.reduce((acumulador, actual) => {
            if (actual.estado == 'reembolsoparcial') {
              //console.log('monto', actual.reembolsado.monto)
              return (
                acumulador +
                (parseFloat(actual.total) -
                  parseFloat(actual.reembolsado.monto))
              )
            }
            return acumulador + parseFloat(actual.total)
          }, 0)

          let reduce1 = 0
          if (dataAnio.length >= 1) {
            reduce1 = (reduce / dataAnio.length).toFixed(2)
          } else {
            reduce1 = reduce
          }

          dateVe.push(reduce.toFixed(2))
          dateVe1.push(reduce1)
          //console.log('PROBANDO ESTE REDUCE', reduce)
        }
        //console.log('Q ESSS1', dateVe)
        setDateY(dateVe)
        setdateYPromedio(dateVe1)

        setVentas(vent)
        //console.log('vent length', vent.length)
        setVentasRealizadas(vent.length)

        // let dineroGen = vent.reduce(
        //   (acumulador, actual) => acumulador + parseInt(actual.total),
        //   0
        // )
        let dineroGen = vent.reduce((acumulador, actual) => {
          if (actual.estado == 'reembolsoparcial') {
            //console.log('monto', actual.reembolsado.monto)
            return (
              acumulador + (parseFloat(actual.total) - actual.reembolsado.monto)
            )
          }
          return acumulador + parseFloat(actual.total)
        }, 0)

        setdineroGenerado(dineroGen)
        dataXX()

        ventasTotales(vent)
        promedioPedido(vent)
        facturasMax(vent)
        contarCategorias(vent)
        formattGh(vent)
        ventasMas(vent)
        setLoader(false)
      })
      .catch((error) => {
        //console.log(error)
      })
  }

  const dataXX = () => {
    //console.log('entramos a ddd')

    if (!startDate && !startDate1) return

    var diasdif = startDate1.getTime() - startDate.getTime()

    var contdias = Math.round(diasdif / (1000 * 60 * 60 * 24))
    if (!startDate || !startDate1) {
      //console.log('vent', ventas)
    }

    let dateVe = []
    if (contdias == 0) {
      dateVe.push(startDate.toLocaleDateString('es-PE'))
    } else if (contdias <= 6) {
      //console.log('1 SEMANANA')
      //console.log('CONT DIAS', contdias)

      for (let index = 0; index < contdias + 1; index++) {
        const dia = new Date(startDate)
        dia.setDate(dia.getDate() + index)
        dateVe.push(dia.toLocaleDateString('es-PE'))
      }
    } else if (contdias <= 30) {
      const semanas = Math.ceil(contdias / 7)
      //console.log('MIS SEMANAS111', semanas)
      //console.log('mi final', startDate1)

      const final = new Date(startDate)
      final.setDate(final.getDate() + 7)

      for (let index = 0; index < semanas; index++) {
        const start = new Date(startDate)
        let final1 = new Date(final)

        start.setDate(start.getDate() + index * 7)
        // //console.log('INCIO', start)
        final1.setDate(final1.getDate() + index * 7)
        if (new Date(final1) > new Date(startDate1)) {
          final1 = new Date(startDate1)
          //console.log('ES MAYOR')
          //console.log('FINAL FIN11', final1)
        }

        date = dateVe.push(
          `${start.toLocaleDateString('es-PE')}- ${final1.toLocaleDateString(
            'es-PE'
          )} `
        )
        // //console.log('start Inicio', start)
        // //console.log('FINAL FIN', final1)
      }

      //console.log('MI DATA', dateVe)
    } else if (contdias > 364) {
      dateVe = getYearDifference(startDate, startDate1)
      // //console.log(startDate.getFullYear())
    } else if (contdias > 30) {
      dateVe = getMonthDifference(startDate, startDate1)
      //console.log('ant', dateVe)
    }
    setDateX(dateVe)
  }

  useEffect(() => {
    // let _currency = JSON.parse(localStorage.user)?.currencyvalue
    // currencyValue.changeCurrency(_currency)
    consultarApi()
  }, [])

  const formattGh = (vent) => {
    const cities = vent.reduce((total, v) => {
      const { metadata } = v
      const ciudad = metadata[0].city

      if (!total[ciudad]) {
        total[ciudad] = { label: ciudad, value: 1 }
      } else {
        total[ciudad] = {
          ...total[ciudad],
          value: total[ciudad].value + 1,
        }
      }
      return total
    }, {})

    console.log(vent)
    // const categories = vent.reduce((total, v) => {
    //   const {
    //     metadata: [{ detailProducts }],
    //   } = v

    //   // console.log(v)
    //   detailProducts.forEach((product) => {
    //     const { categorias } = product
    //     console.log('prod', product)
    //     if (categorias) {
    //       categorias.forEach((category) => {
    //         if (total[category]) {
    //           total[category]++
    //         } else {
    //           total[category] = 1
    //         }
    //       })
    //     }
    //   })

    //   return total
    // }, {})

    console.log(categories)

    const mostPopular = Object.values(cities)
      .sort((a, b) => {
        return b.value - a.value
      })
      .map((item) => {
        return { ...item, value: item.value }
      })
      .slice(0, 5)

    setCities(mostPopular)
  }
  function contarCategorias(obj) {
    const contadorCategorias = {}

    // Recorremos el objeto y contamos la cantidad de productos en cada categoría
    obj.forEach((pedido) => {
      pedido.metadata.forEach((dato) => {
        dato.detailProducts.forEach((producto) => {
          console.log('productooo', producto)
          producto.categorias?.forEach((categoria) => {
            if (contadorCategorias[categoria.nombrecategoria]) {
              contadorCategorias[categoria.nombrecategoria] +=
                1 * producto.amount
            } else {
              contadorCategorias[categoria.nombrecategoria] =
                1 * producto.amount
            }
          })
        })
      })
    })

    // Transformamos el objeto contador en la lista que necesitamos

    //   const mostPopular = Object.values(cities)
    //     .sort((a, b) => {
    //       return b.value - a.value
    //     })
    //     .map((item) => {
    //       return { ...item, value: item.value }
    //     })
    //     .slice(0, 5)

    //   setCities(mostPopular)
    // }

    const listaCategorias = Object.entries(contadorCategorias)
      .sort((a, b) => {
        return b.value - a.value
      })
      .map(([label, value]) => ({
        label,
        value: value.toString(),
      }))
      .slice(0, 5)
    console.log('lafe', listaCategorias)
    setCategories(listaCategorias)
  }

  const ventasTotales = (vent) => {
    //console.log('dia1', startDate1)
    if (!startDate) {
      //console.log('ASS')
    } else {
      var diasdif = startDate1.getTime() - startDate.getTime()
      //console.log(diasdif)
      var contdias = Math.round(diasdif / (1000 * 60 * 60 * 24))
      //console.log(contdias)
      let dateVe = []
      //console.log(startDate1)
      //console.log(startDate)

      if (contdias == 0) {
        //console.log('ES HOY', startDate)

        //console.log(vent)

        let reduce = vent.reduce(
          (acumulador, actual) => acumulador + parseFloat(actual.total),
          0
        )

        // let reduce = vent.reduce((acumulador, actual) => {
        //   if (actual.estado == 'reembolsoparcial') {
        //     //console.log('montoV', actual.reembolsado.monto)
        //     return (
        //       acumulador + (parseFloat(actual.total) - actual.reembolsado.monto)
        //     )
        //   }
        //   return acumulador + parseFloat(actual.total)
        // }, 0)

        //console.log('mi reduce', reduce)

        dateVe.push(reduce.toFixed(2))
        //console.log('PRUEBA', dateVe)
        setDateY(dateVe)
      } else if (contdias <= 6) {
        for (let index = 0; index < contdias + 1; index++) {
          const dia = new Date(startDate)
          dia.setDate(dia.getDate() + index)
          // dateVe.push(dia.toLocaleDateString('es-PE'))

          const dataSemana = vent.filter((venta) => {
            const data = new Date(venta.createdAt)

            return dia.toLocaleDateString() == data.toLocaleDateString()
          })
          //console.log('dia', dia.toLocaleDateString(), dataSemana)

          let reduce = dataSemana.reduce(
            (acumulador, actual) => acumulador + parseFloat(actual.total),
            0
          )
          dateVe.push(reduce.toFixed(2))
        }
        setDateY(dateVe)
      } else if (contdias <= 31) {
        const week1 = getWeek(startDate)
        let week2 = getWeek(startDate1)
        // //console.log('ARRAY')

        const arr = {}

        //console.log(contdias)
        //console.log()
        const semanas = Math.ceil(contdias / 7)
        //console.log('MIS SEMANAS', semanas)
        const final = new Date(startDate)
        final.setDate(final.getDate() + 7)

        for (let index = 0; index < semanas; index++) {
          // //console.log(startDate)

          const start = new Date(startDate)
          const final1 = new Date(final)
          start.setDate(start.getDate() + index * 7)
          // //console.log('INCIO', start)
          final1.setDate(final1.getDate() + index * 7)

          const dataSemana = vent.filter((venta) => {
            const data = new Date(venta.createdAt)
            return (
              new Date(start) <= new Date(data) &&
              new Date(final1) > new Date(data)
            )
          })

          //console.log('MI DATA SEMMM', dataSemana)

          let reduce = dataSemana.reduce(
            (acumulador, actual) => acumulador + parseFloat(actual.total),
            0
          )
          dateVe.push(reduce.toFixed(2))
        }

        setDateY(dateVe)
        // ANTIGUUO

        // if (week2 < 5 && week1 > week2) {
        //   week2 += week1
        //   //console.log('Este es mi week2', week2)
        //   // //console.log('Fff111')
        // }

        // vent.forEach((venta) => {
        //   const createAt1 = getWeek(new Date(venta.createdAt))
        //   //console.log(week1, week2, createAt1)

        //   if (week1 <= createAt1 && createAt1 <= week2) {
        //     //console.log('ENTRO')
        //     if (arr[createAt1] === undefined) {
        //       arr[createAt1] = {
        //         data: [],
        //       }
        //     }

        //     arr[createAt1].data.push(venta)

        //     // return true
        //   }
        // })

        // for (let index = 0; index < week2 - week1 + 1; index++) {
        //   if (arr[week1 + index]) {
        //     //console.log('prueba')
        //     //console.log(arr[week1 + index])
        //   } else {
        //     //console.log('FFFF', arr[week1 + index])
        //     arr[week1 + index] = {
        //       data: [],
        //     }
        //   }
        // }

        //console.log(arr)
      } else if (contdias > 365) {
        let dateVe = []

        //console.log('MAS UN AÑO')
        //console.log(startDate.getFullYear())
        const diferencia = getYearDifference(startDate, startDate1)
        // //console.log('fff', datoanio)
        //console.log(diferencia.length)
        for (let i = 0; i < diferencia.length; i++) {
          // month.push(MESES[startDate.getMonth() + i])
          const dataAnio = vent.filter((venta) => {
            return venta.createdAt.slice(0, 4) == startDate.getFullYear() + i
          })
          let reduce = dataAnio.reduce(
            (acumulador, actual) => acumulador + parseFloat(actual.total),
            0
          )
          dateVe.push(reduce.toFixed(2))
          setDateY(dateVe)

          // dateVe = getMonthDifference(startDate, startDate1)
        }
        //console.log('SUMAAA', dateVe)
        // //console.log(startDate.getFullYear())
      } else if (contdias >= 30) {
        //console.log('FFFGF', vent)
        const diferencia = getMonthDifference(startDate, startDate1)
        //console.log(diferencia, startDate, startDate1)

        for (let i = 0; i < diferencia.length; i++) {
          const dataMes = vent.filter((venta) => {
            return (
              new Date(venta.createdAt).getMonth() + 1 ==
              MESES.findIndex((x) => x == diferencia[i]) + 1
            )
          })

          //console.log('DATA MES', dataMes)
          let reduce = dataMes.reduce(
            (acumulador, actual) => acumulador + parseFloat(actual.total),
            0
          )
          dateVe.push(reduce.toFixed(2))

          // dateVe = getMonthDifference(startDate, startDate1)
        }

        //console.log('fff', dateVe)
        setDateY(dateVe)
      }
    }
  }

  const reembolsoTotal = (vent) => {
    //console.log('dia1', startDate1)
    if (!startDate) {
      //console.log('ASS')
    } else {
      var diasdif = startDate1.getTime() - startDate.getTime()
      //console.log(diasdif)
      var contdias = Math.round(diasdif / (1000 * 60 * 60 * 24))
      //console.log(contdias)
      let dateVe = []
      //console.log(startDate1)
      //console.log(startDate)

      if (contdias == 0) {
        //console.log('ES HOY REEM', startDate)

        //console.log(vent)

        let reduce = vent.reduce(
          (acumulador, actual) => acumulador + parseFloat(actual.total),
          0
        )

        //console.log('mi reduce', reduce)

        dateVe.push(reduce)
        //console.log('PRUEBA', dateVe)
        setdateYPromedio(dateVe)
      } else if (contdias <= 6) {
        for (let index = 0; index < contdias + 1; index++) {
          const dia = new Date(startDate)
          dia.setDate(dia.getDate() + index)
          // dateVe.push(dia.toLocaleDateString('es-PE'))

          const dataSemana = vent.filter((venta) => {
            const data = new Date(venta.createdAt)

            return dia.toLocaleDateString() == data.toLocaleDateString()
          })
          //console.log('dia', dia.toLocaleDateString(), dataSemana)

          let reduce = dataSemana.reduce(
            (acumulador, actual) => acumulador + parseFloat(actual.total),
            0
          )
          dateVe.push(reduce)
        }
        setdateYReembolso(dateVe)
      } else if (contdias <= 31) {
        const week1 = getWeek(startDate)
        let week2 = getWeek(startDate1)
        // //console.log('ARRAY')

        const arr = {}

        //console.log(contdias)
        //console.log()
        const semanas = Math.ceil(contdias / 7)
        //console.log('MIS SEMANAS', semanas)
        const final = new Date(startDate)
        final.setDate(final.getDate() + 7)

        for (let index = 0; index < semanas; index++) {
          // //console.log(startDate)

          const start = new Date(startDate)
          const final1 = new Date(final)
          start.setDate(start.getDate() + index * 7)
          // //console.log('INCIO', start)
          final1.setDate(final1.getDate() + index * 7)

          const dataSemana = vent.filter((venta) => {
            const data = new Date(venta.createdAt)
            return (
              new Date(start) <= new Date(data) &&
              new Date(final1) > new Date(data)
            )
          })

          //console.log('MI DATA SEMMM', dataSemana)

          let reduce = dataSemana.reduce(
            (acumulador, actual) => acumulador + parseFloat(actual.total),
            0
          )
          dateVe.push(reduce)
        }

        setdateYReembolso(dateVe)

        //console.log(arr)
      } else if (contdias > 365) {
        let dateVe = []

        //console.log('MAS UN AÑO')
        //console.log(startDate.getFullYear())
        const diferencia = getYearDifference(startDate, startDate1)
        // //console.log('fff', datoanio)
        //console.log(diferencia.length)
        for (let i = 0; i < diferencia.length; i++) {
          // month.push(MESES[startDate.getMonth() + i])
          const dataAnio = vent.filter((venta) => {
            return venta.createdAt.slice(0, 4) == startDate.getFullYear() + i
          })
          let reduce = dataAnio.reduce(
            (acumulador, actual) => acumulador + parseFloat(actual.total),
            0
          )
          dateVe.push(reduce)
          setdateYReembolso(dateVe)

          // dateVe = getMonthDifference(startDate, startDate1)
        }
        //console.log('SUMAAA', dateVe)
        // //console.log(startDate.getFullYear())
      } else if (contdias >= 30) {
        //console.log('FFFGF', vent)
        const diferencia = getMonthDifference(startDate, startDate1)
        //console.log(diferencia, startDate, startDate1)

        for (let i = 0; i < diferencia.length; i++) {
          const dataMes = vent.filter((venta) => {
            return (
              new Date(venta.createdAt).getMonth() + 1 ==
              MESES.findIndex((x) => x == diferencia[i]) + 1
            )
          })

          //console.log('DATA MES', dataMes)
          let reduce = dataMes.reduce(
            (acumulador, actual) => acumulador + parseFloat(actual.total),
            0
          )
          dateVe.push(reduce)

          // dateVe = getMonthDifference(startDate, startDate1)
        }

        //console.log('fff', dateVe)
        setdateYReembolso(dateVe)
      }
    }
  }

  const promedioPedido = (vent) => {
    //console.log('dia1', startDate1)
    if (!startDate) {
      //console.log('ASS')
    } else {
      var diasdif = startDate1.getTime() - startDate.getTime()
      //console.log(diasdif)
      var contdias = Math.round(diasdif / (1000 * 60 * 60 * 24))
      //console.log(contdias)
      let dateVe = []
      //console.log(startDate1)
      //console.log(startDate)

      if (contdias == 0) {
        //console.log('ES HOY', startDate)

        //console.log(vent)

        let reduce = vent.reduce(
          (acumulador, actual) => acumulador + parseFloat(actual.total),
          0
        )
        //console.log('mi reduce', reduce)
        let reduce1 = 0
        if (vent.length >= 1) {
          reduce1 = (reduce / vent.length).toFixed(2)
        } else {
          reduce1 = reduce
        }

        dateVe.push(reduce1)

        setdateYPromedio(dateVe)
      } else if (contdias <= 6) {
        for (let index = 0; index < contdias + 1; index++) {
          const dia = new Date(startDate)
          dia.setDate(dia.getDate() + index)
          // dateVe.push(dia.toLocaleDateString('es-PE'))

          const dataSemana = vent.filter((venta) => {
            const data = new Date(venta.createdAt)

            return dia.toLocaleDateString() == data.toLocaleDateString()
          })
          //console.log('dia', dia.toLocaleDateString(), dataSemana)

          let reduce = dataSemana.reduce(
            (acumulador, actual) => acumulador + parseFloat(actual.total),
            0
          )
          let reduce1 = 0
          if (dataSemana.length >= 1) {
            reduce1 = (reduce / dataSemana.length).toFixed(2)
          } else {
            reduce1 = reduce
          }

          dateVe.push(reduce1)
        }
        setdateYPromedio(dateVe)
      } else if (contdias <= 31) {
        const week1 = getWeek(startDate)
        let week2 = getWeek(startDate1)
        // //console.log('ARRAY')

        const arr = {}

        //console.log(contdias)
        //console.log()
        const semanas = Math.ceil(contdias / 7)
        //console.log('MIS SEMANAS', semanas)
        const final = new Date(startDate)
        final.setDate(final.getDate() + 7)

        for (let index = 0; index < semanas; index++) {
          // //console.log(startDate)

          const start = new Date(startDate)
          const final1 = new Date(final)
          start.setDate(start.getDate() + index * 7)
          // //console.log('INCIO', start)
          final1.setDate(final1.getDate() + index * 7)

          const dataSemana = vent.filter((venta) => {
            const data = new Date(venta.createdAt)
            return (
              new Date(start) <= new Date(data) &&
              new Date(final1) > new Date(data)
            )
          })

          //console.log('MI DATA SEMMM', dataSemana)

          let reduce = dataSemana.reduce(
            (acumulador, actual) => acumulador + parseFloat(actual.total),
            0
          )

          let reduce1 = 0
          if (dataSemana.length >= 1) {
            reduce1 = (reduce / dataSemana.length).toFixed(2)
          } else {
            reduce1 = reduce
          }

          dateVe.push(reduce1)
        }

        setdateYPromedio(dateVe)
        // ANTIGUUO

        // if (week2 < 5 && week1 > week2) {
        //   week2 += week1
        //   //console.log('Este es mi week2', week2)
        //   // //console.log('Fff111')
        // }

        // vent.forEach((venta) => {
        //   const createAt1 = getWeek(new Date(venta.createdAt))
        //   //console.log(week1, week2, createAt1)

        //   if (week1 <= createAt1 && createAt1 <= week2) {
        //     //console.log('ENTRO')
        //     if (arr[createAt1] === undefined) {
        //       arr[createAt1] = {
        //         data: [],
        //       }
        //     }

        //     arr[createAt1].data.push(venta)

        //     // return true
        //   }
        // })

        // for (let index = 0; index < week2 - week1 + 1; index++) {
        //   if (arr[week1 + index]) {
        //     //console.log('prueba')
        //     //console.log(arr[week1 + index])
        //   } else {
        //     //console.log('FFFF', arr[week1 + index])
        //     arr[week1 + index] = {
        //       data: [],
        //     }
        //   }
        // }

        //console.log(arr)
      } else if (contdias > 365) {
        let dateVe = []

        //console.log('MAS UN AÑO')
        //console.log(startDate.getFullYear())
        const diferencia = getYearDifference(startDate, startDate1)
        // //console.log('fff', datoanio)
        //console.log(diferencia.length)
        for (let i = 0; i < diferencia.length; i++) {
          // month.push(MESES[startDate.getMonth() + i])
          const dataAnio = vent.filter((venta) => {
            return venta.createdAt.slice(0, 4) == startDate.getFullYear() + i
          })
          let reduce = dataAnio.reduce(
            (acumulador, actual) => acumulador + parseFloat(actual.total),
            0
          )
          //console.log('reduce1', reduce)

          let reduce1 = 0
          if (dataAnio.length >= 1) {
            reduce1 = (reduce / dataAnio.length).toFixed(2)
          } else {
            reduce1 = reduce
          }

          dateVe.push(reduce1)
          setdateYPromedio(dateVe)

          // dateVe = getMonthDifference(startDate, startDate1)
        }
        //console.log('SUMAAA', dateVe)
        // //console.log(startDate.getFullYear())
      } else if (contdias >= 30) {
        //console.log('FFFGF', vent)
        const diferencia = getMonthDifference(startDate, startDate1)
        //console.log(diferencia, startDate, startDate1)

        for (let i = 0; i < diferencia.length; i++) {
          const dataMes = vent.filter((venta) => {
            return (
              new Date(venta.createdAt).getMonth() + 1 ==
              MESES.findIndex((x) => x == diferencia[i]) + 1
            )
          })

          //console.log('DATA MES', dataMes)
          let reduce = dataMes.reduce(
            (acumulador, actual) => acumulador + parseFloat(actual.total),
            0
          )
          //console.log('reduce1', reduce)
          let reduce1 = 0
          if (dataMes.length >= 1) {
            reduce1 = (reduce / dataMes.length).toFixed(2)
          } else {
            reduce1 = reduce
          }

          dateVe.push(reduce1)

          // dateVe = getMonthDifference(startDate, startDate1)
        }

        //console.log('fff', dateVe)
        setdateYPromedio(dateVe)
      }
    }
  }

  const facturasMax = (vent) => {
    const masFact = vent
      .sort((a, b) => {
        return b.total - a.total
      })
      .map((item) => {
        return { ...item, total: item.total }
      })
      .slice(0, 5)
    setmaxBoletas(masFact)
  }

  const ventasMas = (vent) => {
    const productMasVendidos1 = vent.map((product) => {
      const data = product.metadata[0].detailProducts
      const Venr = data.reduce((total, product) => {
        if (!total[product.nombreproducto]) {
          total[product.nombreproducto] = {
            label: product.nombreproducto,
            value: 1,
            amount: parseInt(product.amount) || 1,
            image: product.imagenproducto,
          }
        } else {
          const amount = +1

          total[product.nombreproducto] = {
            ...total[product.nombreproducto],
            value: parseInt(total[product.nombreproducto].value),
            amount: parseInt(total[product.nombreproducto].amount) + 1,
          }
        }

        return total
      }, {})
      return Venr
    })

    const newArreglo = []
    //console.log('Producgttt', productMasVendidos1)
    const producto2 = productMasVendidos1.map((actual) => {
      const productoUnique = Object.values(actual)

      const prd = productoUnique.map((v) => {
        const { label } = v
        newArreglo.push(v)
      }, {})
    })

    const vc = newArreglo.reduce((total, v) => {
      const ciudad = v.label

      if (!total[ciudad]) {
        total[ciudad] = {
          label: ciudad,
          value: 1,
          amount: v.amount,
          image: v.image,
        }
      } else {
        total[ciudad] = {
          ...total[ciudad],
          value: parseInt(total[ciudad].value) + 1,
          amount: parseInt(total[ciudad].amount) + v.amount,
        }
      }
      return total
    }, {})

    const productosVendidoss = Object.values(vc)

    let reduce = productosVendidoss.reduce(
      (acumulador, actual) => acumulador + actual.amount,
      0
    )
    setproductosVendidos(reduce)
    const masVendido = Object.values(vc)
      .sort((a, b) => {
        return b.amount - a.amount
      })
      .map((item) => {
        return { ...item, value: item.value }
      })
      .slice(0, 5)
    setProducts(masVendido)
  }

  return (
    <div>
      {loader ? (
        <div>Cargando</div>
      ) : !ventas.length >= 1 ? (
        <div className='w-full'>
          <div className='flex justify-end '>
            <div
              className='flex items-center justify-center w-40 h-10 border-2 rounded-lg cursor-pointer '
              onClick={() => setOpenModal(true)}
            >
              <FontAwesomeIcon icon={faCalendarAlt} className='mr-2' />
              Filtros
            </div>
          </div>

          <p>No existen ventas registradas en estas fechas</p>
        </div>
      ) : (
        <div>
          <div className='flex gap-4 justify-between items-center'>
            <div className='flex gap-4'>
              <Indicador
                text='Pedidos'
                number={VentasRealizadas}
                color='#A38DFF'
                textColor='#FFFFFF'
              />
              <Indicador
                text='Productos Vendidos'
                number={productosVendidos}
                color='#8163FF'
                textColor='#FFFFFF'
              />
              <Indicador
                text='Dinero Generado'
                number={dineroGenerado}
                currency={currency}
                decimal={true}
                color='rgb(0 251 200)'
              />
            </div>
            <div
              className='flex items-center justify-center w-40 h-10 border-2 rounded-lg cursor-pointer'
              onClick={() => setOpenModal(true)}
            >
              <FontAwesomeIcon icon={faCalendarAlt} className='mr-2' />
              Filtros
            </div>
          </div>
          {startDate && startDate1 ? (
            <>
              <p>Desde: {startDate && startDate.toLocaleDateString('es-PE')}</p>
              <p>
                Hasta: {startDate1 && startDate1.toLocaleDateString('es-PE')}
              </p>
            </>
          ) : (
            <h1></h1>
          )}

          <div className='flex w-auto gap-8 mt-4'>
            <div className='w-1/2 p-4 border-2 border-zinc-400 rounded-xl'>
              <h4 className='font-bold text-center'>
                Categorias mas compradas
              </h4>
              <Bar3D
                data={categories}
                x='label'
                y='value'
                startDate={startDate}
                startDate1={startDate1}
              />
            </div>
            <div className='w-full md:w-1/2 p-4 border-2 border-zinc-400 rounded-xl'>
              <TableChart data={products} />
            </div>
          </div>
          {/* <div className='w-1/2 p-4 border-2 border-zinc-400 rounded-xl'>
            <h4 className='font-bold text-center'>Ventas por CATEGORIA</h4>
            <Bar3D
              data={cities}
              x='label'
              y='value'
              startDate={startDate}
              startDate1={startDate1}
            />
          </div> */}
          <div className='flex w-auto gap-8 mt-4'>
            <div className='w-1/2 p-4 border-2 border-zinc-400 rounded-xl'>
              <h4 className='font-bold text-center'>Ventas Totales</h4>
              <MyCharts
                dateX={dateX}
                dateY={dateY}
                currency={currency}
                // background='#A38DFF'
                background='#8163FF'
              />
            </div>
            <div className='w-full md:w-1/2 p-4 border-2 border-zinc-400 rounded-xl'>
              <h4 className='font-bold text-center'>Valor Promedio Pedido</h4>
              <PromedioPedido
                dateX={dateX}
                dateY={dateYPromedio}
                currency={currency}
              />
            </div>
          </div>
          <div className='flex flex-col md:flex-row w-full gap-8 mt-4'>
            <div className='w-full md:w-1/2 p-4 border-2 border-zinc-400 rounded-xl'>
              <h4 className='font-bold text-center'>REEMBOLSOS</h4>
              <MyCharts
                dateX={dateX}
                currency={currency}
                dateY={dateYReembolso}
                background='#A38DFF'
              />
            </div>
            <div className='w-full md:w-1/2 p-4 '>
              {/* <h4 className='font-bold text-center'>REEMBOLSOS</h4> */}
            </div>
          </div>
        </div>
      )}

      {openModal && (
        <Modal
          setOpenModal={setOpenModal}
          onClick={changeFechas}
          onCancelar={onCancelar}
        >
          <div className='mt-3 '>
            <div className='flex items-center justify-center w-24 h-10 border-2 rounded-lg'>
              <FontAwesomeIcon icon={faCalendarAlt} className='mr-2' />
              Fechas
            </div>
            <div className='flex items-center justify-around w-full h-10 mt-5 text-center sm:ml-4 mb-14 '>
              <div>
                <h3>Inicio</h3>
                <DatePicker
                  placeholderText='Selecciona una fecha'
                  selected={startDate}
                  onChange={onChangeFech1}
                  locale='es'
                  dateFormat='dd-MM-yyyy'
                  closeOnScroll={false}
                />
              </div>
              <div>
                <h3>Fin</h3>
                <DatePicker
                  placeholderText='Selecciona una fecha'
                  selected={startDate1}
                  onChange={onChangeFech2}
                  locale='es'
                  dateFormat='dd-MM-yyyy'
                  className='placeholder-fecha'
                />
              </div>
            </div>
            {alert && (
              <div className='mt-3 text-center text-red-600'>
                Rellenar Todos los campos
              </div>
            )}
          </div>
        </Modal>
      )}
    </div>
  )
}

export default HomeGraphics
