import React, { useState, useEffect } from 'react'
import UIinput from './UIinput'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

export default function VaritionsUpdater(props) {
  const [variations, setVariations] = useState([])
  useEffect(() => {
    if (props.variations && props.variations.length > 0) {
      setVariations(props.variations)
    }
  }, [])

  function editVariation(event, i) {
    let name = event.target.name
    let value = event.target.value
    const newVariations = [...variations]
    newVariations[i][name] = value
    setVariations(newVariations)
    props.changeVariations(newVariations)
  }

  function RemoveVariation(e) {
    const newVariations = [...variations]
    newVariations.splice(e, 1)
    setVariations(newVariations)
    props.changeVariations(newVariations)
    //console.log('VARRIACIONES', newVariations)
    //console.log('gollll')
  }

  function AddVariation(e) {
    const newVariations = [...variations]
    newVariations.push({
      name: '',
      price: '',
    })
    setVariations(newVariations)
    props.changeVariations(newVariations)
  }

  return (
    <div className='bg-gray-100 rounded-xl p-4'>
      <div className='mb-3 text-xs font-semibold text-gray-500 uppercase'>
        variaciones
      </div>
      {variations &&
        variations.map((variation, i) => {
          return (
            <div key={i + 'a'} className='flex justify-between gap-2'>
              <UIinput
                type='text'
                label='Variación'
                nameInput='name'
                changePrice={(e) => editVariation(e, i)}
                value={variation.name}
              />
              <div className='w-24'>
                <UIinput
                  type='text'
                  suffix='S/'
                  label='Precio'
                  nameInput='price'
                  changePrice={(e) => editVariation(e, i)}
                  value={variation.price}
                />
              </div>
              <div
                className='flex flex-col justify-end'
                onClick={() => RemoveVariation(i)}
              >
                <div className='flex items-center justify-center w-6 h-6 mb-6 text-white bg-black rounded-full cursor-pointer hover:bg-red-600 '>
                  <FontAwesomeIcon icon={faTimes} />{' '}
                </div>
              </div>
            </div>
          )
        })}
      <div
        onClick={() => AddVariation()}
        className='text-sm font-bold tracking-tighter text-blue-600 opacity-50 cursor-pointer hover:opacity-100'
      >
        + Agregar una variación
      </div>
    </div>
  )
}
