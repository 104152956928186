import React, { Component, useState, useEffect, useRef } from 'react'
import '@toast-ui/editor/dist/toastui-editor.css'

import { Editor } from '@toast-ui/react-editor'
import {
  GetTermsConditionsAPI,
  PutTermsConditionsAPI,
  PostTermsConditionsAPI,
} from '../utils/GetTermsConditions'

export default function EditorTexto() {
  // get cliente from local storage as a variable
  const user = JSON.parse(localStorage.getItem('user'))
  const idcliente = user?.id

  const [markup, setMarkup] = useState('')
  const [markdownvalue, setMarkdownvalue] = useState('')
  const editorRef = useRef()
  const [id, setId] = useState('')

  useEffect(() => {
    GetTermsConditionsAPI(idcliente).then((data) => {
      if (data.length > 0) {
        setMarkup(data[0].texto)
        setId(data[0].id)
      } else {
        //console.log("inicia creación")
        PostTermsConditionsAPI().then((res) => {
          if (res) {
            PutTermsConditionsAPI(res.id, {
              texto: 'Nuevo texto de prueba',
              cliente: idcliente,
            }).then((data) => {
              //console.log("EDITO", data);
              setMarkup(data.texto)
              setId(data.id)
            })
          }
        })
      }
    })
  }, [id])

  function handleChange(e) {
    setMarkdownvalue(e)
    //console.log(markdownvalue);
  }

  function SubmitValue() {
    var body = { texto: editorRef.current.getInstance().getMarkdown() }
    PutTermsConditionsAPI(id, body).then((data) => {
      //console.log(data);
    })
  }

  function CreateNew(idcliente) {
    var body = { texto: idcliente }
    PostTermsConditionsAPI().then((data) => {
      //console.log(data);
    })
  }

  return (
    <div>
      {markup !== '' ? (
        <Editor
          ref={editorRef}
          initialValue={markup}
          height='600px'
          value={markdownvalue}
          events={{ change: handleChange }}
          onChange={(e) => handleChange(e)}
        />
      ) : (
        <div onClick={() => CreateNew}> Crear </div>
      )}
      <div
        onClick={() => SubmitValue()}
        className='flex items-center self-start justify-center w-40 p-2 px-4 mt-2 font-bold text-black rounded-full cursor-pointer text-xxs bg-primary'
      >
        Guardar
      </div>
    </div>
  )
}
