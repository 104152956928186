import { createContext, useEffect, useState } from 'react'
import esPE from '../utils/languages/es-PE.json'
import esMX from '../utils/languages/es-MX.json'
import enUS from '../utils/languages/en-US.json'

const LanguageContext = createContext()
let initialLanguage
let storageUser = JSON.parse(localStorage.getItem('user'))
let _language = storageUser?.language

if (_language === 'es-PE') {
  initialLanguage = esPE
} else if (_language === 'es-MX') {
  initialLanguage = esMX
} else if (_language === 'en-US') {
  initialLanguage = enUS
} else {
  initialLanguage = esMX
}

const LanguageProvider = ({ children }) => {
  //navigator.language de window

  const [languageZone, setLanguageZone] = useState(storageUser?.paises?.name)
  console.log('PROBSSN', storageUser?.paises)
  const [language, setLanguage] = useState(initialLanguage)
  const [text, setText] = useState(language)
  const changeLanguage = (lang) => {
    switch (lang) {
      case 'es-PE':
        setLanguage(esPE)
        setText(esPE)
        break
      case 'es-MX':
        setLanguage(esMX)
        setText(esMX)
        break
      case 'en-US':
        setLanguage(enUS)
        setText(enUS)
        break
      default:
        setLanguage(esMX)
        setText(esMX)
    }
  }

  const data = { text, changeLanguage, languageZone, setLanguageZone }

  return (
    <LanguageContext.Provider value={data}>{children}</LanguageContext.Provider>
  )
}

export { LanguageContext, LanguageProvider }
