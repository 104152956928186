import React, { useContext, useEffect, useState } from 'react'
import Button1 from '../components/Button1'
import Input1 from '../components/Input1'
import { HexColorPicker } from 'react-colorful'
import axios from 'axios'
import Swal from 'sweetalert2'
import { UserContext } from '../../../context/UserContext'
import { getPublicId } from '../../../utils/utils'
import ModalImageCrop from './ModalImageCrop'
import Loading from '../../delivery/components/Loading'

const RESOLUTION = Object.freeze({
  width: 1080,
  height: 400,
})

const aspectRatio = 16 / 9

const designBody = {
  primarycolor: '#00000',
  logo: '',
  primeraimagennosotros: '',
  listbanners: [],
}

const listBannersArray = [{ urlbanner: '', link: '', linkMobile: '' }]

function Design() {
  const { user } = useContext(UserContext)
  const [storeData, setStoreData] = useState(designBody)
  const [activeButton, setActiveButton] = useState(true)
  const [loading, setLoading] = useState(true)
  const [imageData, setImageData] = useState({
    imageIndex: 0,
    imageName: '',
    imagePreview: '',
  })
  const [showModalCropImage, setShowModalCropImage] = useState(false)

  const onChange = (e) => {
    let { name, value } = e.target
    let _storeData = Object.assign({}, storeData)
    _storeData.socialmedia[name] = value
    setStoreData(_storeData)
  }

  const getCustomer = async () => {
    const url = `https://ventafazil-strapi-production.up.railway.app/clientes/${user}`
    try {
      const response = await axios.get(url)
      const data = response.data
      if (response.status === 200) {
        let _data = {
          primarycolor: data.primarycolor,
          logo: data.logo,
          primeraimagennosotros: data.primeraimagennosotros,
          listbanners:
            data.listbanners && data.listbanners.length > 0
              ? data.listbanners
              : listBannersArray,
        }
        setStoreData(_data)
        setLoading(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const deleteBeforeImage = async (beforeUrl) => {
    let publicID = getPublicId(beforeUrl)
    let config = {
      method: 'post',
      url: `https://vf-express-production.up.railway.app/api/images/delete`,
      data: {
        public_id: 'VentaFacil/' + publicID,
      },
    }
    await axios(config)
      .then((response) => {
        //console.log(response)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const onChangeImage = async (e) => {
    setLoading(true)
    const url = 'https://vf-express-production.up.railway.app/api/images/upload'
    let name = e.target.name
    let file = e.target.files[0]
    let formdata = new FormData()
    formdata.append('file', file)
    formdata.append('upload_preset', 'VentaFacil')
    await fetch(url, {
      method: 'POST',
      body: formdata,
    })
      .then((response) => response.json())
      .then((response) => {
        let _storeData = Object.assign({}, storeData)
        if (name === 'logo') {
          if (_storeData.logo && _storeData.logo !== '') {
            deleteBeforeImage(_storeData.logo)
          }
          _storeData.logo = response.secure_url
          setStoreData(_storeData)
        } else if (name === 'primeraimagennosotros') {
          if (
            _storeData.primeraimagennosotros &&
            _storeData.primeraimagennosotros !== ''
          ) {
            deleteBeforeImage(_storeData.primeraimagennosotros)
          }
          _storeData.primeraimagennosotros = response.secure_url
          setStoreData(_storeData)
        }
        setLoading(false)
      })
      .catch((error) => console.log(error))
  }

  const uploadCropperBanner = (e, index) => {
    let files
    if (e.dataTransfer) {
      files = e.dataTransfer.files
    } else if (e.target) {
      files = e.target.files
    }
    const reader = new FileReader()
    reader.onload = () => {
      setImageData({
        ...imageData,
        imageIndex: index,
        imageName: files[0].name,
        imagePreview: reader.result,
      })
      let _listbanners = [...storeData.listbanners]
      _listbanners[index].link = reader.result
      setStoreData({ ...storeData, listbanners: _listbanners })
    }
    reader.readAsDataURL(files[0])
    setShowModalCropImage(true)
  }

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], filename, { type: mime })
  }

  const uploadCloudinary = async (data, index, typeBanner) => {
    setActiveButton(false)
    await fetch(
      'https://vf-express-production.up.railway.app/api/images/upload',
      {
        method: 'POST',
        body: data,
      }
    )
      .then((response) => response.json())
      .then((response) => {
        let _listbanners = [...storeData.listbanners]
        if (typeBanner === 'mobile') {
          _listbanners[index].linkMobile = response.secure_url
        } else {
          _listbanners[index].link = response.secure_url
          _listbanners[index].public_id = response.public_id
        }
        setStoreData({ ...storeData, listbanners: _listbanners })
        setActiveButton(true)
      })
  }

  const cutbanner = async (img, index) => {
    setLoading(true)
    console.log('entreqaads<jsajkadskj')
    let imgtrasnform = dataURLtoFile(img, imageData.imageName)
    let data = new FormData()
    data.append('file', imgtrasnform)
    data.append('upload_preset', 'VentaFacil')
    await uploadCloudinary(data, index)
    setLoading(false)
  }

  const changeUrlBanner = (event, index) => {
    let _listbanners = [...storeData.listbanners]
    _listbanners[index].urlbanner = event.target.value
    setStoreData({ ...storeData, listbanners: _listbanners })
  }

  const addListBanner = (index) => {
    let _listbanners = [...storeData.listbanners]
    let emptybanner = { urlbanner: '', link: '', linkMobile: '' }
    _listbanners.splice(index + 1, 0, emptybanner)
    setActiveButton(false)
    setStoreData({ ...storeData, listbanners: _listbanners })
  }

  const removeListBanner = (index) => {
    let _listbanners = [...storeData.listbanners]
    _listbanners.splice(index, 1)
    setActiveButton(true)
    setStoreData({ ...storeData, listbanners: _listbanners })
  }

  const saveCustomer = async () => {
    const url =
      `https://ventafazil-strapi-production.up.railway.app/clientes/` + user
    const data = {
      primarycolor: storeData.primarycolor,
      logo: storeData.logo,
      primeraimagennosotros: storeData.primeraimagennosotros,
      listbanners: storeData.listbanners,
    }
    const config = {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_KEY_STRAPI}`,
        'Content-Type': 'application/json',
      },
    }
    try {
      const response = await axios.put(url, data, config)
      if (response.status === 200) {
        Swal.fire('Se guardo tu diseño', '', 'success')
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getCustomer()
  }, [])

  return (
    <>
      {showModalCropImage && (
        <ModalImageCrop
          type='banners'
          resolution={RESOLUTION}
          aspectRatio={aspectRatio}
          setShowModalCropImage={setShowModalCropImage}
          cutbanner={cutbanner}
          index={imageData.imageIndex}
          image={imageData.imagePreview}
          data={storeData.listbanners}
          removeListBanner={removeListBanner}
        />
      )}
      <div className='w-full flex flex-col gap-10 mt-5 px-5 py-10 md:mx-0'>
        <div className='flex flex-col gap-3'>
          <h2 className='font-semibold'>Diseño</h2>
          <p className='' style={{ color: '#787878' }}>
            Diseño de tu tienda.
          </p>
        </div>
        <div className='flex justify-between w-11/12'>
          <div className='flex gap-6'>
            <Input1
              classNameDiv='w-fit'
              label='Elige el color de tu tienda'
              className='w-full'
              name='Facebook'
              value={storeData.primarycolor}
              onChange={onChange}
            />
            <div
              className='w-12 h-12 mt-8 bg-gray-100 rounded-full'
              style={{ background: storeData.primarycolor }}
            ></div>
          </div>
          <label className='mt-8 text-xs font-bold text-gray-700 md:w-6/12'>
            <HexColorPicker
              color={storeData.primarycolor}
              onChange={(e) => setStoreData({ ...storeData, primarycolor: e })}
            />
          </label>
        </div>
        <div className='flex flex-col gap-10'>
          <div className='flex flex-col gap-3'>
            <label
              className='flex flex-col w-full font-bold'
              style={{ color: '#8C8C8C' }}
            >
              Sube tu logotipo : 220 x 48 pxs
            </label>
            {/* <input
                type='file'
                name='logo'
                className='h-12 p-2 mb-4 bg-gray-100 rounded-md'
                onChange={(e) => onChangeImage(e)}
              /> */}
            <label
              htmlFor='fileLogo'
              className='w-fit p-2 rounded-xl cursor-pointer'
              style={{ border: '2px dotted #000' }}
            >
              <input
                id='fileLogo'
                type='file'
                name='logo'
                className='hidden'
                onChange={(e) => onChangeImage(e)}
              />
              Subir archivo +
            </label>
            <img className='w-4/12' src={storeData.logo} alt='logotipo' />
          </div>
          <div className='flex flex-col gap-3'>
            <label
              className='flex flex-col w-full font-bold'
              style={{ color: '#8C8C8C' }}
            >
              Sube tu imagen de nosotros : 810x510 px
            </label>
            <label
              htmlFor='filePrimeraimagennosotros'
              className='w-fit p-2 rounded-xl cursor-pointer'
              style={{ border: '2px dotted #000' }}
            >
              <input
                id='filePrimeraimagennosotros'
                type='file'
                name='primeraimagennosotros'
                className='hidden'
                onChange={(e) => onChangeImage(e)}
              />
              Subir archivo +
            </label>
            <img className='w-4/12' src={storeData.primeraimagennosotros} />
          </div>
        </div>
        <div className='flex flex-col gap-4'>
          <p>Banners : 1080 x 400 px</p>
          <div className='flex flex-col gap-4 w-full'>
            {storeData.listbanners.map((banner, i) => {
              return (
                <div className='w-full flex gap-5 ' key={'_banner' + i}>
                  <Input1
                    className='w-full'
                    name='url'
                    placeholder='Link de imagen'
                    value={banner.urlbanner}
                    onChange={(e) => changeUrlBanner(e, i)}
                  />
                  <div className='w-full flex flex-col'>
                    <label className='md:w-3/12 lg:w-full'>
                      Imagen para PC
                    </label>
                    <div className='w-fit h-auto mt-1'>
                      <label
                        htmlFor={'fileBanner' + i}
                        className={`w-full p-2 rounded-xl cursor-pointer ${
                          banner.link === '' ? 'flex' : 'hidden'
                        }`}
                        style={{ border: '2px dotted #000' }}
                      >
                        {/* <input
                              id='fileBanner'
                              className={`${banner.link === '' ? 'w-48' : 'absolute z-0 w-0'}`}
                              type='file'
                              name='banners'
                              placeholder='Seleccionar Imagen'
                              onChange={(e) => uploadCropperBanner(e, i)}
                            /> */}
                        <input
                          id={'fileBanner' + i}
                          className='hidden'
                          type='file'
                          name='banners'
                          placeholder='Seleccionar Imagen'
                          onChange={(e) => uploadCropperBanner(e, i)}
                        />
                        Subir archivo +
                      </label>
                      <label
                        htmlFor={'fileBanner' + i}
                        className='w-full bg-yellow-200'
                      >
                        {banner.link === null ? (
                          <div className='w-6/12'>Cargando...</div>
                        ) : (
                          <img
                            className='w-6/12 cursor-pointer'
                            src={banner.link}
                          />
                        )}
                      </label>
                      <div></div>
                    </div>
                  </div>
                  <div className='w-fit flex gap-5'>
                    <div
                      onClick={() => addListBanner(i)}
                      className='h-10 px-4 py-2 text-white bg-gray-600 rounded-full cursor-pointer'
                    >
                      +
                    </div>
                    {storeData.listbanners.length !== 1 && (
                      <div
                        onClick={() => removeListBanner(i)}
                        className='h-10 px-4 py-2 text-white bg-gray-600 rounded-full cursor-pointer'
                      >
                        -
                      </div>
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div className='flex justify-end'>
          <Button1
            className={!activeButton ? 'cursor-default' : ''}
            textColor='#ffffff'
            background='#8163FF'
            text='Guardar'
            onClick={() => (activeButton ? saveCustomer() : () => {})}
          />
        </div>
      </div>
      {loading && <Loading />}
    </>
  )
}

export default Design
