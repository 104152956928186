import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import Modal from '../components/Modal'
import NavigationSide from '../components/NavigationSide'
import NavigationTop from '../components/NavigationTop'
import UIBackofficeProductCell from '../components/UIBackofficeProductCell'
import { UserContext } from '../context/UserContext'
import {
  metodoEntrega,
  metodoEntregaDelivery,
  metodoRecojo,
  regions,
} from '../utils/data'
import { PostVentasAPI } from '../utils/PostVentas'
import { useHistory } from 'react-router-dom'
import { verifyStock } from '../utils/VerifyStock'
import { discountStock } from '../utils/PutDiscount'
import { PostHistoriaAPI } from '../utils/PostHistorials'
import loadTranslations from '../utils/loadTranslation'
import Select from 'react-select'
import { CurrencyContext } from '../context/CurrencyContext'
import Loading from '../components/Loading'

const VentaNuevo = () => {
  const { currency } = useContext(CurrencyContext)
  console.log('vdesd', currency)
  const history = useHistory()
  const { user, setUser } = useContext(UserContext) //es  el ID del cliente
  const [variation, seVariation] = useState({})
  const [listRegions, setListRegions] = useState(regions)

  const [productosVendidos, setProductosVendidos] = useState([])
  const [productos, setProductos] = useState([])
  const [filterProducto, setFilterProducto] = useState([])
  const [query, setQuery] = useState()
  const [isModalView, setIsModalView] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [producto, setProducto] = useState({})
  const [cantidad, setCantidad] = useState()
  const [prueba, setprueba] = useState({})
  const valuesDefault = {}
  const [saleID, setSaleID] = useState('')
  const [isActiveButton, setIsActiveButton] = useState(false)

  const [store, setStore] = useState()
  const [dataExtras, setDataExtras] = useState([])
  const [extrasInput, setExtrasInput] = useState()
  const [totalExtra, setTotalExtra] = useState(0)
  const [loading, setloading] = useState(false)
  const [error, setError] = useState(false)
  const [oneOptions, setOneOptions] = useState([])

  //*new metodo entrega
  let isHomeDelivery = JSON.parse(localStorage.getItem('user'))?.ishomedelivery
  let ispickupstore = JSON.parse(localStorage.getItem('user'))?.ispickupstore
  let isPricePerZone = JSON.parse(localStorage.getItem('user'))?.iszoneprice
  let isOnlyPrices = JSON.parse(localStorage.getItem('user'))?.isonlyprice
  let clientID = JSON.parse(localStorage.getItem('user')).id
  let userPais = JSON.parse(localStorage.getItem('user')).paises
  console.log('dde', userPais)
  const [selectedItem, setSelectedItem] = useState({
    name: '',
    direccion: '',
    lugar: '',
  })
  const [mexTowOptions, setMexTowOptions] = useState([])
  const [mexOneDivision, setMexOneDivision] = useState([])
  const [oneDivisionUnique, setOneDivisionUnique] = useState('')
  const [mexTwoDivision, setMexTwoDivision] = useState([])
  const [twoDivisionUnique, setTwoDivisionUnique] = useState('')
  const [metodoEntrega1, setMetodoEntrega1] = useState(
    isHomeDelivery && ispickupstore
      ? metodoEntrega[0]
      : isHomeDelivery
        ? metodoEntregaDelivery[0]
        : metodoRecojo[0]
  )
  const [deliveryCondition, setDeliveryCondition] = useState('')
  const [priceDiscountDelivery, setPriceDiscountDelivery] = useState(0)
  const [isDiscountDelivery, setIsDiscountDelivery] = useState()
  const [onlyPricesDel, setOnlyPricesDel] = useState([])
  const [thirdDivi, setThirdDivi] = useState([])

  let storageUser = JSON.parse(localStorage.getItem('user'))
  console.log('Des', storageUser)
  const paisL = storageUser.paises.name
  console.log('tesr paois', paisL)
  const translations = loadTranslations(paisL)

  const [infodata, setInfodata] = useState({
    name: '',
    surname: '',
    email: '',
    mobile: '',
    addres: '',
    city: '',
    district: '',
    date: '',
    aditionalInfo: '',
    goToStore: false,
  })
  const [priceDelivery, setPriceDelivery] = useState(0)
  const [infoMessage, setInfoMessage] = useState('')
  let pais = JSON.parse(localStorage.getItem('user')).paises
  let country = pais.id
  console.log(country)
  const [formatContact, setFormatContact] = useState({})

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('user'))

    let config = {
      method: 'get',
      url: `https://ventafazil-strapi-production.up.railway.app/clientes/${user._id}`,
    }
    if (user) {
      axios(config)
        .then((response) => {
          setStore(response.data)
          //console.log('MY RESSS', response.data)
        })
        .catch((error) => {
          //console.log(error)
        })
    }
  }, [])

  const consultarApi = async () => {
    try {
      const { data } = await axios.get(
        `https://ventafazil-strapi-production.up.railway.app/productos?cliente=${user}&_limit=-1`
      )
      if (data) {
        setProductos(data)
      } else {
        return null
      }
    } catch (error) {
      //console.log(error)
    }
  }
  const onCancelar = () => {
    setOpenModal(false)
  }
  let changeExtras = (i) => {
    const updatedCheckedState = extrasInput.map((item, index) =>
      index === i ? !item : item
    )
    setExtrasInput(updatedCheckedState)
    const totalPrice = updatedCheckedState.reduce(
      (sum, currentState, index) => {
        if (currentState === true) {
          return sum + Number(producto.extras[index].price)
        }
        return sum
      },
      0
    )
    let selectExtras = []
    updatedCheckedState.map((el, i) => {
      if (el === true) {
        selectExtras.push(producto.extras[i])
      }
    })
    setDataExtras(selectExtras)
    setTotalExtra(totalPrice)
  }

  useEffect(() => {
    consultarApi()
  }, [])

  useEffect(() => {
    producto.variations &&
      producto.variations?.forEach((p, i) => {
        valuesDefault[p.name] = p.values[0]
      })

    seVariation(valuesDefault)
    setExtrasInput(
      new Array(producto.extras ? producto.extras.length : []).fill(false)
    )
  }, [openModal])

  const handleChange = (e) => {
    setQuery(e.target.value)

    if (e.key !== 'Enter') {
      return
    }
    filterdedProductos()
  }

  const filterdedProductos = () => {
    if (!query) {
      setIsModalView(false)
      return
    }

    const dataPro = productos.filter((elemento) => {
      if (
        elemento.nombreproducto
          .toString()
          .toLowerCase()
          .includes(query.toLowerCase())
      ) {
        return elemento
      }
    })
    setFilterProducto(dataPro)
    setIsModalView(true)
  }

  const onClick = (p) => {
    setIsModalView(false)
    setQuery('')
    if (p.variations.length >= 1) {
      setOpenModal(true)
      // OpenModalVariants(p)
      setProducto(p)
      return
    }

    const tempItem = productosVendidos.find((i) => i.id === p._id)
    if (tempItem) return
    let newProduct = {
      id: p._id,
      id1: p._id,
      id2: p._id,
      nombreproducto: p.nombreproducto,
      imagenproducto: p.imagenproducto,
      precio: p.precio,
      amount: 1,
      max: p.stock,
    }

    setProductosVendidos([...productosVendidos, newProduct])
  }



  const handleCantidad = (event, i) => {
    let name = event.target.name
    let value = event.target.value
    // //console.log(combinaciones1)
    // //console.log(renderDefault)
    const newVariations = [...productosVendidos]
    newVariations[i][name] = value

    // //console.log('combinaciones1 UPD', combinaciones1)
    // onChangeVariations(newVariations)
    setProductosVendidos(newVariations)

    // hace ALGO=?==
    // setProductosVendidos([...productosVendidos, newVariations])
  }

  const updateVariations = (e) => {
    let name = e.target.name

    seVariation({ ...variation, [name]: e.target.textContent })
  }

  const signalPrice = () => {
    let result
    //console.log('PRUEBADDS')
    producto.productvariations &&
      producto.productvariations.map((pVa) => {
        if (JSON.stringify(pVa.options) === JSON.stringify(variation)) {
          //console.log('entre', pVa.precio)
          result = pVa.precio
        }
      })

    return result
  }

  const signaStock = () => {
    let result
    //console.log('PRUEBADDS')
    producto.productvariations &&
      producto.productvariations.map((pVa) => {
        if (JSON.stringify(pVa.options) === JSON.stringify(variation)) {
          //console.log('entre', pVa.precio)
          result = pVa.stock
        }
      })

    return result
  }

  const registrarProductoConVariaciones = () => {
    let extrasContent = ''
    dataExtras?.map((ex) => {
      extrasContent += ex.name
    })
    const idProduct =
      producto._id + Object.values(variation).join('') + extrasContent
    const idProduct1 = producto._id + Object.values(variation).join('')

    const tempItem = productosVendidos.find((i) => i.id1 === idProduct1)
    //console.log('tempItem', tempItem)
    if (tempItem) {
      setOpenModal(false)
      return
    }
    let newProduct = {
      id: producto.id,
      id1: idProduct,
      id2: idProduct1,
      nombreproducto: producto.nombreproducto,
      imagenproducto: producto.imagenproducto,
      precio: signalPrice(),
      variation: variation,
      extras: dataExtras,
      amount: 1,
      max: signaStock(),
    }
    setProductosVendidos([...productosVendidos, newProduct])
    setOpenModal(false)
  }

  function onChangeForm(event) {
    let newdatainfo = Object.assign({}, infodata)
    newdatainfo[event.target.name] = event.target.value
    if (
      newdatainfo[event.target.name] === '' ||
      newdatainfo[event.target.name] === 'null'
    ) {
      setIsActiveButton(false)
    }
    setInfodata(newdatainfo)
  }

  // function changeRegion(event) {
  //   let newData = Object.assign({}, infodata)
  //   newData.city = event.target.value
  //   let provincesForDelivery = store.deliveryprovincia
  //   let priceselect = []
  //   provincesForDelivery.map((el) => {
  //     if (el.value === event.target.value) {
  //       priceselect.push(el)
  //     }
  //   })
  //   if (priceselect.length > 0) {
  //     setPriceDelivery(priceselect[0])
  //     setInfoMessage('El precio de envio es: S/ ' + priceselect[0].price)
  //     //console.log('fff')
  //     setIsActiveButton(true)
  //   } else {
  //     if (newData.city === store.ciudad) {
  //       let price = Number(store.deliveryprecio).toFixed(2)
  //       setPriceDelivery({
  //         label: event.target.value,
  //         price: price,
  //         value: event.target.value,
  //       })
  //       setInfoMessage('')
  //     } else {
  //       setPriceDelivery(null)
  //       setInfoMessage('No se hacen envios a esta provincia')
  //     }
  //     setIsActiveButton(false)
  //   }
  //   setInfodata(newData)
  // }
  function changeType(event) {
    let newdatainfo = Object.assign({}, infodata)
    newdatainfo.goToStore = event.target.checked
    if (event.target.checked) {
      setPriceDelivery(null)
      // setDataDelivery({ ...dataDelivery, price: 0 })
    }
    setInfodata(newdatainfo)
  }

  let sumTotal = (arr) => {
    // return Number(
    //   arr
    //     .map((x) => x.precio * x.amount)
    //     .reduce((a, b) => Number(a) + Number(b), 0)
    // )
    //console.log('MI PRRR', arr)
    const maprr = arr.map((x) => {
      const amount = x.amount || 1
      return x.precio * amount
    })
    return Number(maprr.reduce((a, b) => Number(a) + Number(b), 0))
  }

  useEffect(() => {
    // sumTotal(productosVendidos)
  }, [productosVendidos])

  const total = () => {
    // if (priceDelivery)
    if (dataDelivery?.price)
      return parseInt(dataDelivery.price) + sumTotal(productosVendidos)

    return sumTotal(productosVendidos)
  }
  //console.log('PROBANDO', total() * 100)

  const crearPedido = async () => {
    const data = await verifyStock(productosVendidos)
    //console.log('data validacion stck', data)
    if (data.length >= 1) {
      Swal.fire({
        icon: 'error',
        title: 'Hubo un problema...',
        text: `Ya no tenemos el mismo stock que tu carrito  en estos producto ${data.join(
          ', '
        )}
        por favor elimina esos productos
        
        `,
      })
    } else {
      //console.log('ENRRANDO')
      let products = productosVendidos.map((product) => {
        return { _id: product.id }
      })

      let delivery
      if (metodoEntrega1.value) {
        // let newValue = oneDivisionUnique.label + ', ' + twoDivisionUnique.label
        let newValue = dataCountry.oneDivision + ', ' + dataCountry.twoDivision + ' ' + dataCountry.threeDivision

        delivery = {
          label: newValue,
          // value: ` ${oneDivisionUnique.label}, ${twoDivisionUnique.label} `,
          value: ` ${dataCountry.oneDivision}, ${dataCountry.twoDivision + ' ' + dataCountry.threeDivision} `,
          // price: priceDelivery,
          price: dataDelivery.price,
        }
      }

      let detailProducts = Object.assign(
        infodata,
        {
          metodoEntrega: metodoEntrega1.value ? 'Delivery' : 'Punto de recojo',
        },
        { puntoRecojo: selectedItem },
        metodoEntrega1.value && { delivery: delivery },

        { detailProducts: productosVendidos }
      )

      //console.log(detailProducts)
      //console.log('PROBANDO', total() * 100)
      //console.log(detailProducts)
      let user = JSON.parse(localStorage.getItem('user'))

      PostVentasAPI(
        store.id,
        products,
        total() * 100,
        detailProducts,
        user.correoelectronico
      ) //NOMBRE COMPRADOR DEbe de venir del formulario
        .then((response) => {
          // //console.log('ID PEDIDO', datas)

          discountStock(productosVendidos)

          Swal.fire('Se creo un pedido con exito ', '', 'success')
          history.push('/ventas')
        })
        .catch((err) => console.log('AQUI VA', err))
    }
  }

  let deleteProduct = (e, i) => {
    let copyProducts = productosVendidos.filter((pr) => pr.id1 !== i)
    // copyProducts.splice(e, i)
    // localStorage.setItem('session', JSON.stringify(copyProducts))
    setProductosVendidos(copyProducts)
  }
  const consultarOneDivision = async () => {
    setloading(true)
    try {
      let url = `https://ventafazil-strapi-production.up.railway.app/Onedivisions?country=${country}&_limit=-1`

      const { data } = await axios.get(url)

      console.log('esta data', data)

      const mapOptions = data.map((option) => ({
        value: option.id,
        label: option.name,
      }))
      setOneOptions(mapOptions)

      setloading(false)
    } catch (error) {
      console.log(error)
      setError(true)
    }
  }
  const [secondOptions, setSecondOptions] = useState([])
  const [oneDivision, setOneDivision] = useState('')

  const consultarTwoDivisions = async () => {
    let url

    url = `https://ventafazil-strapi-production.up.railway.app/Twodivisions?onedivision=${oneDivision.value}&_limit=-1`

    const { data } = await axios.get(url)

    const mapOptions = data.map((option) => ({
      value: option.id,
      label: option.name,
    }))
    setSecondOptions(mapOptions)
  }
  const [twoDivision, setTwoDivision] = useState('')

  useEffect(() => {
    consultarOneDivision()
  }, [])

  useEffect(() => {
    if (oneDivision && oneOptions.length > 0) {
      consultarTwoDivisions()
    }
  }, [oneDivision, oneOptions])

  const handleSelectChange1 = (e) => {
    console.log('valor MUNICIPIO 1', e)

    setTwoDivisionUnique(e)
    console.log('ONLUVVV', onlyPricesDel)
    if (isOnlyPrices) {
      console.log('TEST ds', onlyPricesDel[0].price)
      setPriceDelivery(onlyPricesDel[0].price)
      setPriceDiscountDelivery(onlyPricesDel[0].discountprice)
      setIsDiscountDelivery(onlyPricesDel[0].isdiscount)
      setDeliveryCondition(onlyPricesDel[0].deliverycondition)
    }
    if (isPricePerZone) {
      setPriceDelivery(e.price)

      setDeliveryCondition(e.deliverycondition)
    }

    // setSelectEstadoVenta(e.value)
  }
  function handleSelectChange2(selectedOption) {
    // setThirdDivision('')
    console.log('se', selectedOption)
    setTwoDivision(selectedOption)
  }

  const validateSelect = () => {
    if (isHomeDelivery && ispickupstore) {
      // setMetodoEntrega1(metodoEntrega[0])
      return metodoEntrega
    } else if (isHomeDelivery) {
      // setMetodoEntrega1(metodoEntregaDelivery[0])

      return metodoEntregaDelivery
    } else {
      // setMetodoEntrega1(metodoRecojo[0])

      return metodoRecojo
    }
  }
  const handleSelectMetodo = (selectedOption) => {
    if (selectedOption === metodoEntrega1) {
      return // no hacer nada si el elemento seleccionado es el mismo que el anterior
    }
    // setactiveFormButton(false)

    setSelectedItem({})
    const newObject = { ...infodata }
    delete newObject.addres
    delete newObject.postalCode
    setInfodata(newObject)
    setMetodoEntrega1(selectedOption)
    // setPriceDelivery(0)
    setDataDelivery({ ...dataDelivery, price: 0 })
    if (!selectedOption.value) {
      setOneDivisionUnique('')

      setTwoDivisionUnique('')

      setSelectOption({
        ...selectOption,
        twoDivision: [],
        threeDivision: []
      })
      setDataCountry({
        oneDivision: '',
        twoDivision: '',
        threeDivision: ''
      })
    }
  }

  const handleSelectChange = (e) => {
    console.log('fds', e)

    setOneDivisionUnique(e)
    setTwoDivisionUnique('')
    setDeliveryCondition('')
    if (isOnlyPrices) {
      console.log('TEST ds', onlyPricesDel[0].price)
      setPriceDelivery(0)
      setPriceDiscountDelivery('')
      setIsDiscountDelivery('')
    }
    if (isPricePerZone) {
      setPriceDelivery(0)
    }
  }

  const [tiendaRecojo, setTiendaRecojo] = useState([])
  const consultarData = async () => {
    const url = `https://ventafazil-strapi-production.up.railway.app/Pickpoints/?cliente=${clientID}&country=${userPais.id}&isactive=true`
    console.log('RREE', url)
    try {
      const response = await axios.get(url)
      console.log('DDSAS', response.data)
      setTiendaRecojo(response.data)
      setloading(false)
    } catch (error) {
      setloading(false)
    }
  }
  
  const handleCheckboxChange = (item) => {
    setSelectedItem({
      name: item.name,
      direccion: item.adress,
      place: item.place,
    })
  }

  const consultarOnlyPrices = async () => {
    let url

    try {
      if (isOnlyPrices) {
        url = `https://ventafazil-strapi-production.up.railway.app/Onlyprices?cliente=${clientID}&country=${userPais.id}`
        const response = await axios.get(url)

        const data = response.data
        if (userPais.numberofdivision === 2) getOneDivision(data)
        else if (userPais.numberofdivision === 3) getTwoDivision(data)
        setDataPriceOnly(data)

        let idThreeDivision = []
        setOnlyPricesDel(response.data)

        console.log('MI DATA', response.data)
        const onPrices = response.data[0].delivery[1][0]
        console.log('onlll', onPrices)
        console.log('TEST ISONLY', isOnlyPrices)

        onPrices.forEach((element) => {
          idThreeDivision.push(element.split('_ID').pop())
        })
        console.log('FFFE', idThreeDivision)
        setThirdDivi(thirdDivi)
        // consultarTwoDivision(idThreeDivision)
        consultarTwoDivisionMX(idThreeDivision)
      } else {
        url = `https://ventafazil-strapi-production.up.railway.app/Priceperzones?cliente=${clientID}&country=${userPais.id}`
        const response = await axios.get(url)

        const data = response.data
        if (userPais.numberofdivision === 2) getOneDivision(data)
        else if (userPais.numberofdivision === 3) getTwoDivision(data)
        setDataPriceZone(data)

        const pricePerzones = response.data.map((data) => {
          let idCiudades = []

          data.deliverylist[0].forEach((element) => {
            idCiudades.push(element.split('_ID').pop())
          })

          console.log('fddss', idCiudades)

          return {
            discountprice: data.discountprice,
            price: data.price,
            isdiscount: data.isdiscount,
            deliverycondition: data.deliverycondition,
            deliverylist: idCiudades,
            ratename: data.ratename,
          }
        })

        console.log('NEW DATA', pricePerzones)
        // console.log('CIUDAD', idCiudades)
        consultarTwoDivisionMX(pricePerzones)
      }
    } catch (error) {
      setloading(false)
    }
    setLoading2(false)
  }

  let mapOptions
  const arrayOne = []

  const consultarTwoDivisionMX = async (idThreeDivision) => {
    const url = `https://ventafazil-strapi-production.up.railway.app/oneDivisions?&_limit=-1&country=${userPais.id}`
    const response = await axios.get(url)

    if (isOnlyPrices) {
      try {
        const _oneDiv = response?.data

        const oneOptions = response.data.map((option) => ({
          id: option.id,
          value: option.name,
          label: option.name,
        }))
        _oneDiv.forEach((elemento) => {
          const dateD = elemento.twodivisions.filter((ef) => {
            if (idThreeDivision.includes(ef._id)) {
              arrayOne.push(ef)
            }
          })
          mapOptions = arrayOne.map((option) => ({
            id: option.id,
            value: option.name,
            label: option.name,
            oneDivision: option.onedivision,
          }))
          setMexTowOptions(mapOptions)
        })
        const oneDivision = [
          ...new Set(arrayOne.map((item) => item.onedivision)),
        ]
        console.log('FXS', oneDivision)
        console.log('PRIVVV', mapOptions)

        const filteredOneDivision = oneOptions.filter((objeto) => {
          return oneDivision.includes(objeto.id)
        })
        console.log('PRVV', filteredOneDivision)
        setMexOneDivision(filteredOneDivision)
      } catch (error) { }
    } else {
      console.log('DESDE EL ELSE', JSON.stringify(idThreeDivision))

      const oneDiviv = response.data.map((data) => {
        return {
          id: data.id,
          name: data.name,
          twodivisions: data.twodivisions,
        }
      })

      let result = []

      console.log('municipios', idThreeDivision)

      console.log('estados', oneDiviv)

      for (let i = 0; i < idThreeDivision.length; i++) {
        let deliverylist = idThreeDivision[i].deliverylist
        let estado = ''
        let municipio = ''
        console.log('Fddx', deliverylist)
        for (let j = 0; j < oneDiviv.length; j++) {
          let twodivisions = oneDiviv[j].twodivisions

          for (let k = 0; k < twodivisions.length; k++) {
            if (deliverylist.includes(twodivisions[k].id)) {
              municipio = twodivisions[k].name
              estado = oneDiviv[j].name
              result.push({
                price: idThreeDivision[i].price,
                estado: estado,
                label: municipio,
                value: municipio,
                deliverycondition: idThreeDivision[i].deliverycondition,
              })
            }
          }
        }
      }
      const estados = [...new Set(result.map((item) => item.estado))]
      const estadosTransformados = estados.map((estado) => {
        return {
          label: estado,
          value: estado,
        }
      })
      setMexTowOptions(result)

      setMexOneDivision(estadosTransformados)
      console.log('5445', estadosTransformados)
      console.log('PROBB', result)
    }
  }
  useEffect(() => {
    consultarData()
  }, [])

  useEffect(() => {
    consultarOnlyPrices()
  }, [])

  useEffect(() => {
    if (oneDivisionUnique) {
      console.log('entr')
      console.log('entr', isOnlyPrices)

      if (isOnlyPrices) {
        console.log('llegamos', oneDivisionUnique)
        console.log('llegamos aquii')
        console.log('llegamos', mexTowOptions)
        const _twoDv = mexTowOptions.filter((label) => {
          return label.oneDivision == oneDivisionUnique.id
        })

        setMexTwoDivision(_twoDv)
      } else {
        console.log('llegamos', oneDivisionUnique)
        console.log('llegamos1', mexTowOptions)
        const _twoDv = mexTowOptions.filter((label) => {
          return label.estado == oneDivisionUnique.label
        })

        console.log('entr 111', _twoDv)
        setMexTwoDivision(_twoDv)
      }
    }
  }, [oneDivisionUnique])
  console.log('price', priceDelivery)
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const [dataPriceOnly, setDataPriceOnly] = useState()
  const [dataPriceZone, setDataPriceZone] = useState([])
  const [listOptionOneDivision, setListOptionOneDivision] = useState([])
  const [listOptionTwoDivision, setListOptionTwoDivision] = useState([])
  const [listOptionThreeDivision, setListOptionThreeDivision] = useState([])
  const [selectOption, setSelectOption] = useState({
    oneDivision: [],
    twoDivision: [],
    threeDivision: []
  })
  const [dataDelivery, setDataDelivery] = useState({
    price: 0,
    priceDiscount: 0,
    isDiscount: false,
    condition: ''
  })
  const [dataCountry, setDataCountry] = useState({
    oneDivision: '',
    twoDivision: '',
    threeDivision: ''
  })
  const [loading2, setLoading2] = useState(true)

  const getTwoDivision = async (data) => {
    let _idThreeDivision = []
    let idThreeDivision = []
    if (isOnlyPrices) {
      _idThreeDivision = data[0].delivery[1][0]
      idThreeDivision = _idThreeDivision.map((el) => el.split('_ID').pop())
    } else if (isPricePerZone) {
      let concatIdThreeDivision = []
      _idThreeDivision = data
      _idThreeDivision.forEach(el => {
        if (concatIdThreeDivision.length == 0) concatIdThreeDivision = el.deliverylist[0]
        else concatIdThreeDivision = concatIdThreeDivision.concat(el.deliverylist[0])
      })
      idThreeDivision = concatIdThreeDivision.map(el => el.split('_ID').pop())
      // console.log("TWODIVISION concatIdThreeDivision", concatIdThreeDivision)
      // console.log("TWODIVISION idThreeDivision", idThreeDivision)
      // console.log("TWODIVISION data", data)
    }

    const url = `https://ventafazil-strapi-production.up.railway.app/Twodivisions?&_limit=-1`
    try {
      const response = await axios.get(url)
      const data = response.data
      // console.log("TWODIVISION DATA", data)
      if (data) {
        let newTwoDivisions = []
        let newThreeDivisions = []
        let arrayIdTwoDivision = []
        data.forEach((two) => {
          two.threedivisions.forEach((three) => {
            if (idThreeDivision.includes(three.id)) {
              if (!arrayIdTwoDivision.includes(two.id)) {
                arrayIdTwoDivision.push(two.id)
                newTwoDivisions.push({
                  id: two.id,
                  idOneDivision: two.onedivision.id,
                  value: two.name?.trim(),
                  label: two.name?.trim()
                })
              }
              newThreeDivisions.push({
                id: three.id,
                idTwoDivision: three.twodivision,
                value: three.name?.trim(),
                label: three.name?.trim()
              })
            }
          })
        })
        // console.log("TWODIVISION FINAL", newTwoDivisions)//SET
        // console.log("TWODIVISION(three_IS PERU) FINAL", newThreeDivisions)//SET
        setListOptionTwoDivision(newTwoDivisions)
        setListOptionThreeDivision(newThreeDivisions)
        getOneDivision(null, arrayIdTwoDivision)
      }
    } catch (error) {

    }
  }

  const getOneDivision = async (data, arrayIdTwoDivision) => {
    let idTwoDivision = []
    if (userPais.numberofdivision === 2) {
      if (isOnlyPrices) {
        let _idTwoDivision = data[0]?.delivery[1][0]
        idTwoDivision = _idTwoDivision.map((el) =>
          el.split('_ID').pop()
        )
      } else if (isPricePerZone) {
        let concatIdTwoDivision = []
        let _idTwoDivision = []
        _idTwoDivision = data
        _idTwoDivision.forEach(el => {
          if (concatIdTwoDivision.length == 0) concatIdTwoDivision = el.deliverylist[0]
          else concatIdTwoDivision = concatIdTwoDivision.concat(el.deliverylist[0])
        })
        idTwoDivision = concatIdTwoDivision.map(el => el.split('_ID').pop())
        console.log("ONEDIVISION concatIdTwoDivision", concatIdTwoDivision)
        console.log("ONEDIVISION idTwoDivision", idTwoDivision)
      }
    }
    // console.log("ONEDIVISION idTwoDivision", idTwoDivision)
    const url = `https://ventafazil-strapi-production.up.railway.app/oneDivisions?&_limit=-1&country=${userPais.id}`
    try {
      const response = await axios.get(url)
      const data = response.data
      // console.log("ONEDIVISION DATA", data)
      if (data.length > 0) {
        let newOneDivisions = []
        if (userPais.numberofdivision === 2) {
          let newTwoDivisions = []
          let arrayIdOneDivision = []
          data.forEach((one) => {
            one.twodivisions.forEach(two => {
              if (idTwoDivision.includes(two.id)) {
                if (!arrayIdOneDivision.includes(one.id)) {
                  arrayIdOneDivision.push(one.id)
                  newOneDivisions.push({
                    id: one.id,
                    value: one.name?.trim(),
                    label: one.name?.trim()
                  })
                }
                if (userPais.numberofdivision === 2) {
                  newTwoDivisions.push({
                    id: two.id,
                    idOneDivision: two.onedivision,
                    value: two.name?.trim(),
                    label: two.name?.trim()
                  })
                }
              }
            })
          })
          // console.log("TWODIVISION(one)",newOneDivisions)
          // console.log("TWODIVISION(two)",newTwoDivisions)
          setListOptionOneDivision(newOneDivisions)
          setListOptionTwoDivision(newTwoDivisions)
          setSelectOption({ oneDivision: newOneDivisions, })
        } else if (userPais.numberofdivision === 3) {
          let arrayIdOneDivision = []
          data.forEach((one) => {
            one.twodivisions.forEach((two) => {
              if (arrayIdTwoDivision.includes(two.id)) {
                if (!arrayIdOneDivision.includes(one.id)) {
                  arrayIdOneDivision.push(one.id)
                  newOneDivisions.push({
                    id: one.id,
                    value: one.name?.trim(),
                    label: one.name?.trim()
                  })
                }
              }
            })
          })
        }
        // console.log("ONEDIVISION FINAL", newOneDivisions)//SET
        // console.log("ONEDIVISION(two_IS MEXICO) FINAL", newTwoDivisions)//SET
        setListOptionOneDivision(newOneDivisions)
        setSelectOption({ oneDivision: newOneDivisions })
      }
    } catch (error) {

    }
    setLoading2(false)
  }

  const changeOneDivision = (e) => {
    let filterTwoDivision = listOptionTwoDivision.filter(two => two.idOneDivision === e.id)
    setSelectOption({ ...selectOption, twoDivision: filterTwoDivision })
    setDataCountry({ oneDivision: e.value, twoDivision: '', threeDivision: '' })
    // console.log("changeDivision FilterOne", filterTwoDivision = listOptionTwoDivision.filter(two => two.idOneDivision === e.id))
  }
  const changeTwoDivision = (e) => {
    let filterThreeDivision = listOptionThreeDivision.filter(three => three.idTwoDivision === e.id)
    setSelectOption({ ...selectOption, threeDivision: filterThreeDivision })
    setDataCountry({ ...dataCountry, twoDivision: e.value, threeDivision: '' })
    // console.log("changeDivision FilterTwo", filterThreeDivision = listOptionThreeDivision.filter(three => three.idTwoDivision === e.id))
    if (userPais.numberofdivision === 2) fillDelivery(e)
  }
  const changeThreeDivision = (e) => {
    // console.log("changeDivision Three", e)
    setDataCountry({ ...dataCountry, threeDivision: e.value })
    fillDelivery(e)
  }

  const fillDelivery = (e) => {
    if (isOnlyPrices) {
      setDataDelivery({
        price: dataPriceOnly[0].price,
        priceDiscount: dataPriceOnly[0].discountprice,
        isDiscount: dataPriceOnly[0].isdiscount,
        condition: dataPriceOnly[0].deliverycondition
      })
    } else if (isPricePerZone) {
      let _dataPriceZone = [...dataPriceZone]
      const _priceZone = _dataPriceZone.filter((el, index) => {
        let _deliveryList = el.deliverylist[0].map(el => el.split('_ID').pop())
        console.log("fillDelivery _deliveryList", _deliveryList)
        if (_deliveryList.includes(e.id)) return el
      })
      // console.log("fillDelivery e.id", e.id)
      // console.log("fillDelivery _priceZone", _priceZone)
      setDataDelivery({
        price: _priceZone[0].price,
        priceDiscount: _priceZone[0].discountprice,
        isDiscount: _priceZone[0].isdiscount,
        condition: _priceZone[0].deliverycondition
      })
    }
  }
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (metodoEntrega1.value) {
      if (
        infodata.name !== '' &&
        infodata.surname !== '' &&
        infodata.email !== '' &&
        infodata.mobile !== '' &&
        infodata.addres !== '' &&
        productosVendidos.length >= 1 &&
        dataCountry.oneDivision &&
        dataCountry.twoDivision &&
        dataDelivery.price &&
        (userPais.numberofdivision === 3 ? dataCountry.threeDivision : true)
      ) {
        setIsActiveButton(true)
      } else setIsActiveButton(false)
    } else {
      if (infodata.name !== '' &&
        infodata.surname !== '' &&
        infodata.email !== '' &&
        infodata.mobile !== '' &&
        infodata.addres !== '' &&
        productosVendidos.length >= 1 &&
        selectedItem.name) setIsActiveButton(true)
      else {
        setIsActiveButton(false)
      }
    }
  }, [infodata, productosVendidos, dataCountry.oneDivision, dataCountry.twoDivision, dataCountry.threeDivision, selectedItem])

  return (
    <div className='w-fit lg:w-full pb-24'>
      <NavigationTop />
      <NavigationSide />
      <div className='p-6 bg-white mb-44 md:ml-40'>
        {loading2 && <Loading />}
        <div className='flex justify-between'>
          <h1>Crear Venta</h1>

          {isActiveButton ? (
            <button
              className='p-2 px-10 text-sm font-bold text-center bg-yellow-400 rounded-md '
              onClick={crearPedido}
            >
              Crear Pedido
            </button>
          ) : (
            <div className='self-end py-2 mx-4 text-white rounded-md cursor-pointer text-semibold bg-amber-200 px-14 '>
              Crear Pedido
            </div>
          )}
        </div>

        <div className='flex'>
          <div className='basis-3/6 flex justify-items-center items-center border border-black  rounded-lg px-2 relative'>
            <FontAwesomeIcon
              className='text-slate-700 '
              icon={faMagnifyingGlass}
            />
            <input
              type='text'
              placeholder='Busca un producto '
              className='w-full  bg-transparent outline-none p-2'
              onChange={handleChange}
              onKeyDown={handleChange}
              value={query}
            />
            {openModal && (
              <Modal
                onClick={registrarProductoConVariaciones}
                onCancelar={onCancelar}
              >
                <h1>Seleccionar variante de producto</h1>
                <div className='flex gap-5'>
                  <img
                    className='w-40 h-40'
                    src={producto.imagenproducto}
                    alt=''
                  />

                  <div>
                    <p>{producto.nombreproducto}</p>
                    S/{signalPrice()}
                    <div>
                      {producto.variations &&
                        producto.variations.map((vari, i) => {
                          return (
                            <div key={i}>
                              <p>{vari?.name}</p>
                              <div className='flex gap-3'>
                                {vari.values.map((p, i) => {
                                  return (
                                    <button
                                      className={`${variation[vari.name] === p
                                        ? 'cursor-pointer rounded-sm p-3 bg-yellow-400 '
                                        : 'cursor-pointer rounded-sm p-3 bg-slate-200'
                                        }`}
                                      key={i}
                                      onClick={(e) => {
                                        updateVariations(e, vari)
                                      }}
                                      name={vari?.name}
                                      type='button'
                                    >
                                      {p}
                                    </button>
                                  )
                                })}
                              </div>
                            </div>
                          )
                        })}
                    </div>
                  </div>
                </div>
              </Modal>
            )}

            {isModalView && (
              <div className=' bg-slate-50 absolute left-0 top-full mt-2 w-full z-2'>
                {filterProducto
                  .map((producto) => {
                    return (
                      <div key={producto._id} onClick={() => onClick(producto)}>
                        <div className='flex gap-2 p-2 border-2 '>
                          <img src={producto.imagenproducto} className='w-10' />
                          <h2> {producto.nombreproducto}</h2>
                        </div>
                      </div>
                    )
                  })
                  .slice(0, 7)}
              </div>
            )}
          </div>
          <div></div>
        </div>
        <div className='flex mt-3'>
          <div className='basis-3/6  '>
            <div className='flex'>
              <h2 className='basis-3/6 font-bold'>Detalle del pedido</h2>
              <h2 className='basis-2/6 font-bold'>Cantidad</h2>
              <h2 className='basis-2/6'></h2>
            </div>

            {productosVendidos.length >= 1 && (
              <>
                {productosVendidos.map((p, i) => {
                  return (
                    <div
                      key={i}
                      className='flex px-2   border-purple-200 mb-2 items-center '
                    >
                      <div className='basis-3/6 flex  gap-2'>
                        <img src={p.imagenproducto} className='w-10 h-10' />
                        {p.nombreproducto}

                        {p.variation && Object.values(p.variation).join('/')}
                      </div>
                      <h2 className='basis-2/6  '>
                        <input
                          type='number'
                          defaultValue={1}
                          className='w-24 text-center'
                          onChange={(e) => {
                            handleCantidad(e, i)
                          }}
                          min='1'
                          name='amount'
                        />
                      </h2>
                      <div className='basis-2/6 text-center relative '>
                        {currency} {p.precio}
                        <label
                          className='absolute right-5 text-red-500'
                          onClick={(e) => deleteProduct(e, p.id1)}
                        >
                          X
                        </label>
                      </div>
                    </div>
                  )
                })}

                <div className='flex px-2 mb-2'>
                  <h2 className='basis-3/6 font-bold'>SUBTOTAL</h2>
                  <div className='basis-2/6  '></div>
                  <h2 className='basis-2/6 text-center'>
                    {currency} {sumTotal(productosVendidos)}
                  </h2>
                </div>
                {/* {priceDelivery && priceDelivery !== null ? ( */}
                {dataDelivery && priceDelivery.price !== null ? (
                  <div className='flex px-2 mb-2'>
                    <h2 className='basis-3/6 font-bold'>Costo de envio:</h2>
                    <div className='basis-2/6  '></div>
                    <h2 className='basis-2/6 text-center'>
                      {/* S/ {priceDelivery ? priceDelivery : 0} */}
                      {currency} {dataDelivery ? dataDelivery.price : 0}
                    </h2>
                  </div>
                ) : (
                  ''
                )}
                <div className='mx-2 bg-slate-200 h-1'></div>
                <div className='flex px-2 pt-2'>
                  <h2 className='basis-3/6 font-bold'>Total</h2>
                  <div className='basis-2/6  '></div>
                  <h2 className='basis-2/6 text-center '>
                    {currency} {total()}
                  </h2>
                </div>
              </>
            )}
          </div>
          <div className=' basis-2/6'>
            <div className='border border-black pb-3  pt-3 rounded-md'>
              <h2 className='pl-3'>Añadir informacion del cliente</h2>
              <form>
                <div className='flex flex-col mx-10 gap-1'>
                  <label>Nombre</label>
                  <input
                    type='text'
                    name='name'
                    placeholder='Nombre'
                    value={infodata.name}
                    onChange={onChangeForm}
                    className='outline-none  border border-black rounded-md p-1'
                  />
                </div>
                <div className='flex flex-col mx-10 gap-1'>
                  <label>Apellido</label>
                  <input
                    type='text'
                    name='surname'
                    placeholder='Apellido'
                    className='outline-none  border border-black rounded-md p-1 '
                    value={infodata.surname}
                    onChange={onChangeForm}
                  />
                </div>
                <div className='flex flex-col mx-10 gap-1'>
                  <label>correo electronico</label>
                  <input
                    type='text'
                    className='outline-none  border border-black rounded-md p-1 '
                    value={infodata.email}
                    onChange={onChangeForm}
                    name='email'
                    placeholder='Correo Electronico'
                  />
                </div>
                <div className='flex flex-col mx-10 gap-1'>
                  <label>numero de telefono</label>
                  <input
                    type='text'
                    className='outline-none  border border-black rounded-md p-1 '
                    name='mobile'
                    placeholder='Teléfono'
                    value={infodata.mobile}
                    onChange={onChangeForm}
                  />
                </div>
                <div className='flex flex-col mx-10 gap-1'>
                  <label>Forma de entrega</label>
                  <Select
                    className='w-full py-2 focus:border-yellow-500 '
                    closeMenuOnSelect={true}
                    value={metodoEntrega1}
                    // options={metodoEntrega}
                    options={validateSelect()}
                    onChange={handleSelectMetodo}
                  />
                </div>
                <div className='flex flex-col mx-10 gap-1'>
                  MÉTODO DE ENTREGA
                  {metodoEntrega1?.value ? (
                    <div>
                      <p>Delivery</p>
                      {/* <input
                        type='text'
                        name='addres'
                        placeholder={textFinishBuy.shippingInformation.address}
                        className='w-full p-2 border border-gray-400 rounded-sm'
                        value={infodata.addres}
                        onChange={onChangeForm}
                      ></input> */}
                      <Select
                        className='w-full py-2 focus:border-yellow-500 '
                        // defaultInputValue='Seleccione'
                        options={selectOption.oneDivision}
                        value={{
                          value: dataCountry.oneDivision,
                          label: dataCountry.oneDivision
                        }}
                        onChange={changeOneDivision}
                      // options={mexOneDivision}
                      // value={oneDivisionUnique}
                      // onChange={handleSelectChange}
                      />
                      <Select
                        className='w-full py-2 focus:border-yellow-500 '
                        closeMenuOnSelect={true}
                        // defaultInputValue='Seleccione'
                        options={selectOption.twoDivision}
                        value={{
                          value: dataCountry.twoDivision,
                          label: dataCountry.twoDivision
                        }}
                        onChange={changeTwoDivision}
                      // options={mexTwoDivision}
                      // value={twoDivisionUnique}
                      // onChange={handleSelectChange1}
                      />
                      {
                        userPais.name === 'Peru' && (
                          <Select
                            className='w-full py-2 focus:border-yellow-500 '
                            closeMenuOnSelect={true}
                            // defaultInputValue='Seleccione'
                            options={selectOption.threeDivision}
                            value={{
                              value: dataCountry.threeDivision,
                              label: dataCountry.threeDivision
                            }}
                            onChange={changeThreeDivision}
                          // options={oneOptions}
                          // value={oneDivision}
                          // onChange={handleSelectChange1}
                          />
                        )
                      }
                      {/* <input
                        type='text'
                        name='postalCode'
                        placeholder={
                          textFinishBuy.shippingInformation.postalCode
                        }
                        className='w-full p-2 border border-gray-400 rounded-sm'
                        value={infodata.postalCode}
                        onChange={onChangeForm}
                      /> */}
                    </div>
                  ) : (
                    <div>
                      <p>Recojo en tienda</p>
                      <div>
                        {tiendaRecojo?.map((item) => {
                          return (
                            <div
                              key={item.id}
                              className='flex items-center gap-3 mt-2'
                            >
                              <input
                                type='radio'
                                id={item.name}
                                name='item'
                                value={item.name}
                                checked={selectedItem.name === item.name}
                                onChange={() => handleCheckboxChange(item)}
                                className='w-4 h-4 text-yellow-400 bg-gray-100 border-gray-300 focus:ring-yellow-500 dark:focus:ring-yellow-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
                              />
                              {/* 
                            <label
                              htmlFor={item.name}
                              className='ml-2 text-sm font-medium text-gray-900 dark:text-gray-300'
                            >
                              {item.name}
                              {item.adress}
                              {item.place}
                              {item.conditions}
                            </label> */}
                              <div className='text-xs'>
                                <div> {item.name}</div>
                                <div> {item.adress}</div>
                                <div> {item.place}</div>
                                <div className=' text-center whitespace-pre-line md:max-w-md lg:pr-28 md:text-left'>
                                  {item.conditions}
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  )}
                </div>

                <div className='flex flex-col mx-10 gap-1'>
                  <label>direccion</label>
                  <input
                    type='text'
                    className='outline-none  border border-black rounded-md p-1 '
                    name='addres'
                    placeholder='Dirección'
                    value={infodata.addres}
                    onChange={onChangeForm}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VentaNuevo
