import products1 from '../../assets/images/products1.png'
import products2 from '../../assets/images/products2.png'
import mysale1 from '../../assets/images/mysales1.png'
import mysale2 from '../../assets/images/mysales2.png'
import mysale3 from '../../assets/images/mysales3.png'
import mysale4 from '../../assets/images/mysales4.png'

/*
*Frequent questions sub index data
*/
const products = [
    {
        id: 1,
        title: '¿Cómo crear un producto?',
        paragraph: 'Debes de Ir al portal de Panel, seleccionar “Productos” y presionar el botón derecho superior - “Nuevo producto”.'
    },
    {
        id: 2,
        title: '¿Cómo crear una categoría?',
        paragraph: `Debes de Ir al portal de Panel, seleccionar “Productos”, presionar botón derecho superior - “Ver categorías”  y 
        tendrás una opción para agregar las categorías.`
    },
    {
        id: 3,
        title: '¿Cuáles son los tamaños y formatos recomendados de las imágenes?',
        paragraph: `Para productos : 500 x 500 px y el peso recomendado de las imágenes debe ser menor a 1MB./n
        Para banners : 1080 x 400 px./n
        Para foto de nosotros : 810 x 510 px./n
        Solo se acepta imágenes jpg y PNG.`
    },
    {
        id: 4,
        title: '¿Cómo añadir una variación?',
        paragraph: `Ir al panel > productos > nuevo producto > agregar una variación/n
        Al agregar un producto llena los datos generales, luego iras a la sección agregar una variación 
        usaremos un ejemplo  un polo con talla S,M,L y colores rojo y azul./n
        /n1. Agregaras en el nombre de cada variación opción 1 se llamara talla y opción 2 se llamara color, 
        al lado de cada variacion colocaras las variaciones respectivas seguidas de un enter./n
        <IMAGE>/n
        /n2. Automáticamente se creara una tabla con las variaciones, aquí puedes asignar un precio para cada variación, 
        el stock que desees u guardar./n
        <IMAGE>`,
        imageSrc: [products1, products2]
    },
    {
        id: 5,
        title: '¿Cómo agregar un producto destacado?',
        paragraph: `Ir al panel en la opción productos y ubicas el producto a destacar hacer click 
        en el icono del switch al lado derecho del producto, actualizas la página y se agregara como producto destacado, 
        también puedes acceder desde la creación de producto.`
    }
]

const delivery = [
    {
        id: 1,
        title: '¿Cómo configuro mis precios de delivery?',
        paragraph: `ve a al Panel  > Delivery/n

        El panel te brinda  tres opciones de Delivery./n
        
        /n1. Precio único : si activas esta opción se asignara un precio único general./n
        
        /n2. Precio por zona: aquí se selecciona el precio variable para diferentes zonas del Perú ademas que puedes tener múltiples precios por zona./n
        Recuerda: No puedes tener un precio único y precio por zona activados al mismo tiempo debes elegir uno de ellos./n

        /n3. Punto de recojo: Para tiendas que tengan su sucursal en físico.`
    }
]

const mySales = [
    {
        id: 1,
        title: '¿Cómo realizó Reembolsos?',
        paragraph: `Si hace click en el número de la venta, verá un botón de reembolsar si ocurre la ocasión 
        de hacer un reembolso parcial o total del productos, este cambio sera agregado al log de cambio 
        en el boton de “Ver Historial”/n
        /n<IMAGE>`,
        imageSrc: [mysale1]
    },
    {
        id: 2,
        title: 'Agregar un comentario a tu venta y ver historial',
        paragraph: `Todas las ventas puedes agregar un comentario como recordatorio de la venta como 
        fecha de envío o información adicional de envío./n
        <IMAGE>`,
        imageSrc: [mysale2]
    },
    {
        id: 3,
        title: '¿Cómo veo los estados de ventas?',
        paragraph: `Hay una opción para mi venta/n

        /nAceptado : cuando el pago ha pasado satisfactoriamente, puede revisar mas a fondo el 
        estado del pago en tu backoffice Izipay puede revisar la entrada  como revisar mi backopffice izipay`
    },
    {
        id: 4,
        title: '¿Cómo reviso mi backoffice Izipay?',
        paragraph: `1. Entrar al link https://secure.micuentaweb.pe/vads-merchant/ , entrar con el usuario y contraseña /n

        /n2. En la opción transacciones en curso, puedes revisar el estado del pago del día y 
        si deseas revisar otro día, entrar a la opción Capturas.
        
        /n3. <IMAGE>/n

        /n4. En la opción transferencia capturas puedes revisar el rango de fechas de pagos anteriores y el estado del pago/n

        /n5. Si quisieras realizar una búsqueda mas afondo con el numero de Orden puedes colocarlo 
        en la opción  de Ref. de pedido en el backoffice Izipay para que te lleve al pedido exacto./n
        <IMAGE>`,
        imageSrc: [mysale3, mysale4]
    }
]

const paymentMethods = [
    {
        id: 1,
        title: 'Artículo de pregunta frecuente',
        paragraph: 'Métodos de pago... Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut leo lorem, tempor sit amet auctor ut, mollis quis diam. Mauris placerat, nulla a vehicula sollicitudin.'
    },
    {
        id: 2,
        title: 'Artículo de pregunta frecuente 2',
        paragraph: 'Métodos de pago... Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut leo lorem, tempor sit amet auctor ut, mollis quis diam. Mauris placerat, nulla a vehicula sollicitudin.'
    },
    {
        id: 3,
        title: 'Artículo de pregunta frecuente 23',
        paragraph: 'Métodos de pago... Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut leo lorem, tempor sit amet auctor ut, mollis quis diam. Mauris placerat, nulla a vehicula sollicitudin.'
    },
    {
        id: 4,
        title: 'Artículo de pregunta frecuente 3',
        paragraph: 'Métodos de pago... Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut leo lorem, tempor sit amet auctor ut, mollis quis diam. Mauris placerat, nulla a vehicula sollicitudin.'
    },
    {
        id: 5,
        title: 'Artículo de pregunta frecuente 4',
        paragraph: 'Métodos de pago... Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut leo lorem, tempor sit amet auctor ut, mollis quis diam. Mauris placerat, nulla a vehicula sollicitudin.'
    }
]

const mySettings = [
    {
        id: 1,
        title: '¿Cómo agregar redes sociales?',
        paragraph: `Ir al panel > tienda > redes sociales/n

        En la sección redes sociales puedes agregar el link de tus redes y guardar./n
        
        Ir a tu página principal y aparecerán automáticamente los iconos en la parte inferior de la página.`
    },
    {
        id: 2,
        title: 'Cómo agregar mi botón flotante en whatsapp',
        paragraph: `Ir al panel > tienda/n

        En la sección Información general , agregas en el espacio que dice Whatssap, eso agregara mas el código de país y 
        automáticamente el botón flotante en tu página.`
    },
    {
        id: 3,
        title: '¿Cómo agregar mi pixel de facebook o google?',
        paragraph: `En el caso tengas el código listo para copiar y pegar ve la opción cuenta > Pixel/n 

        aquí podrás pegar el código y guardar para que se integre correctamente.`
    },
    {
        id: 4,
        title: '¿Cómo agregar un banner?',
        paragraph: `Ir al panel > perfil/n

        Al final se encontrara al sección banners , el tamaño recomendado es de 1742 x 980 px`
    }
]

/*
*Tutorials sub index data
*/
const newProduct = [
    {
        id: 1,
        title: 'Artículo de tutoriales',
        paragraph: 'Crear nuevo producto... Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut leo lorem, tempor sit amet auctor ut, mollis quis diam. Mauris placerat, nulla a vehicula sollicitudin.'
    },
    {
        id: 2,
        title: 'Artículo de tutoriales 2',
        paragraph: 'Crear nuevo producto... Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut leo lorem, tempor sit amet auctor ut, mollis quis diam. Mauris placerat, nulla a vehicula sollicitudin.'
    }
]

const newCategory = [
    {
        id: 1,
        title: 'Artículo de tutoriales',
        paragraph: 'Crear nueva categoría... Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut leo lorem, tempor sit amet auctor ut, mollis quis diam. Mauris placerat, nulla a vehicula sollicitudin.'
    },
    {
        id: 2,
        title: 'Artículo de tutoriales 2',
        paragraph: 'Crear nueva categoría... Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut leo lorem, tempor sit amet auctor ut, mollis quis diam. Mauris placerat, nulla a vehicula sollicitudin.'
    },
    {
        id: 3,
        title: 'Artículo de tutoriales 23',
        paragraph: 'Crear nueva categoría... Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut leo lorem, tempor sit amet auctor ut, mollis quis diam. Mauris placerat, nulla a vehicula sollicitudin.'
    }
]

const newOrder = [
    {
        id: 1,
        title: 'Artículo de tutoriales',
        paragraph: 'Crear nueva orden... Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut leo lorem, tempor sit amet auctor ut, mollis quis diam. Mauris placerat, nulla a vehicula sollicitudin.'
    },
    {
        id: 2,
        title: 'Artículo de tutoriales 2',
        paragraph: 'Crear nueva orden... Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut leo lorem, tempor sit amet auctor ut, mollis quis diam. Mauris placerat, nulla a vehicula sollicitudin.'
    },
    {
        id: 3,
        title: 'Artículo de tutoriales 23',
        paragraph: 'Crear nueva orden... Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut leo lorem, tempor sit amet auctor ut, mollis quis diam. Mauris placerat, nulla a vehicula sollicitudin.'
    }
]

/*
*Guies sub index data
*/
const reportBug = [
    {
        id: 1,
        title: 'Artículo de Guía',
        paragraph: 'Reportar un bug... Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut leo lorem, tempor sit amet auctor ut, mollis quis diam. Mauris placerat, nulla a vehicula sollicitudin.'
    },
    {
        id: 2,
        title: 'Artículo de Guía 2',
        paragraph: 'Reportar un bug... Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut leo lorem, tempor sit amet auctor ut, mollis quis diam. Mauris placerat, nulla a vehicula sollicitudin.'
    }
]

const activateStore = [
    {
        id: 1,
        title: 'Artículo de Guía',
        paragraph: 'Activar tu tienda... Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut leo lorem, tempor sit amet auctor ut, mollis quis diam. Mauris placerat, nulla a vehicula sollicitudin.'
    },
    {
        id: 2,
        title: 'Artículo de Guía 2',
        paragraph: 'Activar tu tienda... Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut leo lorem, tempor sit amet auctor ut, mollis quis diam. Mauris placerat, nulla a vehicula sollicitudin.'
    },
    {
        id: 3,
        title: 'Artículo de Guía 23',
        paragraph: 'Activar tu tienda... Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut leo lorem, tempor sit amet auctor ut, mollis quis diam. Mauris placerat, nulla a vehicula sollicitudin.'
    }
]

/*
*ContactSupport index data
*/
const contactSupport = [
    {
        id: 1,
        title: '¿Necesitas contactarnos?',
        paragraph: 'Puedes contactarnos al número de soporte +51 991 115 871.'
    }
]

export const indicePE = [
    {
        id: 1,
        text: 'Preguntas Frecuentes',
        nameIndex: 'frequentQuestions',
        defaultSubIndex: 'products',
        subIndex: [
            {
                id: 1,
                text: 'Productos',
                nameIndex: 'products',
                products: products
            },
            {
                id: 2,
                text: 'Delivery',
                nameIndex: 'delivery',
                delivery: delivery
            },
            {
                id: 3,
                text: 'Mis ventas',
                nameIndex: 'mySales',
                mySales: mySales
            },
            // {
            //     id: 4,
            //     text: 'Metodos de pago',
            //     nameIndex: 'paymentMethods'
            // },
            {
                id: 5,
                text: 'Mi configuración',
                nameIndex: 'mySettings',
                mySettings: mySettings
            },
        ]

    },
    // {
    //     id: 2,
    //     text: 'Tutoriales',
    //     nameIndex: 'tutorials',
    //     defaultSubIndex: 'newProduct',
    //     subIndex: [
    //         {
    //             id: 1,
    //             text: 'Crea un producto',
    //             nameIndex: 'newProduct',
    //             newProduct: newProduct
    //         },
    //         {
    //             id: 2,
    //             text: 'Crea una categoría',
    //             nameIndex: 'newCategory',
    //             newCategory: newCategory
    //         },
    //         {
    //             id: 3,
    //             text: 'Crea una orden',
    //             nameIndex: 'newOrder',
    //             newOrder: newOrder
    //         }
    //     ]
    // },
    // {
    //     id: 3,
    //     text: 'Guías',
    //     nameIndex: 'guides',
    //     defaultSubIndex: 'reportBug',
    //     subIndex: [
    //         {
    //             id: 1,
    //             text: 'Cómo reportar un bug',
    //             nameIndex: 'reportBug',
    //             reportBug: reportBug
    //         },
    //         {
    //             id: 2,
    //             text: 'Cómo activar tu tienda',
    //             nameIndex: 'activateStore',
    //             activateStore: activateStore
    //         }
    //     ]
    // },
    {
        id: 4,
        text: 'Contactar a Soporte',
        nameIndex: 'contactSupport',
        // subIndex: [
        //     {
        //         id: 1,
        //         text: 'Contáctanos por email',
        //         nameIndex: 'contactUsEmail'
        //     },
        //     {
        //         id: 2,
        //         text: 'Contáctanos por whatsapp',
        //         nameIndex: 'contactUsWhatsapp'
        //     }
        // ]
        contactSupport: contactSupport
    },
]

// const frequentQuestions = [
//     {
//         id: 1,
//         title: 'Artículo de pregunta frecuente',
//         paragraph: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut leo lorem, tempor sit amet auctor ut, mollis quis diam. Mauris placerat, nulla a vehicula sollicitudin.'
//     },
//     {
//         id: 2,
//         title: 'Artículo de pregunta frecuente 2',
//         paragraph: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut leo lorem, tempor sit amet auctor ut, mollis quis diam. Mauris placerat, nulla a vehicula sollicitudin.'
//     }
// ]

// const tutorials = [
//     {
//         id: 1,
//         title: 'Artículo de tutorials',
//         paragraph: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut leo lorem, tempor sit amet auctor ut, mollis quis diam. Mauris placerat, nulla a vehicula sollicitudin.'
//     },
//     {
//         id: 2,
//         title: 'Artículo de tutorials 2',
//         paragraph: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut leo lorem, tempor sit amet auctor ut, mollis quis diam. Mauris placerat, nulla a vehicula sollicitudin.'
//     }
// ]

// const guides = [
//     {
//         id: 1,
//         title: 'Artículo de Guías',
//         paragraph: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut leo lorem, tempor sit amet auctor ut, mollis quis diam. Mauris placerat, nulla a vehicula sollicitudin.'
//     },
//     {
//         id: 2,
//         title: 'Artículo de Guías 2',
//         paragraph: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut leo lorem, tempor sit amet auctor ut, mollis quis diam. Mauris placerat, nulla a vehicula sollicitudin.'
//     }
// ]


// const contactUsEmail = [
//     {
//         id: 1,
//         title: 'Artículo de contáctanos',
//         paragraph: 'Contáctannos por email... Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut leo lorem, tempor sit amet auctor ut, mollis quis diam. Mauris placerat, nulla a vehicula sollicitudin.'
//     },
//     {
//         id: 2,
//         title: 'Artículo de contáctanos 2',
//         paragraph: 'Contáctannos por email... Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut leo lorem, tempor sit amet auctor ut, mollis quis diam. Mauris placerat, nulla a vehicula sollicitudin.'
//     }
// ]

// const contactUsWhatsapp = [
//     {
//         id: 1,
//         title: 'Artículo de contáctanos',
//         paragraph: 'Contáctannos por whatsapp... Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut leo lorem, tempor sit amet auctor ut, mollis quis diam. Mauris placerat, nulla a vehicula sollicitudin.'
//     },
//     {
//         id: 2,
//         title: 'Artículo de contáctanos 2',
//         paragraph: 'Contáctannos por whatsapp... Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut leo lorem, tempor sit amet auctor ut, mollis quis diam. Mauris placerat, nulla a vehicula sollicitudin.'
//     },
//     {
//         id: 3,
//         title: 'Artículo de contáctanos 23',
//         paragraph: 'Contáctannos por whatsapp... Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut leo lorem, tempor sit amet auctor ut, mollis quis diam. Mauris placerat, nulla a vehicula sollicitudin.'
//     }
// ]