import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfo } from '@fortawesome/free-solid-svg-icons'

function ModalImageCrop({
  image,
  cutbanner,
  cutimage,
  index,
  setShowModalCropImage,
  type,
  resolution,
  aspectRatio,
  data,
  removeListBanner,
  setLoading,
}) {
  const [cropper, setCropper] = useState()

  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      setShowModalCropImage(false)
      if (type === 'banners') {
        cutbanner(cropper.getCroppedCanvas().toDataURL(), index)
      } else {
        cutimage(cropper.getCroppedCanvas().toDataURL())
      }
    }
  }

  const cancelcropper = () => {
    setCropper('')
    setLoading(false)

    setShowModalCropImage(false)

    data[index].link = ''

    removeListBanner(index)
  }
  return (
    <div className='fixed w-full h-screen top-0 left-0 z-50 fixed'>
      <div
        onClick={() => setShowModalCropImage(false)}
        className='absolute w-full h-full top-0 left-0 bg-[#00000080]'
      ></div>
      <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-xl w-3/4 h-3/4 top-0 left-0 bg-white'>
        <div className='w-full h-full flex flex-col justify-between'>
          <div className='flex mt-6 px-6 justify-between items-top w-full h-auto text-right'>
            <div></div>
            <p className='font-semibold'>Cargar Imagen</p>
            <p
              onClick={cancelcropper}
              className=' mb-6 px-4 text-xl text-gray-500 rounded-full p-2 cursor-pointer bg-gray-300 inline'
            >
              X
            </p>
          </div>
          <div className='relative w-full h-full px-6'>
            <Cropper
              style={{ height: 'auto', width: '100%', maxHeight: '60%' }}
              initialAspectRatio={1}
              preview='.img-preview'
              src={image}
              viewMode={1}
              minCropBoxHeight={10}
              minCropBoxWidth={10}
              background={false}
              responsive={true}
              autoCropArea={1}
              checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
              onInitialized={(instance) => {
                setCropper(instance)
              }}
              guides={true}
            />
            {/* <Cropper ref={cropperRef} src={src} />; */}
          </div>
          <div className='w-full mt-4 mb-6'>
            <div className='flex items-center gap-x-2 px-6'>
              <div className='flex'>
                <FontAwesomeIcon
                  className='text-slate-700 rounded-full p-2 px-3 bg-gray-400'
                  icon={faInfo}
                />
              </div>
              <div className='text-xs'>
                <p>
                  La imagen debe tener dimensiones de {resolution.width} x{' '}
                  {resolution.height} pixeles.
                </p>
                <p>Los formatos aceptables son .png, jpg y jpeg.</p>
              </div>
            </div>
            <hr className='my-4' />
            <div className='flex justify-end  px-6'>
              <button className='font-semibold' onClick={() => cancelcropper()}>
                Cancelar
              </button>
              <button
                className='bg-primary py-2 px-4 rounded-lg font-semibold ml-6'
                onClick={() => getCropData()}
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
        {/* <img className=" w-auto h-auto" src={image} /> */}
      </div>
    </div>
  )
}

export default ModalImageCrop
