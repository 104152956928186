import axios from 'axios'

export const DeleteCupon = async (idcupon) => {
  try {
    const { data } = await axios.delete(
      `https://ventafazil-strapi-production.up.railway.app/cupons/${idcupon}`
    )
    if (data) {
      return data
    } else {
      return null
    }
  } catch (error) {
    //console.log(error);
  }
}
