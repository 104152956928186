import React, { useState } from 'react'
import NavigationTop from '../components/NavigationTop'
import NavigationSide from '../components/NavigationSide'
import UIBackofficeHeader from '../components/UIBackofficeHeader'
import WaysOfDelivery from '../components/delivery/ways-delivery/WaysOfDelivery'
import PricePerZone from '../components/delivery/price-per-zone/PricePerZone'
import PickupStore from '../components/delivery/pickup-store/PickupStore'
import PriceOnly from '../components/delivery/price-only/PriceOnly'
import Swal from 'sweetalert2'

const deliveryOptions = [
   {
      id: 1,
      name: 'Formas de Entrega',
      component: <WaysOfDelivery />
   },
   {
      id: 2,
      name: 'Precios por Zona',
      component: <PricePerZone />,
      storagename: 'iszoneprice'
   },
   {
      id: 3,
      name: 'Precio Único',
      component: <PriceOnly />,
      storagename: 'isonlyprice'
   },
   {
      id: 4,
      name: 'Puntos de Recojo',
      component: <PickupStore />,
      storagename: 'ispickupstore'
   }
]

function Delivery() {
   const [selectedOption, setSelectedOption] = useState({
      option1: true
   })
   const validateOptionClicked = (el) => {
      const _user = JSON.parse(localStorage.getItem('user'))
      if (el.storagename == 'iszoneprice' && !_user.iszoneprice) {
         Swal.fire('Envío a domicilio', 'Para poder ver esta sección debe activar la opción precio por zona', 'info')
         return
      }
      if (el.storagename == 'isonlyprice' && !_user.isonlyprice) {
         Swal.fire('Envío a domicilio', 'Para poder ver esta sección debe activar la opción precio único', 'info')
         return
      }
      if (el.storagename == 'ispickupstore' && !_user.ispickupstore) {
         Swal.fire('Tienda física', 'Para poder ver esta sección debe activar puntos de recojo', 'info')
         return
      }
      setSelectedOption({ ['option' + el.id]: true })
   }

   const ViewByOption = () => {
      return (
         <>
            {
               deliveryOptions.map((el, index) => {
                  return (
                     <div
                        className='w-full lg:w-10/12 '
                        key={index + el.name}
                     >
                        {selectedOption['option' + el.id] && el.component}
                     </div>
                  )
               })
            }
         </>
      )
   }

   return (
      <div className='pb-24 w-fit lg:w-full overflow-hidden'>
         <NavigationTop />
         <NavigationSide />
         <div className='md:ml-28 md:mt-5'>
            <UIBackofficeHeader
               title='Delivery'
            />
            <div className='w-fit lg:w-full flex flex-col md:ml-20 lg:ml-28 px-5 md:px-0'>
               <div
                  className='w-fit flex justify-center p-5 rounded-full'
                  style={{ background: '#F0F0F0' }}
               >
                  <ul className='flex gap-7 overflow-x-auto'>
                     {
                        deliveryOptions.map(el =>
                           <button key={'_' + el.id + el.name} onClick={() => validateOptionClicked(el)}>
                              <li
                                 className='w-max px-5 py-2 rounded-full'
                                 style={selectedOption['option' + el.id] ? { background: '#8163FF', color: '#FFFFFF' } : { background: '#D9D9D9', color: '#787878' }}
                                 key={el.id + '_' + el.name}
                              >{el.name}</li>
                           </button>
                        )
                     }
                  </ul>
               </div>
               <ViewByOption />
               {/* {ViewByOption()} */}
            </div>
         </div>
      </div>
   )
}

export default Delivery