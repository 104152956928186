import React from 'react'

export default function UIBackofficeProductCell(props) {
  //  extras
  //  variation

  return (
    <div className='flex flex-col items-center justify-start gap-3 p-4 text-sm text-gray-600 border-b border-gray-300 md:flex-row '>
      <div>{props.index + 1}</div>
      <div className='w-20 h-auto overflow-hidden rounded-lg'>
        <img src={props.img} alt={props.nombre} />{' '}
      </div>
      <div className='flex flex-col w-48'>
        <div className='text-sm font-semibold'>{props.nombre}</div>
        <div className='text-xs'>
          {' '}
          {props.codigoproducto ? props.codigoproducto : ''}
        </div>
        {props.variation ? (
          <>
            <div className='text-xs font-semibold'> Variaciones:</div>
            <div className='text-xs'>
              {Object.values(props.variation).join('/')}
            </div>
          </>
        ) : (
          ''
        )}

        {props.extras ? (
          <>
            <div className='text-xs font-semibold'> Extras:</div>
            {props.extras.map((extra, i) => {
              return (
                <div key={i} className='text-xs'>
                  {' '}
                  {extra.name}: + S/ {extra.price}
                </div>
              )
            })}
          </>
        ) : (
          ''
        )}
      </div>
      <div className='flex justify-end flex-grow gap-1 text-2xl tracking-tighter text-right'>
        {props.amount}
      </div>
      <div className='flex justify-end flex-grow gap-1 text-2xl tracking-tighter text-right'>
        <div className='text-sm'>{props.currency} </div>
        {props.precio}
      </div>
    </div>
  )
}
