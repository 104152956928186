function InputChecked({ label = '', type = '', arrayData = [{}], onChange = () => { }, value = '' }) {
    return (
        <div className='flex flex-col gap-2'>
            <label>{label}</label>
            {arrayData.map(el =>
                <div className='flex items-center' key={el.id + '_' + el.name}>
                    <input
                        className='w-12 h-5'
                        type={type || 'checkbox'}
                        name={el.name}
                        onChange={(e) => onChange(e, el.value)}
                        checked={value === el.value}
                    />
                    <p>{el.text}</p>
                </div>
            )}
        </div>
    )
}

export default InputChecked