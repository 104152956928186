function NeedHelp() {
    return (
        <div className='fixed z-20 bottom-28 md:bottom-10 right-3/4 md:right-6 mx-6 md:mr-0 flex flex-col items-center justify-center'>
            <a
                className='flex items-center justify-center rounded-full shadow-xl w-14 h-14 md:w-16 md:h-16 Zhover:cursor-pointer'
                style={{ background: '#00FBC8' }}
                href="/ayuda"
            >
                <svg
                    width='40'
                    height='40'
                    viewBox='0 0 47 47'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                >
                    <path
                        d='M3 37.1667V23.5C3 18.0631 5.15982 12.8488 9.00431 9.00431C12.8488 5.15982 18.0631 3 23.5 3C28.9369 3 34.1512 5.15982 37.9957 9.00431C41.8402 12.8488 44 18.0631 44 23.5V37.1667'
                        stroke='black'
                        strokeWidth='5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M44 39.4446C44 40.6528 43.52 41.8115 42.6657 42.6658C41.8114 43.5201 40.6527 44.0001 39.4444 44.0001H37.1667C35.9585 44.0001 34.7997 43.5201 33.9454 42.6658C33.0911 41.8115 32.6111 40.6528 32.6111 39.4446V32.6112C32.6111 31.403 33.0911 30.2443 33.9454 29.39C34.7997 28.5356 35.9585 28.0557 37.1667 28.0557H44V39.4446ZM3 39.4446C3 40.6528 3.47996 41.8115 4.33429 42.6658C5.18862 43.5201 6.34735 44.0001 7.55556 44.0001H9.83333C11.0415 44.0001 12.2003 43.5201 13.0546 42.6658C13.9089 41.8115 14.3889 40.6528 14.3889 39.4446V32.6112C14.3889 31.403 13.9089 30.2443 13.0546 29.39C12.2003 28.5356 11.0415 28.0557 9.83333 28.0557H3V39.4446Z'
                        stroke='black'
                        strokeWidth='5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    />
                </svg>
                <br />
            </a>
            <div className='text-center font-semibold'>¿Necesitas ayuda?</div>
        </div>
    )
}

export default NeedHelp