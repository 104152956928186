import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../../context/UserContext'
import axios from 'axios'
import Swal from 'sweetalert2'
import Checkbox1 from '../components/Checkbox1'
import Button1 from '../components/Button1'
import Loading from '../components/Loading'

const TOAST = Object.freeze({
  TITLE: {
    ERROR: 'Active una opción de envío a domicilio',
    INFO: 'Solo puede seleccionar una opción de envío a domicilio'
  },
  ICON: {
    SUCCESS: 'success',
    ERROR: 'error',
    INFO: 'info'
  },
})

const DEFAULT_COUNTRY = Object.freeze({
  PERU: 'Peru',
  MEXICO: 'Mexico'
})

function WaysOfDelivery() {
  const { user } = useContext(UserContext)

  const _localstorage = localStorage.getItem('user')
  const pais = JSON.parse(_localstorage).paises.name
  const [loading, setLoading] = useState(true)
  const [listCountries, setListCountries] = useState([])
  const [country, setCountry] = useState({})
  const [chkCountry, setChkCountry] = useState({
    isPeru: false,
    isMexico: false
  })
  const [chkDeliveryMethod, setChkDeliveryMethod] = useState({
    isHomeDelivery: false,
    isPickUpInStore: false,
  })
  const [chkShippingType, setChkShippingType] = useState({
    isPricePerZone: false,
    isPriceOnly: false,
  })

  const getCountries = async () => {
    const url = 'https://ventafazil-strapi-production.up.railway.app/Countries'
    try {
      const response = await axios.get(url)
      const data = response.data
      if (response.status === 200) {
        setListCountries(data)
        fillCountries(data)
        setValuesCheckbox(data)
        setLoading(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const fillCountries = (data) => {
    let _chkCountries = {}
    data.forEach((el) => {
      if (el.name === 'Mexico') _chkCountries['is' + el.name] = false
    })
    setChkCountry(_chkCountries)
  }

  const setValuesCheckbox = (data) => {
    let _user = JSON.parse(localStorage.getItem('user'))
    if (_user.paises?.name) {
      let _country = data.filter((el) => el.name == _user.paises.name)
      setCountry(_country[0])
      setChkCountry({ ...chkCountry, ['is' + _user.paises?.name]: true })
    }
    // else {// Error no country in services user
    //   let _country = data.filter((el) => el.name == DEFAULT_COUNTRY.MEXICO)
    //   setCountry(_country[0])
    //   setChkCountry({ ...chkCountry, ['is' + DEFAULT_COUNTRY.MEXICO]: true })

    //   console.log("pasieses",data)
    // }
    setChkDeliveryMethod({
      ...chkDeliveryMethod,
      isHomeDelivery: _user.ishomedelivery,
      isPickUpInStore: _user.ispickupstore,
    })
    setChkShippingType({
      ...chkShippingType,
      isPriceOnly: _user.isonlyprice,
      isPricePerZone: _user.iszoneprice,
    })

  }

  const changeCountry = (e) => {
    let _checked = e.target.checked
    let _name = e.target.name
    if (_name === 'Peru') {
      if (chkCountry.isPeru) return
      // setChkCountry({ ...chkCountry, isPeru: _checked, isMexico: !_checked })
    } else if (_name === 'Mexico') {
      if (chkCountry.isMexico) return
      // setChkCountry({ ...chkCountry, isMexico: _checked, isPeru: !_checked })
    }
    if (e.target.checked) {
      let _country = listCountries.filter((el) => el.name == _name)
      setCountry(_country[0])
    }
  }

  const changeDeliveryMethod = (e) => {
    let _checked = e.target.checked
    if (e.target.name === 'homeDelivery') {
      setChkDeliveryMethod({ ...chkDeliveryMethod, isHomeDelivery: _checked })
    } else if (e.target.name == 'isPickUpStore') {
      setChkDeliveryMethod({ ...chkDeliveryMethod, isPickUpInStore: _checked })
    }
  }

  const changeShippingType = (e) => {
    let _checked = e.target.checked
    setChkDeliveryMethod({ ...chkDeliveryMethod, isHomeDelivery: _checked })
    if (e.target.name === 'priceperzone') {
      if (_checked && chkShippingType.isPriceOnly) {
        Swal.fire('Envío a domicilio', 'Solo puede seleccionar una opción', 'info')
        return
      }
      setChkShippingType({ ...chkShippingType, isPricePerZone: _checked })
    } else if (e.target.name === 'priceonly') {
      if (_checked && chkShippingType.isPricePerZone) {
        Swal.fire('Envío a domicilio', 'Solo puede seleccionar una opción', 'info')
        return
      }
      setChkShippingType({ ...chkShippingType, isPriceOnly: _checked })
    }
  }

  const updateCustomer = () => {
    setLoading(true)
    let config = {
      method: 'put',
      url: `https://ventafazil-strapi-production.up.railway.app/clientes/` + user,
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_KEY_STRAPI}`,
      },
      data: {
        paises: { _id: country._id },
        ishomedelivery: chkDeliveryMethod.isHomeDelivery,
        ispickupstore: chkDeliveryMethod.isPickUpInStore,
        iszoneprice: chkShippingType.isPricePerZone,
        isonlyprice: chkShippingType.isPriceOnly,
      },
    }
    // if (!chkDeliveryMethod.isHomeDelivery) {
    //   toastAlert(TOAST.ICON.ERROR, TOAST.TITLE.ERROR)
    //   return
    // }
    axios(config)
      .then((response) => {
        if (response.status == 200) {
          localStorage.setItem('user', JSON.stringify(response.data))
          setLoading(false)
          Swal.fire('Se guardo la forma de entrega ', '', 'success')
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const toastAlert = (icon, title) => {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top',
      iconColor: 'white',
      customClass: {
        popup: 'colored-toast',
      },
      showConfirmButton: false,
      timer: 2500,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })
    Toast.fire({
      icon: icon,
      title: title
    })
  }

  useEffect(() => {
    getCountries()
  }, [])

  return (
    <div className='w-fit flex flex-col gap-10 py-10'>
      {loading && <Loading />}
      <p className='font-semibold'>Activa una o las dos opciones para configurar.</p>
      <div className='flex flex-col gap-5'>
        <div className='flex flex-col gap-3'>
          <h2 className='font-semibold'>Elige tu país</h2>
          <p className='' style={{ color: '#787878' }}>Elige el país donde esta<br /> ubicada tu tienda.</p>
        </div>
        <div className='flex flex-col gap-5 py-5 w-56 bg-white border-2 rounded-xl'>
          {listCountries.map((el, i) => {
            if (el.name === pais) {
              return (
                <label
                  className={`flex items-center ml-3 w-48 h-8 bg-white rounded-lg ${chkCountry['is' + el.name] && 'bg-white'}`}
                  style={{ color: '#787878' }}
                  key={'_' + i}
                >
                  <input
                    className='ml-2 mr-2'
                    type='checkbox'
                    name={el.name}
                    value={chkCountry['is' + el.name]}
                    checked={chkCountry['is' + el.name]}
                    onChange={(e) => changeCountry(e)}
                  />
                  {el.name}
                </label>
              )
            }
          })}
        </div>
      </div>
      <div className='flex flex-col gap-10'>
        <div className='flex flex-col gap-3'>
          <h2 className='font-semibold'>Forma de entrega</h2>
          <p style={{ color: '#787878' }}>Activa la forma de entrega que tengas <br />en tu negocio, puedes elegir ambas.</p>
        </div>
        <div className='w-max flex flex-col gap-5'>
          <p className='font-semibold' style={{ color: '#8163FF' }}>
            Envío a Domicilio
          </p>
          <div className='flex gap-5'>
            <Checkbox1
              name='priceperzone'
              value={chkShippingType.isPricePerZone}
              onChange={changeShippingType}
            />
            <p className='w-[150px]' style={chkShippingType.isPricePerZone ? { color: '#787878' } : { color: '#AFAFAF' }}>Precio por zona</p>
          </div>
          <div className='flex gap-5'>
            <Checkbox1
              name='priceonly'
              value={chkShippingType.isPriceOnly}
              onChange={changeShippingType}
            />
            <p className='w-[150px]' style={chkShippingType.isPriceOnly ? { color: '#787878' } : { color: '#AFAFAF' }}>Precio único</p>
          </div>
        </div>
        <div className='w-max flex flex-col gap-5'>
          <p className='font-semibold' style={{ color: '#8163FF' }}>
            Tienda física
          </p>
          <div className='flex gap-5'>
            <Checkbox1
              name='isPickUpStore'
              value={chkDeliveryMethod.isPickUpInStore}
              onChange={changeDeliveryMethod}
            />
            <p className='w-[150px]' style={chkDeliveryMethod.isPickUpInStore ? { color: '#787878' } : { color: '#AFAFAF' }}>Puntos de recojo</p>
          </div>
        </div>
      </div>
      <div className='w-auto flex justify-center items-center'>
        <Button1
          className='w-fit'
          textColor='#fff'
          background='#8163FF'
          text='Guardar'
          onClick={updateCustomer}
        />
      </div>
    </div >
  )
}

export default WaysOfDelivery