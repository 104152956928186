import * as Yup from 'yup'

const required = '* Campo obligatorio'

const loginValidator = Yup.object().shape({
  email: Yup.string().email('Ingrese un email valido').required(required),
  password: Yup.string().required(required),
})

const tiendaValidator = Yup.object().shape({
  pmeta: Yup.string()
    .min(55, 'Debe ingresar 55 caracteres como minimo')
    .max(70, 'Debe ingresar 70 caracteres como maximo'),
  pgoogle: Yup.string()
    .min(55, 'Debe ingresar 55 caracteres como minimo')
    .max(70, 'Debe ingresar 70 caracteres como maximo'),
  nombre: Yup.string()
    .required(required)
    .min(2, 'Debe ingresar 2 caracteres como minimo')
    .max(30, 'Debe ingresar 30 caracteres como maximo'),
  apellido: Yup.string()
    .required(required)
    .min(2, 'Debe ingresar 2 caracteres como minimo')
    .max(30, 'Debe ingresar 30 caracteres como maximo'),
  email: Yup.string().email('Ingrese un email valido').required(required),
  nombreTienda: Yup.string().required(required),
  presentacion: Yup.string()
    .required(required)
    .min(2, 'Debe ingresar 2 caracteres como minimo')
    .max(150, 'Debe ingresar 150 caracteres como minimo'),
  telefono: Yup.number()
    .required(required)
    .min(7, 'Debe ingresar 7 caracteres como minimo')
    .max(30, 'Debe ingresar 30 caracteres como maximo'),
  Whassapp: Yup.number()
    .required(required)
    .min(9, 'Debe ingresar 9 caracteres como minimo')
    .max(12, 'Debe ingresar 12 caracteres como maximo'),
  distrito: Yup.string()
    .min(4, 'Debe ingresar 4 caracteres como minimo')
    .max(30, 'Dene ingresar 30 caracteres como maximo'),
  horario: Yup.string()
    .required(required)
    .min(3, 'Debe ingresar 3 caracteres como minimo')
    .max(13, 'Dene ingresar 13 caracteres como maximo'),
  websiteFB: Yup.string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'Enter correct url!'
    )
    .required('Please enter website'),
})
const tiendaValidator1 = Yup.object().shape({
  metapixelid: Yup.string()
    .min(55, 'Debe ingresar 55 caracteres como minimo')
    .max(70, 'Debe ingresar 70 caracteres como maximo'),
  name: Yup.string()
    .required(required)
    .min(2, 'Debe ingresar 2 caracteres como minimo')
    .max(30, 'Debe ingresar 30 caracteres como maximo'),
})
export { loginValidator, tiendaValidator, tiendaValidator1 }
