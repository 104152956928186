import React, { useContext, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell } from '@fortawesome/free-solid-svg-icons'
import userOutline from '../assets/images/user-outline.svg'
import ask from '../assets/images/ask.svg'
import delivery from '../assets/images/delivery-fill.svg'
import mandat from '../assets/images/mandat-fill.svg'
import home from '../assets/images/home-fill.svg'
import downArrow from '../assets/images/down-arrow.svg'
import { UserContext } from '../context/UserContext'
import { Link, withRouter } from 'react-router-dom'
import axios from 'axios'

const createList = [
  {
    id: 1,
    src: delivery,
    href: '/producto/nuevo',
    name: 'Crear Producto',
  },
  {
    id: 1,
    src: mandat,
    href: '/categorias',
    name: 'Crear Categoría',
  },
  {
    id: 3,
    src: home,
    href: '/venta/nuevo',
    name: 'Crear Orden',
  },
]

function NavigationTop(props) {
  const { user, setUser } = useContext(UserContext)
  const [userdata, setUserdata] = useState({ name: '', id: '' })
  const [urlPage, setUrlPage] = useState('')
  const [showOptions, setShowOptions] = useState(false)
  const [showLogout, setShowLogout] = useState(false)

  const openOptions = () => {
    setShowOptions(!showOptions)
  }

  const openLogout = () => {
    setShowLogout(!showLogout)
  }

  const logoutUser = () => {
    setUser(null)
    localStorage.removeItem('user')
    props.history.push({
      pathname: `/`,
    })
  }

  const getCustomer = () => {
    const user = JSON.parse(localStorage.getItem('user'))
    const config = {
      method: 'get',
      url: `https://ventafazil-strapi-production.up.railway.app/clientes/${user._id}`,
    }
    if (user) {
      axios(config)
        .then((response) => {
          localStorage.setItem('user', JSON.stringify(response.data))
          setUserdata({ name: response.data.Nombre, id: response.data.id })
          setUrlPage(response.data.urlpage)
        })
        .catch((error) => {
          //console.log(error)
        })
    }
  }

  useEffect(() => {
    getCustomer()
  }, [])

  return (
    <div
      className='min-w-max w-full sticky z-20 top-0 flex flex-row gap-8 justify-between items-center py-4 px-5 md:px-10'
      style={{ background: '#F0F0F0' }}
    >
      <a
        href='/'
        className='text-xl font-bold tracking-tighter'
        style={{ color: '#8163FF' }}
      >
        Power
        <span className='text-black'>Bizz</span>
      </a>
      {user !== null ? (
        <div className='w-max flex flex-row gap-4 text-sm font-semibold justify-end items-center'>
          {urlPage && urlPage.length > 1 && (
            <a
              href={urlPage}
              target='_blank'
              rel='noopener noreferrer'
              className='flex justify-center text-sm font-semibold text-center px-7 py-1 rounded-xl'
              style={{
                color: '#8163FF',
                border: '1px solid #8163FF',
              }}
            >
              Ver tienda
            </a>
          )}
          <div
            className='relative flex justify-center items-center'
            style={{ background: '#00fbc8', borderRadius: '20px' }}
            onClick={() => openOptions()}
          >
            <div className='flex gap-2 items-center cursor-pointer py-1 px-5'>
              <p>Crear</p>
              <img
                src={downArrow}
                className={`${showOptions ? '' : 'rotate-180'}`}
              />
            </div>
            {showOptions && (
              <nav className='w-max absolute top-0 right-0 mt-14'>
                <ul
                  className='py-1 px-5 rounded-3xl bg-white'
                  style={{ border: '4px solid #F0F0F0' }}
                >
                  {createList.map((el, i) => (
                    <li
                      key={i + '_' + el.name}
                      className='w-full py-3'
                      style={{ color: '#8163ff' }}
                    >
                      <div className='flex gap-2'>
                        <img src={el.src} />
                        <a href={el.href}>{el.name}</a>
                      </div>
                    </li>
                  ))}
                </ul>
              </nav>
            )}
          </div>
          <div className='w-max flex gap-2'>
            <a href='#'>
              <img
                src={ask}
                className='rounded-2xl px-2 py-1 cursor-pointer'
                style={{ background: '#4A4A4A' }}
              />
            </a>
            <p>Ayuda</p>
          </div>
          <div className='relative ' onClick={() => openLogout()}>
            <img className='cursor-pointer' src={userOutline} />
            {showLogout && (
              <ul className='w-max absolute top-0 right-0 mt-14'>
                <li
                  className='py-1 px-2 rounded-xl cursor-pointer'
                  style={{ background: '#00fbc8', border: '1px solid #00fbc8' }}
                  onClick={logoutUser}
                >
                  Cerrar Sesión
                </li>
              </ul>
            )}
          </div>
        </div>
      ) : (
        <a
          className='text-sm text-white py-2 px-5 rounded-2xl'
          style={{ background: '#8163ff' }}
          href='https://www.powerbizz.com/'
        >
          Registrate
        </a>
      )}
    </div>
  )
}

export default withRouter(NavigationTop)
