import React, { useState, useEffect } from 'react'
import VentaFazilLoginForm from '../components/VentaFazilLoginForm'

const Login = (props) => {
  const [logged, setLogged] = useState([])

  return (
    <div className=''>
      <div className='sticky top-0 z-20 flex flex-row items-center w-full gap-8 px-8 py-4 bg-black '>
        <a
          href='https://www.powerbizz.com/'
          className='text-xl font-semibold tracking-tighter text-yellow-300 '
          style={{ color: 'rgb(129, 99, 255)' }}
        >
          PowerBizz
        </a>
        <div className='flex flex-grow'></div>
        <div className='flex items-center gap-3'>
          <a href='https://www.powerbizz.com/' className='text-sm text-white'>
            Registrate
          </a>
        </div>
      </div>

      <div>
        <div className='grid h-screen grid-cols-1 lg:grid-cols-6'>
          <div className='grid items-center col-start-1 col-end-3 '>
            <VentaFazilLoginForm logged={logged} setLogged={() => setLogged} />
          </div>
          <div
            data-aos='fade-left'
            className='grid col-start-3 col-end-7 bg-cover bg-login-texture'
          ></div>
        </div>
      </div>
    </div>
  )
}

/* rebuild */
export default Login
