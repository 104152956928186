function InputLabel({ labelName, type, name, value, onChange, disabled }) {
  return (
    <div className='md:w-1/2 flex flex-col gap-1'>
      <label className='text-sm w-max'>{labelName}</label>
      <input
        className='text-sm border-2 rounded-xl py-2 px-2    '
        style={{
          border: '2px solid #AAAAAA',
          background: disabled && 'rgb(240, 240, 240)',
          color: disabled && 'rgb(187,184,184)',
        }}
        type={type || 'text'}
        name={name}
        value={value}
        onChange={(e) => onChange(e)}
        disabled={disabled}
      />
    </div>
  )
}

export default InputLabel
