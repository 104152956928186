import React from 'react'

const NavigationOrder = () => {
  let arrayOrder = [
    'Por atender',
    'Detenido',
    'Preparando pedido',
    'Programado',
    'Despachado',
    'Entregado',
    'Finalizado',
  ]

  return (
    // <div className='flex flex-row items-start justify-center no-scrollbar w-full gap-12 py-4  overflow-x-auto text-sm font-semibold rounded-lg '>
    //   <div
    //     exact
    //     // activeClassName='ext-black border-b-2 border-yellow-400 font-bold '
    //     className='text-gray-400 capitalize transition shrink-0 hover:text-yellow-500'
    //   >
    //     Todo(0)
    //   </div>

    //   {arrayOrder.map((x, i) => {
    //     return (
    //       <div
    //         activeClassName='text-black border-b-2 border-yellow-400 font-bold '
    //         className='py-1 tracking-tighter text-gray-400 capitalize transition shrink-0 hover:text-yellow-500'
    //         key={i}
    //       >
    //         {x}
    //       </div>
    //     )
    //   })}
    // </div>
    <div></div>
  )
}

export default NavigationOrder
