import axios from "axios"
import { useContext } from "react"
import { useEffect } from "react"
import { useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import Swal from "sweetalert2"
import Country from "../components/Country"
import NavigationSide from "../components/NavigationSide"
import NavigationTop from "../components/NavigationTop"
import { CurrencyContext } from "../context/CurrencyContext"
import { decimalNumber } from "../utils/InputDecimal"
import { isEmpty, minPrice, maxPrice, minDivisionSelected } from "../utils/Validation"


const shippingType = {
    priceOnly: 'Precio único',
    pricePerZone: 'Precio por zona',
    noShippingPolicy: 'Aún no tienes configurado tu política de envío',
}

const PricePerZone = () => {
    const { state } = useLocation()
    let history = useHistory()
    // console.log("STATE", state)
    if (history.location.pathname === '/delivery/precio-zona/editar' && !state) {
        history.push('/delivery')
    }
    const _user = JSON.parse(localStorage.getItem('user'))
    const pais = _user.paises

    const currencyValue = useContext(CurrencyContext)
    const currency = currencyValue.currency

    const [listOneDivision, setListOneDivision] = useState([])
    const [listTwoDivision, setListTwoDivision] = useState([])
    const [listThreeDivision, setListThreeDivision] = useState([])

    const [openOneDivision, setOpenOneDivision] = useState({})
    const [openTwoDivision, setOpenTwoDivision] = useState({})

    const [chkOneDivision, setChkOneDivision] = useState({})
    const [chkTwoDivision, setChkTwoDivision] = useState({})
    const [chkThreeDivision, setChkThreeDivision] = useState({})

    const [isDisabled, setIsDisabled] = useState({})

    const [country, setCountry] = useState({
        id: '',
        name: '',
        nroDivision: 0
    })
    const [loading, setLoading] = useState(true)
    const [isGet, setIsGet] = useState(true)

    const [pricePerZone, setPricePerZone] = useState({
        rateName: '',
        price: 0,
        delivery: [],
        deliveryCondition: '',
        isDiscount: false,
        discountPrice: 0,
        idCliente: '',
        deliveryList: []
    })

    const getAllDeliveryList = () => {
        let _state = { ...state }
        let _concat = []
        let _allDeliveryList = []
        if (_state?.id) {
            _state.allDeliveryList.forEach(el => {
                if (el.idRate != _state.id) {
                    if (_concat.length == 0) {
                        _concat = _concat.concat(el.allDeliveryList[0])
                    } else {
                        _concat = _concat.concat(el.allDeliveryList[0])
                    }
                }
            });
        } else {
            _state.allDeliveryList.forEach(el => {
                if (_concat.length == 0) {
                    _concat = _concat.concat(el.allDeliveryList[0])
                } else {
                    _concat = _concat.concat(el.allDeliveryList[0])
                }
            });
        }
        _concat.forEach(el => {
            _allDeliveryList.push(el.split('_ID').pop())
        })
        return _allDeliveryList
    }

    const validate = () => {
        let error = {}
        let _pricePerZone = { ...pricePerZone }
        const _minPrice = 0
        const _minDivisionSelected = 1

        error['rateName'] = isEmpty(_pricePerZone.rateName, 'nombre de tarifa')
        error['price'] = isEmpty(_pricePerZone.price, 'precio de delivery')
        if (error['price'] === "") error['price'] = minPrice(_pricePerZone.price, _minPrice, ' de delivery')
        error['deliveryList'] = minDivisionSelected(_pricePerZone.deliveryList[0], _minDivisionSelected, ' municipio')
        error['deliveryCondition'] = isEmpty(_pricePerZone.deliveryCondition, 'las condiciones de delivery')
        if (_pricePerZone.isDiscount) {
            error['discountPrice'] = isEmpty(_pricePerZone.discountPrice, 'precio de descuento')
            if (error['discountPrice'] === "") error['discountPrice'] = minPrice(_pricePerZone.discountPrice, _minPrice, ' de descuento')
        }

        Object.keys(error).forEach(elem => {
            if (error[elem] === "") delete error[elem]
        })

        return error
    }

    const toast = (error) => {
        let msgError =
            error.rateName != undefined ? error.rateName
                : error.price != undefined ? error.price
                    : error.deliveryList != undefined ? error.deliveryList
                        : error.deliveryCondition != undefined ? error.deliveryCondition
                            : error.discountPrice != undefined ? error.discountPrice
                                : ''

        const Toast = Swal.mixin({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        if (msgError) {
            Toast.fire({
                icon: 'error',
                title: msgError,
            })
        }
    }

    const onChange = (e) => {
        let name = e.target.name
        let value = e.target.value
        if (e.target.type === 'checkbox') {
            if (!e.target.checked) {
                setPricePerZone({ ...pricePerZone, [name]: e.target.checked, discountPrice: 0 })
            } else setPricePerZone({ ...pricePerZone, [name]: e.target.checked })
        } else if (name === 'price' || name === 'discountPrice') {
            let amount = e.target.value;
            if (!amount || amount.match(/^\d{1,7}(\.\d{0,2})?$/)) {
                setPricePerZone({ ...pricePerZone, [name]: amount })
            }
            // let decimalValue = decimalNumber(e)
            // setPricePerZone({ ...pricePerZone, [name]: decimalValue })
        } else setPricePerZone({ ...pricePerZone, [name]: value })
    }

    const getOneDivion = async (id, dataDelivery) => {
        const url = `https://ventafazil-strapi-production.up.railway.app/Onedivisions?country=` + id
        try {
            const res = await axios.get(url)
            const data = res.data
            if (res.status == 200) {
                setListOneDivision(data)
                fillOpenOneDivision(data)
                fillChkOneDivision(data, dataDelivery)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getTwoDivion = async (_country, dataDelivery) => {
        const url = `https://ventafazil-strapi-production.up.railway.app/Twodivisions?_limit=-1`
        try {
            const res = await axios.get(url)
            const data = res.data
            if (res.status == 200) {
                twoDivisionXCountry(_country, data, dataDelivery)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getThreeDivion = async (dataDelivery) => {
        const url = `https://ventafazil-strapi-production.up.railway.app/Threedivisions?_limit=-1`
        try {
            const res = await axios.get(url)
            const data = res.data
            if (res.status == 200) {
                setListThreeDivision(data)
                fillChkThreeDivision(data, dataDelivery)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const twoDivisionXCountry = async (_country, data, dataDelivery) => {
        let newTwoDivision = await data.filter(el =>
            el.onedivision.country == _country.id
        )
        setListTwoDivision(newTwoDivision)
        fillOpenTwoDivision(newTwoDivision)
        fillChkTwoDivision(newTwoDivision, dataDelivery, _country)
    }

    const getDivisionsXNumbersDivisions = async () => {
        // let _user = JSON.parse(localStorage.getItem('user'))
        setPricePerZone({ ...pricePerZone, idCliente: _user._id })
        // let pais = _user.paises
        setCountry({
            id: pais.id,
            name: pais.name,
            nroDivision: pais.numberofdivision
        })
        let _country = {
            id: pais.id,
            nroDivision: pais.numberofdivision
        }
        let dataDelivery = []
        if (_user.ishomedelivery) {
            if (_user.iszoneprice) {
                if (state?.id) {
                    dataDelivery = await getPricePerZoneByID()
                }
                // dataDelivery = await getPricePerZoneByID()
                for (let index = 1; index <= _country.nroDivision; index++) {
                    switch (index) {
                        case 1:
                            await getOneDivion(_country.id, dataDelivery)
                            break;
                        case 2:
                            await getTwoDivion(_country, dataDelivery)
                            break
                        case 3:
                            await getThreeDivion(dataDelivery)
                            break
                        default:
                            console.log("NO HAY MAS DIVISIONES")
                        //return
                    }
                }
            }
        }
        setLoading(false)
        setIsGet(false)
    }

    const fillOpenOneDivision = async (data) => {
        let _seeOneDivision = {}
        await data.forEach(el => {
            _seeOneDivision['open' + el.name] = false
        });
        setOpenOneDivision(_seeOneDivision)
    }

    const fillOpenTwoDivision = async (data) => {
        let _seeTwoDivision = {}
        await data.forEach(el => {
            _seeTwoDivision['open' + el.name] = false
        });
        setOpenTwoDivision(_seeTwoDivision)
    }

    const seeTwoDivision = (_id) => {
        setOpenOneDivision({ ...openOneDivision, [_id]: !openOneDivision[_id] })
    }

    const seeThreeDivision = (_id) => {
        setOpenTwoDivision({ ...openTwoDivision, [_id]: !openTwoDivision[_id] })
    }

    const fillChkOneDivision = (data, dataDelivery) => {
        let _oneDivision = {}
        let _dataDelivery = []
        if (dataDelivery?.length > 0) {
            dataDelivery[0][0].forEach(el => {
                _dataDelivery.push(el.split('_ID')[0].split('chk1').pop())
            })
            data.forEach(el => {
                if (_dataDelivery.includes(el.name.trim())) {
                    _oneDivision['chk1' + el.name.trim() + '_ID' + el._id] = true
                } else {
                    _oneDivision['chk1' + el.name.trim() + '_ID' + el._id] = false
                }
            });
        } else {
            data.forEach(el => {
                _oneDivision['chk1' + el.name.trim() + '_ID' + el._id] = false
            });
        }
        setChkOneDivision(_oneDivision)
    }

    const fillChkTwoDivision = (data, dataDelivery, _country) => {
        let _allDeliveryList = getAllDeliveryList()
        let dataDeliveryChk2True = []
        let _NewDataDeliveryChk2True = []
        let _twoDivision = {}
        let _isDisabled = {}
        if (dataDelivery?.length > 0) {
            if (_country.nroDivision == 2) {
                dataDeliveryChk2True = dataDelivery[1][0]
            } else if (_country.nroDivision == 3) {
                dataDeliveryChk2True = dataDelivery[0][0]
            }
        }
        if (dataDeliveryChk2True?.length > 0) {
            dataDeliveryChk2True.forEach(el => {
                _NewDataDeliveryChk2True.push(el.split('_ID')[0].split('chk2').pop())
            })
            data.forEach(el => {
                if (_allDeliveryList.includes(el._id.trim())) {
                    _isDisabled['isDisabled' + el._id.trim()] = true
                } else _isDisabled['isDisabled' + el._id.trim()] = false
                if (_NewDataDeliveryChk2True.includes(el.name.trim())) {
                    _twoDivision['chk2' + el.name.trim() + '_ID' + el._id] = true
                } else {
                    _twoDivision['chk2' + el.name.trim() + '_ID' + el._id] = false
                }
            });
        } else {
            data.forEach(el => {
                if (_allDeliveryList.includes(el._id.trim())) {
                    _isDisabled['isDisabled' + el._id.trim()] = true
                } else _isDisabled['isDisabled' + el._id.trim()] = false
                _twoDivision['chk2' + el.name.trim() + '_ID' + el._id] = false
            });
        }
        setIsDisabled(_isDisabled)
        setChkTwoDivision(_twoDivision)
    }

    const fillChkThreeDivision = (data, dataDelivery) => {
        let _allDeliveryList = getAllDeliveryList()
        let dataDeliveryChk3True = []
        let _NewDataDeliveryChk3True = []
        let _threeDivision = {}
        let _isDisabled = {}
        if (dataDelivery?.length > 0) {
            console.log("IF")
            dataDeliveryChk3True = dataDelivery[1][0]
            dataDeliveryChk3True.forEach(el =>
                _NewDataDeliveryChk3True.push(el.split('_ID')[0].split('chk3').pop())
            )
            data.forEach(el => {
                if (_allDeliveryList.includes(el._id.trim())) {
                    _isDisabled['isDisabled' + el._id.trim()] = true
                } else _isDisabled['isDisabled' + el._id.trim()] = false
                if (_NewDataDeliveryChk3True.includes(el.name.trim())) {
                    _threeDivision['chk3' + el.name.trim() + '_ID' + el._id] = true
                } else (
                    _threeDivision['chk3' + el.name.trim() + '_ID' + el._id] = false
                )
            });
        } else {
            console.log("ELSE")
            data.forEach(el => {
                if (_allDeliveryList.includes(el._id.trim())) {
                    _isDisabled['isDisabled' + el._id.trim()] = true
                } else _isDisabled['isDisabled' + el._id.trim()] = false
                _threeDivision['chk3' + el.name.trim() + '_ID' + el._id] = false
            });
        }
        setChkThreeDivision(_threeDivision)
        setIsDisabled(_isDisabled)
    }

    const changeCheckOneDivision = (_id, idOne, lstTwo, e) => {
        setChkOneDivision({ ...chkOneDivision, [_id]: !chkOneDivision[_id] })
        if (country.nroDivision == 2) checkedAllTtwoDivision(idOne, lstTwo, e)
    }

    const changeCheckTwoDivision = (_id, idTwo, lstThree, e) => {
        setChkTwoDivision({ ...chkTwoDivision, [_id]: !chkTwoDivision[_id] })
        if (country.nroDivision == 3) checkedAllThreeDivision(idTwo, lstThree, e)
    }

    const changeCheckThreeDivision = (_id) => {
        setChkThreeDivision({ ...chkThreeDivision, [_id]: !chkThreeDivision[_id] })
    }

    const checkedAllTtwoDivision = async (idOne, lstTwo, e) => {
        let _checked = e.target.checked
        let _lstTwoDivision = await lstTwo.filter(el =>
            el.onedivision.id == idOne
        )
        let _chkTwoDivision = { ...chkTwoDivision }
        let _allDeliveryList = getAllDeliveryList()
        _lstTwoDivision.forEach((el, i) => {
            if (!_allDeliveryList.includes(el._id.trim())) _chkTwoDivision['chk2' + el.name.trim() + '_ID' + el._id] = _checked
        });
        setChkTwoDivision(_chkTwoDivision)
    }

    const checkedAllThreeDivision = async (idTwo, lstThree, e) => {
        let _checked = e.target.checked
        let _lstThreeDivision = await lstThree.filter(el =>
            el.twodivision.id == idTwo
        )
        let _chkThreeDivision = { ...chkThreeDivision }
        let _allDeliveryList = getAllDeliveryList()
        _lstThreeDivision.forEach((el, i) => {
            if (!_allDeliveryList.includes(el._id.trim())) _chkThreeDivision['chk3' + el.name.trim() + '_ID' + el._id] = _checked
        });
        setChkThreeDivision(_chkThreeDivision)
    }

    const checkedDivisionsTrue = () => {
        let _chk1Filtro = Object.keys(chkOneDivision).filter(function (key) {
            return chkOneDivision[key] === true;
        });
        let _chk2Filtro = Object.keys(chkTwoDivision).filter(function (key) {
            return chkTwoDivision[key] === true;
        });
        let _chk3Filtro = Object.keys(chkThreeDivision).filter(function (key) {
            return chkThreeDivision[key] === true;
        });
        if (country.nroDivision == 2) {
            setPricePerZone({ ...pricePerZone, delivery: [[_chk1Filtro], [_chk2Filtro]], deliveryList: [_chk2Filtro] })
        } else if (country.nroDivision == 3) {
            setPricePerZone({ ...pricePerZone, delivery: [[_chk2Filtro], [_chk3Filtro]], deliveryList: [_chk3Filtro] })
        }
    }

    const getPricePerZoneByID = async () => {
        const url = `https://ventafazil-strapi-production.up.railway.app/Priceperzones?id=${state.id}`
        const config = {
            headers: {
                'Authorization': `Bearer ${process.env.REACT_APP_KEY_STRAPI}`,
                'Content-Type': 'application/json',
            },
        }
        try {
            const response = await axios.get(url, config)
            const data = response.data[0]
            if (response.status == 200) {
                if (data) {
                    // console.log("DATA",data)
                    setPricePerZone({
                        rateName: data.ratename,
                        price: data.price,
                        delivery: data.delivery,
                        deliveryCondition: data.deliverycondition,
                        isDiscount: data.isdiscount,
                        discountPrice: data.discountprice,
                        idCliente: data.cliente._id,
                        deliveryList: data.deliverylist,
                    })
                    return data.delivery
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const savePricePerZone = async () => {
        let errorMessage = validate()
        const config = {
            headers: {
                'Authorization': `Bearer ${process.env.REACT_APP_KEY_STRAPI}`,
                'Content-Type': 'application/json'
            }
        }
        if (state?.id) {
            const url = `https://ventafazil-strapi-production.up.railway.app/Priceperzones/${state.id}`
            const data = {
                ratename: pricePerZone.rateName,
                price: Number(pricePerZone.price),
                delivery: pricePerZone.delivery,
                deliverycondition: pricePerZone.deliveryCondition,
                isdiscount: pricePerZone.isDiscount,
                discountprice: Number(pricePerZone.discountPrice),
                cliente: pricePerZone.idCliente,
                deliverylist: pricePerZone.deliveryList
            }
            if (Object.keys(errorMessage).length > 0) {
                toast(errorMessage)
            } else if (Object.keys(errorMessage).length == 0) {
                try {
                    const response = await axios.put(url, data, config)
                    if (response.status == 200) {
                        Swal.fire({
                            title: 'Se edito el precio por zona con exito ',
                            icon: 'success',
                            timer: 3000
                        }).then(() =>
                            history.push('/delivery')
                        )
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        } else {
            const url = 'https://ventafazil-strapi-production.up.railway.app/Priceperzones'
            const data = {
                ratename: pricePerZone.rateName,
                price: Number(pricePerZone.price),
                delivery: pricePerZone.delivery,
                deliverycondition: pricePerZone.deliveryCondition,
                isdiscount: pricePerZone.isDiscount,
                discountprice: Number(pricePerZone.discountPrice),
                cliente: pricePerZone.idCliente,
                deliverylist: pricePerZone.deliveryList,
                country: country.id
            }
            if (Object.keys(errorMessage).length > 0) {
                toast(errorMessage)
            } else if (Object.keys(errorMessage).length == 0) {
                try {
                    const response = await axios.post(url, data, config)
                    if (response.status == 200) {
                        Swal.fire({
                            title: 'Se guardo el precio por zona con exito ',
                            icon: 'success',
                            timer: 3000
                        }).then(() =>
                            history.push('/delivery')
                        )
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        }
    }

    useEffect(() => {
        getDivisionsXNumbersDivisions()
    }, [])

    useEffect(() => {
        if (!isGet) {
            checkedDivisionsTrue()
        }
    }, [chkTwoDivision, chkThreeDivision])

    return (
        <div className="pb-24 md:pb-30 ml:pb-44 lg:pb-24 w-fit md:w-full overflow-hidden">
            <NavigationTop />
            <NavigationSide />
            {
                loading ? (
                    <div className='flex items-center justify-center min-h-screen'>
                        <div className="border-t-transparent w-28 h-28 border-4 border-blue-200 rounded-full animate-spin"></div>
                        {/* <p class="ml-5 text-lg">Cargando...</p> */}
                    </div>
                ) : (
                    <div className='w-full Zcontainer py-8 px-5 lg:px-32 md:pl-44 lg:ml-16'>
                        <div className="w-full Zcontainer flex justify-between py-4">
                            <h1 className="text-3xl font-bold tracking-tighter">{shippingType.pricePerZone}</h1>
                            <button
                                className="py-2 px-10 font-bold text-center rounded-xl text-white"
                                style={{ background: '#8163FF' }}
                                onClick={savePricePerZone}
                            > {state?.isEdit ? 'Guardar' : 'Guardar'}
                            </button>
                        </div>
                        <div className="w-full Zcontainer py-4" id="shippingPolicy">
                            <div className="flex flex-col gap-10 w-full">
                                <div className='flex gap-10 2xl:gap-24 flex-col md:flex-row lg:flex-row'>
                                    <div className='flex flex-col md:w-3/6'>
                                        <label className='font-semibold'>Nombre</label>
                                        <div className='flex justify-center p-2 w-full h-14 bg-gray-200 rounded-lg'>
                                            <input
                                                className='px-2 w-full rounded-lg '
                                                name='rateName'
                                                type='text'
                                                placeholder='Ingrese el nombre de tarifa'
                                                value={pricePerZone.rateName}
                                                onChange={(e) => onChange(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className='flex flex-col md:w-3/6'>
                                        <label className='font-semibold'>Costo Delivery</label>
                                        <div className='flex justify-center p-2 w-full h-14 bg-gray-200 rounded-lg'>
                                            <div className='flex items-center px-2 w-full bg-white rounded-lg'>
                                                <label className='font-semibold '>
                                                    {currency}
                                                </label>
                                                <input
                                                    className='px-2 w-full rounded-lg'
                                                    name='price'
                                                    type='text'
                                                    placeholder='Ingrese costo de delivey'
                                                    value={pricePerZone.price}
                                                    onChange={(e) => onChange(e)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='w-full flex gap-5 md:gap-10 2xl:gap-24'>
                                    <Country
                                        isOnlyPrice={false}
                                        country={country}
                                        listOneDivision={listOneDivision}
                                        listTwoDivision={listTwoDivision}
                                        listThreeDivision={listThreeDivision}
                                        openOneDivision={openOneDivision}
                                        openTwoDivision={openTwoDivision}
                                        seeTwoDivision={seeTwoDivision}
                                        seeThreeDivision={seeThreeDivision}
                                        chkOneDivision={chkOneDivision}
                                        chkTwoDivision={chkTwoDivision}
                                        chkThreeDivision={chkThreeDivision}
                                        changeCheckOneDivision={changeCheckOneDivision}
                                        changeCheckTwoDivision={changeCheckTwoDivision}
                                        changeCheckThreeDivision={changeCheckThreeDivision}
                                        isDisabled={isDisabled}
                                    />
                                    <div className='w-full md:w-3/6 flex flex-col'>
                                        <div className='font-semibold'>Condiciones de envío</div>
                                        <div className='p-2 bg-gray-200 rounded-lg'>
                                            <textarea
                                                name='deliveryCondition'
                                                rows={10}
                                                className='w-full p-2 rounded-lg resize-none'
                                                value={pricePerZone.deliveryCondition}
                                                onChange={(e) => onChange(e)}
                                            />
                                        </div>
                                        <div className='flex justify-center'>
                                            <div>
                                                (!)
                                            </div>
                                            <div className='text-center'>
                                                Es importante incluir información como:<br />
                                                Despacho los martes y jueves<br />
                                                -Lima entre 24-48 giras<br />
                                                -Provincia 3-4 días hábiles
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="flex flex-col gap-4 md:pr-0 lg:pr-5 2xl:pr-11 md:w-full lg:w-3/6 Zbg-yellow-200">
                                    <div className='flex items-center'>
                                        <input
                                            className="mr-2 h-full"
                                            name='isDiscount'
                                            type={"checkbox"}
                                            checked={pricePerZone.isDiscount}
                                            value={pricePerZone.isDiscount}
                                            onChange={(e) => onChange(e)}
                                        />
                                        <label>
                                            Aplicar descuento de {currency}/0. por compras mayores a:
                                        </label>
                                    </div>
                                    <div className='w-full '>
                                        <div className="p-2 w-full rounded-lg bg-gray-200">
                                            <div className={`flex items-center w-full h-10 px-3 rounded-lg bg-white ${!pricePerZone.isDiscount ? 'opacity-30' : ''}`}>
                                                <label className="flex w-full font-semibold" >
                                                    {currency}
                                                    <input
                                                        className="px-2 w-full font-normal rounded-lg "
                                                        name='discountPrice'
                                                        type='text'
                                                        placeholder='Ingrese el descuento'
                                                        disabled={!pricePerZone.isDiscount}
                                                        value={pricePerZone.discountPrice}
                                                        onChange={(e) => onChange(e)}
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
}
export default PricePerZone