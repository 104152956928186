import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import moment from 'moment'
import React from 'react'
import NavigationSide from '../components/NavigationSide'
import NavigationTop from '../components/NavigationTop'
/* import UIHistorialPagos from "../components/UIHistorialPagos"; */
import { UserContext } from '../context/UserContext'

class Suscripcion extends React.Component {
  static contextType = UserContext

  constructor(props) {
    super(props)
    this.state = {
      subscription: null,
      card: {
        object: 'card',
        id: 'crd_live_1rgkYdKvLJCqOOyu',
        active: true,
        creation_date: 1648833446000,
        customer_id: 'cus_live_UuB6oTWGkcMMBaA0',
        source: {
          object: 'token',
          id: 'tkn_live_nM6VHx8OrdMpm1YW',
          type: 'card',
          creation_date: 1648833439000,
          email: 'atencion.alcliente.izipay@gmail.com',
          card_number: '463401******4167',
          last_four: '4167',
          active: true,
          iin: {
            object: 'iin',
            bin: '463401',
            card_brand: 'Visa',
            card_type: 'credito',
            card_category: 'Clásica',
            issuer: {
              name: 'BCP',
              country: 'PERU',
              country_code: 'PE',
              website: null,
              phone_number: null,
            },
            installments_allowed: [
              2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 15, 18, 21, 24, 36, 48,
            ],
          },
          client: {
            ip: '190.233.207.4',
            ip_country: null,
            ip_country_code: null,
            browser: 'Chrome-99.0.4844.74',
            device_fingerprint: 'd2d9d76603b95c5df89badc4263261d7',
            device_type: 'Escritorio',
          },
          metadata: {
            installments: '1',
          },
        },
        metadata: {},
      },
      cargos: [
        {
          duplicated: false,
          object: 'charge',
          id: 'chr_live_fu3ip0JeFunVCCcM',
          creation_date: 1648833446744,
          amount: 24780,
          amount_refunded: 0,
          current_amount: 24780,
          installments: 0,
          installments_amount: null,
          currency_code: 'PEN',
          email: 'atencion.alcliente.izipay@gmail.com',
          description: null,
          source: {
            object: 'card',
            id: 'crd_live_1rgkYdKvLJCqOOyu',
            active: true,
            creation_date: 1648833446000,
            customer_id: 'cus_live_UuB6oTWGkcMMBaA0',
            source: {
              object: 'token',
              id: 'tkn_live_nM6VHx8OrdMpm1YW',
              type: 'card',
              creation_date: 1648833439000,
              email: 'atencion.alcliente.izipay@gmail.com',
              card_number: '463401******4167',
              last_four: '4167',
              active: true,
              iin: {
                object: 'iin',
                bin: '463401',
                card_brand: 'Visa',
                card_type: 'credito',
                card_category: 'Clásica',
                issuer: {
                  name: 'BCP',
                  country: 'PERU',
                  country_code: 'PE',
                  website: null,
                  phone_number: null,
                },
                installments_allowed: [
                  2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 15, 18, 21, 24, 36, 48,
                ],
              },
              client: {
                ip: '190.233.207.4',
                ip_country: null,
                ip_country_code: null,
                browser: 'Chrome-99.0.4844.74',
                device_fingerprint: 'd2d9d76603b95c5df89badc4263261d7',
                device_type: 'Escritorio',
              },
              metadata: {
                installments: '1',
              },
            },
            metadata: {},
          },
          outcome: {
            type: 'venta_exitosa',
            code: 'DEP0000',
            merchant_message:
              'La operación de venta ha sido autorizada exitosamente',
          },
          fraud_score: 3.0,
          antifraud_details: {
            first_name: 'SILKE YANET',
            last_name: 'HUAMANI URQUIZO',
            address: 'calle los higos 163',
            address_city: 'LIMA',
            country_code: 'PE',
            phone: '981349877',
            object: 'client',
          },
          dispute: false,
          capture: true,
          reference_code: '92665296220',
          authorization_code: '003422',
          metadata: {},
          total_fee: 1228,
          fee_details: {
            fixed_fee: {
              amount: 30,
              currency_code: 'USD',
              exchange_rate: 3,
              exchange_rate_currency_code: 'PEN',
              total: 112,
            },
            variable_fee: {
              currency_code: 'PEN',
              commision: 0.042,
              total: 1041,
            },
          },
          total_fee_taxes: 187,
          transfer_amount: 23552,
          paid: false,
          statement_descriptor: 'CULQI*',
          transfer_id: null,
          capture_date: 1648833447000,
        },
      ],
      plan: [
        {
          object: 'subscription',
          id: 'sub_test_St4RzR1gZGtrGcwP',
          creation_date: 1648322316000,
          status: 'Activa',
          current_period: 0,
          total_period: null,
          current_period_start: 1648270800000,
          current_period_end: 1650949200000,
          cancel_at_period_end: false,
          cancel_at: null,
          ended_at: null,
          next_billing_date: 1648270800000,
          trial_start: 1648322316000,
          trial_end: 1648322316000,
          charges: [],
          plan: {
            object: 'plan',
            id: 'pln_test_xi4fiTn7pRnIQhHt',
            creation_date: 1648151789000,
            name: 'Ventafacil Mensual Subscription',
            amount: 24780,
            currency_code: 'PEN',
            interval_count: 1,
            interval: 'Meses',
            limit: 0,
            trial_days: 0,
            total_subscriptions: 1,
            metadata: {
              descripcion: 'Plan inicial- suscripcion mensual',
              cuota: '1',
              userid: '1234567890',
            },
          },
          card: {
            object: 'card',
            id: 'crd_test_rw23jbtQWOIcDR3V',
            active: true,
            creation_date: 1648322123000,
            customer_id: 'cus_test_z1DJ5UtcWFxce1ke',
            source: {
              object: 'token',
              id: 'tkn_test_R92Ntlzz5vH0BLyR',
              type: 'card',
              creation_date: 1648321971000,
              email: 'victory@gmail.com',
              card_number: '411111******1111',
              last_four: '1111',
              active: true,
              iin: {
                object: 'iin',
                bin: '411111',
                card_brand: 'Visa',
                card_type: 'credito',
                card_category: 'Clásica',
                issuer: {
                  name: 'BBVA',
                  country: 'PERU',
                  country_code: 'PE',
                  website: null,
                  phone_number: null,
                },
                installments_allowed: [2, 4, 6, 8, 10, 12, 3, 5, 7, 9, 24, 48],
              },
              client: {
                ip: '190.237.122.14',
                ip_country: 'Peru',
                ip_country_code: 'PE',
                browser: 'Chrome-99.0.4844.82',
                device_fingerprint: 'b40cc2a20c77759597d80d64e35b817f',
                device_type: 'Escritorio',
              },
              metadata: {
                installments: '1',
              },
            },
            metadata: {},
          },
          metadata: {},
        },
      ],
    }
  }

  getSuscription() {
    let config = {
      method: 'get',
      url: 'https://api.culqi.com/v2/subscriptions/' + this.state.subscription,
      headers: { Authorization: 'Bearer sk_live_543a308594f4c66e' },
    }
    axios(config)
      .then((response) => {
        this.setState({ plan: response.data })
        this.setState({ cargos: response.data.charges })
        this.setState({ card: response.data.card })
      })
      .then(() => {
        //console.log(this.state.card.id)
      })
      .catch((error) => {
        //console.log(error)
      })
  }

  componentDidMount() {
    const user = this.context.user
    let config = {
      method: 'get',
      url:
        `https://ventafazil-strapi-production.up.railway.app/clientes/` + user,
      headers: {},
    }
    axios(config)
      .then((response) => {
        this.setState({ subscription: response.data.culqisub })
      })
      .then(() => {
        this.getSuscription()
      })
      .catch((error) => {
        //console.log(error)
      })
  }
  render() {
    return (
      <div className='bg-gray-200 '>
        <NavigationTop />
        <NavigationSide />

        <div className='container flex flex-col gap-2 my-8 md:ml-20'>
          <div className='mt-8 mb-6 text-3xl font-semibold tracking-tighter'>
            Información de tu Facturación
          </div>
          <div className='text-lg font-semibold tracking-tighter text-gray-500'>
            TU SUSCRIPCIÓN
          </div>
          <div className='bg-white rounded-lg '>
            <div className='p-6 bg-green-100'>
              <div className='flex items-center justify-between mb-1'>
                <div className='text-2xl font-semibold'>Plan Mensual</div>
                <div className='p-2 px-4 text-white bg-green-700 rounded'>
                  estado: {this.state.plan.status}{' '}
                </div>
              </div>
              <div className='flex flex-row text-sm tracking-tight text-gray-700 gp-2 '>
                <div>{this.state.plan.id}</div>
              </div>
              <div className='flex flex-row'>
                Fecha de creación:{' '}
                {moment(this.state.plan.creation_date).format('DD/MM/YYYY')}
              </div>
              <div className='flex items-center gap-2 text-gray-700 md:flex-row'>
                <div className='text-xs font-bold uppercase'>
                  periodo: {this.state.plan.current_period}
                </div>
                <div>
                  fecha de inicio:{' '}
                  {moment(this.state.plan.current_period_start).format(
                    'DD/MM/YYYY'
                  )}
                </div>
                <div>
                  fecha de fin:{' '}
                  {moment(this.state.plan.current_period_end).format(
                    'DD/MM/YYYY'
                  )}
                </div>
              </div>
            </div>
            <div className='p-6'>
              <div className='flex flex-row items-center gap-2 tracking-tighter'>
                <div className='text-lg '>Próxima fecha de facturación:</div>
                <div className='items-end text-lg font-semibold'>
                  Último día de pago:{' '}
                  {moment(this.state.plan.next_billing_date).format(
                    'DD/MM/YYYY'
                  )}{' '}
                </div>
              </div>
              <div></div>
            </div>
          </div>
          <div className='py-4 text-xs font-light text-gray-500 '>
            Las cuotas se facturan a principio de cada periodo y podrían
            aparecer en tu cuenta algunos días después de la fecha de
            facturación
          </div>
        </div>

        <div className='container flex flex-col gap-2 py-12 my-8 md:ml-20'>
          <div className='p-10 bg-white rounded-lg'>
            <div className='mb-3 text-lg font-semibold tracking-tighter text-gray-500'>
              TUS TARJETAS
            </div>
            <div className='flex flex-row gap-2 pb-8'>
              <div className='relative flex flex-col justify-between h-48 p-6 overflow-hidden transition-all bg-black border-t border-l border-r border-white hover:shadow-lg hover:-translate-y-1 w-80 rounded-2xl bg-gradient-to-t from-gray-900'>
                <div className='flex flex-row items-center justify-between'>
                  <div className='text-2xl font-semibold text-yellow-200'>
                    Ventafacil
                  </div>
                  <div className='p-1 px-2 text-xs text-white bg-green-700 rounded-full '>
                    status:{' '}
                    {this.state.card.source.active ? 'activo' : 'desactivado'}
                  </div>
                </div>
                <div>
                  <div className='text-white'>
                    {this.state.card.source.card_number}
                  </div>
                  <div className='text-white shadow-sm text-xxs opacity-70'>
                    {this.state.card.id}{' '}
                  </div>
                </div>
                <div className='flex flex-row justify-between text-xs font-semibold text-white opacity-30'>
                  <div>
                    {this.state.card.source.iin.card_brand}{' '}
                    {this.state.card.source.iin.card_type}
                  </div>
                  <div>{this.state.card.source.iin.issuer.name}</div>
                </div>
                <div className='absolute top-0 left-0 flex items-center justify-center w-full h-full font-semibold transition-all bg-yellow-200 opacity-0 cursor-pointer hover:opacity-90'>
                  <FontAwesomeIcon icon={faEdit} /> Editar
                </div>
              </div>
              {/* <div className="flex flex-col items-center justify-center h-48 p-6 transition-all bg-gray-400 border-t border-l border-r border-white cursor-pointer hover:-translate-y-1 hover:bg-gray-300 opacity-90 hover:opacity-100 hover:shadow-lg w-80 rounded-2xl hover:from-gray-200 bg-gradient-to-t from-gray-300">
                                <div className="font-semibold opacity-50">agregar tarjeta + </div>
                            </div> */}
            </div>

            <div className='pb-2 text-lg font-semibold text-gray-500 uppercase border-b border-gray-400'>
              Historial de Pagos
            </div>
            <div className='flex flex-col py-8'>
              <div className='flex flex-row justify-between py-4 bg-white border-b border-gray-300 rounded-xl'>
                <div>
                  <div className='font-semibold'>Fecha</div>
                  creation dte{' '}
                  {moment(this.state.cargos[0].creation_date).format(
                    'DD/MM/YYYY'
                  )}
                  <div className='text-sm font-semibold tracking-tighter opacity-40'>
                    {this.state.cargos[0].id}
                  </div>
                </div>
                <div>
                  <div className='font-semibold'>Forma de Pago</div>
                  <div>
                    {this.state.cargos[0].source.source.iin.card_brand}{' '}
                    {this.state.cargos[0].source.source.iin.card_type}{' '}
                  </div>
                  <div>{this.state.cargos[0].source.source.card_number} </div>
                </div>
                <div>
                  <div className='font-semibold'>Periodo de Servicio</div>
                  {moment(this.state.cargos[0].creation_date).format(
                    'DD/MM/YYYY'
                  )}
                </div>
                <div>
                  <div className='font-semibold '>Total</div>
                  <div>
                    S/.{(this.state.cargos[0].amount * 0.01).toFixed(2)}
                  </div>
                  <div>
                    fecha de captura:{' '}
                    {moment(this.state.cargos[0].capture_date).format(
                      'DD/MM/YYYY'
                    )}
                  </div>
                </div>
                <div className='flex flex-col items-center justify-center'>
                  <div className='font-semibold'>Estado</div>
                  {this.state.cargos[0].capture ? (
                    <div className='p-2 px-3 text-white bg-green-500 rounded-full'>
                      capturado
                    </div>
                  ) : (
                    'no capturado'
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Suscripcion
