import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { UserContextProvider } from './context/UserContext'
import { LanguageProvider } from './context/languageContext'
import { CurrencyProvider } from './context/CurrencyContext'
import AppNavigation from './navigation/AppNavigation'
// dotenv.config()

/* estas en BETA */
ReactDOM.render(
  <React.StrictMode>
    <UserContextProvider>
      <LanguageProvider>
        <CurrencyProvider>
          <AppNavigation />
        </CurrencyProvider>
      </LanguageProvider>
    </UserContextProvider>
  </React.StrictMode>
  ,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
