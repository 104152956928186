import React, { useState, useEffect, useContext } from 'react'
import NavigationTop from '../components/NavigationTop'
import NavigationSide from '../components/NavigationSide'
import UIBackofficeHeader from '../components/UIBackofficeHeader'
import GeneralInformation from '../components/store/general-information/GeneralInformation'
import Design from '../components/store/design/Design'
import SocialNetworks from '../components/store/social-networks/SocialNetworks'
import Seo from '../components/store/seo/Seo'
import Coupon from '../components/store/coupon/Coupon'

const storeOptions = [
  {
    id: 1,
    name: 'Información general',
    component: <GeneralInformation data='data' />,
  },
  {
    id: 2,
    name: 'Diseño',
    component: <Design data='data' />,
  },
  {
    id: 3,
    name: 'Redes Sociales',
    component: <SocialNetworks data='data' />,
  },
  {
    id: 4,
    name: 'SEO',
    component: <Seo data='data' />,
  },
  {
    id: 5,
    name: 'Cupones',
    component: <Coupon />,
  },
]

function Store() {
  const [selectedOption, setSelectedOption] = useState({
    option1: true,
  })

  const ViewByOption = () => {
    return (
      <>
        {storeOptions.map((el, index) => {
          return (
            <div
              className={`w-full ${
                el.name === 'Cupones' ? 'lg:w-10/12' : 'lg:w-10/12'
              } `}
              // style={{ width: '75%' }}
              key={index + el.name}
            >
              {selectedOption['option' + el.id] && el.component}
            </div>
          )
        })}
      </>
    )
  }

  return (
    <div className='pb-24 w-fit lg:w-full zoverflow-hidden'>
      <NavigationTop />
      <NavigationSide />
      <div
        className='md:ml-28 md:mt-5 Zmd:w-11/12'
        // style={{ width: '90%' }}
      >
        <UIBackofficeHeader title='Tienda' />
        <div className='w-fit lg:w-full flex flex-col md:pl-20 lg:pl-28'>
          <div
            className='w-11/12 lg:w-3/5 flex justify-center lg:justify-evenly p-5 mx-3 md:mx-0 rounded-full'
            style={{ background: '#F0F0F0' }}
          >
            <ul className='flex gap-7 overflow-x-auto'>
              {storeOptions.map((el) => (
                <button
                  className=''
                  key={'_' + el.id + el.name}
                  onClick={() =>
                    setSelectedOption({ ['option' + el.id]: true })
                  }
                >
                  <li
                    className='w-max px-5 py-2 rounded-full font-semibold'
                    style={
                      selectedOption['option' + el.id]
                        ? { background: '#8163FF', color: '#FFFFFF' }
                        : { background: '#D9D9D9', color: '#787878' }
                    }
                    key={el.id + '_' + el.name}
                  >
                    {el.name}
                  </li>
                </button>
              ))}
            </ul>
          </div>
          <ViewByOption />
        </div>
      </div>
    </div>
  )
}

export default Store
