import React from 'react'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function Modal({ setOpenModal, children, onClick, onCancelar }) {
  return (
    <>
      <div className='fixed inset-0 z-10 overflow-y-auto'>
        <div
          className='fixed inset-0 w-full h-full bg-black opacity-40'
          // onClick={() => setOpenModal(false)}
        ></div>
        <div className='flex items-center min-h-screen px-4 py-8'>
          <div className='relative w-full max-w-2xl   p-4 mx-auto bg-white rounded-md shadow-lg'>
            {children}
            <div className='items-center gap-2 mt-3 sm:flex'>
              <button
                className='w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 '
                onClick={onCancelar}
              >
                Cancelar
              </button>
              <button
                className='w-full mt-2 p-2.5 flex-1  bg-yellow-400  rounded-md outline-none ring-offset-2 '
                onClick={onClick}
              >
                Aplicar
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
