import Subscription from '../components/payments/subscription/Subscription'
import PaymentMethods from '../components/payments/payment-methods/PaymentMethods'
import { useState } from 'react'

function Payments() {
  return (
    // <Subscription />
    <>
      <PaymentMethods />
    </>
  )
}

export default Payments
