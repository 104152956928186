import { createContext, useState } from 'react'

const CurrencyContext = createContext()

let initialValue
let _currency
if (localStorage.getItem('user')) {
  _currency = JSON.parse(localStorage.user)?.currencyvalue?.split(',')[0]
}
if (_currency == 'S/') {
  initialValue = 'S/'
} else if (_currency == '$') {
  initialValue = '$'
} else initialValue = '$'

const CurrencyProvider = ({ children }) => {
  const [currency, setCurrency] = useState(initialValue)

  const changeCurrency = (val) => {
    let _val
    if (val) {
      // _val = JSON.parse(val)?.currencyvalue?.split(',')[0]
      _val = val.split(',')[0]
    }
    switch (_val) {
      case 'S/':
        setCurrency(_val)
        break
      case '$':
        setCurrency(_val)
        break
      default:
        setCurrency('$')
    }
  }

  const data = { currency, changeCurrency }
  return (
    <CurrencyContext.Provider value={data}>{children}</CurrencyContext.Provider>
  )
}

export { CurrencyContext, CurrencyProvider }
