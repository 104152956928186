let currencyFromStrapi = (price) => {
    let newprice = price.substring(0, price.length - 2) + '.' + price.slice(-2)
    return newprice
}
let getPublicId = (beforeUrl) => {
    var reg = /^((http[s]?|ftp):\/)?\/?([^:\/\s]+)((\/\w+)*\/)([\w\-\.]+[^#?\s]+)(.*)?(#[\w\-]+)?$/;
    let str = beforeUrl;
    let attributes = reg.exec(str);
    let image = attributes[6];
    let index = image.indexOf(".");
    let publicID = image.substring(0, index);
    return publicID
}
const parseDate = (date) => {
    let date1 = new Date(date).toLocaleDateString('ES')
    let date2 = new Date(date).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', })
    return date1 + ' ' + date2
}
export { currencyFromStrapi, getPublicId, parseDate }