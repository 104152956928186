function Input1({ key, classNameDiv, classNameLabel, labelTextColor, label, className, type, name, placeholder, value, onChange, disabled }) {
    return (
        <div className={`flex flex-col gap-2 ${classNameDiv ? classNameDiv : 'w-full'}`} key={key}>
            <label className={classNameLabel ? classNameLabel : 'font-bold'} style={labelTextColor ? { color: labelTextColor } : { color: '#8C8C8C' }}>
                {label}
            </label>
            <input
                className={`px-4 py-3 rounded-xl bg-white ${className ? className : 'w-7/12'}`}
                style={{ border: '2px solid #D6D6D6' }}
                type={type ? type : 'text'}
                placeholder={placeholder}
                name={name}
                value={value}
                onChange={(e) => onChange(e)}
                disabled={disabled}
            />
        </div>
    )
}

export default Input1