import React from 'react'
import ReactApexChart from 'react-apexcharts'

export class PromedioPedido extends React.Component {
  constructor(props) {
    super(props)

    // this.state = {
    //   series: [
    //     {
    //       name: 'Promedio de Ventas',
    //       data: [10, 2, 3],
    //     },
    //   ],
    //   options: {
    //     chart: {
    //       type: 'bar',
    //       height: 350,
    //     },
    //     plotOptions: {
    //       bar: {
    //         horizontal: false,
    //         columnWidth: '55%',
    //         endingShape: 'rounded',
    //       },
    //     },
    //     dataLabels: {
    //       enabled: false,
    //     },
    //     stroke: {
    //       show: true,
    //       width: 2,
    //       colors: ['transparent'],
    //     },
    //     xaxis: {
    //       categories: this.props.dateX,
    //     },
    //     yaxis: {
    //       title: {
    //         text: 'Promeio Pedido',
    //       },
    //     },
    //     fill: {
    //       opacity: 1,
    //     },
    //     tooltip: {
    //       y: {
    //         formatter: function (val) {
    //           return 'S/ ' + val + ' '
    //         },
    //       },
    //     },
    //   },
    // }

    this.state = {
      series: [
        {
          name: 'Promedio Pedido',
          data: this.props.dateY,
        },
      ],
      options: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'straight',
        },

        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },

        xaxis: {
          categories: this.props.dateX,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return props.currency + ' ' + val + ' '
            },
          },
        },

        colors: ['#8163FF', '#008000'],
      },
    }
  }

  render() {
    //console.log('onstructor', this.props.dateY)

    return (
      <div id='chart'>
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type='line'
          height={350}
        />
      </div>
    )
  }
}
