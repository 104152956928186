import UIBackofficeTableCell from './UIBackofficeTableCell'
import axios from 'axios'
import { UserContext } from '../context/UserContext'
import NavigationCategories from './NavigationCategories'

// export default class UIBackofficeCategoriesFilter extends Component {
//   constructor(props) {
//     super(props)
//     this.state = {
//       api: [
//         /* de aqui pintas carmen CARMEN*/
//         {
//           imagenproducto:
//             'https://images.pexels.com/photos/302896/pexels-photo-302896.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
//           _id: '62301be685189554f0d98cd6',
//           nombreproducto: 'Specialty Coffee Yana Muru House Blend ',
//           categoriaproducto: 'Cafe Molido',
//           precio: '22.90',
//           codigoproducto: 'SCYMHB',
//           detalladoproducto:
//             'Cafe de Especialidad Notas a Cascara de Mandarina, con fondo a Chocolate Acidez Suave | Cuerpo Medio Puntaje en Taza: 81 pts. Origen: Cajamarca, San Martin, Ayacucho. Variedad: Catimor, Catuai. Elevación: 1200 msnm. Secado: FitoToldo. Control de Calidad y Catado por:  Gracia Maria Ampuero Estremadoyro Lic Q-Grader.',
//           published_at: '2022-03-15T05:59:59.346Z',
//           createdAt: '2022-03-15T04:53:58.405Z',
//           updatedAt: '2022-03-18T05:08:44.108Z',
//           __v: 0,
//           cliente: {
//             _id: '6220472247620f3dd40ed393',
//             nombrecomercio: 'YANA MURU Café Peruano',
//             published_at: '2022-03-03T04:49:01.963Z',
//             createdAt: '2022-03-03T04:42:10.989Z',
//             updatedAt: '2022-03-03T04:49:02.692Z',
//             __v: 0,
//             Nombre: 'VICTOR HUGO ',
//             apelido: 'GALARRETA AMPUERO',
//             ciudad: 'LIMA-SAN BORJA',
//             confirmed: true,
//             correoelectronico: 'v.galarreta93@gmail.com',
//             direccionfiscal: 'JIRON FRAY ANGELICO 487 DPTO 402',
//             numerodedocumento: '10703115794',
//             region: 'LIMA',
//             telefono: '935394215',
//             tipodedocumento: 'RUC10 CN',
//             id: '6220472247620f3dd40ed393',
//           },
//           id: '62301be685189554f0d98cd6',
//         },
//       ],
//       products: [
//         {
//           category: 'Burguer, promociones',
//           description: 'dieliciosa burger de carne de res , clasica',
//           featured: 'TRUE',
//           keywords: 'carne, fullmeat',
//           photo:
//             'https://images.unsplash.com/photo-1550547660-d9450f859349?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=765&q=80',
//           productname: 'Burguer',
//           productprice: '32',
//           productslug: 'burguer',
//           udid: '1233',
//         },
//       ],
//     }
//   }

//   componentDidMount() {
//     let config = {
//       method: 'get',
//       url:
//         `https://ventafazil-strapi-production.up.railway.app/categorias/` +
//         this.props.category,
//       headers: {},
//     }

//     axios(config)
//       .then((response) => {
//         //console.log(response.data)
//         this.setState({ products: response.data.productos })
//       })
//       .catch((error) => {
//         //console.log(error)
//       })
//   }

//   render() {
//     return (
//       <div className='flex flex-col items-start w-full gap-2'>
//         <NavigationCategories />
//         cagada
//         {this.state.products.map((product, index) => {
//           return (
//             <UIBackofficeTableCell
//               category={product.categoriaproducto}
//               id={product.id}
//               codigo={product.codigoproducto}
//               nombre={product.nombreproducto}
//               image={product.imagenproducto}
//               description={product.detalladoproducto}
//               variables={product.variables}
//               price={product.precio}
//               extras={product.extras}
//               stock={product.stock}
//               key={index}
//             />
//           )
//         })}
//       </div>
//     )
//   }
// }

import React, { useEffect, useState } from 'react'

const UIBackofficeCategoriesFilter = (props) => {
  const [products, setProducts] = useState([])
  const [filtered_Products, setFiltered_Products] = useState([])
  const [sort, setSort] = useState('price-lowest')
  const [filters, setFilters] = useState({ text: '', category: 'all' })
  const [categories, setCategories] = useState([])
  const [sinStock, setSinStock] = useState(false)

  const { text } = filters

  const consultarApi = async () => {
    let config = {
      method: 'get',
      url:
        `https://ventafazil-strapi-production.up.railway.app/categorias/` +
        props.category,
      headers: {},
    }

    try {
      const { data } = await axios(config)
      setFiltered_Products(data.productos)
      setProducts(data.productos)
      //console.log(data.productos)
    } catch (error) {
      //console.log(error)
    }
  }

  useEffect(() => {
    consultarApi()
  }, [])

  const updateSort = (e) => {
    const value = e.target.value

    setSort(value)
  }

  const sortProducts = (e) => {
    let tempProducts = [...filtered_Products]
    //console.log('robannddd')

    if (sort === 'price-highest') {
      // tempProducts = tempProducts.sort((a, b) => b.precio + a.precio)
      tempProducts = tempProducts.sort((a, b) => {
        return b.precio + a.precio
      })
      //console.log(tempProducts)
      //console.log('price highest')
    }

    if (sort === 'price-lowest') {
      tempProducts = tempProducts.sort((a, b) => a.precio - b.precio)
      //console.log(tempProducts)

      //console.log('price lowest')
    }

    if (sort === 'name-a') {
      tempProducts = tempProducts.sort((a, b) => {
        return a.nombreproducto.localeCompare(b.nombreproducto)
      })
      //console.log(tempProducts)

      //console.log('name-a')
    }

    if (sort === 'name-z') {
      tempProducts = tempProducts.sort((a, b) => {
        return b.nombreproducto.localeCompare(a.nombreproducto)
      })

      //console.log('name-z')
    }

    setFiltered_Products(tempProducts)

    //console.log(tempProducts)
  }

  const updateFilters = (e) => {
    let name = e.target.name
    let value = e.target.value

    if (name === 'sinStock') {
      setSinStock(!sinStock)
    }
    setFilters({ ...filters, [name]: value })
  }

  const filterProducts = (e) => {
    let tempProducts = [...products]
    //console.log('ENTRAMOS')
    //console.log(sinStock)
    // filtering
    // text
    if (text) {
      //console.log('newTexr')
      tempProducts = tempProducts.filter((product) =>
        product.nombreproducto.toLowerCase().startsWith(text.toLowerCase())
      )
    }
    if (sinStock) {
      //console.log('TER', sinStock)
      tempProducts = tempProducts.filter((product) => product.stock == 0)

      setFiltered_Products(tempProducts)
    }
    //console.log('NOS VAMOS DE LARGO')

    setFiltered_Products(tempProducts)
  }

  useEffect(() => {
    filterProducts()
  }, [filters])

  useEffect(() => {
    sortProducts()
  }, [products, sort])

  return (
    <div className='flex flex-col items-start w-full gap-2'>
      <NavigationCategories />
      <div className='flex w-full  justify-between pr-11 '>
        <form onSubmit={(e) => e.preventDefault()}>
          <input
            className='w-40'
            type='text'
            name='text'
            placeholder='Search'
            value={text}
            onChange={updateFilters}
          />
        </form>
        <div className='form-control shipping'>
          <label htmlFor='sinStock'>Sin Stock</label>
          <input
            type='checkbox'
            name='sinStock'
            id='sinStock'
            checked={sinStock}
            onChange={updateFilters}
          />
        </div>
        <form>
          <label htmlFor='sort'>Ordenar :</label>

          <select name='sort' id='sort' value={sort} onChange={updateSort}>
            <option value='price-lowest'>Precio Ascendente</option>
            <option value='price-highest'>Precio Descendente</option>
            <option value='name-a'>Nombre A - Z</option>
            <option value='name-z'>Nombre Z - A</option>
          </select>
        </form>
      </div>
      {filtered_Products &&
        filtered_Products.map((product, index) => {
          return (
            <UIBackofficeTableCell
              consultarAPi={consultarApi}
              istrending={product.istrending}
              category={product.categoriaproducto}
              id={product.id}
              codigo={product.codigoproducto}
              nombre={product.nombreproducto}
              image={product.imagenproducto}
              description={product.detalladoproducto}
              variables={product.variables}
              price={product.precio}
              extras={product.extras}
              stock={product.stock}
              key={product._id}
            />
          )
        })}
    </div>
  )
}

export default UIBackofficeCategoriesFilter
