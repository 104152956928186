import React from 'react'
import { Link } from 'react-router-dom'

function UIBackofficeVentaTableCell(props) {
  return (
    <div className="flex bg-white my-4 md:flex-row flex-col items-center gap-2 p-4 transition-all saturate-0 hover:saturate-100 transform duration-500  rounded-md cursor-pointer after:duration-500  hover:shadow-xl hover:-translate-y-1 after:-ml-3 after:opacity-0 after:text-3xl hover:after:ml-2 hover:after:transition-all hover:after:opacity-100 md:after:content-['>'] hover:after:text-green-500 hover:bg-white cursor">
      <div className='flex flex-col font-semibold tracking-tight grow text-1xl'>
        <div>Orden</div>
        <div>{props.id}</div>
        <div
          className='flex flex-col w-full h-full text-xs tracking-tight placeholder-black opacity-50 placeholder:font-bold'
          type='text'
        >
          fecha{props.createdAt}
        </div>
      </div>
      <div className='flex flex-col justify-end'>
        <div
          className='text-2xl font-semibold tracking-tighter text-green-500 '
          type='text'
        >
          S/ {props.total}
        </div>
      </div>
      <a
        className='flex self-center justify-center p-2 px-3 font-bold uppercase transition bg-white border-4 border-gray-400 rounded-full text-xxs hover:border-yellow-400'
        href={`/venta/${props.id}`}
      >
        ver más
      </a>
    </div>
  )
}

export default UIBackofficeVentaTableCell
