import axios from 'axios'

export const PutVentasAPI = async (
  saleID,
  estadoventa,
  comment,
  isreembolsado,
  reembolsado,
  estado
) => {
  let body = JSON.stringify({
    estadoventa,
    comment,
    isreembolsado,
    reembolsado,
    estado,
  })

  try {
    const { data } = await axios.put(
      'https://ventafazil-strapi-production.up.railway.app/ventas/' + saleID,
      body,
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_KEY_STRAPI}`,

          'Content-Type': 'application/json',
        },
      }
    )
    return data
  } catch (error) {
    //console.log(error)
  }
}
