import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import HomeGraphics from '../components/HomeGraphics'
import NavigationSide from '../components/NavigationSide'
import NavigationTop from '../components/NavigationTop'
import UIBackofficeTable from '../components/UIBackofficeTable'
import UIBackofficeVentaTable from '../components/UIBackofficeVentaTable'
import VentaFazilLoginForm from '../components/VentaFazilLoginForm'
import { UserContext } from '../context/UserContext'

const Home = (props) => {
  // console.log('PRUEBA DE STRAPPIII', process.env)
  // console.log('PRUEBA DE fff', process.env.development)
  // console.log('PRUEBA DE xssd', process.env.production)

  //ppW
  const { user, setUser } = useContext(UserContext)
  let _user = JSON.parse(localStorage.getItem('user'))
  const [logged, setLogged] = useState([])

  return (
    <div className='pb-24 w-fit md:w-full'>
      <NavigationTop
        storeurl={logged.url}
        username={logged.Nombre}
        userid={logged.correoelectronico}
      // userlogo='../images/user1.png'
      />
      <NavigationSide />
      <div className='w-auto pb-24 ml-0 md:ml-48 py-10 px-5 md:pl-0 md:pr-5'>
        <div className='font-semibold text-xl'>
          Bienvenido,<span style={{ color: '#8163FF' }}>{_user?.Nombre}</span>
        </div>
        <h2 className='mb-6 pt-3 text-2xl font-semibold tracking-tighter md:text-3xl'>
          Vista general
        </h2>
        <HomeGraphics />
      </div>
    </div>
  )
}

/* rebuild */
export default Home
