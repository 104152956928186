import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'

function HelpBody({ handleSearchWord = () => { }, listItem = [], selectedMainIndex = '', selectedSubIndex = '' }) {
    return (
        <div className='w-8/12 md:pl-20 lg:pl-28 flex flex-col gap-10'>
            <h1 className='text-4xl font-semibold'>¿Cómo podemos ayudarte ? </h1>
            <div className='w-full flex justify-between items-center gap-5 '>
                <div
                    className='w-full flex justify-center items-center border rounded-lg py-1 px-2'
                    style={{ borderColor: '#A0A0A0' }}
                >
                    <FontAwesomeIcon className='text-gray-400' icon={faMagnifyingGlass} />
                    <input
                        type='text'
                        placeholder='Busca el tema que necesitas...'
                        className='w-full outline-none p-2'
                        onChange={(e) => handleSearchWord(e)}
                    />
                </div>
            </div>
            <div className='flex flex-col gap-5'>
                <h2 className='flex items-center gap-2 text-lg font-semibold'>{selectedMainIndex}
                    <span className='text-3xl pt-0.5'>{'>'}</span>
                    <span style={{ color: '#8163FF' }}>{selectedSubIndex}</span>
                </h2>
                {listItem.map((el, index) => {
                    let srcImageIndex = -1
                    return (<div className='' style={{ color: '#5A5A5A' }} key={index + '_article'}>
                        <h3 className='font-semibold text-lg'>{el.title}</h3>
                        {el.paragraph.split('/n').map((line, subindex) => {
                            if (line.includes('<IMAGE>')) {
                                srcImageIndex++
                                return <img src={el.imageSrc[srcImageIndex]} key={subindex + '_image'} />
                            } else {
                                return (
                                    <p key={subindex + '_paragraph'}>
                                        {line}
                                        <br />
                                    </p>
                                )
                            }
                        })}
                    </div>)
                })}
            </div>
        </div>
    )
}

export default HelpBody