import React from 'react'
import InputLabel from '../components/InputLabel'

const MethodMexicoClip = ({ onChange, disabled, dataKeychain }) => {
  return (
    <>
      <p className='font-bold'>
        Aqui coloca los keys de Clip que se encuentran en tu cuenta “Mi Cuenta
        web”
      </p>
      <div className='lg:min-w-[500px] lg:w-5/12 flex flex-col gap-12'>
        <div className='flex flex-col md:flex-row gap-2 md:gap-5'>
          <InputLabel
            labelName={'API_KEY'}
            name='userapirest'
            value={dataKeychain.userapirest}
            onChange={onChange}
            disabled={disabled}
          />
          <InputLabel
            labelName={'Clave Secreta'}
            name='endpoint'
            value={dataKeychain.endpoint}
            onChange={onChange}
            disabled={disabled}
          />
        </div>
      </div>
    </>
  )
}

export default MethodMexicoClip
