import React from 'react'
import ReactApexChart from 'react-apexcharts'

export class MyCharts extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      series: [
        {
          name: 'Ventas',
          data: this.props.dateY,
        },
      ],
      options: {
        chart: {
          type: 'bar',
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded',
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },
        xaxis: {
          categories: this.props.dateX,
        },
        yaxis: {
          title: {
            text: `${props.currency} Ventas`,
          },
        },
        colors: props.background,
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return props.currency + ' ' + val + ' '
            },
          },
        },
      },
    }
  }

  render() {
    //console.log(this.props)
    //console.log('fff')
    return (
      <div id='chart'>
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type='bar'
          height={350}
        />
      </div>
    )
  }
}
