import axios from 'axios';

const config = {
    headers: {
        'Authorization': `Bearer ${process.env.REACT_APP_KEY_STRAPI}`,
        'Content-Type': 'application/json'
    }
}

class ProductApi {
    static async Get(idUser = '', idCategory) {
        let url = `https://ventafazil-strapi-production.up.railway.app/categorias/?cliente=${idUser}&_sort=id:DESC&_limit=-1`
        if (idCategory) url += `&id=${idCategory}`
        try {
            const response = await axios.get(url);
            if (response.status === 200) return response.data;
        } catch (error) {
            const customError = new Error('No se pudo obtener los datos de la API');
            customError.response = error.response;
            throw customError;
        }
    }

    static async Post(data = {}) {
        const url = `https://ventafazil-strapi-production.up.railway.app/categorias/`
        try {
            const response = await axios.post(url, data, config);
            if (response.status === 200) return response;
        } catch (error) {
            const customError = new Error('No se pudo agregar datos a la API');
            customError.response = error.response;
            throw customError;
        }
    }

    static async Put(idCategory = "", data = {}) {
        const url = `https://ventafazil-strapi-production.up.railway.app/categorias/${idCategory}`
        try {
            const response = await axios.put(url, data, config);
            if (response.status === 200) return response;
        } catch (error) {
            const customError = new Error('No se pudo actualizar los datos de la API');
            customError.response = error.response;
            throw customError;
        }
    }

    static async Delete(idCategory = "") {
        const url = `https://ventafazil-strapi-production.up.railway.app/categorias/${idCategory}`
        try {
            const response = await axios.delete(url, config);
            if (response.status === 200) return response;
        } catch (error) {
            const customError = new Error('No se pudo eliminar datos de la API');
            customError.response = error.response;
            throw customError;
        }
    }
}

export default ProductApi;