import axios from 'axios';

const config = {
    headers: {
        'Authorization': `Bearer ${process.env.REACT_APP_KEY_STRAPI}`,
        'Content-Type': 'application/json'
    }
}

class CouponApi {
    static async Get(idUser = '', idCoupon = '') {
        let url = `https://ventafazil-strapi-production.up.railway.app/cupons?cliente=${idUser}`
        if (idCoupon) url += `&id=${idCoupon}`
        try {
            const response = await axios.get(url);
            if (response.status === 200) return response.data;
        } catch (error) {
            const customError = new Error('No se pudo obtener los datos de la API');
            customError.response = error.response;
            throw customError;
        }
    }

    static async Post(data = {}) {
        const url = `https://ventafazil-strapi-production.up.railway.app/cupons/`
        try {
            const response = await axios.post(url, data, config);
            if (response.status === 200) return response;
        } catch (error) {
            const customError = new Error('No se pudo agregar datos a la API');
            customError.response = error.response;
            throw customError;
        }
    }

    static async Put(idCoupon = "", data = {}) {
        const url = `https://ventafazil-strapi-production.up.railway.app/cupons/${idCoupon}`
        try {
            const response = await axios.put(url, data, config);
            if (response.status === 200) return response;
        } catch (error) {
            const customError = new Error('No se pudo actualizar los datos de la API');
            customError.response = error.response;
            throw customError;
        }
    }

    static async Delete(idCoupon = "") {
        const url = `https://ventafazil-strapi-production.up.railway.app/cupons/${idCoupon}`
        try {
            const response = await axios.delete(url, config);
            if (response.status === 200) return response;
        } catch (error) {
            const customError = new Error('No se pudo eliminar datos de la API');
            customError.response = error.response;
            throw customError;
        }
    }
}

export default CouponApi;