import React, { Component, useState, useContext, useEffect } from 'react'
import UIBackofficeTableCell from './UIBackofficeTableCell'
import axios from 'axios'
import { UserContext } from '../context/UserContext'
import { NavLink } from 'react-router-dom'
import NavigationCategories from './NavigationCategories'
import FilterOrder from '../components/FilterOrder'
import filter from '../assets/images/filter.svg'
import rectangleUp from '../assets/images/rectangle-up.svg'
import rectangleDown from '../assets/images/rectangle-down.svg'
import { useRef } from 'react'

const headers = [
  {
    id: 0,
    name: '',
    className: 'w-24 flex shrink-0'
  },
  {
    id: 1,
    name: 'Nombre',
    className: 'Zw-5/12 break-words w-[350px]',
    // style:{width:'500px'}
  },
  {
    id: 2,
    name: 'Destacados',
    className: 'text-center  Zw-1/12 w-[85px]'
  },
  {
    id: 3,
    name: 'Categoría',
    className: 'text-center  Zw-2/12 w-[150px]'
  },
  {
    id: 4,
    name: 'Stock',
    className: 'text-center  Zw-1/12 w-[50px]'
  },
  {
    id: 5,
    name: 'Precio',
    className: 'text-center Zw-1/12 w-[100px]'
  },
  {
    id: 6,
    name: '',
    className: 'w-10'
  },
  {
    id: 7,
    name: '',
    className: 'w-10'
  },
]

function UIBackofficeTableFilter() {
  const { user, setUser } = useContext(UserContext) //es  el ID del cliente
  const [loader, setLoader] = useState(true)
  const [param, setParam] = useState('nombreproducto')
  const [order, setOrder] = useState('ASC') //DESC o ASC

  // Logica filters
  const [filtered_Products, setFiltered_Products] = useState([])
  const [sort, setSort] = useState('price-lowest')
  const [filters, setFilters] = useState({ text: '', category: 'all' })
  const [categories, setCategories] = useState([])
  const [sinStock, setSinStock] = useState(false)
  //
  const [probando11, setprobando11] = useState({})
  const { text, category } = filters

  const [dataUser, setDataUser] = useState(
    JSON.parse(localStorage.getItem('user'))
  )
  const [products, setProducts] = useState([])

  const [productsTable, setProductsTable] = useState([])

  const [seeActions, setSeeActions] = useState(false)
  const [allProducts, setAllProducts] = useState([])
  const [page, setPage] = useState(0)
  const productByText = useRef()
  const [query, setquery] = useState()
  const [selectedCategory, setSelectedCategory] = useState()

  const transformCategory = (array) => {
    let newarr = array?.map((el) => el.nombrecategoria)
    return newarr
  }
  const camelCase = (str) => {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index == 0 ? word.toLowerCase() : word.toUpperCase()
      })
      .replace(/\s+/g, '')
  }

  const consultarAPi = async (isTrending = false) => {
    setLoader(true)
    if (productByText.current) {
      let url = `https://ventafazil-strapi-production.up.railway.app/productos/?cliente=${user}&_sort=id:DESC&_limit=-1`
      if(selectedCategory) url += ``
      let config = {
        method: 'get',
        url: url,
        headers: {},
      }
      try {
        const { data } = await axios(config)
        if (isTrending) setSeeActions(true)
        if (!isTrending) {
          let productFiltrered = data.filter(elem => {
            if (
              elem.nombreproducto
                .toString()
                .toLowerCase()
                .includes(productByText.current.toLowerCase())
            ) {
              return elem
            }
          })
          setFiltered_Products(productFiltrered)
          setProducts(data)
        }
        setAllProducts(data)
        setLoader(false)
      } catch (error) {
        console.log(error)
      }
    } else {
      let url = ``
      if (selectedCategory) {
        console.log("ES selectedCategory", selectedCategory)
        url = `https://ventafazil-strapi-production.up.railway.app/categorias/?cliente=${user}&id=${selectedCategory}&_sort=id:DESC`
      } else {
        console.log("NO selectedCategory", selectedCategory)
        url = `https://ventafazil-strapi-production.up.railway.app/productos/?cliente=${user}&_sort=id:DESC&_start=${page}&_limit=10`
      }
      try {
        const { data } = await axios.get(url)
        let _data = []
        // console.log(selectedCategory, "Category DATA", data)
        if (selectedCategory != undefined && selectedCategory != '') {
          // console.log("Is Category selected ", _data = data[0].productos)
          _data = data[0].productos
        } else {
          _data = data
        }
        // data = data[0].productos
        if (isTrending) setSeeActions(true)
        if (!isTrending) {
          setFiltered_Products(_data)
          // setProducts(data)
        }
        setAllProducts(_data)
        setLoader(false)
      } catch (error) {
        console.log(error)
      }
    }
    setLoader(false)
  }

  const findProductByText = (e) => {
    e.persist()
    if (productByText.current) {
      clearTimeout(productByText.current)
    }
    productByText.current = setTimeout(() => {
      productByText.current = e.target.value
      setquery(e.target.value)
      consultarAPi(false, e.target.value)
    }, 500)
  }

  const consultarCategorias = async () => {
    const url = `https://ventafazil-strapi-production.up.railway.app/categorias/?cliente=` + user
    try {
      const response = await axios.get(url)
      const data = response.data
      if (data) {
        let options = [
          {
            value: '',
            label: 'Selecciona una opción'
          }
        ]
        data.forEach(element => {
          options.push({
            value: element.id,
            label: element.nombrecategoria
          })
        });
        setCategories(options)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    consultarCategorias()
  }, [])


  const handleSelectedChangeCategory = (e) => {
    console.log('Categories event', e.value)
    setSelectedCategory(e.value)
  }

  useEffect(() => {
    consultarAPi()
  }, [page, selectedCategory])


  // const searchName = (termino) => {
  //   const filterName = productsTable.filter(
  //     (product) => product.nombreproducto.toLowerCase().indexOf(termino) > -1
  //   )
  //   console.log(filterName)
  //   setProducts(filterName)
  // }

  // const updateSort = (e) => {
  //   const value = e.target.value

  //   setSort(value)
  // }

  // const sortProducts = (e) => {
  //   let tempProducts = [...filtered_Products]

  //   if (sort === 'price-lowest') {
  //     tempProducts = tempProducts.sort((a, b) => a.precio - b.precio)
  //     console.log('price-lowest')
  //   }
  //   if (sort === 'price-highest') {
  //     tempProducts = tempProducts.sort((a, b) => b.precio + a.precio)
  //     console.log('price-highest')
  //   }

  //   if (sort === 'name-a') {
  //     tempProducts = tempProducts.sort((a, b) => {
  //       return a.nombreproducto.localeCompare(b.nombreproducto)
  //     })

  //     console.log('name-a')
  //   }

  //   if (sort === 'name-z') {
  //     tempProducts = tempProducts.sort((a, b) => {
  //       return b.nombreproducto.localeCompare(a.nombreproducto)
  //     })

  //     console.log('name-z')
  //   }

  //   setFiltered_Products(tempProducts)
  // }

  // const updateFilters = (e) => {
  //   let name = e.target.name
  //   let value = e.target.value
  //   let tempProducts = [...filtered_Products]

  //   console.log('PRINERO', sinStock)

  //   // setSinStock(!sinStock)
  //   // console.log('SEG', sinStock)

  // if (!sinStock === true) {
  //   console.log('TER', sinStock)
  //   tempProducts = tempProducts.filter((product) => product.stock == 0)
  //   setFiltered_Products(tempProducts)
  // } else {
  //   console.log('ent33amoss')

  //   //   // let tempProducts = filterProducts
  //   //   // console.log('Ddd', tempProducts)
  //   // }
  // }

  // const updateFilters = (e) => {
  //   let name = e.target.name
  //   let value = e.target.value

  //   if (name === 'sinStock') {
  //     setSinStock(!sinStock)
  //   }
  //   setFilters({ ...filters, [name]: value })
  // }

  // const filterProducts = (e) => {
  //   let tempProducts = [...products]
  //   console.log('ENTRAMOS')
  //   console.log(sinStock)
  //   // filtering
  //   // text
  //   if (text) {
  //     console.log('newTexr')
  //     tempProducts = tempProducts.filter((product) =>
  //       product.nombreproducto.toLowerCase().startsWith(text.toLowerCase())
  //     )
  //   }
  //   if (sinStock) {
  //     console.log('TER', sinStock)
  //     tempProducts = tempProducts.filter((product) => product.stock == 0)

  //     setFiltered_Products(tempProducts)
  //   }
  //   console.log('NOS VAMOS DE LARGO')

  //   setFiltered_Products(tempProducts)
  // }

  // const filterTrending = () => {
  //   if (seeActions) {
  //     let tempProducts = [...allProducts]
  //     tempProducts = tempProducts
  //       .filter((product) => product.istrending == true)
  //       .sort((a, b) => a.order - b.order)
  //     setFiltered_Products(tempProducts)
  //     // console.log("filtered_Products", filtered_Products)
  //   }
  // }

  // useEffect(() => {
  //   filterProducts()
  // }, [filters])

  // useEffect(() => {
  //   sortProducts()
  // }, [products, sort])

  // useEffect(() => {
  //   // console.log("filtered_Products",filtered_Products)
  //   if (seeActions) {
  //     //console.log("IF===",filtered_Products)
  //     filterTrending()
  //   }
  // }, [allProducts, seeActions])

  return (
    <div className='flex flex-col w-full gap-2'>
      <div className='w-full '>
        <FilterOrder
          handleChange={findProductByText}
          // handleSelectChange={handleChange}
          handleSelectChange1={handleSelectedChangeCategory}
          placeHolder='Busca tu producto'
          img1={filter}
          img2={rectangleUp}
          img3={rectangleDown}
          text1='Filtro'
          // text2='Orden: Precio Ascendente'
          // options1={estadoventa}
          options2={categories}
        />
      </div>
      {/* <NavigationCategories /> */}
      {/* <div className='flex justify-between w-full pr-11 '>
        <form onSubmit={(e) => e.preventDefault()}>
          <input
            className='w-40'
            type='text'
            name='text'
            placeholder='Search'
            value={text}
            onChange={updateFilters}
            disabled={seeActions}
          />
        </form>
        <form onSubmit={(e) => e.preventDefault()}>
          <button
            className='text-xl font-bold text-gray-400 capitalize hover:text-yellow-600'
            onClick={(e) => {
              // e.preventDefault();
              setSeeActions(true)
              filterTrending()
            }}
          >
            Ordenar Destacados
          </button>
        </form>

        <div className='form-control shipping'>
          <label htmlFor='sinStock'>Sin Stock</label>
          <input
            type='checkbox'
            name='sinStock'
            id='sinStock'
            checked={sinStock}
            onChange={updateFilters}
            disabled={seeActions}
          />
        </div>

        <form>
          <label htmlFor='sort' className='text-gray-400'>
            Ordenar :
          </label>

          <select
            name='sort'
            id='sort'
            value={sort}
            onChange={updateSort}
            disabled={seeActions}
            className={seeActions && 'text-gray-400'}
          >
            <option value='price-lowest'>Precio Ascendente</option>
            <option value='price-highest'>Precio Descendente</option>
            <option value='name-a'>Nombre A - Z</option>
            <option value='name-z'>Nombre Z - A</option>
          </select>
        </form>
      </div> */}
      {loader ? (
        <div className='w-full flex justify-center items-center min-h-screen'>
          <div className="border-t-transparent w-28 h-28 border-4 border-blue-200 rounded-full animate-spin"></div>
        </div>
      ) : (
        <div className='w-full h-auto'>
          <div
            // className="flex w-full justify-evenly items-center gap-2 p-4 py-0 transition-all  transform duration-500 bg-gray-400 rounded-md after:duration-500 hover:shadow-xl hover:-translate-y-1 after:-ml-3 after:opacity-0 after:text-3xl hover:after:ml-2 hover:after:transition-all hover:after:opacity-100 after:content-['>'] hover:after:text-white hover:bg-white cursor"
            className="min-w-max flex items-center justify-evenly gap-2 w-full py-3 px-5 rounded-xl text-sm"
            style={{ background: '#E6E6E6' }}
          >
            {
              headers.map(el => {
                if (selectedCategory != undefined && selectedCategory != '') {
                  if (el.name != 'Categoría') {
                    return (
                      <div className={el.className} key={el.id + el.name} style={el.style}>{el.name}</div>
                    )
                  }
                } else {
                  return (
                    <div className={el.className} key={el.id + el.name} style={el.style}>{el.name}</div>
                  )
                }
              })
            }
          </div>
          {filtered_Products ?
            filtered_Products.map((product, index) => {
              return (
                <div key={index+'_'+product.name}>
                <UIBackofficeTableCell
                  consultarAPi={consultarAPi}
                  category={transformCategory(product.categorias)}//Productos que vienen de un array categoría no tienen este campo
                  _key={index + "_" + product.id}
                  product={product}
                  allProducts={allProducts}
                  filtered_Products={filtered_Products}
                  selectedCategory={selectedCategory}
                // seeActions={seeActions}
                // index={index}
                />
                </div>
              )
            }) :
            (
              <div>No hay productos para mostrar</div>
            )
          }
          <div
            className='flex justify-end items-center gap-3 mb-28 pt-4'
            style={{ borderTop: '4px solid #F2F2F2' }}
          >
            {allProducts.length >= 1 && (
              <>
                <div
                  className='w-auto h-auto cursor-pointer'
                  onClick={() => setPage(page - 10)}
                >
                  {page !== 0 && (
                    <button className='rounded-xl px-3 py-1' style={{ border: '1px solid #909090' }}>
                      Anterior
                    </button>
                  )}
                </div>

                <div
                  className='w-auto h-auto cursor-pointer'
                  onClick={() => setPage(page + 10)}
                >
                  {(allProducts.length === 0 || allProducts.length === 10) && (
                    <button className='rounded-xl px-3 py-1' style={{ border: '1px solid #909090' }}>
                      Siguiente
                    </button>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  )
}
export default UIBackofficeTableFilter