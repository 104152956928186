import React from 'react'
import { Link } from 'react-router-dom'

const VentTableCell = ({
  orderId,
  fecha,
  cliente,
  total,
  cantidad,
  estado,
  estadoVenta,
  currency,
}) => {
  return (
    <div className='flex items-center justify-evenly w-auto mb-3 text-sm gap-7 '>
      <div className='text-center w-52' style={{}}>
        <a href={`/venta/${orderId}`}>{orderId}</a>
      </div>
      <div className='text-center w-36'>
        {new Date(fecha).toLocaleDateString('ES')}{' '}
        {new Date(fecha).toLocaleTimeString('en-US', {
          hour: 'numeric',
          minute: 'numeric',
        })}
      </div>
      <div className='text-center w-36'>{cliente}</div>
      <div className='text-center w-28'>{currency + ' ' + total}</div>
      <div className='w-24 text-center'>{cantidad}</div>
      <div className='w-40 text-center'>
        <button className='w-32 p-1 rounded-lg' style={{background:'#00FBC8'}}>{estado}</button>
      </div>
      <div className='w-40 text-center'>
        <button className='p-1 rounded-lg w-28' style={{background:'#FBBF24'}}>
          {estadoVenta && estadoVenta == 'poratender'
            ? 'por Atender'
            : estadoVenta || 'null'}
        </button>
      </div>
    </div>
  )
}

export default VentTableCell
