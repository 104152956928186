import React, { useEffect, useState } from 'react'
import { DeleteCupon } from '../utils/DeleteCupons'
import { GetCuponsByDomainAPI } from '../utils/GetCupons'
import { PostCuponAPI } from '../utils/PostCupon'
import { PutCuponAPI } from '../utils/PutCupon'
import Swal from 'sweetalert2'
import UIInputOutSelect from '../components/UIInputOutSelect'

export default function CuponsModule() {
  const clientID = JSON.parse(localStorage.getItem('user')).id
  const [eventsCount, setEventsCount] = useState(0)

  const [cupons, setCupons] = useState([])

  const [newName, setNewName] = useState('')
  const [newCode, setNewCode] = useState('')
  const [newDiscount, setNewDiscount] = useState('')
  const [newValue, setNewValue] = useState('')

  function createNewCoupon() {
    var defaultNewCupon = {
      benefit: 'discount',
      name: 'new cupon' + Number(cupons.length + 1),
      value: 10,
      stock: 10,
      cliente: {
        _id: clientID,
      },
    }
    PostCuponAPI(defaultNewCupon).then((data) => {
      //console.log('createNewCoupon', data)
      setEventsCount(eventsCount + 1)
    })
  }

  function handleChange() {
    setEventsCount(eventsCount + 1)
  }

  useEffect(() => {
    GetCuponsByDomainAPI(clientID).then((data) => {
      setCupons(data)
    })
  }, [eventsCount])

  return (
    <div className='p-4 mt-12 bg-gray-200 rounded-lg md:mt-24'>
      <p className='font-bold text-gray-500'>Cupones</p>
      <p className='text-xs font-bold text-gray-700'>Agrega tus cupones</p>

      {/* listar cupones */}
      {cupons?.length > 0 ? (
        <div className='flex flex-col my-4'>
          {cupons.map((cupon, index) => {
            console.log('ddd', index)

            return (
              <UICuponCell
                key={index}
                cupon={cupon}
                parentFunction={handleChange}
                cupons={cupons}
                index={index}
              />
            )
          })}
        </div>
      ) : (
        <div className='py-6 text-gray-700 text-xxs'>
          no tienes cupones aun, crea tu primer cupón
        </div>
      )}

      <div
        className='flex items-center self-start justify-center w-40 p-2 px-4 font-bold text-black rounded-full cursor-pointer text-xxs bg-primary'
        onClick={() => createNewCoupon()}
      >
        crear nuevo cupon
      </div>
    </div>
  )
}

export function UICuponCell({ cupon, parentFunction, cupons, index }) {
  const data = cupon
  const [cliente, setCliente] = useState('')
  const [benefit, setBenefit] = useState('')
  const [name, setName] = useState('')
  const [value, setValue] = useState('')
  const [stock, setStock] = useState('')

  useEffect(() => {
    setCliente(data.cliente.id)
    setBenefit(data.benefit)
    setName(data.name)
    setValue(data.value)
    setStock(data.stock)
  }, [data])

  const body = {
    benefit: benefit,
    name: name,
    value: value,
    stock: stock,
    cliente: {
      _id: cliente,
    },
  }

  function deleteCupon() {
    DeleteCupon(data.id).then((data) => {
      parentFunction()
    })
  }

  function duplicateCupon() {
    body.name += Math.floor(Math.random() * 1000)
      .toString()
      .padStart(3, '0')
    PostCuponAPI(body).then((data) => {
      parentFunction()
    })
  }
  const existingCouponIndex = cupons.findIndex(
    (coupon) => coupon.name === body.name
  )

  function updateCupon(index) {
    if (existingCouponIndex !== -1 && existingCouponIndex !== index) {
      console.log('entre al ifff')
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-right',
        iconColor: 'white',
        customClass: {
          popup: 'colored-toast',
        },
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
      })
      return Toast.fire({
        icon: 'error',
        title: 'No puede tener cupones con el mismo nombre',
      })
    }

    PutCuponAPI(data.id, body).then((data) => {
      parentFunction()
    })
    console.log('paseeee')

    const Toast = Swal.mixin({
      toast: true,
      position: 'top-right',
      iconColor: 'white',
      customClass: {
        popup: 'colored-toast',
      },
      showConfirmButton: false,
      timer: 1500,
      timerProgressBar: true,
    })
    Toast.fire({
      icon: 'success',
      title: 'Se actualizo el cupon correctamente',
    })
  }

  return (
    <div
      id={data.id}
      className='flex flex-col justify-between p-4 mt-4 bg-white rounded-lg shadow-md md:flex-row'
    >
      <div className='flex flex-grow gap-2 pr-4'>
        <label className='flex flex-col text-xs font-bold text-gray-700 md:w-6/12'>
          Name
          <input
            className='h-12 p-2 bg-gray-100 rounded-md'
            type='text'
            value={name}
            onChange={(e) => {
              setName(e.target.value)
            }}
          />
        </label>
        <UIInputOutSelect
          label='Facturación por'
          placeholder='Tipo de beneficio'
          options={benefitOptions}
          initialValue={benefit}
          value={benefit}
          handleChange={(e) => setBenefit(e)}
        />
      </div>
      <div className='flex flex-row items-start justify-start gap-2 pl-4 border-l'>
        <label className='flex flex-col text-xs font-bold text-gray-700 md:w-6/12'>
          Value
          <input
            type={`number`}
            className='h-12 p-2 bg-gray-100 rounded-md'
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </label>
        <label className='flex flex-col text-xs font-bold text-gray-700 md:w-6/12'>
          Stock
          <input
            type={`number`}
            className='h-12 p-2 bg-gray-100 rounded-md'
            value={stock}
            onChange={(e) => setStock(e.target.value)}
          />
        </label>
      </div>
      {/* actionbtns */}
      <div className='flex items-center gap-2 pl-4'>
        {/* savebutton */}
        <div className='cursor-pointer' onClick={() => updateCupon(index)}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 24 24'
            fill='currentColor'
            strokeWidth='1'
            className='w-6 h-6'
          >
            <path d='M5 21h14a2 2 0 0 0 2-2V8a1 1 0 0 0-.29-.71l-4-4A1 1 0 0 0 16 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2zm10-2H9v-5h6zM13 7h-2V5h2zM5 5h2v4h8V5h.59L19 8.41V19h-2v-5a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v5H5z' />
          </svg>
        </div>
        {/* duplicate */}
        <div className='cursor-pointer' onClick={duplicateCupon}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth='1.5'
            stroke='currentColor'
            className='w-6 h-6'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75'
            />
          </svg>
        </div>
        {/* deleteButton */}
        <div className='cursor-pointer' onClick={deleteCupon}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth='1.5'
            stroke='currentColor'
            className='w-6 h-6'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0'
            />
          </svg>
        </div>
      </div>
    </div>
  )
}

export const benefitOptions = [
  { name: 'percentage' },
  { name: 'discount' },
  { name: 'freeshipping' },
]
