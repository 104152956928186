import React from 'react'
import { useEffect, useContext } from 'react'
import {
  GetLibroReclamoAPI,
  PutLibroReclamoAPI,
} from '../../../utils/PutLibroReclamos'
import { UserContext } from '../../../context/UserContext'

export default function UIInputLibroReclamo(props) {
  const { user } = useContext(UserContext)
  const [updateCount, setUpdateCount] = React.useState(0)
  const [checkboxValue, setCheckboxValue] = React.useState('')
  const [data, setData] = React.useState(null)

  function handleChange(e) {
    PutLibroReclamoAPI(user, e.target.checked).then((data) => {
      //console.log(data)
      setUpdateCount(updateCount + 1)
    })
    setCheckboxValue(e.target.checked)
  }
  //init
  useEffect(() => {
    GetLibroReclamoAPI(user).then((data) => {
      setCheckboxValue(data)
      //console.log(data)
    })
  }, [updateCount])

  return (
    <div className='flex justify-between p-4 mt-4 rounded-lg shadow-md md:flex-row' style={{border:'2px solid #F2F2F2'}}>
      Visibilidad de Libro de Reclamaciones
      <input
        className='w-12 border-2 border-gray-800 '
        type={'checkbox'}
        onChange={handleChange}
        checked={checkboxValue}
      />
    </div>
  )
}
