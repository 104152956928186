import { useEffect } from 'react'
import { useState } from 'react'
import { conceptos } from '../utils/data'

const UIInputOutSelect = ({
  label,
  int = false,
  initialValue = '',
  disabled = false,
  className,
  placeholder = '',
  handleChange = () => console.log('selected'),
  options = [],
}) => {
  const [value, setValue] = useState('')
  const [onFocus, setOnFocus] = useState(false)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const onChange = (e) => {
    setValue(e)
    handleChange(e)
    setOpen(true)
  }

  const selectOption = (e) => {
    setValue(e)
    setOpen(false)
    setOnFocus(false)
    handleChange(e)
  }

  return (
    <div className={`flex w-full flex-col  ${className}`}>
      <label className='flex flex-col text-xs font-bold text-gray-700 md:w-6/12'>
        {label}
      </label>
      <div className='relative flex h-12 bg-gray-100 rounded-md'>
        {onFocus && (
          <div className='absolute w-full h-full animate-pulse blur-sm bg-primary-100'></div>
        )}
        <div
          className={`absolute z-10 flex w-2 h-2 rotate-45 -translate-y-1 border-b-2 border-r-2 right-3 top-1/2 border-gray-700`}
        />
        <div className='absolute flex w-full h-full bg-gray-100 rounded '>
          <input
            onFocus={() => {
              setOnFocus(true)
              setOpen(true)
            }}
            onBlur={() => {
              setOnFocus(false)
              // setOpen(false);
            }}
            className={`p-4 text-xs ${int && 'text-right'}
                        ${
                          disabled
                            ? 'text-slate-400 cursor-not-allowed bg-slate-200 border-slate-200 border'
                            : 'bg-gray-100'
                        } w-full outline-none transition-all rounded `}
            type='text'
            value={value}
            disabled={disabled}
            placeholder={placeholder}
            onChange={onChange}
          />
          {open && (
            <ul
              className={`absolute w-full top-[38px] z-10 rounded-lg bg-white shadow-xl border border-slate-100`}
            >
              {options.map((option, index) => (
                <li
                  key={index}
                  className='p-2 px-4 text-xs cursor-pointer text-slate-600 hover:bg-primary-100'
                  onClick={() => selectOption(option.name)}
                >
                  {option.name}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  )
}

export default UIInputOutSelect
