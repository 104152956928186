import { faPencil, faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom"
import CustomCheckbox from "./CustomCheckbox"

const ListRate = (props) => {
    return (
        <div className='pb-32 bg-white md:ml-40 md:p-6'>
            <div className='flex justify-between'>
                <h1 className='font-bold text-2xl'>{props.title}</h1>
                <Link
                    to={{
                        pathname: props.pathnameNew,
                        state: { allDeliveryList: props.allDeliveryList }
                    }}
                    className='p-2 px-10 text-sm font-bold text-center bg-yellow-400 rounded-md '
                >
                    {props.nameButton}
                </Link>
            </div>

            {props.data?.length < 1 && (
                <div>{props.empyList}</div>
            )}
            <div className=''>
                {props.data?.length >= 1 && (
                    <div>
                        <h1 className='font-bold'>{props.subtitle}</h1>
                        <div className='grid gap-3'>
                            {props.data?.map((elem, i) => {
                                return (
                                    <div
                                        className='flex bg-slate-100 w-5/5 p-2'
                                        key={elem._id}
                                    >
                                        <div className='flex items-center w-11/12'>
                                            {' '}
                                            <CustomCheckbox
                                                checked={elem.isactive}
                                                onChange={() => props.handleCheckboxChange(elem)}
                                            />
                                            {props.isPricePerZone ? elem.ratename : elem.name}
                                        </div>
                                        <div className=''>
                                            <Link
                                                to={{
                                                    pathname: props.pathnameEdit,
                                                    state: { id: elem._id, isEdit: true, allDeliveryList: props.allDeliveryList },
                                                }}
                                            >
                                                <FontAwesomeIcon
                                                    className='hover:text-amber-500 p-2 cursor-pointer'
                                                    icon={faPencil}
                                                />
                                            </Link>
                                            <FontAwesomeIcon
                                                className='hover:text-red-600 p-2 cursor-pointer'
                                                onClick={() => props.deleteRate(elem._id, i)}
                                                icon={faTrash}
                                            />
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
export default ListRate