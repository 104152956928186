let districts = [
  { value: 'ANC', label: 'Ancón' },
  { value: 'ATE', label: 'Ate' },
  { value: 'BAR', label: 'Barranco' },
  { value: 'BRE', label: 'Breña' },
  { value: 'CAR', label: 'Carabayllo' },
  { value: 'CDL', label: 'Cercado de Lima' },
  { value: 'CHA', label: 'Chaclacayo' },
  { value: 'CHO', label: 'Chorrillos' },
  { value: 'CIE', label: 'Cieneguilla' },
  { value: 'COM', label: 'Comas' },
  { value: 'AGU', label: 'El agustino' },
  { value: 'IND', label: 'Independencia' },
  { value: 'JM', label: 'Jesús maría' },
  { value: 'LM', label: 'La molina' },
  { value: 'LV', label: 'La victoria' },
  { value: 'LIN', label: 'Lince' },
  { value: 'LO', label: 'Los olivos' },
  { value: 'LRG', label: 'Lurigancho' },
  { value: 'LUR', label: 'Lurín' },
  { value: 'MDM', label: 'Magdalena del mar' },
  { value: 'MIR', label: 'Miraflores' },
  { value: 'PAC', label: 'Pachacámac' },
  { value: 'PUC', label: 'Pucusana' },
  { value: 'PL', label: 'Pueblo libre' },
  { value: 'PP', label: 'Puente piedra' },
  { value: 'PH', label: 'Punta hermosa' },
  { value: 'PN', label: 'Punta negra' },
  { value: 'RIM', label: 'Rímac' },
  { value: 'SBL', label: 'San bartolo' },
  { value: 'SBJ', label: 'San borja' },
  { value: 'SI', label: 'San isidro' },
  { value: 'SJL', label: 'San Juan de Lurigancho' },
  { value: 'SJM', label: 'San Juan de Miraflores' },
  { value: 'SL', label: 'San Luis' },
  { value: 'SMP', label: 'San Martin de Porres' },
  { value: 'SMG', label: 'San Miguel' },
  { value: 'SAN', label: 'Santa Anita' },
  { value: 'SMM', label: 'Santa María del Mar' },
  { value: 'SR', label: 'Santa Rosa' },
  { value: 'SDS', label: 'Santiago de Surco' },
  { value: 'SUQ', label: 'Surquillo' },
  { value: 'VES', label: 'Villa el Salvador' },
  { value: 'VMT', label: 'Villa Maria del Triunfo' },
]

const provinces = [
  { label: 'Amazonas', value: 'Amazonas' },
  { label: 'Áncash', value: 'Áncash' },
  { label: 'Apurímac', value: 'Apurímac' },
  { label: 'Arequipa', value: 'Arequipa' },
  { label: 'Ayacucho', value: 'Ayacucho' },
  { label: 'Cajamarca', value: 'Cajamarca' },
  { label: 'Callao', value: 'Callao' },
  { label: 'Cusco', value: 'Cusco' },
  { label: 'Huancavelica', value: 'Huancavelica' },
  { label: 'Huánuco', value: 'Huánuco' },
  { label: 'Ica', value: 'Ica' },
  { label: 'Junín', value: 'Junín' },
  { label: 'La Libertad', value: 'La Libertad' },
  { label: 'Lambayeque', value: 'Lambayeque' },
  { label: 'Lima', value: 'Lima' },
  { label: 'Loreto', value: 'Loreto' },
  { label: 'Madre de Dios', value: 'Madre de Dios' },
  { label: 'Moquegua', value: 'Moquegua' },
  { label: 'Pasco', value: 'Pasco' },
  { label: 'Piura', value: 'Piura' },
  { label: 'Puno', value: 'Puno' },
  { label: 'San Martín', value: 'San Martín' },
  { label: 'Tacna', value: 'Tacna' },
  { label: 'Tumbes', value: 'Tumbes' },
  { label: 'Ucayali', value: 'Ucayali' },
]
let regions = [
  { name: 'Amazonas', code: 'AMA' },
  { name: 'Áncash', code: 'ANC' },
  { name: 'Apurímac', code: 'APU' },
  { name: 'Arequipa', code: 'ARQ' },
  { name: 'Ayacucho', code: 'AYA' },
  { name: 'Cajamarca', code: 'CAJ' },
  { name: 'Callao', code: 'CALL' },
  { name: 'Cusco', code: 'CUS' },
  { name: 'Huancavelica', code: 'HCV' },
  { name: 'Huánuco', code: 'HNC' },
  { name: 'Ica', code: 'ICA' },
  { name: 'Junín', code: 'JUN' },
  { name: 'La Libertad', code: 'LLB' },
  { name: 'Lambayeque', code: 'LMB' },
  { name: 'Lima', code: 'LIM' },
  { name: 'Loreto', code: 'LOR' },
  { name: 'Madre de Dios', code: 'MDD' },
  { name: 'Moquegua', code: 'MOQ' },
  { name: 'Pasco', code: 'PAS' },
  { name: 'Piura', code: 'PIU' },
  { name: 'Puno', code: 'PUN' },
  { name: 'San Martín', code: 'SMT' },
  { name: 'Tacna', code: 'TAC' },
  { name: 'Tumbes', code: 'TUM' },
  { name: 'Ucayali', code: 'UCA' },
]
const estadoventa = [
  { value: '', label: 'Selecciona una opcion' },

  { value: 'poratender', label: 'por Atender' },
  { value: 'entregado', label: 'Entregado' },
  { value: 'enruta', label: 'En ruta' },
  { value: 'finalizado', label: 'Finalizado' },
]
const estadoPago = [
  { value: '', label: 'Selecciona una opcion' },
  { value: 'aceptado', label: 'Aceptado' },
  { value: 'reembolsoparcial', label: 'Rembolso Parcial' },
  { value: 'reembolso', label: 'Reembolso' },
]
const estadoventa1 = [
  { value: 'poratender', label: 'por Atender' },
  { value: 'entregado', label: 'Entregado' },
  { value: 'enruta', label: 'En ruta' },
  { value: 'finalizado', label: 'Finalizado' },
]
const estadoPago1 = [{ value: 'aceptado', label: 'Aceptado' }]
const estadoPago2 = [{ value: 'aceptado', label: 'Aceptado' }]
export {
  districts,
  provinces,
  estadoventa,
  regions,
  estadoventa1,
  estadoPago,
  estadoPago1,
  estadoPago2,
}
export const metodoEntrega = [
  { value: true, label: 'Delivery' },
  { value: false, label: 'Recojo en Tienda' },
]
export const metodoEntregaDelivery = [{ value: true, label: 'Delivery' }]
export const metodoRecojo = [{ value: false, label: 'Recojo en Tienda' }]
