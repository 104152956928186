import React, { useState } from 'react'
import Checkbox1 from '../components/Checkbox1'
import Button1 from '../components/Button1'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import axios from 'axios'
import { useEffect } from 'react'
import List from '../components/List'
import Loading from '../components/Loading'

const TOAST_TITLE = Object.freeze({
  EDIT: 'Se edito un precio por zona con exito',
  DELETE: 'Se elimino un precio por zona con exito'
})

const PATHNAME = Object.freeze({
  NEW: '/delivery/tienda-recojo/nuevo',
  EDIT: '/delivery/tienda-recojo/edit'
})

const headers = [
  {
    id: 1,
    name: 'Punto de recojo',
    className: '',
    style: { width: '65%' }
  },
  {
    id: 2,
    name: 'Activo / Inactivo',
    className: 'text-center ',
    style: { width: '15%' }
  },
  {
    id: 3,
    name: '',
    className: 'text-center',
    style: { width: '10%' }
  },
  {
    id: 4,
    name: '',
    className: 'text-center',
    style: { width: '10%' }
  },
]

function PickupStore() {
  const _user = JSON.parse(localStorage.getItem('user'))
  const idCustomer = _user._id
  const idCountry = _user.paises.id

  const [isPickupPoint, setIPickupPoint] = useState(false)
  const [pickupPointData, setPickupPointData] = useState([])
  const [loading, setLoading] = useState(true)

  const toastAlert = (title) => {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-right',
      iconColor: 'white',
      customClass: {
        popup: 'colored-toast',
      },
      showConfirmButton: false,
      timer: 1500,
      timerProgressBar: true,
    })
    Toast.fire({
      icon: 'success',
      title: title
    })
  }

  const getPickupPointBycustomer = async () => {
    setIPickupPoint(_user.ispickupstore)
    const url = `https://ventafazil-strapi-production.up.railway.app/Pickpoints/?cliente=${idCustomer}&country=${idCountry}`
    try {
      const response = await axios.get(url)
      const data = response.data
      if (response.status == 200) {
        setPickupPointData(data)
      }
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }

  const changeActivePickupPoint = async (item) => {
    setLoading(true)
    const url = `https://ventafazil-strapi-production.up.railway.app/Pickpoints/${item._id}`
    const data = {
      isactive: !item.isactive,
    }
    const config = {
      headers: {
        'Authorization': `Bearer ${process.env.REACT_APP_KEY_STRAPI}`,
        'Content-Type': 'application/json'
      }
    }
    try {
      const response = await axios.put(url, data, config)
      if (response.status === 200) {
        getPickupPointBycustomer()
        toastAlert(TOAST_TITLE.EDIT)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const deletePickupPoint = async (id) => {
    setLoading(true)
    const url = `https://ventafazil-strapi-production.up.railway.app/Pickpoints/${id}`
    const config = {
      headers: {
        'Authorization': `Bearer ${process.env.REACT_APP_KEY_STRAPI}`
      }
    }
    try {
      const response = await axios.delete(url, config)
      if (response.status === 200) {
        getPickupPointBycustomer()
        toastAlert(TOAST_TITLE.DELETE)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getPickupPointBycustomer()
  }, [])

  return (
    <div className='w-full md:w-9/12 flex flex-col gap-10 py-10'>
      {loading && <Loading />}
      {
        isPickupPoint ? (
          <div className='flex flex-col justify-center gap-6'>
            <div className='flex justify-between items-center'>
              <div className='' style={{ color: '#787878' }}>Crea tus puntos de recojo para que tus cliente elijan el que más les conviene.</div>
              <div className='w-fit'>
                <Link
                  to={{
                    pathname: PATHNAME.NEW,
                  }}
                >
                  <Button1
                    textColor='#000000'
                    background='#00FBC8'
                    text='Crear punto de recojo'
                  />
                </Link>
              </div>
            </div>
            <div className='w-full' >
              <div
                className="flex items-center justify-evenly gap-7 w-full py-2 px-10 rounded-xl text-sm"
                style={{ background: '#E6E6E6' }}
              >
                {
                  headers.map(el =>
                    <div className={el.className} key={el.id + el.name} style={el.style}>{el.name}</div>
                  )
                }
              </div>
              {
                pickupPointData && pickupPointData.map((element, index) => {
                  return (
                    <div key={index + element.name}>
                      <List
                        isPickupPoint={true}
                        pathnameEdit={PATHNAME.EDIT}
                        element={element}
                        onChange={changeActivePickupPoint}
                        deleteElement={deletePickupPoint}
                      />
                    </div>
                  )
                })
              }
            </div>
          </div>
        ) : (
          <p className='font-semibold'>Para editar esta sección activalo desde la pestaña delivery.</p>
        )
      }
    </div>
  )
}

export default PickupStore