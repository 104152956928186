import NavigationTop from '../../NavigationTop'
import NavigationSide from '../../NavigationSide'
import UIBackofficeHeader from '../../UIBackofficeHeader'
import verified from '../../../assets/images/verified.svg'

function Subscription() {
    return (
        <div className='pb-24 w-fit lg:w-full min-w-full Zoverflow-hidden'>
            <NavigationTop />
            <NavigationSide />
            <div className='md:ml-28 md:mt-5'>
                <UIBackofficeHeader
                    title='Mi suscripción'
                />
                <div className='Zw-fit lg:w-auto flex flex-col gap-10 md:ml-20 lg:ml-28 px-5 md:px-0'>
                    <div
                        className='md:w-11/12 lg:w-5/6 flex flex-col gap-8 md:gap-0 md:flex-row md:justify-between py-5 px-10 rounded-xl'
                        style={{ background: '#F3F4F6' }}
                    >
                        <div className='md:w-1/2 flex flex-col gap-5'>
                            <div className='flex items-center gap-10'>
                                <h2 className='font-semibold text-xl'>Próximo Pago</h2>
                                <p style={{ color: '#212121' }}>20/01/2022</p>
                            </div>
                            <p>midominio.com</p>
                        </div>
                        <div className='md:w-1/2 flex flex-col md:items-end gap-5 font-semibold'>
                            <div className='flex gap-3 md:gap-1'>
                                <img src={verified} />
                                <p style={{ color: '#8163FF' }}>La renovación automatica esta activada</p>
                            </div>
                            <p>Plan actual : Mensual S/. 30.00</p>
                        </div>
                    </div>
                    <div className='flex flex-col gap-5'>
                        <h2 className='font-bold'>Detalle del Pago</h2>
                        <div className='w-max border-2 rounded-xl py-4 px-5' style={{ borderColor: '#8163FF' }}>
                            <p className='font-semibold'>Tarjeta de crédito</p>
                            <p className='text-xs' style={{ color: '#767676' }}>Fecha de expiración : 07/24 </p>
                            <p className='font-semibold text-2xl' style={{ color: '#8163FF' }}>**** **** **** 1234</p>
                        </div>
                        <button className='w-max  text-sm text-white py-3 px-2 rounded-xl' style={{ background: '#8163FF' }}>
                            Cambiar Tarjeta de credito
                        </button>
                    </div>
                    <div className='flex flex-col gap-5'>
                        <div >
                            <h2 className='font-bold'>Detalle del Facturación</h2>
                            <p>Factura o Boleta enviado a : </p>
                        </div>
                        <p className='w-max border-2 rounded-xl py-2 px-4' style={{ borderColor: '#8163FF' }}>prueba@gmail.com</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Subscription