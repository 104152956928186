import React from 'react'
// import { ResponsiveContainer, PieChart, Pie, Legend, Tooltip, Cell } from 'recharts'
import { PieChart, Pie, Sector, Cell } from 'recharts'

const colors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042']
const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
    amount,
    label,
  } = props
  // //console.log('midAngle', midAngle)
  const sin = Math.sin(-RADIAN * midAngle)
  const cos = Math.cos(-RADIAN * midAngle)
  const sx = cx + (outerRadius + 10) * cos
  const sy = cy + (outerRadius + 10) * sin
  const mx = cx + (outerRadius + 30) * cos
  const my = cy + (outerRadius + 30) * sin
  const ex = mx + (cos >= 0 ? 1 : -1) * 22
  const ey = my
  const textAnchor = cos >= 0 ? 'start' : 'end'
  //console.log('payload', payload.name)
  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor='middle' fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      {payload.name !== 'No Data Found' && (
        <React.Fragment>
          <path
            d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
            stroke={fill}
            fill='none'
          />
          <circle cx={ex} cy={ey} r={2} fill={fill} stroke='none' />
          <text
            x={ex + (cos >= 0 ? 1 : -1) * 12}
            y={ey}
            textAnchor={textAnchor}
            fill='#333'
          >{` ${label} `}</text>

          <text
            x={ex + (cos >= 0 ? 1 : -1) * 12}
            y={ey}
            dy={18}
            textAnchor={textAnchor}
            fill='#999'
          >
            {`(Porcentaje ${(percent * 100).toFixed(2)}%)`}
          </text>
        </React.Fragment>
      )}
    </g>
  )
}
const initialState = {
  activeIndex: 0,
}
class SimpleLineChart extends React.Component {
  constructor() {
    super()
    this.state = initialState
  }

  onPieEnter = (data, index) => {
    this.setState({
      activeIndex: index,
    })
  }
  render() {
    return (
      <div className='bg-red-300 text-center'>
        <PieChart width={1200} height={400} className='text-center'>
          <Pie
            activeIndex={this.state.activeIndex}
            // activeShape={renderActiveShape}
            data={this.props.data}
            cx={300}
            cy={200}
            innerRadius={60}
            outerRadius={80}
            onMouseEnter={this.onPieEnter}
          >
            {this.props.data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colors[index]} />
            ))}
          </Pie>
        </PieChart>
      </div>
    )
  }
}

export default SimpleLineChart
