import React, { useContext, useEffect, useState } from 'react'
import Input1 from '../components/Input1'
import axios from 'axios'
import { UserContext } from '../../../context/UserContext'
import Button1 from '../components/Button1'
import Swal from 'sweetalert2'
import TextArea1 from '../components/TextArea1'
import EditorTexto from './EditorTexto'
import UIInputLibroReclamo from './UIInputLibroReclamo'
import Loading from '../../Loading'

const generalInformationBody = {
  storeName: '',
  email: '',
  currencyvalue: '',
  language: '',
  about: {
    info: '',
    address: '',
    district: '',
    timeatention: '',
  },
  whatsapp: '',
  telefono: ''
}

function GeneralInformation() {
  const { user } = useContext(UserContext)
  const [storeData, setStoreData] = useState(generalInformationBody)
  const [loading, setLoading] = useState(true)

  const formattedValueForPhone = (value = '') => {
    return value.replace(/[^\d]/g, '').replace(/(.{9})/g, '$1-');
  }

  const onChange = (e) => {
    let { name, value } = e.target
    let _storeData = Object.assign({}, storeData)
    if (name === 'info') {
      _storeData.about[name] = value
    } else if (name === 'whatsapp') {
      if (!value || value.match(/^\d{1,15}?$/)) _storeData[name] = value
    } else if (name === 'telefono') {
      _storeData[name] = formattedValueForPhone(value);
    } else _storeData[name] = value
    setStoreData(_storeData)
  }
  const getCurrency = (currency) => {
    let _currency = 'No se escogio una moneda'
    if (currency?.startsWith('$')) _currency = 'Peso Mexicano (MXN)'
    else if (currency?.startsWith('S/')) _currency = 'Sol Peruano (PEN)'
    return _currency
  }

  const getLanguague = (language) => {
    let _languague = 'No se escogio un lenguaje'
    if (language?.startsWith('es')) _languague = 'Español - Latinoamérica'
    return _languague
  }

  const getCustomer = async () => {
    const url = `https://ventafazil-strapi-production.up.railway.app/clientes/${user}`
    try {
      const response = await axios.get(url)
      const data = response.data
      if (response.status === 200) {
        const _currency = getCurrency(data.currencyvalue)
        const _languague = getLanguague(data.language)
        let _data = {
          storeName: data.Ntorazonsocial,
          email: data.correoelectronico,
          currencyvalue: _currency,
          language: _languague,
          about: data.about
            ? data.about
            : {
              info: '',
              address: '',
              district: '',
              timeatention: '',
            },
          whatsapp: data.whatsapp,
          telefono: data.telefono
        }
        setStoreData(_data)
      }
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }

  const saveCustomer = async () => {
    setLoading(true)
    const url =
      `https://ventafazil-strapi-production.up.railway.app/clientes/` + user
    const data = {
      Ntorazonsocial: storeData.storeName,
      correoelectronico: storeData.email,
      about: storeData.about,
      whatsapp: storeData.whatsapp,
      telefono: storeData.telefono
    }
    const config = {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_KEY_STRAPI}`,
        'Content-Type': 'application/json',
      },
    }
    try {
      const response = await axios.put(url, data, config)
      if (response.status === 200) {
        setLoading(false)
        Swal.fire('Se guardo la información general', '', 'success')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const Line = () => {
    return <div style={{ border: '3px solid #F2F2F2' }}></div>
  }

  const toastFire = ()=>{
    Swal.fire('Se guardo términos y condiciones', '', 'success')
  }

  useEffect(() => {
    getCustomer()
  }, [])

  return (
    <div className='w-full flex flex-col gap-10 mt-5 px-5 py-10 md:mx-0'>
      {loading && <Loading />}
      <div className='flex flex-col gap-3'>
        <h2 className='font-semibold'>Información general</h2>
        <p className='' style={{ color: '#787878' }}>
          Información tuya y de tu tienda.
        </p>
      </div>
      <div className='flex flex-col gap-4'>
        <Input1
          label='Nombre de la tienda'
          name='storeName'
          placeholder='Taquitos Ernesto'
          value={storeData.storeName}
          onChange={onChange}
        />
        <Input1
          label='Correo electrónico'
          name='email'
          placeholder='tocosernesto@gmail.com'
          value={storeData.email}
          onChange={onChange}
        />
        <Input1
          label='Teléfonos'
          name='telefono'
          placeholder='999888777'
          value={storeData.telefono}
          onChange={onChange}
        />
        <Input1
          label='Whatsapp (Debe empezar con el código del país)'
          name='whatsapp'
          placeholder='999666333'
          value={storeData.whatsapp}
          onChange={onChange}
        />
        <div className='flex gap-10 w-7/12'>
          <Input1
            label='Moneda'
            className='w-full bg-gray-100 cursor-not-allowed'
            name='email'
            value={storeData.currencyvalue}
            disabled
          />
          <Input1
            label='Idioma'
            className='w-full bg-gray-100 cursor-not-allowed'
            name='email'
            value={storeData.language}
            disabled
          />
        </div>
      </div>
      <Line />
      <TextArea1
        labelName='Escribe una breve descripción de tu tienda'
        className='h-60'
        name='info'
        placeholder='Tacos Ernesto comenzó en 1998 atendiendo con mucho amor...'
        value={storeData.about.info}
        onChange={(e) => onChange(e)}
      />
      <div className='flex justify-end'>
        <Button1
          textColor='#ffffff'
          background='#8163FF'
          text='Guardar'
          onClick={saveCustomer}
        />
      </div>
      <Line />
      <div className='flex flex-col gap-4'>
        <label className='font-bold' style={{ color: '#8C8C8C' }}>
          Términos y Condiciones
        </label>
        <EditorTexto setLoading={setLoading} toastFire={toastFire}/>
      </div>
      <UIInputLibroReclamo />
      <Line />
    </div>
  )
}

export default GeneralInformation
