import React from 'react'

function Checkbox1({ name, value,  onChange }) {
    return (
        <div className='relative inline-block w-14 align-middle select-none transition duration-200 ease-in' >
            <input
                type='checkbox'
                name={name}
                className={`absolute block w-6 h-6 rounded-full bg-white border-1 appearance-none cursor-pointer ${value ? 'right-0' : ''}`}
                checked={value}
                value={value}
                onChange={(e) => onChange && onChange(e)}
            />
            <div
                className='block overflow-hidden h-6 rounded-full bg-gray-300 '
                style={value ? { background: '#00FBC8' } : { background: '#CBCBCB' }}
            ></div>
        </div>
    )
}

export default Checkbox1