import { useState, useContext, useEffect, useRef } from 'react'
import DatosNegocioRegistro from '../components/DatosNegocioRegistro'
import NavigationSide from '../components/NavigationSide'
import NavigationTop from '../components/NavigationTop'
import ModalMessage from '../components/ModalMessage'
import ModalImageCrop from '../components/ModalImageCrop'

import { getPublicId } from '../utils/utils.js'
import { provinces } from '../utils/data.js'
import { HexColorPicker } from 'react-colorful'
import Select from 'react-select'
import axios from 'axios'
import CuponsModule from '../components/CuponsModule'

import { UserContext } from '../context/UserContext'
import { LanguageContext } from '../context/languageContext'
import EditorTexto from '../components/EditorTexto'
import UIInputLibroReclamo from '../components/UIInputLibroReclamo'
import { CurrencyContext } from '../context/CurrencyContext'

let subNav = [
  { name: 'Datos de Cuenta', anchor: '#cuenta', active: true },
  // {name: "Dominio", anchor: "#dominio", active:false},
  // {name: "Tipo de Negocio", anchor: "", active:false},
  // {name: "Ubicación y Delivery", anchor: "", active:false},
  // {name: "Metodo de Pago", anchor: "", active:false},
]
let dataAcount = {
  name: '',
  lastname: '',
  urlPage: '',
  shopName: '',
  metapixelid: '',
  GTM: '',
  email: '',
  whatsapp: '',
  doyouhaveavirtualstore: false,
  logo: '',
  primeraimagennosotros: '',
  primarycolor: '',
  telefono: '',
  theme: '',
  currencyvalue: '',
  language: '',
  generalDelivery: {
    price: '',
    takeprice: false,
  },
  socialmedia: {
    facebook: '',
    twitter: '',
    youtube: '',
    instagram: '',
  },
  about: {
    info: '',
    address: '',
    district: '',
    timeatention: '',
  },
}
let minLengths = {
  metapixelid: 55,
  GTM: 55,
  name: 2,
  lastname: 2,
  // shopName: 12,
  email: 12,
  info: 20,
  telefono: 7,
  whatsapp: 9,
  district: 4,
  address: 15,
  timeatention: 3,
  socialmedia: 50,
}
let templates = [
  // { label: 'Minimal', value: 'minimal' },
  { label: 'Delicious', value: 'delicious' },
]
const tipoMoneda = [
  // { label: 'Sol peruano', value: 'S/,PEN' }, //  S/
  { label: 'Peso mexicano', value: '$,MXN' }, //  $
]
const typeLanguage = [
  // { label: 'Español de Perú', value: 'es-PE' },
  { label: 'Español de México', value: 'es-MX' },
  // { label: 'Inglés de Estados Unidos', value: 'en-US' },
]

let listBannersArray = [{ urlbanner: '', link: '' }]
function Cuenta() {
  const { user } = useContext(UserContext)
  const language = useContext(LanguageContext)
  const currency = useContext(CurrencyContext)

  const [textLang, setTextLang] = useState(language.text)
  const [imagePrev, setImagePrev] = useState('')

  const [listSubNav, setListSubNav] = useState(subNav)
  const [acountData, setAcountData] = useState(dataAcount)
  const [minLength, setMinLength] = useState(minLengths)
  const [getuserData, setGetUserData] = useState({})
  const [viewModal, setViewModal] = useState(false)
  const [activeButton, setActiveButton] = useState(true)
  const [filesBanners, setFilesBanners] = useState(listBannersArray)
  const [isvirtualstore, setisvirtualstore] = useState(false)
  const [showModalCropImage, setShowModalCropImage] = useState(false)
  const [logoURL, setLogoURL] = useState('')
  const [indexBanner, setIndexBanner] = useState('')
  const [aboutURL, setAboutURL] = useState('')
  const [color, setColor] = useState('#00000')
  const [loadingImg, setLoadingImg] = useState(false)
  const [domain, setDomain] = useState('')
  const [imageName, setImagename] = useState('')
  const [errors, setErrors] = useState({})

  const [loading, setLoading] = useState(false)
  const [contentModal, setContentModal] = useState({
    title: '',
    text: '',
    linkgo: '',
  })
  const resolution = {
    width: 1080,
    height: 400,
  }

  const aspectRatio = 16 / 9

  useEffect(() => {
    setTextLang(language.text)
  }, [language])

  useEffect(() => {
    axios
      .get(
        `https://ventafazil-strapi-production.up.railway.app/clientes/${user}`
      )
      .then((response) => {
        let dataUser = response.data
        // //console.log('data cliente', response.data);
        if (dataUser.doyouhaveavirtualstore) {
          setisvirtualstore(true)
        }
        setGetUserData(dataUser)
        setFilesBanners(
          response.data.listbanners && response.data.listbanners.length > 0
            ? response.data.listbanners
            : listBannersArray
        )
        setLogoURL(dataUser.logo)
        setColor(dataUser.primarycolor)
        setAboutURL(dataUser.primeraimagennosotros)
        let newbody = {
          name: dataUser.Nombre,
          metapixelid: dataUser.metapixelid,
          urlPage: dataUser.urlPage,
          GTM: dataUser.GTM,
          lastname: dataUser.apelido,
          shopName: dataUser.Ntorazonsocial,
          email: dataUser.correoelectronico,
          ciudad: dataUser.ciudad,
          pass: dataUser.contrasenha,
          reppass: dataUser.contrasenha,
          telefono: dataUser.telefono,
          whatsapp: dataUser.whatsapp,
          doyouhaveavirtualstore: dataUser.doyouhaveavirtualstore,
          logo: dataUser.logo,
          primeraimagennosotros: dataUser.primeraimagennosotros,
          primarycolor: dataUser.primarycolor,
          theme: dataUser.theme,
          currencyvalue: dataUser.currencyvalue,
          language: dataUser.language,

          socialmedia: dataUser.socialmedia
            ? dataUser.socialmedia
            : {
                facebook: '',
                twitter: '',
                youtube: '',
                instagram: '',
              },
          about: dataUser.about
            ? dataUser.about
            : {
                info: '',
                address: '',
                district: '',
                timeatention: '',
              },
        }

        setAcountData(newbody)
      })
      .catch((err) => {
        //console.log(err)
      })
  }, []) //alert aca un loadin conectado al []

  let deleteBeforeImage = async (beforeUrl) => {
    let publicID = getPublicId(beforeUrl)
    let config = {
      method: 'post',
      url: `https://vf-express-production.up.railway.app/api/images/delete`,
      data: {
        public_id: 'VentaFacil/' + publicID,
      },
    }
    await axios(config)
      .then((response) => {
        //console.log(response)
      })
      .catch((error) => {
        //console.log(error)
      })
  }

  let changeImage = async (data, type) => {
    let file = data.target.files[0]
    let formdata = new FormData()
    formdata.append('file', file)
    formdata.append('upload_preset', 'VentaFacil')
    await fetch(
      'https://vf-express-production.up.railway.app/api/images/upload',
      {
        method: 'POST',
        body: formdata,
      }
    )
      .then((response) => response.json())
      .then((response) => {
        //console.log('gello1')

        let newdata = Object.assign({}, acountData)
        if (type === 'logo') {
          if (acountData.logo && acountData.logo !== '') {
            deleteBeforeImage(acountData.logo)
          }

          newdata.logo = response.secure_url
          setAcountData(newdata)
          setLogoURL(response.secure_url)
        } else if (type === 'about') {
          if (
            acountData.primeraimagennosotros &&
            acountData.primeraimagennosotros !== ''
          ) {
            deleteBeforeImage(acountData.primeraimagennosotros)
          }

          newdata.primeraimagennosotros = response.secure_url
          setAcountData(newdata)
          setAboutURL(response.secure_url)
        }

        //console.log('gello')
      })
      .catch((err) => console.log(err))
  }

  let uploadCloudinary = async (data, index, typeBanner) => {
    setActiveButton(false)
    console.log('uploadCloudinary', { data, index })
    await fetch(
      'https://vf-express-production.up.railway.app/api/images/upload',
      {
        method: 'POST',
        body: data,
      }
    )
      .then((response) => response.json())
      .then((response) => {
        let newlist = [...filesBanners]
        if (typeBanner === 'mobile') {
          newlist[index].linkMobile = response.secure_url
        } else {
          newlist[index].link = response.secure_url
          newlist[index].public_id = response.public_id
        }
        //console.log(newlist, 'newlist')
        setFilesBanners(newlist)
        setActiveButton(true)
      })
  }

  let activeItem = (index) => {
    let copyarr = [...listSubNav]
    copyarr.map((el, i) => {
      if (i == index) {
        el.active = true
      } else {
        el.active = false
      }
      return el
    })
    setListSubNav(copyarr)
  }
  let updateDataUser = (userDat) => {
    let config = {
      method: 'put',
      url:
        `https://ventafazil-strapi-production.up.railway.app/clientes/` + user,
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_KEY_STRAPI}`,
      },
      data: {
        Nombre: userDat.name,
        apelido: userDat.lastname,
        urlPage: userDat.urlPage,
        Ntorazonsocial: userDat.shopName,
        correoelectronico: userDat.email,
        socialmedia: userDat.socialmedia,
        whatsapp: userDat.whatsapp,
        ciudad: userDat.ciudad,
        telefono: userDat.telefono,
        about: userDat.about,
        logo: userDat.logo,
        theme: userDat.theme,
        currencyvalue: userDat.currencyvalue,
        language: userDat.language,
        primeraimagennosotros: userDat.primeraimagennosotros,
        doyouhaveavirtualstore: userDat.doyouhaveavirtualstore,
        primarycolor: color,
        listbanners: filesBanners,
        metapixelid: userDat.metapixelid,
        GTM: userDat.GTM,
      },
    }
    axios(config)
      .then((response) => {
        let storageUser = JSON.parse(localStorage.getItem('user'))
        storageUser.currencyvalue = userDat.currencyvalue
        storageUser.language = userDat.language
        language.changeLanguage(storageUser.language)
        currency.changeCurrency(storageUser.currencyvalue)
        localStorage.setItem('user', JSON.stringify(storageUser))
        setViewModal(true)
        setContentModal({
          title: 'Usuario editado',
          text: 'Los datos del usuario han sido editados.',
        })
      })
      .catch((error) => {
        //console.log(error)
      })
  }
  let changeDataAcount = (event) => {
    let newdataacount = Object.assign({}, acountData)
    newdataacount[event.target.name] =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value
    setAcountData(newdataacount)
    setErrors(validationsDataAcount(newdataacount))
  }
  let validationsDataAcount = (e) => {
    let dataAcount = e //acountData
    let minLengths = minLength
    let errors = {}
    let regexName = /^[A-Za-z-~'´¨ÑñÁáÉéÍíÓóÚúÜü\s]+$/
    let regexMetaPixel = /^[a-zA-Z0-9ÑñÁáÉéÍíÓóÚúÜü!,.:/;?¿{}<>()'\]\[\@\s]+$/
    let regexGooglePixel = /^[a-zA-Z0-9ÑñÁáÉéÍíÓóÚúÜü!,.:/;?¿{}<>()'\]\[\@\s]+$/
    let regexAddress = /^[a-zA-Z0-9.-\s]+$/
    let regexInfo =
      /^[a-zA-Z0-9`¨ÑñÁáÉéÍíÓóÚúÜü._!"'^|\]\\\{[}@~#$%&,:;=+*?()/-\s]+$/
    let regexNumber = /^[0-9]+$/
    let regexNumberTel = /^[0-9-/\s]+$/
    let regexTimeAtention = /^[0-9-:/\s]+$/
    let regexSocialMedia = /^[a-zA-Z0-9.:/]+$/
    let regexEmail = /^\b[A-Z0-9._-]+@[A-Z0-9._-]+\.[A-Z]{2,6}\b$/i
    let facebook = 'https://www.facebook.com/'
    let twitter = 'https://www.twitter.com/'
    let instagram = 'https://www.instagram.com/'
    let youtube = 'https://www.youtube.com/'

    if (!dataAcount.metapixelid.trim()) {
      // errors.metapixelid = "El campo 'Pixel Meta' es requerido"
    } else if (!regexMetaPixel.test(dataAcount.metapixelid.trim())) {
      errors.metapixelid =
        "El campo 'Pixel Meta' solo acepta letras, números y estos caracteres ! ( ) < > , . : ; [] / ' @ ?¿ {}"
    } else if (dataAcount.metapixelid.length < minLengths.metapixelid) {
      errors.metapixelid =
        "El campo 'Pixel Meta' debe tener mínimo " +
        minLengths.metapixelid +
        ' caracteres'
    }
    if (!dataAcount.GTM.trim()) {
      // errors.gtm = "El campo 'Pixel Google' es requerido"
    } else if (!regexGooglePixel.test(dataAcount.GTM.trim())) {
      errors.gtm =
        "El campo 'Pixel Google' solo acepta letras, números y estos caracteres ! ( ) < > , . : ; [] / ' @ ?¿ {}"
    } else if (dataAcount.GTM.length < minLengths.GTM) {
      errors.gtm =
        "El campo 'Pixel Google' debe tener mínimo " +
        minLengths.GTM +
        ' caracteres'
    }
    if (!dataAcount.name.trim()) {
      errors.name = "El campo 'Nombres' es requerido"
    } else if (!regexName.test(dataAcount.name.trim())) {
      errors.name =
        "El campo 'Nombres' solo acepta letras, espacios en blanco y los caracteres - ~ ' ´ ¨"
    } else if (dataAcount.name.length < minLengths.name) {
      errors.name =
        "El campo 'Nombres' debe tener mínimo " +
        minLengths.name +
        ' caracteres'
    }
    if (!dataAcount.lastname.trim()) {
      errors.lastname = "El campo 'Apellidos' es requerido"
    } else if (!regexName.test(dataAcount.lastname.trim())) {
      errors.lastname =
        "El campo 'Apellidos' solo acepta letras, espacios en blanco y los caracteres - ~ ' ´ ¨"
    } else if (dataAcount.lastname.length < minLengths.lastname) {
      errors.lastname =
        "El campo 'Apellidos' debe tener mínimo " +
        minLengths.lastname +
        ' caracteres'
    }
    if (!dataAcount.shopName.trim()) {
      errors.shopName = "El campo 'Nombre de tu tienda' es requerido"
    } else if (!regexName.test(dataAcount.shopName.trim())) {
      errors.shopName =
        "El campo 'Nombre de tu tienda' solo acepta letras, espacios en blanco y los caracteres - ~ ' ´ ¨"
    } //else if (dataAcount.shopName.length < minLengths.shopName) {
    //   errors.shopName = "El campo 'Nombre de tu tienda' debe tener mínimo " + minLengths.shopName + " caracteres"
    // }
    if (!dataAcount.email.trim()) {
      errors.email = "El campo 'Correo electrónico' es requerido"
    } else if (!regexEmail.test(dataAcount.email.trim())) {
      errors.email =
        "El campo 'Correo electrónico' es incorrecto o tiene caracteres no válido, solo acepta letras, números y los siguientes caracteres @ - _ ."
    } else if (dataAcount.email.length < minLengths.email) {
      errors.email =
        "El campo 'Correo electrónico' debe tener mínimo " +
        minLengths.email +
        ' caracteres'
    }
    if (!dataAcount.about.info.trim()) {
      errors.info = "El campo 'Presentación' es requerido"
    } else if (!regexInfo.test(dataAcount.about.info.trim())) {
      errors.info =
        "El campo 'Presentación' solo acepta letras, números, espacios en blanco y caracteres especiales para texto"
    } else if (dataAcount.about.info.length < minLengths.info) {
      errors.info =
        "El campo 'Presentación' debe tener mínimo " +
        minLengths.info +
        ' caracteres'
    }
    if (!dataAcount.telefono.trim()) {
      errors.telefono = "El campo 'Teléfonos' es requerido"
    } else if (!regexNumberTel.test(dataAcount.telefono.trim())) {
      errors.telefono =
        "El campo 'Teléfonos' solo acepta números, espacios en blanco y estos caracteres - /"
    } else if (dataAcount.telefono.length < minLengths.telefono) {
      errors.telefono =
        "El campo 'Teléfonos' debe tener mínimo " +
        minLengths.telefono +
        ' caracteres'
    }
    if (!dataAcount.whatsapp.trim()) {
      errors.whatsapp = "El campo 'WhatsApp' es requerido"
    } else if (!regexNumber.test(dataAcount.whatsapp.trim())) {
      errors.whatsapp = "El campo 'WhatsApp' solo acepta números"
    } else if (dataAcount.whatsapp.length < minLengths.whatsapp) {
      errors.whatsapp =
        "El campo 'WhatsApp' debe tener mínimo " +
        minLengths.whatsapp +
        ' caracteres'
    }
    if (!dataAcount.about.district.trim()) {
      errors.district = "El campo 'Distrito' es requerido"
    } else if (!regexName.test(dataAcount.about.district.trim())) {
      errors.district =
        "El campo 'Distrito' solo acepta letras y espacios en blanco"
    } else if (dataAcount.about.district.length < minLengths.district) {
      errors.district =
        "El campo 'Distrito' debe tener mínimo " +
        minLengths.district +
        ' caracteres'
    }
    if (!dataAcount.about.address.trim()) {
      //errors.address = "El campo 'Dirección' es requerido"
    } else if (!regexAddress.test(dataAcount.about.address.trim())) {
      errors.address =
        "El campo 'Dirección' solo acepta letras, números, espacios en blanco y estos caracteres . -"
    } else if (dataAcount.about.address.length < minLengths.address) {
      errors.address =
        "El campo 'Dirección' debe tener mínimo " +
        minLengths.address +
        ' caracteres'
    }
    if (!dataAcount.about.timeatention.trim()) {
      errors.timeatention = "El campo 'Horarios de atención' es requerido"
    } else if (!regexTimeAtention.test(dataAcount.about.timeatention.trim())) {
      errors.timeatention =
        "El campo 'Horarios de atención' solo acepta números, espacios en blanco y estos caracteres : - /"
    } else if (dataAcount.about.timeatention.length < minLengths.timeatention) {
      errors.timeatention =
        "El campo 'Horarios de atención' debe tener mínimo " +
        minLengths.timeatention +
        ' caracteres'
    }
    if (!dataAcount.socialmedia.facebook.trim()) {
      //errors.address = "El campo 'Facebook' es requerido"
    } else if (!dataAcount.socialmedia.facebook.startsWith(facebook)) {
      errors.facebook =
        "El campo 'Facebook' es incorrecto, debe comenzar '" + facebook + "'"
    } else if (!regexSocialMedia.test(dataAcount.socialmedia.facebook.trim())) {
      errors.facebook =
        "El campo 'Facebook' es incorrecto, solo se aceptan letras, números y estos caracteres / : ."
    } else if (
      dataAcount.socialmedia.facebook.length < minLengths.socialmedia
    ) {
      errors.facebook =
        "El campo 'Facebook' debe tener mínimo " +
        minLengths.socialmedia +
        ' caracteres'
    }
    if (!dataAcount.socialmedia.twitter.trim()) {
      //errors.address = "El campo 'Twitter' es requerido"
    } else if (!dataAcount.socialmedia.twitter.startsWith(twitter)) {
      errors.twitter =
        "El campo 'Twitter' es incorrecto, debe comenzar '" + twitter + "'"
    } else if (!regexSocialMedia.test(dataAcount.socialmedia.twitter.trim())) {
      errors.twitter =
        "El campo 'Twitter' es incorrecto, solo se aceptan números, letras y estos caracteres / : ."
    } else if (dataAcount.socialmedia.twitter.length < minLengths.socialmedia) {
      errors.twitter =
        "El campo 'Twitter' debe tener mínimo " +
        minLengths.socialmedia +
        ' caracteres'
    }
    if (!dataAcount.socialmedia.instagram.trim()) {
      //errors.address = "El campo 'Instagram' es requerido"
    } else if (!dataAcount.socialmedia.instagram.startsWith(instagram)) {
      errors.instagram =
        "El campo 'Instagram' es incorrecto, debe comenzar '" + instagram + "'"
    } else if (
      !regexSocialMedia.test(dataAcount.socialmedia.instagram.trim())
    ) {
      errors.instagram =
        "El campo 'Instagram' es incorrecto, solo se aceptan números, letras y estos caracteres / : ."
    } else if (
      dataAcount.socialmedia.instagram.length < minLengths.socialmedia
    ) {
      errors.instagram =
        "El campo 'Instagram' debe tener mínimo " +
        minLengths.socialmedia +
        ' caracteres'
    }
    if (!dataAcount.socialmedia.youtube.trim()) {
      //errors.address = "El campo 'Youtube' es requerido"
    } else if (!dataAcount.socialmedia.youtube.startsWith(youtube)) {
      errors.youtube =
        "El campo 'Youtube' es incorrecto, debe comenzar '" + youtube + "'"
    } else if (!regexSocialMedia.test(dataAcount.socialmedia.youtube.trim())) {
      errors.youtube =
        "El campo 'Youtube' es incorrecto, solo se aceptan números, letras y estos caracteres / : ."
    } else if (dataAcount.socialmedia.youtube.length < minLengths.socialmedia) {
      errors.youtube =
        "El campo 'Youtube' debe tener mínimo " +
        minLengths.socialmedia +
        ' caracteres'
    }
    // //console.log("ERRORS",errors)
    return errors
  }
  let changeSocial = (event) => {
    let newdataacount = Object.assign({}, acountData)
    newdataacount.socialmedia[event.target.name] = event.target.value
    setAcountData(newdataacount)
    setErrors(validationsDataAcount(newdataacount))
  }
  let changeAbout = (event) => {
    let newdataacount = Object.assign({}, acountData)
    newdataacount.about[event.target.name] = event.target.value
    console.log(newdataacount.about)
    setAcountData(newdataacount)
    setErrors(validationsDataAcount(newdataacount))
  }
  let closeModal = (val) => {
    setViewModal(val)
  }

  const uploadCropperBanner = (e, index) => {
    let files
    let file = e.target.files[0]
    if (e.dataTransfer) {
      files = e.dataTransfer.files
    } else if (e.target) {
      files = e.target.files
    }
    setImagename(files[0].name)
    const reader = new FileReader()
    setIndexBanner(index)
    reader.onload = () => {
      setImagePrev(reader.result)
      let newlist = [...filesBanners]
      newlist[index].link = reader.result
      setFilesBanners(newlist)
    }
    reader.readAsDataURL(files[0])
    setShowModalCropImage(true)
  }

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], filename, { type: mime })
  }

  const cutbanner = (img, index) => {
    let imgtrasnform = dataURLtoFile(img, imageName)
    let data = new FormData()
    // let newlist = [...filesBanners];
    // newlist[index].link = img;
    data.append('file', imgtrasnform)
    data.append('upload_preset', 'VentaFacil')
    console.log('CUTBANNER', { img, index }, data)
    uploadCloudinary(data, index)
    // setFilesBanners(newlist)
  }

  let changeUrlBanner = (event, index) => {
    let newlist = [...filesBanners]
    newlist[index].urlbanner = event.target.value
    setFilesBanners(newlist)
  }
  let addListBanner = (index) => {
    let newlist = [...filesBanners]
    let newbody = { urlbanner: '', link: '', linkMobile: '' }
    newlist.splice(index + 1, 0, newbody)
    setActiveButton(false)
    setFilesBanners(newlist)
  }
  let removeListBanner = (index) => {
    let newlist = [...filesBanners]
    newlist.splice(index, 1)
    setActiveButton(true)
    setFilesBanners(newlist)
  }
  let changeVirtualMarket = (e) => {
    setisvirtualstore(!isvirtualstore)
    let newdataacount = Object.assign({}, acountData)
    newdataacount.doyouhaveavirtualstore = !newdataacount.doyouhaveavirtualstore
    if (isvirtualstore === false) {
      newdataacount.about.district = ''
      newdataacount.about.address = ''
    }
    setAcountData(newdataacount)
  }
  return (
    <div className='pb-24 bg-gray-50'>
      {viewModal && (
        <ModalMessage
          title={contentModal.title}
          text={contentModal.text}
          deniedBtn={false}
          textButton='Aceptar'
          linkgo={contentModal.linkgo}
          acceptModal={() => closeModal()}
        />
      )}
      <NavigationTop />
      <NavigationSide />
      {showModalCropImage && (
        <ModalImageCrop
          resolution={resolution}
          aspectRatio={aspectRatio}
          cutbanner={cutbanner}
          type={'banners'}
          data={filesBanners}
          index={indexBanner}
          setShowModalCropImage={setShowModalCropImage}
          image={imagePrev}
          removeListBanner={removeListBanner}
        />
      )}
      <div className='md:ml-10'>
        <div className='py-6 bg-white md:pl-28'>
          <div className='Zcontainer py-4 mx-auto'>
            <div className='container flex items-start content-start flex-grow gap-3'>
              <h2 className='flex flex-grow text-3xl font-bold tracking-tighter'>
                Mi Tienda
                {/* {textLang.account.myStore} */}
              </h2>
            </div>
            <div className='container flex flex-row gap-6 tracking-tighter bg-white black-slate-700 rounded-xl'>
              {listSubNav.map((item, i) => {
                return (
                  <a
                    key={i}
                    href={item.anchor}
                    className={`text-lg ${item.active ? 'font-bold' : ''}`}
                    onClick={() => activeItem(i)}
                  >
                    {item.name}
                  </a>
                )
              })}
              {/* {textLang.account.accountData} */}
            </div>
          </div>
        </div>
        <div id='cuenta' className='container px-6 md:ml-28 w-auto'>
          <div className='p-4 mb-10 bg-gray-200 rounded-lg'>
            <p className='font-bold text-gray-500'>Plantilla</p>
            <p className='text-gray-600'>Selecciona el tipo de plantilla</p>

            <div className='flex flex-col gap-2 pt-4 md:flex-row'>
              <label className='flex flex-col text-xs font-bold text-gray-700 md:w-6/12'>
                Plantilla
                <Select
                  className='w-full py-2 focus:border-yellow-500 '
                  closeMenuOnSelect={true}
                  onChange={(e) => {
                    setAcountData({
                      ...acountData,
                      theme: e.value,
                    })
                  }}
                  value={{
                    value: acountData.theme,
                    label: acountData.theme,
                  }}
                  options={templates}
                />
                {/* <select onChange={(e)=>{setAcountData({...acountData,theme:e.target.value})}} className='h-12 p-2 bg-gray-100 rounded-md'>
                  <option className="py-2" value={null} disabled>Seleccionar template</option>
                  {templates.map((template,i)=>{
                    return <option className="py-2" key={i} value={template.value}>{template.name}</option>
                  })}
                </select> */}
              </label>
            </div>
          </div>

          <div className='p-4 mb-10 bg-gray-200 rounded-lg'>
            <p className='font-bold text-gray-500'>Pixel</p>
            <div className='w-full'>
              <p className='text-gray-600'>Pixel Meta</p>
              <label className='flex flex-col w-full mt-2 text-xs font-bold text-gray-700'>
                <textarea
                  type='text'
                  className='h-20 p-2 bg-gray-100 rounded-md resize-none'
                  name='metapixelid'
                  placeholder='Insertar el pixel de meta'
                  value={acountData.metapixelid}
                  onChange={(e) => changeDataAcount(e)}
                ></textarea>
                {errors.metapixelid && (
                  <p className='p-1 text-sm text-red-600'>
                    {errors.metapixelid}
                  </p>
                )}
              </label>
            </div>
            <div className='w-full mt-6'>
              <p className='text-gray-600'>Pixel Google</p>
              <label className='flex flex-col w-full mt-2 text-xs font-bold text-gray-700'>
                <textarea
                  type='text'
                  className='h-20 p-2 bg-gray-100 rounded-md resize-none'
                  name='GTM'
                  placeholder='Insertar el pixel de meta'
                  value={acountData.GTM}
                  onChange={(e) => changeDataAcount(e)}
                ></textarea>
                {errors.gtm && (
                  <p className='p-1 text-sm text-red-600'>{errors.gtm}</p>
                )}
              </label>
            </div>
          </div>

          <div className='p-4 bg-gray-200 rounded-lg'>
            <p className='font-bold text-gray-500'>Información general</p>
            <div className='flex flex-col gap-6 pt-4 md:flex-row'>
              <label className='flex flex-col flex-grow text-xs font-bold text-gray-700'>
                Nombres:
                <input
                  type='text'
                  value={acountData.name}
                  onChange={(e) => changeDataAcount(e)}
                  className='flex-grow h-12 p-2 text-sm bg-gray-100 rounded-md'
                  name='name'
                  placeholder='Ingrese su texto'
                />
                {errors.name && (
                  <p className='p-1 text-sm text-red-600'>{errors.name}</p>
                )}
              </label>
              <label className='flex flex-col flex-grow text-xs font-bold text-gray-700'>
                Apellidos:
                <input
                  type='text'
                  value={acountData.lastname}
                  onChange={(e) => changeDataAcount(e)}
                  className='h-12 p-2 text-sm bg-gray-100 rounded-md'
                  name='lastname'
                  placeholder='Franco Escamila...'
                />
                {errors.lastname && (
                  <p className='p-1 text-sm text-red-600'>{errors.lastname}</p>
                )}
              </label>
            </div>
            <div className='flex flex-col gap-4 pt-4 md:items-center md:flex-row'>
              <label className='flex flex-col py-2 text-xs font-bold text-gray-700 md:w-6/12'>
                Nombre de tu tienda:
                <input
                  type='text'
                  value={acountData.shopName}
                  onChange={(e) => changeDataAcount(e)}
                  className='h-12 p-2 bg-gray-100 rounded-md'
                  name='shopName'
                  placeholder='Nombre de la tienda'
                />
                {errors.shopName && (
                  <p className='p-1 text-sm text-red-600'>{errors.shopName}</p>
                )}
              </label>
              <label className='flex flex-col text-xs font-bold text-gray-700 md:w-6/12'>
                Correo electrónico:
                <input
                  type='email'
                  value={acountData.email}
                  onChange={(e) => changeDataAcount(e)}
                  className='h-12 p-2 bg-gray-100 rounded-md'
                  name='email'
                  placeholder='mitienda@mitienda.com'
                />
                {errors.email && (
                  <p className='p-1 text-sm text-red-600'>{errors.email}</p>
                )}
              </label>
            </div>
            <div className='flex flex-col gap-6 pt-4 md:flex-row'>
              <label className='text-xs font-bold text-gray-700 md:w-6/12'>
                Tipo de moneda:
                <Select
                  className='w-full py-2 focus:border-yellow-500'
                  closeMenuOnSelect={true}
                  // defaultValue={tipoMoneda[0]}
                  onChange={(e) => {
                    setAcountData({
                      ...acountData,
                      currencyvalue: e.value,
                    })
                    // console.log("E",e)
                    // console.log("acountData", acountData)
                  }}
                  value={{
                    label: acountData.currencyvalue?.split(',').pop(), //tipoMoneda[tipoMoneda.findIndex(e => e.value == acountData.currencyvalue)]?.label + ' ' + acountData.currencyvalue.split(',')[0]+ ' ' + acountData.currencyvalue.split(',')[1],
                    value: acountData.currencyvalue,
                  }}
                  options={tipoMoneda}
                />
              </label>
              <label className='text-xs font-bold text-gray-700 md:w-6/12'>
                Tipo de idioma:
                <Select
                  className='w-full py-2 focus:border-yellow-500'
                  closeMenuOnSelect={true}
                  // defaultValue={typeLanguage[0]}
                  onChange={(e) => {
                    setAcountData({
                      ...acountData,
                      language: e.value,
                    })
                    // console.log("E",e)
                    // console.log("acountData", acountData)
                  }}
                  value={{
                    label: acountData.language,
                    value: acountData.language,
                  }}
                  options={typeLanguage}
                />
              </label>
            </div>
            <div className='flex flex-col gap-4 pt-4 md:items-top md:flex-row'>
              <label className='flex flex-col py-2 text-xs font-bold text-gray-700 md:w-6/12'>
                Color:
                <div className='flex flex-col md:flex-row'>
                  <input
                    type='email'
                    value={color}
                    onChange={(e) => changeDataAcount(e)}
                    name='primarycolor'
                    className='w-3/12 h-12 p-2 bg-gray-100 rounded-md'
                  />
                  <div
                    className='w-12 h-12 p-2 ml-10 bg-gray-100 rounded-full'
                    style={{ backgroundColor: color }}
                  ></div>
                </div>
              </label>
              <label className='flex flex-col pt-4 text-xs font-bold text-gray-700 md:w-6/12'>
                <HexColorPicker color={color} onChange={setColor} />
              </label>
            </div>
            <div className='flex flex-row gap-6 pt-4'>
              <label className='flex flex-col w-full py-2 text-xs font-bold text-gray-700'>
                Logotipo:
                <input
                  type='file'
                  className='h-12 p-2 pt-3 bg-gray-100 rounded-md'
                  name='name'
                  placeholder='Nombre de la tienda'
                  onChange={(e) => changeImage(e, 'logo')}
                />
              </label>
            </div>
            <div className='w-2/12'>
              <img className='w-full h-auto' src={logoURL} alt='' />
            </div>
          </div>

          <div className='p-4 mt-12 bg-gray-200 rounded-lg md:mt-24'>
            <p className='font-bold text-gray-500'>Nosotros</p>
            <label className='flex flex-col w-full pt-6 text-xs font-bold text-gray-700'>
              Presentación
              <textarea
                type='text'
                className='h-40 p-2 bg-gray-100 rounded-md resize-none'
                name='info'
                placeholder='Nosotros'
                value={acountData.about.info}
                onChange={(e) => changeAbout(e)}
              ></textarea>
              {errors.info && (
                <p className='p-1 text-sm text-red-600'>{errors.info}</p>
              )}
            </label>
            <label className='flex py-4 text-xs font-bold text-gray-700'>
              <input
                className='mr-2'
                type='checkbox'
                name='district'
                checked={acountData.doyouhaveavirtualstore}
                onChange={(e) => changeVirtualMarket(e)}
              />
              Soy una tienda virtual
            </label>
            <div className='flex flex-col gap-2 pt-4 md:flex-row'>
              <label className='flex flex-col text-xs font-bold text-gray-700 md:w-6/12'>
                Telefonos
                <input
                  type='text'
                  className='h-12 p-2 bg-gray-100 rounded-md'
                  name='telefono'
                  placeholder='Celular o fijo'
                  value={acountData.telefono}
                  onChange={(e) => changeDataAcount(e)}
                />
                {errors.telefono && (
                  <p className='p-1 text-sm text-red-600'>{errors.telefono}</p>
                )}
              </label>
              <label className='flex flex-col text-xs font-bold text-gray-700 md:w-6/12'>
                WhatsApp
                <input
                  type='text'
                  className='h-12 p-2 bg-gray-100 rounded-md'
                  name='whatsapp'
                  placeholder='999356444'
                  value={acountData.whatsapp}
                  onChange={(e) => changeDataAcount(e)}
                />
                {errors.whatsapp && (
                  <p className='p-1 text-sm text-red-600'>{errors.whatsapp}</p>
                )}
                <div className='mt-2 font-light text-gray-700 opacity-80'>
                  {' '}
                  No necesitas añadir el +51 en tu número de WhatsApp
                </div>
              </label>
            </div>
            <label className='flex flex-col w-full mt-4 mb-1 text-sm font-semibold tracking-tight text-gray-700'>
              <div className=''>Seleccionar Provincias</div>
              <Select
                className='w-full py-2 focus:border-yellow-500 '
                closeMenuOnSelect={true}
                onChange={(e) => {
                  setAcountData({
                    ...acountData,
                    ciudad: e.value,
                  })
                }}
                value={{
                  value: acountData.ciudad,
                  label: acountData.ciudad,
                }}
                options={provinces}
              />
            </label>
            <div className='flex flex-col gap-2 pt-4 md:flex-row'>
              <label className='flex flex-col text-xs font-bold text-gray-700 md:w-6/12'>
                Distrito
                <input
                  type='text'
                  className='h-12 p-2 rounded-md'
                  name='district'
                  disabled={isvirtualstore === true}
                  placeholder='av. calle. urb. '
                  value={acountData.about.district}
                  onChange={(e) => changeAbout(e)}
                />
                {errors.district && (
                  <p className='p-1 text-sm text-red-600'>{errors.district}</p>
                )}
              </label>
              <label className='flex flex-col text-xs font-bold text-gray-700 md:w-6/12'>
                Dirección
                <input
                  type='text'
                  className='h-12 p-2 rounded-md'
                  name='address'
                  disabled={isvirtualstore === true}
                  placeholder='av. calle. urb. '
                  value={acountData.about.address}
                  onChange={(e) => changeAbout(e)}
                />
                {errors.address && (
                  <p className='p-1 text-sm text-red-600'>{errors.address}</p>
                )}
              </label>
            </div>

            <label className='flex flex-col w-full py-4 text-xs font-bold text-gray-700'>
              Horarios de atención
              <input
                type='text'
                className='h-12 p-2 bg-gray-100 rounded-md'
                name='timeatention'
                placeholder='De: 7:00am - 9:00pm'
                value={acountData.about.timeatention}
                onChange={(e) => changeAbout(e)}
              />
              {errors.timeatention && (
                <p className='p-1 text-sm text-red-600'>
                  {errors.timeatention}
                </p>
              )}
            </label>
            <label className='flex flex-col w-full py-4 text-xs font-bold text-gray-700'>
              Imagen
              <input
                type='file'
                className='h-12 p-2 mb-4 bg-gray-100 rounded-md'
                onChange={(e) => changeImage(e, 'about')}
              />
              <img className='w-4/12' src={aboutURL} />
            </label>
          </div>

          <div className='p-4 mt-12 bg-gray-200 rounded-lg md:mt-24'>
            <p className='font-bold text-gray-500'>Redes Sociales</p>
            <p className='text-xs font-bold text-gray-700'>
              Tus redes son importante para que tus clientes siempre estén
              actualizados.
            </p>
            <div className='flex flex-col gap-2 pt-4 md:flex-row'>
              <label className='flex flex-col text-xs font-bold text-gray-700 md:w-6/12'>
                Facebook
                <input
                  type='text'
                  className='h-12 p-2 bg-gray-100 rounded-md'
                  name='facebook'
                  placeholder='https://www.facebook.com/mitienda'
                  value={acountData.socialmedia.facebook}
                  onChange={(e) => changeSocial(e)}
                />
                {errors.facebook && (
                  <p className='p-1 text-sm text-red-600'>{errors.facebook}</p>
                )}
              </label>
              <label className='flex flex-col text-xs font-bold text-gray-700 md:w-6/12'>
                Twitter
                <input
                  type='text'
                  className='h-12 p-2 bg-gray-100 rounded-md'
                  name='twitter'
                  placeholder='https://www.twitter.com/mitienda'
                  value={acountData.socialmedia.twitter}
                  onChange={(e) => changeSocial(e)}
                />
                {errors.twitter && (
                  <p className='p-1 text-sm text-red-600'>{errors.twitter}</p>
                )}
              </label>
            </div>
            <div className='flex flex-col gap-2 pt-4 md:flex-row'>
              <label className='flex flex-col text-xs font-bold text-gray-700 md:w-6/12'>
                Instagram
                <input
                  type='text'
                  className='h-12 p-2 bg-gray-100 rounded-md'
                  name='instagram'
                  placeholder='https://www.instagram.com/mitienda'
                  value={acountData.socialmedia.instagram}
                  onChange={(e) => changeSocial(e)}
                />
                {errors.instagram && (
                  <p className='p-1 text-sm text-red-600'>{errors.instagram}</p>
                )}
              </label>
              <label className='flex flex-col text-xs font-bold text-gray-700 md:w-6/12'>
                Youtube
                <input
                  type='email'
                  className='h-12 p-2 bg-gray-100 rounded-md'
                  name='youtube'
                  placeholder='https://www.youtube.com/mitienda'
                  value={acountData.socialmedia.youtube}
                  onChange={(e) => changeSocial(e)}
                />
                {errors.youtube && (
                  <p className='p-1 text-sm text-red-600'>{errors.youtube}</p>
                )}
              </label>
            </div>
          </div>
          <div className='p-4 mt-12 bg-gray-200 rounded-lg md:mt-24'>
            <p className='font-bold text-gray-500'>Banners</p>
            <p className='text-xs font-bold text-gray-700'>
              Estas imágenes se verán en la vista principal de tu tienda
            </p>
            {filesBanners.map((banner, i) => {
              return (
                <div
                  key={i}
                  className='flex flex-col items-center justify-start w-full md:flex-row'
                >
                  <div className='flex flex-col w-full gap-2 pt-4 md:flex-row'>
                    <label className='flex flex-col text-xs font-bold text-gray-700 md:w-6/12'>
                      URL
                      <input
                        type='text'
                        className='h-12 p-2 bg-gray-100 rounded-md'
                        name='url'
                        placeholder='Link de imagen'
                        value={banner.urlbanner}
                        onChange={(e) => changeUrlBanner(e, i)}
                      />
                    </label>
                    <label className='flex flex-col text-xs font-bold text-gray-700 cursor-pointer md:w-3/12'>
                      Imagen para PC
                      <div className='relative w-full h-auto'>
                        {banner.link === '' ? (
                          <input
                            className='w-48'
                            type='file'
                            name='banners'
                            placeholder='Seleccionar Imagen'
                            onChange={(e) => uploadCropperBanner(e, i)}
                          />
                        ) : (
                          <input
                            className='absolute z-0 w-0'
                            type='file'
                            name='banners'
                            placeholder='Seleccionar Imagen'
                            onChange={(e) => uploadCropperBanner(e, i)}
                          />
                        )}
                        {banner.link === null ? (
                          <div className='w-6/12'>Cargando...</div>
                        ) : (
                          <img className='w-6/12' src={banner.link} />
                        )}
                      </div>
                    </label>
                  </div>
                  <div
                    onClick={() => addListBanner(i)}
                    className='px-4 py-2 text-white bg-gray-600 rounded-full cursor-pointer'
                  >
                    +
                  </div>
                  {filesBanners.length !== 1 ? (
                    <div
                      onClick={() => removeListBanner(i)}
                      className='px-4 py-2 text-white bg-gray-600 rounded-full cursor-pointer'
                    >
                      -
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              )
            })}
          </div>

          {/* Editor de Texto*/}

          <div className='flex flex-col gap-4 px-4 py-8 mt-4 bg-gray-200 rounded-sm'>
            <div className='font-bold text-gray-500'>
              Términos y Condiciones
            </div>
            <EditorTexto className='bg-white '></EditorTexto>
          </div>

          {/* checkbox libro de reclamaciones visible */}

          <div>
            <UIInputLibroReclamo></UIInputLibroReclamo>
          </div>

          {/* /CUPONS GOES HERE */}
          <CuponsModule />

          <div className='flex flex-row items-start content-start justify-center flex-grow mt-6'>
            {activeButton ? (
              <button
                onClick={() => updateDataUser(acountData)}
                className='w-full p-2 px-4 text-sm font-bold text-center bg-yellow-400 rounded-md cursor-pointer md:w-1/4'
              >
                Guardar cambios
              </button>
            ) : (
              <button className='w-full p-2 px-4 text-sm font-bold text-center bg-yellow-200 rounded-md cursor-pointer md:w-1/4'>
                Guardar Cambios
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Cuenta
