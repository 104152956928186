import { useState } from "react"

function TableActions({ actionUpdate, actionDelete }) {
    const [viewActions, setViewActions] = useState(false)

    return (
        <>
            <td className="py-2 px-4 text-center">
                <button
                    className="py-2 px-4 zfont-semibold text-white"
                    style={{ color: '#8163FF' }}
                    onClick={() => actionUpdate()}
                >Editar</button>
            </td>
            <td className="py-2 px-4 text-center relative">
                <button
                    className="py-2 px-4 font-bold text-xl"
                    style={{ color: '#8163FF' }}
                    onClick={() => setViewActions(!viewActions)}
                >...</button>
                {
                    viewActions && (
                        <div className='absolute top-14 right-10'>
                            <ul className='bg-white border rounded-md py-1'>
                                <li className='px-4 py-2 hover:bg-gray-200 cursor-pointer' style={{ color: '#FF0000' }}
                                    onClick={() => {
                                        actionDelete()
                                    }}>Eliminar
                                </li>
                            </ul>
                        </div>
                    )
                }
            </td>
        </>
    )
}

export default TableActions