import React, { useContext } from 'react'
import NavigationSide from '../components/NavigationSide'
import NavigationTop from '../components/NavigationTop'
import axios from 'axios'
import { useEffect } from 'react'
import { useState } from 'react'
import Select from 'react-select'
import Swal from 'sweetalert2'
import { LanguageContext } from '../context/languageContext'
import { useHistory, useLocation } from 'react-router-dom'
import loadTranslations from '../utils/loadTranslation'

const NewShop = () => {
  let storageUser = JSON.parse(localStorage.getItem('user'))

  const paisL = storageUser.paises.name
  const { state } = useLocation()
  let history = useHistory()
  console.log('MU', state)
  const translations = loadTranslations(paisL)
  // console.log(translations)
  if (history.location.pathname === '/delivery/tienda-recojo/edit' && !state) {
    console.log('change')
    history.push('/delivery')
  }

  const [filtroData, setfiltroData] = useState([])
  const [oneDivision, setOneDivision] = useState('')
  const [twoDivision, setTwoDivision] = useState('')
  const [thirdDivision, setThirdDivision] = useState('')
  const [error, setError] = useState(false)
  const [info, setInfo] = useState({
    adress: '',
    name: '',
    conditions: '',
  })

  const consultarPickPoint = async () => {
    const url = `https://ventafazil-strapi-production.up.railway.app/Pickpoints/${state.id}`
    const { data } = await axios.get(url)
    console.log(data)
    setInfo({
      ...info,
      name: data.name,
      conditions: data.conditions,
      adress: data.adress,
    })

    switch (pais.numberofdivision) {
      case 2:
        setOneDivision({ values: data.onedivision, label: data.onedivision })
        setTwoDivision({ values: data.twodivision, label: data.twodivision })

        break

      default:
        setOneDivision({ values: data.onedivision, label: data.onedivision })
        setTwoDivision({ values: data.twodivision, label: data.twodivision })
        setThirdDivision({
          values: data.threedivision,
          label: data.threedivision,
        })

        break
    }
  }

  useEffect(() => {
    if (state) {
      consultarPickPoint()
    }
  }, [])

  const [validationErrors, setValidationErrors] = useState(false)

  const { adress, name, conditions } = info
  const clientID = JSON.parse(localStorage.getItem('user')).id
  const [loading, setloading] = useState(true)

  const [oneOptions, setOneOptions] = useState([])
  const [secondOptions, setSecondOptions] = useState([])
  const [thirdOptions, setThirdOptions] = useState([])

  const handleChange = (e) => {
    setInfo({ ...info, [e.target.name]: e.target.value })
  }

  let pais = JSON.parse(localStorage.getItem('user')).paises
  let country = pais.id

  const consultarOneDivision = async () => {
    setloading(true)
    try {
      let url = `https://ventafazil-strapi-production.up.railway.app/Onedivisions?country=${country}&_limit=-1`

      const { data } = await axios.get(url)

      console.log('esta data', data)

      const mapOptions = data.map((option) => ({
        value: option.id,
        label: option.name,
      }))
      setOneOptions(mapOptions)

      setloading(false)
    } catch (error) {
      console.log(error)
      setError(true)
    }
  }

  const consultarTwoDivisions = async () => {
    let url
    if (state) {
      const filtereD = oneOptions.find(
        (option) => option.label === oneDivision.values
      )
      if (filtereD) {
        url = `https://ventafazil-strapi-production.up.railway.app/Twodivisions?onedivision=${filtereD.value}&_limit=-1`
      } else {
        url = `https://ventafazil-strapi-production.up.railway.app/Twodivisions?onedivision=${oneDivision.value}&_limit=-1`
      }

      console.log('filtere d', filtereD)
    } else {
      url = `https://ventafazil-strapi-production.up.railway.app/Twodivisions?onedivision=${oneDivision.value}&_limit=-1`
    }

    const { data } = await axios.get(url)

    const mapOptions = data.map((option) => ({
      value: option.id,
      label: option.name,
    }))
    setSecondOptions(mapOptions)
  }

  useEffect(() => {
    consultarOneDivision()
  }, [])

  useEffect(() => {
    if (oneDivision && oneOptions.length > 0) {
      consultarTwoDivisions()
    }
  }, [oneDivision, oneOptions])

  const consultarThreeDivisions = async () => {
    const url = `https://ventafazil-strapi-production.up.railway.app/Threedivisions?twodivision=${twoDivision.value}&_limit=-1`

    const { data } = await axios.get(url)

    const mapOptions = data.map((option) => ({
      value: option.id,
      label: option.name.trim(),
    }))

    setThirdOptions(mapOptions)
  }

  useEffect(() => {
    if (pais.numberofdivision === 3) {
      if (twoDivision) consultarThreeDivisions()
    }
  }, [twoDivision])

  function handleSelectChange1(selectedOption) {
    setOneDivision(selectedOption)
    setTwoDivision('')
    setThirdDivision('')
  }
  function handleSelectChange2(selectedOption) {
    setThirdDivision('')
    setTwoDivision(selectedOption)
  }

  function handleSelectChange3(selectedOption) {
    setThirdDivision(selectedOption)
  }

  const submit = async () => {
    if (pais.numberofdivision == 2) {
      if (!name || !adress || !twoDivision) {
        setValidationErrors(true)
        setTimeout(() => {
          setValidationErrors(false)
        }, 2000)

        return
      }
    } else {
      if (!name || !adress || !thirdDivision) {
        setValidationErrors(true)
        setTimeout(() => {
          setValidationErrors(false)
        }, 2000)

        return
      }
    }

    // setInfo({ ...info, place: thirdDivision.label })

    let date = info
    // const [oneDivision, setOneDivision] = useState('')
    // const [twoDivision, setTwoDivision] = useState('')
    // const [thirdDivision, setThirdDivision] = useState('')
    console.log('TEX00', oneDivision)
    if (pais.numberofdivision == 2) {
      info.onedivision = oneDivision.label
      info.twodivision = twoDivision.label
    } else {
      info.threedivision = thirdDivision.label
    }

    info.place =
      pais.numberofdivision === 2 ? twoDivision.label : thirdDivision.label
    info.cliente = { _id: clientID }
    info.country = country
    console.log(date)

    if (state) {
      try {
        console.log(date)
        const { data } = await axios.put(
          `https://ventafazil-strapi-production.up.railway.app/Pickpoints/${state.id}`,
          date,
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_KEY_STRAPI}`,

              'Content-Type': 'application/json',
            },
          }
        )

        Swal.fire('Se edito un punto de recojo con exito ', '', 'success')
        history.push('/delivery')
      } catch (error) {
        console.log(error)
      }
    } else {
      try {
        console.log('miinfo', info)
        console.log('mi date', date)
        const { data } = await axios.post(
          `https://ventafazil-strapi-production.up.railway.app/Pickpoints`,
          date,
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_KEY_STRAPI}`,

              'Content-Type': 'application/json',
            },
          }
        )

        Swal.fire('Se creo un punto de recojo con exito ', '', 'success')
        history.push('/delivery')
      } catch (error) {
        console.log(error)
      }
    }
  }

  if (error) {
    return <div className='text-center'>HUBO UN ERROR INTENTALO MAS TARDE</div>
  }

  return (
    <div className='w-fit md:w-full'>
      <NavigationTop />
      <NavigationSide />

      {loading ? (
        <div className='pb-32 bg-white md:ml-40 md:p-6 text-center'>
          CARGANDO
        </div>
      ) : (
        <div className='flex flex-col gap-5 bg-white md:ml-40 p-3 pb-48 md:pb-40'>
          <div className='flex justify-between md:px-4'>
            <h1 className='font-bold text-2xl'>Puntos de Recojo</h1>
            <button
              className='p-2 px-10 text-sm font-bold text-center rounded-xl text-white'
              style={{ background: '#8163FF' }}
              onClick={submit}
            >
              {state?.isEdit ? 'Guardar' : 'Guardar'}
            </button>
          </div>
          {validationErrors && (
            <div className='text-red-600 text-center'>
              Por favor llena todos los campos
            </div>
          )}
          <div className='w-full flex flex-col md:flex-row gap-2'>
            <div className='w-full md:w-1/2 flex-col md:px-4'>
              <p>Direccion</p>
              <div className='bg-slate-100 w-5/5 p-2 '>
                <input
                  type='text'
                  name='adress'
                  className='w-full border shadow-2xl p-2'
                  onChange={handleChange}
                  value={adress}
                />
              </div>
            </div>
            <div className='w-full md:w-1/2 flex-col md:px-4'>
              <p>Nombre</p>
              <div className='bg-slate-100 w-5/5 p-2 '>
                <input
                  type='text'
                  className='w-full border shadow-2xl p-2'
                  name='name'
                  value={name}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className='w-full flex flex-col md:flex-row gap-2'>
            <div className={`w-full ${pais.numberofdivision === 3 ? 'md:w-1/3' : 'md:w-1/2'} flex-col md:px-4`}>
              <p>{translations.pickPoints.oneDivision}</p>
              <div className='bg-slate-100 w-5/5 p-2 '>
                <Select
                  className='w-full py-2 focus:border-yellow-500 '
                  closeMenuOnSelect={true}
                  value={oneDivision}
                  options={oneOptions}
                  onChange={handleSelectChange1}
                />
              </div>
            </div>
            <div className={`w-full ${pais.numberofdivision === 3 ? 'md:w-1/3' : 'md:w-1/2'} flex-col md:px-4`}>
              <p>{translations.pickPoints.twoDivision}</p>
              <div className='bg-slate-100 w-5/5 p-2 '>
                <Select
                  className='w-full py-2 focus:border-yellow-500 '
                  closeMenuOnSelect={true}
                  value={twoDivision}
                  options={secondOptions}
                  onChange={handleSelectChange2}
                />
              </div>
            </div>
            {pais.numberofdivision === 3 && (
              <div className='w-1/3  flex-col md:px-4'>
                <p>{translations.pickPoints?.threeDivision}</p>
                <div className='bg-slate-100 w-5/5 p-2 '>
                  <Select
                    className='w-full py-2 focus:border-yellow-500 '
                    closeMenuOnSelect={true}
                    value={thirdDivision}
                    options={thirdOptions}
                    onChange={handleSelectChange3}
                  />
                </div>
              </div>
            )}
          </div>
          <pre>
            <code>{/* {oneDivision} -{twoDivision}-{thirdDivision} */}</code>
          </pre>
          <div className='md:px-4'>
            Condiciones de recojo
            <div className='bg-slate-100 w-5/5 p-2 '>
              <textarea
                type='text'
                className='w-full border shadow-2xl p-2 resize-none'
                rows={5}
                name='conditions'
                value={conditions}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className='w-56  m-auto text-center'>
            <p className='text-gray-400 '>
              Es importante incluir información como: Horario de atencion de
              9:00 am a 4 pm Lunes a Sabado
            </p>
          </div>
        </div>
      )}
    </div>
  )
}

export default NewShop
