function InputLabel({ labelName, type, name, value, onChange, disabled = false }) {
    return (
        <div className="flex flex-col gap-2">
            <label className="text-sm w-max">{labelName}</label>
            <input
                className="text-sm border-2 rounded-xl py-2 px-2"
                style={{ border: '2px solid #AAAAAA' }}
                type={type || "text"}
                name={name}
                value={value}
                onChange={(e) => onChange(e)}
                disabled={disabled}
            />
        </div>
    )
}

export default InputLabel