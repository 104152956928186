import axios from 'axios'

export const PutLibroReclamoAPI = async (user, value) => {
  const body = JSON.parse(`{"libroreclamo":${value}}`)
  try {
    const { data } = await axios.put(
      `https://ventafazil-strapi-production.up.railway.app/clientes/${user}`,
      body
    )
    if (data) {
      return data
    } else {
      return null
    }
  } catch (error) {
    //console.log(error);
  }
}

//ALERT: no get user uitl
export const GetLibroReclamoAPI = async (user) => {
  try {
    const { data } = await axios.get(
      `https://ventafazil-strapi-production.up.railway.app/clientes/${user}`
    )
    if (data) {
      return data['libroreclamo'] //parcea al objeto bool de libro de reclamos
    } else {
      return null
    }
  } catch (error) {
    //console.log(error);
  }
}
