import axios from 'axios'

export const PostHistoriaAPI = async (data) => {
  let body = data
  try {
    // //console.log(body)

    const { data } = await axios.post(
      'https://ventafazil-strapi-production.up.railway.app/Historials',
      body,
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_KEY_STRAPI}`,

          'Content-Type': 'application/json',
        },
      }
    )
    return data
  } catch (error) {
    //console.log(error)
  }
}
