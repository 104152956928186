import axios from 'axios'
import { PostHistoriaAPI } from './PostHistorials'

export const PostVentasAPI = async (
  store,
  products,
  total,
  infouser,
  email
) => {
  let body = JSON.stringify({
    productos: products,
    estado: 'aceptado',
    total: total,
    clientefinal: infouser.name + ' ' + infouser.surname,
    cliente: {
      _id: store,
    },
    metadata: [infouser],
  })
  try {
    // //console.log(body)

    const { data } = await axios.post(
      'https://ventafazil-strapi-production.up.railway.app/ventas',
      body,
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_KEY_STRAPI}`,

          'Content-Type': 'application/json',
        },
      }
    )
    //console.log(data)
    //console.log('prueba')
    let datasET = {}
    datasET.description = 'Se creo un pedido'
    datasET.estado = 'Por atender'
    datasET.idpedido = data.id
    datasET.usuario = email
    datasET.estadopdo = 'Aceptado'

    //console.log(datasET)
    PostHistoriaAPI(datasET)

    return data
  } catch (error) {
    //console.log(error)
  }
}
