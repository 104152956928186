import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import VentTableCell from './VentTableCell'

const headers = [
  {
    id: 1,
    width: 'w-52',
    textAlign: 'text-center',
    name: 'Orden ID'
  },
  {
    id: 2,
    width: 'w-36',
    textAlign: 'text-center',
    name: 'Fecha y Hora'
  },
  {
    id: 3,
    width: 'w-36',
    textAlign: 'text-center',
    name: 'Cliente'
  },
  {
    id: 4,
    width: 'w-28',
    textAlign: 'text-center',
    name: 'Total'
  },
  {
    id: 5,
    width: 'w-24',
    textAlign: 'text-center',
    name: 'Items'
  },
  {
    id: 6,
    width: 'w-40',
    textAlign: 'text-center',
    name: 'Estado del Pago'
  },
  {
    id: 7,
    width: 'w-40',
    textAlign: 'text-center',
    name: 'Estado del Pedido'
  },
]

const VentTable = ({ ventas, currency }) => {
  return (
    <div className=''>
      <div
        className='flex gap-7 justify-evenly items-center w-auto py-2 mb-6 rounded-xl'
        style={{ background: '#E6E6E6' }}
      >
        {headers.map(el =>
          <div
            className={`${el.textAlign + ' ' + el.width}`}
            key={el.id}
          // style={{ width: el.width }}
          >
            {el.name}
          </div>
        )}
      </div>
      {ventas.map((v, i) => {
        return (
          <div key={i+'item_'+v.id}>
            <VentTableCell
              orderId={v.id}
              fecha={v.createdAt}
              cliente={v.clientefinal}
              total={v.total}
              cantidad={v.metadata.length}
              estado={v.estado}
              estadoVenta={v.estadoventa}
              currency={currency}
            />
          </div>
        )
      })}
    </div>
  )
}

export default VentTable
