import axios from 'axios'
import React from 'react'
import { useEffect } from 'react'
import NavigationSide from '../components/NavigationSide'
import NavigationTop from '../components/NavigationTop'
import { useParams } from 'react-router-dom'
import { useState } from 'react'
import { faPieChart } from '@fortawesome/free-solid-svg-icons'
const Historials = () => {
  let { ventaid } = useParams()

  const [historials, setHistorials] = useState([])
  const [loader, setLoader] = useState(false)
  //console.log(ventaid)
  const consultaApi = () => {
    setLoader(true)
    let config = {
      method: 'get',
      url: `https://ventafazil-strapi-production.up.railway.app/Historials?idpedido=${ventaid}`,
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_KEY_STRAPI}`,
      },
    }
    axios(config)
      .then((response) => {
        setLoader(false)

        setHistorials(response.data)
      })
      .catch((error) => {
        setLoader(false)

        //console.log(error)
      })
  }
  useEffect(() => {
    consultaApi()
  }, [])

  return (
    <div className='bg-white '>
      <NavigationTop />
      <NavigationSide />
      <section className='p-4 pb-24 bg-white md:p-6 md:ml-36 '>
        <h1>Historial de pedido</h1>
        {loader ? (
          <>Cargando</>
        ) : historials.length >= 1 ? (
          <>
            <div className='flex gap-7 justify-center  items-center	  bg-gray-300 w-max m-auto mb-2'>
              <div className='w-80 text-center'>Fecha y Hora </div>
              <div className='w-52 text-center '>Descripcion</div>
              <div className='w-80 text-center'>Usuario</div>
              <div className='w-28 text-center '>Estado Pedido</div>
              <div className='w-28 text-center '>Estado de Pago</div>
            </div>

            {historials.map((his) => {
              const fecha = () => {
                var input = his.createdAt
                var date = new Date(Date.parse(input))
                var options = {
                  weekday: 'long',
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                }
                return date.toLocaleTimeString('es-ES', options)
              }

              return (
                <div className='flex gap-7 justify-center  items-center	   w-auto mb-2'>
                  <div className='w-80 text-center'>{fecha()} </div>
                  <div className='w-52 text-center '>{his.description}</div>
                  <div className='w-80 text-center'>{his.usuario}</div>
                  <div className='w-28 text-center '>{his.estado}</div>
                  <div className='w-28 text-center '>{his.estadopdo}</div>
                </div>
              )
            })}
          </>
        ) : (
          'Esta orden no tiene historial'
        )}
      </section>
    </div>
  )
}

export default Historials
