import React, { useEffect, useState } from 'react'
import { DeleteCupon } from '../../../utils/DeleteCupons'
import { GetCuponsByDomainAPI } from '../../../utils/GetCupons'
import { PostCuponAPI } from '../../../utils/PostCupon'
import { PutCuponAPI } from '../../../utils/PutCupon'
import Swal from 'sweetalert2'
import Input1 from '../components/Input1'
import Actions from './Actions'
import Button1 from '../components/Button1'

const TOAST = Object.freeze({
  TITLE: {
    NEW: 'Se agregó un nuevo cupón',
    DUPLICATE: 'Se duplico el cupón correctamente',
    SAVE: 'Se guardó el cupón correctamente',
    UPDATE: 'Se actualizo el cupón correctamente',
    DELETE: 'Se eliminó el cupón correctamente',
    ERROR: 'No puede tener cupones con el mismo nombre'
  },
  ICON: {
    SUCCESS: 'success',
    ERROR: 'error',
    INFO: 'info'
  },
})

export default function CuponsModule() {
  const clientID = JSON.parse(localStorage.getItem('user')).id
  const [eventsCount, setEventsCount] = useState(0)
  const [couponsData, setCouponsData] = useState([])

  const defaultNewCupon = {
    benefit: 'discount',
    name: 'new cupon' + Number(couponsData.length + 1),
    value: 10,
    stock: 10,
    cliente: {
      _id: clientID,
    },
  }

  const toastAlert = (title, icon) => {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-right',
      iconColor: 'white',
      customClass: {
        popup: 'colored-toast',
      },
      showConfirmButton: false,
      timer: 1500,
      timerProgressBar: true,
    })
    Toast.fire({
      icon: icon,
      title: title,
    })
  }

  function handleChange() {
    setEventsCount(eventsCount + 1)
  }

  const onChange = (e, index) => {
    let { name, value } = e.target
    let _couponsData = [...couponsData]
    _couponsData[index][name] = value
    setCouponsData(_couponsData)
  }

  function newCoupon() {
    PostCuponAPI(defaultNewCupon).then((data) => {
      data && toastAlert(TOAST.TITLE.NEW, TOAST.ICON.INFO)
      setEventsCount(eventsCount + 1)
    })
  }

  function updateCupon(item, index) {
    const existingCouponIndex = couponsData.findIndex((_cupon) =>
      _cupon.name === item.name)
    if (existingCouponIndex !== -1 && existingCouponIndex !== index) {
      return toastAlert(TOAST.TITLE.ERROR, TOAST.ICON.ERROR)
    }
    PutCuponAPI(item.id, item).then((data) => {
      toastAlert(TOAST.TITLE.UPDATE, TOAST.ICON.SUCCESS)
      handleChange()
    })
  }

  function duplicateCupon(item) {
    item.name += Math.floor(Math.random() * 1000)
      .toString()
      .padStart(3, '0')
    PostCuponAPI(item).then((data) => {
      data && toastAlert(TOAST.TITLE.DUPLICATE, TOAST.ICON.SUCCESS)
      handleChange()
    })
  }

  function deleteCupon(item) {
    DeleteCupon(item.id).then((data) => {
      data && toastAlert(TOAST.TITLE.DELETE, TOAST.ICON.SUCCESS)
      handleChange()
    })
  }

  useEffect(() => {
    GetCuponsByDomainAPI(clientID).then((data) => {
      setCouponsData(data)
    })
  }, [eventsCount])

  return (
    <div className='flex flex-col gap-5'>
      <div className='p-4 rounded-2xl' style={{ border: '2px solid #8163FF' }}>
        {couponsData?.length > 0 ? (
          <div className='w-full px-2 md:px-5 my-1'>
            {couponsData.map((cupon, index) => {
              return (
                <div className='w-full flex gap-10 py-3 md:py-4' key={'_cupon' + index}>
                  <Input1
                    classNameLabel='font-semibold'
                    labelTextColor='#000'
                    label='Nombre'
                    className='w-full'
                    name='name'
                    value={cupon.name}
                    onChange={(e) => onChange(e, index)}
                  />
                  <Input1
                    classNameLabel='font-semibold'
                    labelTextColor='#000'
                    label='Facturación por'
                    className='w-full'
                    name='benefit'
                    value={cupon.benefit}
                    onChange={(e) => onChange(e, index)}
                  />
                  <Input1
                    classNameDiv='w-5/12'
                    classNameLabel='font-semibold'
                    labelTextColor='#000'
                    label='Monto'
                    className='w-full'
                    type='number'
                    name='value'
                    value={cupon.value}
                    onChange={(e) => onChange(e, index)}
                  />
                  <Input1
                    classNameDiv='w-5/12'
                    classNameLabel='font-semibold'
                    labelTextColor='#000'
                    label='Stock'
                    className='w-full'
                    type='number'
                    name='stock'
                    value={cupon.stock}
                    onChange={(e) => onChange(e, index)}
                  />
                  <Actions
                    updateCupon={() => updateCupon(cupon, index)}
                    duplicateCupon={() => duplicateCupon(cupon)}
                    deleteCupon={() => deleteCupon(cupon)}
                  />
                </div>
              )
            })}
          </div>
        ) : (
          <div className='py-6'>
            No tienes cupones aun, crea tu primer cupón
          </div>
        )}
      </div>
      <Button1
        textColor='#ffffff'
        background='#8163FF'
        text='Crear nuevo cupon'
        onClick={newCoupon}
      />
    </div>
  )
}

export const benefitOptions = [
  { name: 'percentage' },
  { name: 'discount' },
  { name: 'freeshipping' },
]