import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import Select from 'react-select'
import { estadoventa, estadoPago } from '../utils/data'
import { CSVLink } from 'react-csv'

const FilterOrder = ({
  handleChange,
  handleSelectChange,
  handleSelectChange1,
  placeHolder,
  img1,
  img2,
  img3,
  text1,
  text2,
  options1,
  options2,
  csvVisible,
  csvHeader,
  csvData,
  csvDownloadRef,
  getVentasToCSV
}) => {
  return (
    <div className='py-6 flex justify-between items-center gap-5'>
      <div
        className='w-8/12 flex justify-center items-center border rounded-lg p-2 bg-white'
        style={{ borderColor: '#A0A0A0' }}
      >
        <FontAwesomeIcon className='text-slate-700 ' icon={faMagnifyingGlass} />
        <input
          type='text'
          placeholder={placeHolder}
          className='w-full outline-none p-2'
          onChange={handleChange}
        />
      </div>
      <div className='flex gap-5'>
        <div
          className='flex justify-center items-center gap-1 border rounded-lg py-2 px-2 bg-white'
          style={{ border: '1px solid #A0A0A0' }}
        >
          <img className='w-max' src={img1} alt='Filtro' />
          <label className='text-sm font-semibold text-gray-700 '>
            <Select
              className='w-full'
              placeholder='Seleccionar'
              closeMenuOnSelect={true}
              options={options2}
              onChange={handleSelectChange1}
            />
          </label>
        </div>
        {text2 && (
          <div
            className='flex justify-center items-center gap-1 border rounded-lg py-2 px-2 bg-white'
            style={{ border: '1px solid #A0A0A0' }}
          >
            <div className='flex flex-col gap-1'>
              <img src={img2} alt='img2' />
              {img3 && <img src={img3} alt='img3' />}
            </div>
            <label className='text-sm font-semibold text-gray-700'>
              <Select
                className='w-full'
                placeholder='Seleccionar'
                closeMenuOnSelect={true}
                options={options1}
                onChange={handleSelectChange}
              />
            </label>
          </div>
        )}
        {csvVisible &&
          <div className='flex justify-center items-center'>
            <CSVLink
              target="_blank"
              filename={"Ventas.csv"}
              headers={csvHeader}
              data={csvData}
              ref={csvDownloadRef}
            // onClick={() => {
            // }}
            // asyncOnClick={true}
            // separator={";"}
            // enclosingCharacter={`'`}
            />
            <button
              className='w-max h-full flex items-center text-white p-2 border rounded-lg'
              style={{ backgroundColor: 'rgb(129, 99, 255)', border: '1px solid #A0A0A0' }}
              onClick={getVentasToCSV}
            >Descargar CSV</button>
          </div>
        }
      </div>
    </div>
  )
}

export default FilterOrder
