import React, { useState, useEffect } from 'react'
import UIinput from './UIinput'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

export default function ExtrasUpdater(props) {
  const [extras, setExtras] = useState([])
  useEffect(() => {
    if (props.extras && props.extras.length > 0) {
      setExtras(props.extras)
    }
  }, [])

  function editVariation(event, i) {
    let name = event.target.name
    let value = event.target.value
    let index = i
    const newExtras = [...extras]
    if (name == 'price') {
      newExtras[i][name] = props.changeInputDecimal(event, i)
    } else {
      newExtras[i][name] = value
    }
    setExtras(newExtras)
    props.changeExtras(newExtras, index)
    // //console.log("newExtras[i] ",i,"index ",index,newExtras[i], newExtras[i].name, newExtras[i].price)
  }

  function RemoveVariation(e) {
    // //console.log("props.errors1", props.errors)
    delete props.errors['extrasName' + e]
    delete props.errors['extrasPrice' + e]
    // //console.log("props.errors2", props.errors)
    const newExtras = [...extras]
    newExtras.splice(e, 1)
    setExtras(newExtras)
    props.changeExtras(newExtras)
  }

  function AddVariation() {
    const newExtras = [...extras]
    newExtras.push({
      name: '',
      price: '',
    })
    setExtras(newExtras)
    props.changeExtras(newExtras)
  }

  return (
    <div className='bg-gray-100 rounded-xl p-4'>
      <div className='mb-3 text-xs font-semibold text-gray-500 uppercase'>
        Extras
      </div>
      {extras &&
        extras.map((variation, i) => {
          return (
            <div key={i} className='flex justify-between gap-2'>
              {/* <div> */}
              <div className='w-full flex flex-col'>
                <UIinput
                  type='text'
                  label='Extra'
                  nameInput='name'
                  changePrice={(e) => editVariation(e, i)}
                  value={variation.name}
                  errors={props.errors}
                />
                {props.errors && props.errors['extrasName' + i] && (
                  <p className='p-1 text-sm text-red-500'>
                    {props.errors['extrasName' + i]}
                  </p>
                )}
              </div>
              {/* </div> */}

              <div className='w-154 flex flex-col'>
                <UIinput
                  type='text'
                  suffix={props.currency}
                  label='Precio'
                  nameInput='price'
                  changePrice={(e) => editVariation(e, i)}
                  value={variation.price}
                  // maxLength={5}
                />
                <div>
                  {props.errors && props.errors['extrasPrice' + i] && (
                    <p className='p-1 text-sm text-red-500'>
                      {props.errors['extrasPrice' + i]}
                    </p>
                  )}
                </div>
              </div>
              <div
                className='flex flex-col items-center justify-end'
                onClick={() => RemoveVariation(i)}
              >
                <div className='flex items-center justify-center w-6 h-6 mb-6 text-white bg-black rounded-full cursor-pointer hover:bg-red-600'>
                  <FontAwesomeIcon icon={faTimes} />{' '}
                </div>
              </div>
              {/* {props.errors?.extrasName && <p className="p-1 text-sm text-red-500">{props.errors?.extrasName}</p>} */}
              {/* {props.errors?.extrasPrice && <p className="p-1 text-sm text-red-500">{props.errors?.extrasPrice}</p>} */}
            </div>
          )
        })}
      <div
        onClick={() => AddVariation()}
        className='text-sm font-bold tracking-tighter text-blue-600 opacity-50 cursor-pointer hover:opacity-100'
      >
        + Agregar un extra
      </div>
    </div>
  )
}
