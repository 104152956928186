import TimePicker from "./TimePicker"
import { selectHours, selectMinutes, selectPeriod } from '../../../../utils/constants/couponData'

function TimePickerContainer({
    datePeriod = '',
    nameHours = '', valueHours = '', labelNameHours = '',
    nameMinutes = '', valueMinutes = '', labelNameMinutos = '',
    namePeriod = '', valuePeriod = '',
    onChange = () => { }
}) {
    return (
        <div className='flex flex-col gap-2'>
            <div className='flex flex-col gap-4 py-2 rounded-xl' style={{ border: '1px solid #8163FF' }}>
                <p className='font-semibold text-xs px-4'>{datePeriod}</p>
                <div className='flex justify-evenly items-center'>
                    <TimePicker
                        options={selectHours}
                        name={nameHours}
                        value={valueHours}
                        labelName={labelNameHours}
                        onChange={onChange}
                    />
                    <span className='font-semibold text-4xl'>:</span>
                    <TimePicker
                        options={selectMinutes}
                        name={nameMinutes}
                        value={valueMinutes}
                        labelName={labelNameMinutos}
                        onChange={onChange}
                    />
                    <TimePicker
                        options={selectPeriod}
                        name={namePeriod}
                        value={valuePeriod}
                        onChange={onChange}
                    />
                </div>
            </div>
        </div>
    )
}

export default TimePickerContainer