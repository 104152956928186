import { parse } from '@fortawesome/fontawesome-svg-core'
import {
  faCheck,
  faTrash,
  faTriangleExclamation,
  faStar,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import React, { useState, useContext } from 'react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { CurrencyContext } from '../context/CurrencyContext'
import ModalMessage from './ModalMessage'
import actions from '../assets/images/actions.svg'

function UIBackofficeTableCell(props) {
  const [confirm, setConfirm] = React.useState(false)
  const [deleted, setDeleted] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const [checked, setChecked] = React.useState(props.product.istrending)
  const [viewModal, setViewModal] = React.useState(false)
  const [contentModal, setContentModal] = React.useState({
    title: '',
    text: '',
  })
  const [productTrending, setProductTrending] = useState([])
  const [allProducts, setAllProducts] = useState([])
  const currencyValue = useContext(CurrencyContext)
  const currency = currencyValue.currency
  const [showOptions, setShowOptions] = useState(false)

  function openActions() {
    setShowOptions(!showOptions)
  }

  function deleteProduct() {
    // console.log(props.product.nombreproducto)
    setConfirm(!confirm)
    console.log('deleteProduct')
  }
  const closeModal = (val) => {
    setViewModal(val)
  }
  function dangerouslyDeleteProduct() {
    let config = {
      method: 'delete',
      url:
        `https://ventafazil-strapi-production.up.railway.app/productos/` +
        props.product.id,
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_KEY_STRAPI}`,
      },
    }
    axios(config)
      .then((response) => {
        setLoading(true)
        // console.log(response.data, 'productos por categoria')
        setDeleted(response.data)
        setShowOptions(!showOptions)
      })
      .then(() => {
        setConfirm(!confirm)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const editProductDes = async () => {
    let newOrder = 1
    if (props.product.order && props.product.order > 0) {
      newOrder = parseInt(props.product.order)
      // console.log("props.product.order", props.product.order)
    } else {
      allProducts.forEach((el, index) => {
        if (parseInt(el.order) >= newOrder) {
          newOrder = parseInt(el.order) + 1
        }
      })
    }
    let config = {
      method: 'put',
      url: `https://ventafazil-strapi-production.up.railway.app/productos/${props.product.id}`,
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_KEY_STRAPI}`,
      },
      data: {
        istrending: !props.product.istrending,
        order: newOrder,
      },
    }
    try {
      const data = await axios(config).finally(() => {
        if (props.seeActions) {
          props.consultarAPi(true)
        } else props.consultarAPi()
      })
      console.log(data)
    } catch (error) {
      console.log(error)
    }
  }

  // const editOrder = async (params) => {
  //   let config = {
  //     method: 'put',
  //     url: `https://ventafazil-strapi-production.up.railway.app/productos/${params.id}`,
  //     headers: {
  //       Authorization: `Bearer ${process.env.REACT_APP_KEY_STRAPI}`,
  //     },

  //     data: {
  //       order: params.order,
  //     },
  //   }
  //   try {
  //     const data = await axios(config)
  //       // .then((data) => {
  //       //   if (data?.status == 200) {
  //       //     console.log("STATUS", data.status)
  //       //   }
  //       // })
  //       .finally(() => {
  //         if (params?.refresh) props.consultarAPi(true)
  //       })
  //     // console.log(data)
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  // const trendingTop = () => {
  //   let products = productTrending
  //   let index = props.index
  //   let currentProduct = products[index]
  //   let swapProduct = products[index - 1]

  //   let params1 = {
  //     id: currentProduct.id,
  //     order: swapProduct.order,
  //     refresh: false,
  //   }
  //   let _params2 = {
  //     id: swapProduct.id,
  //     order: currentProduct.order,
  //     refresh: true,
  //   }
  //   editOrder(params1)
  //   editOrder(_params2)
  //   // console.log("params1",params1)
  //   // console.log("params2",_params2)
  // }

  // const trendingBot = () => {
  //   // let prods = allProducts
  //   // prods = prods.filter((e)=>e.order !== undefined || e.order == 0).sort((a, b) => a.order - b.order)
  //   // prods.forEach(e => {
  //   //   if (e.order !== undefined || e.order == 0) console.log("Orden:", e.order, e.nombreproducto)
  //   // })

  //   let products = productTrending
  //   let index = props.index
  //   let currentProduct = products[index]
  //   let swapProduct = products[index + 1]
  //   let params1 = {
  //     id: currentProduct.id,
  //     order: swapProduct.order,
  //     refresh: false,
  //   }
  //   let _params2 = {
  //     id: swapProduct.id,
  //     order: currentProduct.order,
  //     refresh: true,
  //   }
  //   editOrder(params1)
  //   editOrder(_params2)
  //   // console.log("params1",params1)
  //   // console.log("params2",_params2)
  // }

  useEffect(() => {
    // setProductTrending(props.filtered_Products)
    setAllProducts(props.allProducts)
    // console.log("productTrending",productTrending)
  }, []) //props.filtered_Products

  // const changeSwitch = (event) => {
  //   let newProduct = {
  //     ...producto,
  //     istrending: !producto.istrending,
  //   }
  //   setProducto(newProduct)
  // }

  return deleted !== null ? (
    <div
    // className='flex items-center w-full gap-2 p-4 overflow-hidden text-gray-400 transition-all duration-500 transform bg-gray-200 rounded-md after:duration-500 after:-ml-3 '
    >
      <div className='flex items-center gap-3 cursor-pointer'>
        <FontAwesomeIcon icon={faCheck} />
        <div>Producto eliminado</div>
      </div>
    </div>
  ) : (
    // <div className="flex w-full  justify-start items-center gap-2 p-4 transition-all  transform duration-500 bg-white rounded-md after:duration-500 hover:shadow-xl hover:-translate-y-1 after:-ml-3 after:opacity-0 after:text-3xl hover:after:ml-2 hover:after:transition-all hover:after:opacity-100 after:content-['>'] hover:after:text-green-500 hover:bg-white cursor">
    //   <div className='flex overflow-hidden text-sm rounded shrink-0 '>
    //     <a
    //       href={`/producto/${props.product.id}`}
    //       className='flex w-24 h-24 overflow-hidden shrink-0'
    //     >
    //       <img
    //         className='flex w-full shrink-0'
    //         src={
    //           props.product.imagenproducto
    //             ? props.product.imagenproducto
    //             : props.product.image && props.product.image.length > 0
    //               ? props.product.image[0].url
    //               : ''
    //         }
    //         alt={props.product.nombreproducto}
    //       />
    //     </a>
    //   </div>
    //   <div className='flex flex-col justify-start w-1/3'>
    //     <div className='mb-1 font-bold tracking-tight'>{props.product.nombreproducto}</div>
    //     <div className='font-mono text-xs' type='text'>
    //       {props.category}
    //     </div>
    //     <div className='font-mono text-xs'>
    //       {props.product.stock >= 1 ? (
    //         `En stock: ${props.product.stock}`
    //       ) : (
    //         <span className='text-red-500'>Agotado</span>
    //       )}
    //     </div>
    //   </div>
    //   {!props.ishome && (
    //     <>
    //       <div className='flex justify-center w-40 text-center '>
    //         <FontAwesomeIcon
    //           onClick={editProductDes}
    //           icon={faStar}
    //           size='2x'
    //           color={`${props.product.istrending ? 'yellow' : 'black'}`}
    //           className='cursor-pointer'
    //         />
    //       </div>
    //     </>
    //   )}

    //   <div className='w-24'>
    //     <div className='text-2xl tracking-tighter'>
    //       {currency + ' ' + props.product.precio}
    //     </div>
    //   </div>

    //   {props.seeActions == false ? (
    //     <>
    //       <div
    //         onClick={deleteProduct}
    //         className='flex items-center gap-2 p-4 text-lg font-bold uppercase cursor-pointer hover:text-red-600 '
    //       >
    //         <FontAwesomeIcon icon={faTrash} />
    //       </div>
    //       <a
    //         className='flex self-center justify-center p-2 px-3 font-bold text-center uppercase transition bg-white border-4 border-gray-400 rounded-full w-28 text-xxs hover:border-yellow-400'
    //         href={`/producto/${props.product.id}`}
    //       >
    //         {' '}
    //         ver mÃ¡s{' '}
    //       </a>
    //     </>
    //   ) : (
    //     <>
    //       {props.index != props.filtered_Products?.length - 1 && (
    //         <div
    //           onClick={() => {
    //             trendingBot()
    //           }}
    //           className='flex items-center p-1 ml-3 text-lg font-semibold uppercase cursor-pointer hover:text-amber-600 '
    //         >
    //           Bajar
    //         </div>
    //       )}
    //       {props.index != 0 && (
    //         <div
    //           onClick={() => {
    //             trendingTop()
    //           }}
    //           className='flex items-center p-1 ml-3 text-lg font-semibold uppercase cursor-pointer hover:text-amber-600 '
    //         >
    //           Subir
    //         </div>
    //       )}
    //     </>
    //   )}

    //   {confirm ? (
    //     <div className='absolute top-0 left-0 flex flex-col items-center justify-center w-full h-full gap-2 text-white bg-black bg-opacity-90'>
    //       <div className='gap-2 font-semibold'>
    //         <FontAwesomeIcon icon={faTriangleExclamation} /> Â¿Borrar
    //         Permanentemente?
    //       </div>
    //       <div className='flex gap-2'>
    //         <button
    //           className='opacity-70 hover:opacity-100'
    //           onClick={deleteProduct}
    //         >
    //           cancelar
    //         </button>
    //         <button
    //           className='p-2 px-3 text-white transition-all bg-red-500 rounded-lg hover:bg-red-400'
    //           onClick={dangerouslyDeleteProduct}
    //         >
    //           borrar
    //         </button>
    //       </div>
    //     </div>
    //   ) : null}
    // </div>
    <div className='flex flex-col w-full gap-2'>
      <div
        className='flex items-center justify-evenly gap-2 w-full my-5 px-5 rounded-xl text-sm'
        key={props._key}
      >
        <img
          // className='w-full'
          className='w-24 h-24 flex shrink-0 '
          src={
            props.product.imagenproducto
              ? props.product.imagenproducto
              : props.product.image && props.product.image.length > 0
              ? props.product.image[0].url
              : ''
          }
          alt={props.product.nombreproducto}
        />
        <div className='break-words w-[350px]' style={{}}>
          {props.product.nombreproducto}
        </div>
        <div className='flex justify-center w-[85px]' onClick={editProductDes}>
          <div className='relative inline-block w-14 mr-2 align-middle select-none transition duration-200 ease-in'>
            <input
              type='checkbox'
              name='istrending'
              // id='toggle'
              className={`${
                props.product.istrending ? 'right-0' : ''
              } absolute block w-6 h-6 rounded-full bg-white border-1 appearance-none cursor-pointer`}
              checked={props.product.istrending}
              onChange={() => {}}
            />
            <label
              // for='toggle'
              className='block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer'
              style={
                props.product.istrending
                  ? { background: '#00FBC8' }
                  : { background: '#CBCBCB' }
              }
            ></label>
          </div>
        </div>
        {!props.selectedCategory && (
          <div className='text-center w-[150px] break-words'>
            {props.category && props.category.map((cate) => cate + ' ')}
          </div>
        )}
        <div className='text-center w-[50px] break-words px-1'>
          {props.product.stock ? props.product.stock : 0}
        </div>
        <div className='text-center w-[100px] break-all px-1'>
          {props.product.precio && currency + props.product.precio}
        </div>
        <div className='text-center w-10' style={{ color: '#8163FF' }}>
          <button>
            <a href={`/producto/${props.product.id}`}>Editar</a>
          </button>
        </div>
        <div className='w-10 flex justify-center items-center relative'>
          <button
            className='flex justify-center items-center'
            onClick={openActions}
          >
            <img src={actions} alt='actions' />
          </button>
          {showOptions && (
            <div className='absolute top-0 right-0 mt-6'>
              <ul
                className='bg-white border zborder-gray-300 rounded-md py-1'
                style={{}}
              >
                {/* <li className='px-4 py-2 hover:bg-gray-200 cursor-pointer border-b-2' style={{ color: '#8163FF' }} onClick={() => console.log('Duplicar')}>
                  Duplicar
                </li> */}
                <li
                  className='px-4 py-2 hover:bg-gray-200 cursor-pointer'
                  style={{ color: '#FF0000' }}
                  onClick={() => {
                    // console.log("DELETE ID", props.product.id)
                    deleteProduct()
                  }}
                >
                  Eliminar
                </li>
              </ul>
              {/* <div className='fixed top-0 left-0' onClick={openActions} /> */}
              {/* <div className='fixed top-0 left-0 w-full h-full bg-gray-800 opacity-50' onClick={closeActions} /> */}
            </div>
          )}
        </div>
      </div>

      {confirm ? (
        <div
          className='absolute flex flex-col items-center justify-center gap-2 w-10/12 h-24 my-5 px-10 rounded-xl text-sm text-white bg-black bg-opacity-80'
          style={{ background: '' }}
        >
          <div className='gap-2 font-semibold'>
            <FontAwesomeIcon icon={faTriangleExclamation} />
            ¿Borrar Permanentemente?
          </div>
          <div className='flex gap-2'>
            <button
              className='opacity-70 hover:opacity-100'
              onClick={deleteProduct}
            >
              cancelar
            </button>
            <button
              className='p-2 px-3 text-white transition-all bg-red-500 rounded-lg hover:bg-red-400'
              onClick={dangerouslyDeleteProduct}
            >
              borrar
            </button>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default UIBackofficeTableCell
