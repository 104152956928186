import { useState, useEffect, useContext } from 'react'
import Checkbox1 from '../components/Checkbox1'
import axios from 'axios'
import { CurrencyContext } from '../../../context/CurrencyContext'
import Button1 from '../components/Button1'
import Swal from 'sweetalert2'
import { isEmpty, minDivisionSelected, minPrice } from '../../../utils/Validation'
import Country from '../../Country'
import { UserContext } from '../../../context/UserContext'
import Loading from '../components/Loading'

const TOAST_TITLE = Object.freeze({
    ENABLED: 'Se activo el precio único',
    DISABLED: 'Se desactivo el precio único'
})

function PriceOnly() {
    const { user } = useContext(UserContext)
    const { currency } = useContext(CurrencyContext)

    const _user = JSON.parse(localStorage.getItem('user'))
    const idCustomer = _user._id
    const idCountry = _user.paises.id

    const [listOneDivision, setListOneDivision] = useState([])
    const [listTwoDivision, setListTwoDivision] = useState([])
    const [listThreeDivision, setListThreeDivision] = useState([])

    const [openOneDivision, setOpenOneDivision] = useState({})
    const [openTwoDivision, setOpenTwoDivision] = useState({})

    const [chkOneDivision, setChkOneDivision] = useState({})
    const [chkTwoDivision, setChkTwoDivision] = useState({})
    const [chkThreeDivision, setChkThreeDivision] = useState({})

    const [country, setCountry] = useState({
        id: '',
        name: '',
        nroDivision: 0
    })

    const [isGet, setIsGet] = useState(true)
    const [idPriceOnly, setIdPriceOnly] = useState('')
    const [isPriceOnly, setIsPriceOnly] = useState(false)
    const [isPricePerZone, setIsPricePerZone] = useState(false)
    const [priceOnlyData, setPriceOnlyData] = useState({
        idCliente: '',
        price: 0,
        delivery: [],
        deliveryCondition: '',
        isDiscount: false,
        discountPrice: 0
    })
    const [loading, setLoading] = useState(true)

    const toastAlert = (title) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top',
            iconColor: 'white',
            customClass: {
                popup: 'colored-toast',
            },
            showConfirmButton: false,
            timer: 2500,
            timerProgressBar: true,
        })
        Toast.fire({
            icon: 'success',
            title: title
        })
    }

    const validate = () => {
        const _isPriceOnly = _user.isonlyprice
        let error = {}
        let _priceOnly = { ...priceOnlyData }
        const _minPrice = 0
        const _minDivisionSelected = 1

        error['price'] = isEmpty(_priceOnly.price, 'precio de delivery')
        if (error['price'] === "") error['price'] = minPrice(_priceOnly.price, _minPrice, ' de delivery')
        error['delivery'] = minDivisionSelected(_priceOnly.delivery, _minDivisionSelected, ' municipio', _isPriceOnly)
        error['deliveryCondition'] = isEmpty(_priceOnly.deliveryCondition, 'las condiciones de delivery')
        if (_priceOnly.isDiscount) {
            error['discountPrice'] = isEmpty(_priceOnly.discountPrice, 'precio de descuento')
            if (error['discountPrice'] === "") error['discountPrice'] = minPrice(_priceOnly.discountPrice, _minPrice, ' de descuento')
        }

        Object.keys(error).forEach(elem => {
            if (error[elem] === "") delete error[elem]
        })

        return error
    }

    const toast = (error) => {
        let msgError =
            error.rateName != undefined ? error.rateName
                : error.price != undefined ? error.price
                    : error.delivery != undefined ? error.delivery
                        : error.deliveryCondition != undefined ? error.deliveryCondition
                            : error.discountPrice != undefined ? error.discountPrice
                                : ''

        const Toast = Swal.mixin({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        if (msgError) {
            Toast.fire({
                icon: 'error',
                title: msgError,
            })
        }
    }

    const swalSuccess = () => {
        Swal.fire({
            title: "Se guardo el precio único con exito",
            // text: "¿Eliminar?",
            icon: 'success',
            // showCancelButton: true,
            // confirmButtonText: "Sí, eliminar",
            // cancelButtonText: "Cancelar",
            timer: 3000
        })
            .then(resultado => {
                // console.log(resultado)
                setIsGet(true)
                getDivisionsByNumbersDivisions()
                // if (resultado.value) {
                //     // Hicieron click en "Sí"
                //     // console.log("*se elimina la venta*");
                //     // window.location.reload(true);

                // } else {
                //     // Dijeron que no
                //     // console.log("*NO se elimina la venta*");
                // }
            });
    }

    const onChange = (e) => {
        let name = e.target.name
        let value = e.target.value
        // if (e.target.type === 'checkbox') {
        //     if (!e.target.checked) {
        //         setPriceOnlyData({ ...priceOnlyData, [name]: e.target.checked, discountPrice: 0 })
        //     } else setPriceOnlyData({ ...priceOnlyData, [name]: e.target.checked })
        // } else
        if (name === 'price' || name === 'discountPrice') {
            let amount = e.target.value;
            if (!amount || amount.match(/^\d{1,7}(\.\d{0,2})?$/)) {
                setPriceOnlyData({ ...priceOnlyData, [name]: amount })
            }
        } else setPriceOnlyData({ ...priceOnlyData, [name]: value })
    }

    const getPriceOnlyByCustomer = async () => {
        const url = `https://ventafazil-strapi-production.up.railway.app/OnlyPrices?cliente=${idCustomer}&country=${idCountry}`
        // const config = {
        //     headers: {
        //        'Authorization': `Bearer ${process.env.REACT_APP_KEY_STRAPI}`,
        //        'Content-Type': 'application/json',
        //     },
        //  }
        try {
            const response = await axios.get(url)
            const data = response.data[0]
            if (response.status == 200) {
                if (data) {
                    setIdPriceOnly(data._id)
                    setPriceOnlyData({
                        idCliente: data.cliente._id,
                        price: data.price,
                        delivery: data.delivery,
                        deliveryCondition: data.deliverycondition,
                        isDiscount: data.isdiscount,
                        discountPrice: data.discountprice
                    })
                    return data.delivery
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const savePriceOnly = async () => {
        let errorMessage = validate()
        const config = {
            headers: {
                'Authorization': `Bearer ${process.env.REACT_APP_KEY_STRAPI}`,
                'Content-Type': 'application/json',
            },
        }
        if (idPriceOnly) {
            const url = `https://ventafazil-strapi-production.up.railway.app/OnlyPrices/${idPriceOnly}`
            const data = {
                price: priceOnlyData.price,
                delivery: priceOnlyData.delivery,
                deliverycondition: priceOnlyData.deliveryCondition,
                isdiscount: priceOnlyData.isDiscount,
                discountprice: priceOnlyData.discountPrice,
                cliente: priceOnlyData.idCliente
            }
            if (Object.keys(errorMessage).length > 0) {
                toast(errorMessage)
            } else if (Object.keys(errorMessage).length == 0) {
                try {
                    const response = await axios.put(url, data, config)
                    if (response.status == 200) {
                        swalSuccess()
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        } else {
            const url = 'https://ventafazil-strapi-production.up.railway.app/OnlyPrices'
            const data = {
                cliente: priceOnlyData.idCliente,
                price: priceOnlyData.price,
                delivery: priceOnlyData.delivery,
                deliverycondition: priceOnlyData.deliveryCondition,
                isdiscount: priceOnlyData.isDiscount,
                discountprice: priceOnlyData.discountPrice,
                country: country.id
            }
            if (Object.keys(errorMessage).length > 0) {
                toast(errorMessage)
            } else if (Object.keys(errorMessage).length == 0) {
                try {
                    const response = await axios.post(url, data, config)
                    if (response.status == 200) {
                        swalSuccess()
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        }
    }

    const getDivisionsByNumbersDivisions = async (getServices) => {
        const userStorage = JSON.parse(localStorage.getItem('user'))
        if (getServices) setIsPriceOnly(userStorage.isonlyprice)
        else setIsPriceOnly(_user.isonlyprice)
        setPriceOnlyData({ ...priceOnlyData, idCliente: _user._id })
        const pais = _user.paises
        setCountry({
            id: pais.id,
            name: pais.name,
            nroDivision: pais.numberofdivision
        })
        const _country = {
            id: pais.id,
            nroDivision: pais.numberofdivision
        }
        let dataDelivery = []
        if (_user.ishomedelivery) {
            if (_user.isonlyprice || getServices) {
                dataDelivery = await getPriceOnlyByCustomer()
                for (let index = 1; index <= _country.nroDivision; index++) {
                    switch (index) {
                        case 1:
                            await getOneDivion(_country.id, dataDelivery)
                            break;
                        case 2:
                            await getTwoDivion(_country, dataDelivery)
                            break
                        case 3:
                            await getThreeDivion(dataDelivery)
                            break
                        default:
                            console.log("NO HAY MAS DIVISIONES")
                        //return
                    }
                }
            }
        }
        setIsGet(false)
        setLoading(false)
    }

    const getOneDivion = async (id, dataDelivery) => {
        const url = `https://ventafazil-strapi-production.up.railway.app/Onedivisions?country=` + id
        try {
            const response = await axios.get(url)
            const data = response.data
            if (response.status == 200) {
                setListOneDivision(data)
                fillOpenOneDivision(data)
                fillChkOneDivision(data, dataDelivery)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getTwoDivion = async (_country, dataDelivery) => {
        const url = `https://ventafazil-strapi-production.up.railway.app/Twodivisions?_limit=-1`
        try {
            const response = await axios.get(url)
            const data = response.data
            if (response.status == 200) {
                twoDivisionXCountry(_country, data, dataDelivery)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getThreeDivion = async (dataDelivery) => {
        const url = `https://ventafazil-strapi-production.up.railway.app/Threedivisions?_limit=-1`
        try {
            const response = await axios.get(url)
            const data = response.data
            if (response.status == 200) {
                setListThreeDivision(data)
                fillChkThreeDivision(data, dataDelivery)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const twoDivisionXCountry = async (_country, data, dataDelivery) => {
        let newTwoDivision = await data.filter(el =>
            el.onedivision.country == _country.id
        )
        setListTwoDivision(newTwoDivision)
        fillOpenTwoDivision(newTwoDivision)
        fillChkTwoDivision(newTwoDivision, dataDelivery, _country)
    }

    const fillOpenOneDivision = async (data) => {
        let _seeOneDivision = {}
        await data.forEach(el => {
            _seeOneDivision['open' + el.name] = false
        });
        setOpenOneDivision(_seeOneDivision)
    }

    const fillOpenTwoDivision = async (data) => {
        let _seeTwoDivision = {}
        await data.forEach(el => {
            _seeTwoDivision['open' + el.name] = false
        });
        setOpenTwoDivision(_seeTwoDivision)
    }

    const fillChkOneDivision = (data, dataDelivery) => {
        let _oneDivision = {}
        let _dataDelivery = []
        if (dataDelivery?.length > 0) {
            dataDelivery[0][0].forEach(el => {
                _dataDelivery.push(el.split('_ID')[0].split('chk1').pop())
            })
            data.forEach(el => {
                if (_dataDelivery.includes(el.name.trim())) {
                    _oneDivision['chk1' + el.name.trim() + '_ID' + el._id] = true
                } else {
                    _oneDivision['chk1' + el.name.trim() + '_ID' + el._id] = false
                }
            });
        } else {
            data.forEach(el => {
                _oneDivision['chk1' + el.name.trim() + '_ID' + el._id] = false
            });
        }
        setChkOneDivision(_oneDivision)
    }

    const fillChkTwoDivision = (data, dataDelivery, _country) => {
        let dataDeliveryChk2True = []
        let _NewDataDeliveryChk2True = []
        let _twoDivision = {}
        if (dataDelivery?.length > 0) {
            if (_country.nroDivision == 2) {
                dataDeliveryChk2True = dataDelivery[1][0]
            } else if (_country.nroDivision == 3) {
                dataDeliveryChk2True = dataDelivery[0][0]
            }
        }
        if (dataDeliveryChk2True?.length > 0) {
            dataDeliveryChk2True.forEach(el => {
                _NewDataDeliveryChk2True.push(el.split('_ID')[0].split('chk2').pop())
            })
            data.forEach(el => {
                if (_NewDataDeliveryChk2True.includes(el.name.trim())) {
                    _twoDivision['chk2' + el.name.trim() + '_ID' + el._id] = true
                } else {
                    _twoDivision['chk2' + el.name.trim() + '_ID' + el._id] = false
                }
            });
        } else {
            data.forEach(el => {
                _twoDivision['chk2' + el.name.trim() + '_ID' + el._id] = false
            });
        }
        // Object.keys(_twoDivision).filter((key) => {
        //     if (_twoDivision[key] === true) {
        //         console.log("KEY=====>", key)
        //     }
        // });
        setChkTwoDivision(_twoDivision)
    }

    const fillChkThreeDivision = (data, dataDelivery) => {
        let _threeDivision = {}
        let _dataDelivery = []
        if (dataDelivery?.length > 0) {
            dataDelivery[1][0].forEach(el =>
                _dataDelivery.push(el.split('_ID')[0].split('chk3').pop())
            )
            data.forEach(el => {
                if (_dataDelivery.includes(el.name.trim())) {
                    _threeDivision['chk3' + el.name.trim() + '_ID' + el._id] = true
                } else (
                    _threeDivision['chk3' + el.name.trim() + '_ID' + el._id] = false
                )
            });
        } else {
            data.forEach(el => {
                _threeDivision['chk3' + el.name.trim() + '_ID' + el._id] = false
            });
        }
        setChkThreeDivision(_threeDivision)
    }

    const seeTwoDivision = (_id) => {
        setOpenOneDivision({ ...openOneDivision, [_id]: !openOneDivision[_id] })
    }

    const seeThreeDivision = (_id) => {
        setOpenTwoDivision({ ...openTwoDivision, [_id]: !openTwoDivision[_id] })
    }

    const changeCheckOneDivision = (_id, idOne, lstTwo, e) => {
        setChkOneDivision({ ...chkOneDivision, [_id]: !chkOneDivision[_id] })
        if (country.nroDivision == 2) checkedAllTtwoDivision(idOne, lstTwo, e)
    }

    const changeCheckTwoDivision = (_id, idTwo, lstThree, e) => {
        setChkTwoDivision({ ...chkTwoDivision, [_id]: !chkTwoDivision[_id] })
        if (country.nroDivision == 3) checkedAllThreeDivision(idTwo, lstThree, e)
    }

    const changeCheckThreeDivision = (_id) => {
        setChkThreeDivision({ ...chkThreeDivision, [_id]: !chkThreeDivision[_id] })
    }

    const checkedAllTtwoDivision = (idOne, lstTwo, e) => {
        let _checked = e.target.checked
        let _lstTwoDivision = lstTwo.filter(el =>
            el.onedivision.id == idOne
        )
        let _chkTwoDivision = { ...chkTwoDivision }
        _lstTwoDivision.forEach((el, i) => {
            _chkTwoDivision['chk2' + el.name.trim() + '_ID' + el._id] = _checked
        });
        setChkTwoDivision(_chkTwoDivision)
    }

    const checkedAllThreeDivision = (idTwo, lstThree, e) => {
        let _checked = e.target.checked
        let _lstThreeDivision = lstThree.filter(el =>
            el.twodivision.id == idTwo
        )
        let _chkThreeDivision = { ...chkThreeDivision }
        _lstThreeDivision.forEach((el, i) => {
            _chkThreeDivision['chk3' + el.name.trim() + '_ID' + el._id] = _checked
        });
        setChkThreeDivision(_chkThreeDivision)
    }

    const checkedDivisionsTrue = () => {
        let _chk1Filtro = Object.keys(chkOneDivision).filter(function (key) {
            return chkOneDivision[key] === true;
        });
        let _chk2Filtro = Object.keys(chkTwoDivision).filter(function (key) {
            return chkTwoDivision[key] === true;
        });
        let _chk3Filtro = Object.keys(chkThreeDivision).filter(function (key) {
            return chkThreeDivision[key] === true;
        });
        if (country.nroDivision == 2) {
            setPriceOnlyData({ ...priceOnlyData, delivery: [[_chk1Filtro], [_chk2Filtro]] })
        } else if (country.nroDivision == 3) {
            setPriceOnlyData({ ...priceOnlyData, delivery: [[_chk2Filtro], [_chk3Filtro]] })
        }
    }

    useEffect(() => {
        getDivisionsByNumbersDivisions()
    }, [])

    useEffect(() => {
        if (!isGet) checkedDivisionsTrue()
    }, [chkTwoDivision, chkThreeDivision])



    return (
        <div className='w-full md:w-10/12 flex flex-col gap-10 py-10'>
            {
                loading && <Loading />
            }
            {
                isPriceOnly ? (
                    <>
                        <div
                            className='flex flex-col gap-5 w-full p-5 rounded-lg'
                            style={{ background: '#EAE5FF' }}
                        >
                            <p style={{ color: '#464646' }}>Se define un solo precio para todos los lugares de reparto.</p>
                            <div className='flex gap-2' >
                                <div
                                    className='flex items-center gap-1 px-3 rounded-xl bg-white'
                                    style={{ color: '#646464', width: '10%' }}>
                                    <label className=''>
                                        {currency}
                                    </label>
                                    <input
                                        className='w-full'
                                        name='price'
                                        type='text'
                                        placeholder='Precio'
                                        value={priceOnlyData.price}
                                        onChange={(e) => onChange(e)}
                                    />
                                </div>
                                <div className='w-fit'>
                                    <Button1
                                        textColor='#6E6E6E'
                                        background='transparent'
                                        text='Guardar'
                                        className='rounded-xl'
                                        border='1px solid #6E6E6E'
                                        onClick={savePriceOnly}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='flex gap-10 2xl:gap-24'>
                            <Country
                                isOnlyPrice={true}
                                country={country}
                                listOneDivision={listOneDivision}
                                listTwoDivision={listTwoDivision}
                                listThreeDivision={listThreeDivision}
                                openOneDivision={openOneDivision}
                                openTwoDivision={openTwoDivision}
                                seeTwoDivision={seeTwoDivision}
                                seeThreeDivision={seeThreeDivision}
                                chkOneDivision={chkOneDivision}
                                chkTwoDivision={chkTwoDivision}
                                chkThreeDivision={chkThreeDivision}
                                changeCheckOneDivision={changeCheckOneDivision}
                                changeCheckTwoDivision={changeCheckTwoDivision}
                                changeCheckThreeDivision={changeCheckThreeDivision}
                            />
                            <div className='flex flex-col Zgap-3 w-full md:w-3/6'>
                                <div className='font-semibold'>Condiciones de envío</div>
                                <div className='p-2 bg-gray-200 rounded-lg'>
                                    <textarea
                                        name='deliveryCondition'
                                        rows={10}
                                        className='w-full p-2 rounded-lg'
                                        value={priceOnlyData.deliveryCondition}
                                        onChange={(e) => onChange(e)}
                                    />
                                </div>
                                <div className='flex justify-center'>
                                    <div>
                                        (!)
                                    </div>
                                    <div className='text-center'>
                                        Es importante incluir información como:<br />
                                        Despacho los martes y jueves<br />
                                        -Lima entre 24-48 giras<br />
                                        -Provincia 3-4 días hábiles
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) :
                    <p className='font-semibold'>Para editar esta sección activalo desde la pestaña delivery.</p>
            }
        </div>
    )
}

export default PriceOnly