function isEmpty(value, text = '') {
    let message = ""
    if (typeof (value) === "string") {
        if (!value.trim()) {
            message = "Ingrese " + text
            return message
        }
    } else if (typeof (value) === "number") {
        if (!toString(value)) {
            message = "Ingrese " + text
            return message
        }
    }
    return message
}

function minDivisionSelected(value = [], _minCheckBoxSelected, text = '', isOnlyPrice = false) {
    let message = ""
    console.log("VALUE", value.length)
    if (value.length == 0) {
        message = 'Debe seleccionar mínimo ' + _minCheckBoxSelected + text
        return message
    } else if (isOnlyPrice) {
        if (value[1][0].length == 0) {
            message = 'Debe seleccionar mínimo ' + _minCheckBoxSelected + text
            return message
        }
    }
    return message
}

function minPrice(value, minValue, text = '') {
    let message = ""
    if (Number(value) == 0) {
        message = "El precio" + text + " debe ser mayor a " + minValue
        return message
    }
    return message
}

function maxPrice(value, maxValue, text) {
    let message = ""
    if (Number(value) > maxValue) {
        message = "El precio" + text + " no debe ser mayor a " + maxValue
        return message
    }
    return message
}

function minAmount(value, minValue, text = '') {
    let message = ""
    if (Number(value) == 0) {
        message = "La cantidad mínima de " + text + " debe ser " + minValue
        return message
    }
    return message
}

function minSelected(value, text = '') {
    let message = ""
    if (value.trim() == "") {
        message = "Debe seleccionar una opción de " + text
        return message
    }
    return message
}

export { isEmpty, minPrice, maxPrice, minDivisionSelected, minAmount, minSelected }