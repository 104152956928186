import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import NavigationSide from '../components/NavigationSide'
import NavigationTop from '../components/NavigationTop'
import Country from '../components/Country'
import Swal from 'sweetalert2'
import ListRate from '../components/ListRate'
import { Link } from 'react-router-dom'
import { isEmpty, minDivisionSelected, minPrice } from '../utils/Validation'
import { CurrencyContext } from '../context/CurrencyContext'

const shippingType = {
   priceOnly: 'Precio único',
   pricePerZone: 'Precio por zona',
   noShippingPolicy: 'Aún no tienes configurado tu política de envío',
}

const pathName = {
   new: '/delivery/politica-envio/precio-zona/nuevo',
   edit: '/delivery/politica-envio/precio-zona/editar'
}

const PoliticaEnvio = () => {
   const currencyValue = useContext(CurrencyContext)
   const currency = currencyValue.currency

   const [listOneDivision, setListOneDivision] = useState([])
   const [listTwoDivision, setListTwoDivision] = useState([])
   const [listThreeDivision, setListThreeDivision] = useState([])

   const [openOneDivision, setOpenOneDivision] = useState({})
   const [openTwoDivision, setOpenTwoDivision] = useState({})

   const [chkOneDivision, setChkOneDivision] = useState({})
   const [chkTwoDivision, setChkTwoDivision] = useState({})
   const [chkThreeDivision, setChkThreeDivision] = useState({})

   const [country, setCountry] = useState({
      id: '',
      name: '',
      nroDivision: 0
   })
   const [loading, setLoading] = useState(true)
   const [idOnlyPrice, setIdOnlyPrice] = useState('')
   const [isGet, setIsGet] = useState(true)
   const [dataZone, setDataZone] = useState([])

   const [shippingPolicy, setShippingPolicy] = useState({
      isPriceOnly: false,
      isPricePerZone: false
   })

   const [priceOnly, setPriceOnly] = useState({
      price: 0,
      delivery: [],
      deliveryCondition: '',
      isDiscount: false,
      discountPrice: 0,
      idCliente: ''
   })

   const [allDeliveryList, setAllDeliveryList] = useState([])

   const validate = () => {
      const _user = JSON.parse(localStorage.getItem('user'))
      const isOnlyPrice = _user.isonlyprice
      let error = {}
      let _priceOnly = { ...priceOnly }
      const _minPrice = 0
      const _minDivisionSelected = 1

      error['price'] = isEmpty(_priceOnly.price, 'precio de delivery')
      if (error['price'] === "") error['price'] = minPrice(_priceOnly.price, _minPrice, ' de delivery')
      error['delivery'] = minDivisionSelected(_priceOnly.delivery, _minDivisionSelected, ' municipio', isOnlyPrice)
      error['deliveryCondition'] = isEmpty(_priceOnly.deliveryCondition, 'las condiciones de delivery')
      if (_priceOnly.isDiscount) {
         error['discountPrice'] = isEmpty(_priceOnly.discountPrice, 'precio de descuento')
         if (error['discountPrice'] === "") error['discountPrice'] = minPrice(_priceOnly.discountPrice, _minPrice, ' de descuento')
      }

      Object.keys(error).forEach(elem => {
         if (error[elem] === "") delete error[elem]
      })

      return error
   }

   const toast = (error) => {
      let msgError =
         error.rateName != undefined ? error.rateName
            : error.price != undefined ? error.price
               : error.delivery != undefined ? error.delivery
                  : error.deliveryCondition != undefined ? error.deliveryCondition
                     : error.discountPrice != undefined ? error.discountPrice
                        : ''

      const Toast = Swal.mixin({
         toast: true,
         position: 'top',
         showConfirmButton: false,
         timer: 3000,
         timerProgressBar: true,
         didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
         }
      })

      if (msgError) {
         Toast.fire({
            icon: 'error',
            title: msgError,
         })
      }
   }

   const swalSuccess = () => {
      Swal.fire({
         title: "Se guardo el precio único con exito",
         // text: "¿Eliminar?",
         icon: 'success',
         // showCancelButton: true,
         // confirmButtonText: "Sí, eliminar",
         // cancelButtonText: "Cancelar",
         timer: 3000
      })
         .then(resultado => {
            // console.log(resultado)
            if (resultado.value) {
               // Hicieron click en "Sí"
               // console.log("*se elimina la venta*");
               window.location.reload(true);
            } else {
               // Dijeron que no
               // console.log("*NO se elimina la venta*");
               window.location.reload(true);
            }
         });
   }

   const priceType = () => {
      let _user = JSON.parse(localStorage.getItem('user'))
      if (_user.ishomedelivery) {
         if (_user.isonlyprice) setShippingPolicy({ ...shippingPolicy, isPriceOnly: _user.isonlyprice })
         else if (_user.iszoneprice) setShippingPolicy({ ...shippingPolicy, isPricePerZone: _user.iszoneprice })
      }
      setLoading(false)
   }

   const onChange = (e) => {
      let name = e.target.name
      let value = e.target.value
      if (shippingPolicy.isPriceOnly) {
         if (e.target.type === 'checkbox') {
            if (!e.target.checked) {
               setPriceOnly({ ...priceOnly, [name]: e.target.checked, discountPrice: 0 })
            } else setPriceOnly({ ...priceOnly, [name]: e.target.checked })
         } else if (name === 'price' || name === 'discountPrice') {
            let amount = e.target.value;
            if (!amount || amount.match(/^\d{1,7}(\.\d{0,2})?$/)) {
               setPriceOnly({ ...priceOnly, [name]: amount })
            }
         } else setPriceOnly({ ...priceOnly, [name]: value })
      }
   }

   const getOneDivion = async (id, dataDelivery) => {
      const url = `https://ventafazil-strapi-production.up.railway.app/Onedivisions?country=` + id
      try {
         const res = await axios.get(url)
         const data = res.data
         if (res.status == 200) {
            setListOneDivision(data)
            fillOpenOneDivision(data)
            fillChkOneDivision(data, dataDelivery)
         }
      } catch (error) {
         console.log(error)
      }
   }

   const getTwoDivion = async (_country, dataDelivery) => {
      const url = `https://ventafazil-strapi-production.up.railway.app/Twodivisions?_limit=-1`
      try {
         const res = await axios.get(url)
         const data = res.data
         if (res.status == 200) {
            twoDivisionXCountry(_country, data, dataDelivery)
         }
      } catch (error) {
         console.log(error)
      }
   }

   const getThreeDivion = async (dataDelivery) => {
      const url = `https://ventafazil-strapi-production.up.railway.app/Threedivisions?_limit=-1`
      try {
         const res = await axios.get(url)
         const data = res.data
         if (res.status == 200) {
            setListThreeDivision(data)
            fillChkThreeDivision(data, dataDelivery)
         }
      } catch (error) {
         console.log(error)
      }
   }

   const twoDivisionXCountry = async (_country, data, dataDelivery) => {
      let newTwoDivision = await data.filter(el =>
         el.onedivision.country == _country.id
      )
      setListTwoDivision(newTwoDivision)
      fillOpenTwoDivision(newTwoDivision)
      fillChkTwoDivision(newTwoDivision, dataDelivery, _country)
   }

   const getDivisionsXNumbersDivisions = async () => {
      let _user = JSON.parse(localStorage.getItem('user'))
      setPriceOnly({ ...priceOnly, idCliente: _user._id })
      let pais = _user.paises
      setCountry({
         id: pais.id,
         name: pais.name,
         nroDivision: pais.numberofdivision
      })
      let _country = {
         id: pais.id,
         nroDivision: pais.numberofdivision
      }
      let dataDelivery = []
      if (_user.ishomedelivery) {
         if (_user.isonlyprice) {
            dataDelivery = await getOnlyPriceBycustomer(_user._id, _country.id)
            for (let index = 1; index <= _country.nroDivision; index++) {
               switch (index) {
                  case 1:
                     await getOneDivion(_country.id, dataDelivery)
                     break;
                  case 2:
                     await getTwoDivion(_country, dataDelivery)
                     break
                  case 3:
                     await getThreeDivion(dataDelivery)
                     break
                  default:
                     console.log("NO HAY MAS DIVISIONES")
                  //return
               }
            }
         } else if (_user.iszoneprice) {
            dataDelivery = await getPricePerZoneBycustomer()
         }
      }
      priceType()
      setIsGet(false)
   }

   const fillOpenOneDivision = async (data) => {
      let _seeOneDivision = {}
      await data.forEach(el => {
         _seeOneDivision['open' + el.name] = false
      });
      setOpenOneDivision(_seeOneDivision)
   }

   const fillOpenTwoDivision = async (data) => {
      let _seeTwoDivision = {}
      await data.forEach(el => {
         _seeTwoDivision['open' + el.name] = false
      });
      setOpenTwoDivision(_seeTwoDivision)
   }

   const fillChkOneDivision = (data, dataDelivery) => {
      let _oneDivision = {}
      let _dataDelivery = []
      if (dataDelivery?.length > 0) {
         dataDelivery[0][0].forEach(el => {
            _dataDelivery.push(el.split('_ID')[0].split('chk1').pop())
         })
         data.forEach(el => {
            if (_dataDelivery.includes(el.name.trim())) {
               _oneDivision['chk1' + el.name.trim() + '_ID' + el._id] = true
            } else {
               _oneDivision['chk1' + el.name.trim() + '_ID' + el._id] = false
            }
         });
      } else {
         data.forEach(el => {
            _oneDivision['chk1' + el.name.trim() + '_ID' + el._id] = false
         });
      }
      setChkOneDivision(_oneDivision)
   }

   const fillChkTwoDivision = (data, dataDelivery, _country) => {
      let dataDeliveryChk2True = []
      let _NewDataDeliveryChk2True = []
      let _twoDivision = {}
      if (dataDelivery?.length > 0) {
         if (_country.nroDivision == 2) {
            dataDeliveryChk2True = dataDelivery[1][0]
         } else if (_country.nroDivision == 3) {
            dataDeliveryChk2True = dataDelivery[0][0]
         }
      }
      if (dataDeliveryChk2True?.length > 0) {
         dataDeliveryChk2True.forEach(el => {
            _NewDataDeliveryChk2True.push(el.split('_ID')[0].split('chk2').pop())
         })
         data.forEach(el => {
            if (_NewDataDeliveryChk2True.includes(el.name.trim())) {
               _twoDivision['chk2' + el.name.trim() + '_ID' + el._id] = true
            } else {
               _twoDivision['chk2' + el.name.trim() + '_ID' + el._id] = false
            }
         });
      } else {
         data.forEach(el => {
            _twoDivision['chk2' + el.name.trim() + '_ID' + el._id] = false
         });
      }
      // Object.keys(_twoDivision).filter((key) => {
      //     if (_twoDivision[key] === true) {
      //         console.log("KEY=====>", key)
      //     }
      // });
      setChkTwoDivision(_twoDivision)
   }

   const fillChkThreeDivision = (data, dataDelivery) => {
      let _threeDivision = {}
      let _dataDelivery = []
      if (dataDelivery?.length > 0) {
         dataDelivery[1][0].forEach(el =>
            _dataDelivery.push(el.split('_ID')[0].split('chk3').pop())
         )
         data.forEach(el => {
            if (_dataDelivery.includes(el.name.trim())) {
               _threeDivision['chk3' + el.name.trim() + '_ID' + el._id] = true
            } else (
               _threeDivision['chk3' + el.name.trim() + '_ID' + el._id] = false
            )
         });
      } else {
         data.forEach(el => {
            _threeDivision['chk3' + el.name.trim() + '_ID' + el._id] = false
         });
      }
      setChkThreeDivision(_threeDivision)
   }

   const seeTwoDivision = (_id) => {
      setOpenOneDivision({ ...openOneDivision, [_id]: !openOneDivision[_id] })
   }

   const seeThreeDivision = (_id) => {
      setOpenTwoDivision({ ...openTwoDivision, [_id]: !openTwoDivision[_id] })
   }

   const changeCheckOneDivision = (_id, idOne, lstTwo, e) => {
      setChkOneDivision({ ...chkOneDivision, [_id]: !chkOneDivision[_id] })
      if (country.nroDivision == 2) checkedAllTtwoDivision(idOne, lstTwo, e)
   }

   const changeCheckTwoDivision = (_id, idTwo, lstThree, e) => {
      setChkTwoDivision({ ...chkTwoDivision, [_id]: !chkTwoDivision[_id] })
      if (country.nroDivision == 3) checkedAllThreeDivision(idTwo, lstThree, e)
   }

   const changeCheckThreeDivision = (_id) => {
      setChkThreeDivision({ ...chkThreeDivision, [_id]: !chkThreeDivision[_id] })
   }

   const checkedAllTtwoDivision = async (idOne, lstTwo, e) => {
      let _checked = e.target.checked
      let _lstTwoDivision = await lstTwo.filter(el =>
         el.onedivision.id == idOne
      )
      let _chkTwoDivision = { ...chkTwoDivision }
      _lstTwoDivision.forEach((el, i) => {
         _chkTwoDivision['chk2' + el.name.trim() + '_ID' + el._id] = _checked
      });
      setChkTwoDivision(_chkTwoDivision)
   }

   const checkedAllThreeDivision = async (idTwo, lstThree, e) => {
      let _checked = e.target.checked
      let _lstThreeDivision = await lstThree.filter(el =>
         el.twodivision.id == idTwo
      )
      let _chkThreeDivision = { ...chkThreeDivision }
      _lstThreeDivision.forEach((el, i) => {
         _chkThreeDivision['chk3' + el.name.trim() + '_ID' + el._id] = _checked
      });
      setChkThreeDivision(_chkThreeDivision)
   }

   const checkedDivisionsTrue = () => {
      let _chk1Filtro = Object.keys(chkOneDivision).filter(function (key) {
         return chkOneDivision[key] === true;
      });
      let _chk2Filtro = Object.keys(chkTwoDivision).filter(function (key) {
         return chkTwoDivision[key] === true;
      });
      let _chk3Filtro = Object.keys(chkThreeDivision).filter(function (key) {
         return chkThreeDivision[key] === true;
      });
      if (country.nroDivision == 2) {
         setPriceOnly({ ...priceOnly, delivery: [[_chk1Filtro], [_chk2Filtro]] })
      } else if (country.nroDivision == 3) {
         setPriceOnly({ ...priceOnly, delivery: [[_chk2Filtro], [_chk3Filtro]] })
      }
   }

   const saveOnlyPrice = async () => {
      let errorMessage = validate()
      let url = ''
      let data = {}
      const config = {
         headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_KEY_STRAPI}`,
            'Content-Type': 'application/json',
         },
      }
      if (idOnlyPrice) {
         url = `https://ventafazil-strapi-production.up.railway.app/OnlyPrices/${idOnlyPrice}`
         data = {
            price: priceOnly.price,
            delivery: priceOnly.delivery,
            deliverycondition: priceOnly.deliveryCondition,
            isdiscount: priceOnly.isDiscount,
            discountprice: priceOnly.discountPrice,
            cliente: priceOnly.idCliente
         }
         if (Object.keys(errorMessage).length > 0) {
            toast(errorMessage)
         } else if (Object.keys(errorMessage).length == 0) {
            try {
               const response = await axios.put(url, data, config)
               if (response.status == 200) {
                  swalSuccess()
               }
            } catch (error) {
               console.log(error)
            }
         }
      } else {
         url = 'https://ventafazil-strapi-production.up.railway.app/OnlyPrices'
         data = {
            price: priceOnly.price,
            delivery: priceOnly.delivery,
            deliverycondition: priceOnly.deliveryCondition,
            isdiscount: priceOnly.isDiscount,
            discountprice: priceOnly.discountPrice,
            cliente: priceOnly.idCliente,
            country: country.id
         }
         if (Object.keys(errorMessage).length > 0) {
            toast(errorMessage)
         } else if (Object.keys(errorMessage).length == 0) {
            try {
               const response = await axios.post(url, data, config)
               if (response.status == 200) {
                  swalSuccess()
               }
            } catch (error) {
               console.log(error)
            }
         }
      }


   }

   const getOnlyPriceBycustomer = async (idCustomer, idCountry) => {
      // const _user = JSON.parse(localStorage.getItem('user'))
      // const idCustomer = _user._id
      // const idCountry = _user.paises.id
      const url = `https://ventafazil-strapi-production.up.railway.app/OnlyPrices?cliente=${idCustomer}&country=${idCountry}`
      const config = {
         headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_KEY_STRAPI}`,
            'Content-Type': 'application/json',
         },
      }
      try {
         const res = await axios.get(url, config)
         const data = res.data[0]
         if (res.status == 200) {
            if (data) {
               setIdOnlyPrice(data._id)
               setPriceOnly({
                  price: data.price,
                  delivery: data.delivery,
                  deliveryCondition: data.deliverycondition,
                  isDiscount: data.isdiscount,
                  discountPrice: data.discountprice,
                  idCliente: data.cliente._id
               })
               return data.delivery
            }
         }
      } catch (error) {
         console.log(error)
      }
   }

   const getAllDeliveryList = (data) => {
      let _allDeliveryList = []
      let _contact = []
      if (data.length > 0) {
         data.forEach(el => {
            _allDeliveryList.push(
               {
                  idRate: el._id,
                  allDeliveryList: el.deliverylist
               }
            )
         })
         setAllDeliveryList(_allDeliveryList)
      }
   }

   const getPricePerZoneBycustomer = async () => {
      const _user = JSON.parse(localStorage.getItem('user'))
      const idCustomer = _user._id
      const idCountry = _user.paises.id
      const url = `https://ventafazil-strapi-production.up.railway.app/Priceperzones?cliente=${idCustomer}&country=${idCountry}`
      const config = {
         headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_KEY_STRAPI}`,
            'Content-Type': 'application/json',
         },
      }
      try {
         const res = await axios.get(url, config)
         const data = res.data
         if (res.status == 200) {
            setDataZone(res.data)
            getAllDeliveryList(data)
         }
      } catch (error) {
         console.log(error)
      }
   }

   const changeActiveRate = async (item) => {
      const url = `https://ventafazil-strapi-production.up.railway.app/Priceperzones/${item._id}`
      const data = {
         isactive: !item.isactive,
      }
      const config = {
         headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_KEY_STRAPI}`,
            'Content-Type': 'application/json'
         }
      }
      try {
         const response = await axios.put(url, data, config)
         if (response.status === 200) {
            getPricePerZoneBycustomer()
            const Toast = Swal.mixin({
               toast: true,
               position: 'top-right',
               iconColor: 'white',
               customClass: {
                  popup: 'colored-toast',
               },
               showConfirmButton: false,
               timer: 1500,
               timerProgressBar: true,
            })
            Toast.fire({
               icon: 'success',
               title: 'Se edito un precio por zona con exito',
            })
         }
      } catch (error) {
         console.log(error)
      }
   }

   const deletePricePerZone = async (id) => {
      const url = `https://ventafazil-strapi-production.up.railway.app/Priceperzones/${id}`
      const config = {
         headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_KEY_STRAPI}`
         }
      }
      try {
         const response = await axios.delete(url, config)
         if (response.status === 200) {
            const Toast = Swal.mixin({
               toast: true,
               position: 'top-right',
               iconColor: 'white',
               customClass: {
                  popup: 'colored-toast',
               },
               showConfirmButton: false,
               timer: 1500,
               timerProgressBar: true,
            })
            Toast.fire({
               icon: 'success',
               title: 'Se elimino un precio por zona con exito',
            })
            getPricePerZoneBycustomer()
         }
      } catch (error) {
         console.log(error)
      }
   }

   useEffect(() => {
      getDivisionsXNumbersDivisions()
   }, [])

   useEffect(() => {
      if (!isGet) checkedDivisionsTrue()
   }, [chkTwoDivision, chkThreeDivision])

   return (
      <div className='pb-24'>
         <NavigationTop />
         <NavigationSide />
         {
            loading ? (
               <div class='flex items-center justify-center min-h-screen'>
                  <div class="border-t-transparent w-28 h-28 border-4 border-blue-200 rounded-full animate-spin"></div>
                  {/* <p class="ml-5 text-lg">Cargando...</p> */}
               </div>
            ) : (
               shippingPolicy.isPriceOnly ? (
                  <div className='Zbg-yellow-400 container py-8 sm:px-0 md:px-10 lg:px-32 2xl:px-32'>
                     <div className="Zbg-green-300 container flex justify-between py-4">
                        <h1 className="text-3xl font-bold tracking-tighter">{shippingPolicy.isPriceOnly ? shippingType.priceOnly : shippingType.noShippingPolicy}</h1>
                        {
                           shippingPolicy.isPriceOnly &&
                           <button
                              className="py-2 px-10 font-bold text-center bg-yellow-400 rounded-md"
                              onClick={saveOnlyPrice}
                           >Guardar</button>
                        }
                     </div>
                     <div id="shippingPolicy" className="Zbg-red-400 container py-4 ">
                        {
                           shippingPolicy.isPriceOnly && (
                              <div className="flex flex-col gap-8 h-full">
                                 <div className="flex flex-col md:pr-0 lg:pr-5 2xl:pr-11 md:w-full lg:w-3/6">
                                    <div className="py-1 font-bold">Precio único</div>
                                    <div className='w-full'>
                                       <div className="p-2 w-full rounded-lg bg-gray-200 ">
                                          <div className="flex items-center w-full h-10 px-3 rounded-lg bg-white">
                                             <label className="flex font-semibold w-full bg-white-500">
                                                {currency}
                                                <input
                                                   className="px-2 w-full font-normal rounded-lg"
                                                   name='price'
                                                   type='text'
                                                   placeholder='Ingresa el precio'
                                                   value={priceOnly.price}
                                                   onChange={(e) => onChange(e)}
                                                />
                                             </label>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className='flex gap-10 2xl:gap-24'>
                                    <Country
                                       isOnlyPrice={true}
                                       country={country}
                                       listOneDivision={listOneDivision}
                                       listTwoDivision={listTwoDivision}
                                       listThreeDivision={listThreeDivision}
                                       openOneDivision={openOneDivision}
                                       openTwoDivision={openTwoDivision}
                                       seeTwoDivision={seeTwoDivision}
                                       seeThreeDivision={seeThreeDivision}
                                       chkOneDivision={chkOneDivision}
                                       chkTwoDivision={chkTwoDivision}
                                       chkThreeDivision={chkThreeDivision}
                                       changeCheckOneDivision={changeCheckOneDivision}
                                       changeCheckTwoDivision={changeCheckTwoDivision}
                                       changeCheckThreeDivision={changeCheckThreeDivision}
                                    />
                                    <div className='flex flex-col Zgap-3 md:w-3/6'>
                                       <div className='font-semibold'>Condiciones de envío</div>
                                       <div className='p-2 bg-gray-200 rounded-lg'>
                                          <textarea
                                             name='deliveryCondition'
                                             rows={10}
                                             className='w-full p-2 rounded-lg'
                                             value={priceOnly.deliveryCondition}
                                             onChange={(e) => onChange(e)}
                                          />
                                       </div>
                                       <div className='flex justify-center'>
                                          <div>
                                             (!)
                                          </div>
                                          <div className='text-center'>
                                             Es importante incluir información como:<br />
                                             Despacho los martes y jueves<br />
                                             -Lima entre 24-48 giras<br />
                                             -Provincia 3-4 días hábiles
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 {/* <div className="flex flex-col gap-4 md:pr-0 lg:pr-5 2xl:pr-11 md:w-full lg:w-3/6">
                                                <div className="font-bold">Descuento de envío</div>
                                                <div className='flex items-center'>
                                                    <input
                                                        className="mr-2"
                                                        name='isDiscount'
                                                        type={"checkbox"}
                                                        value={priceOnly.isDiscount}
                                                        checked={priceOnly.isDiscount}
                                                        onChange={(e) => onChange(e)}
                                                    />
                                                    <label>
                                                        Aplicar descuento de {currency}0. por compras mayores a:
                                                    </label>
                                                </div>
                                                <div className='w-full'>
                                                    <div className="p-2 w-full rounded-lg bg-gray-200">
                                                        <div className={`flex items-center w-full h-10 px-3 rounded-lg bg-white ${!priceOnly.isDiscount ? 'opacity-30' : ''}`}>
                                                            <label className="flex w-full font-semibold">
                                                                {currency}
                                                                <input
                                                                    className="px-2 w-full font-normal rounded-lg"
                                                                    name='discountPrice'
                                                                    type='text'
                                                                    placeholder='Ingresa el descuento'
                                                                    disabled={!priceOnly.isDiscount}
                                                                    value={priceOnly.discountPrice}
                                                                    onChange={(e) => onChange(e)}
                                                                />
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                              </div>
                           )
                        }
                     </div>
                  </div>
               ) : shippingPolicy.isPricePerZone ? (
                  <ListRate
                     isPricePerZone={true}
                     title={shippingType.pricePerZone}
                     subtitle='Tarifas'
                     nameButton='Crear'
                     empyList='Aún no tienes creado ningún precio por zona'
                     data={dataZone}
                     pathnameNew={pathName.new}
                     pathnameEdit={pathName.edit}
                     handleCheckboxChange={changeActiveRate}
                     deleteRate={deletePricePerZone}
                     allDeliveryList={allDeliveryList}
                  />
               ) : (
                  <div class='flex flex-wrap justify-center gap-28'>
                     <div className="Zbg-green-300 pt-20 2xl:pr-20">
                        <h1 className="text-3xl font-bold tracking-tighter">{!shippingPolicy.isPriceOnly && shippingType.noShippingPolicy}</h1>
                     </div>
                     <div className={`w-3/5 p-3 h-20 rounded-lg bg-gray-200`}>
                        <Link to={'/delivery/formas-entrega'}>
                           <button className="h-full w-full font-semibold rounded-lg bg-white hover:bg-yellow-200">{'>> '}Puedes configurarlo aquí{' <<'}</button>
                        </Link>
                     </div>
                  </div>
               )
            )
         }
      </div >
   )
}

export default PoliticaEnvio