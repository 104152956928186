import React from "react";

function UIinputLabeled(props) {
    return (
        <div className="flex flex-col mb-2 grow">
            <label className="mb-1 text-sm font-semibold tracking-tight text-gray-700">{props.label}</label>
            <div className="flex items-center gap-2">
                {props.suffix && <div>{props.suffix}</div>}
                <input
                    className="block w-full px-3 py-3 border border-gray-400 rounded-lg appearance-none focus:outline-none focus:border-yellow-500"
                    type="text"
                    placeholder={props.placeholder}
                    value={props.value}
                    onChange={props.changeFunction}
                    name={props.nameInput}
                    onBlur={props.onBlur}
                    // onKeyUp={props.onKeyUp}
                />
            </div>
        </div>
    );
}

export default UIinputLabeled;