import React from 'react'
import { useState } from 'react'
import Modal from '../components/Modal'
import Select from 'react-select'
import axios from 'axios'
import Swal from 'sweetalert2'
import { useHistory } from 'react-router-dom'
import { PostHistoriaAPI } from '../utils/PostHistorials'
const Reembolsar = ({
  idPedido,
  estado,
  estadopdo,
  usuario,
  open,
  isModalFalse,
  total,
}) => {
  //console.log('FFMI', total)
  const [openModal, setOpenModal] = useState(open)
  const [tipoReembolso, settipoReembolso] = useState('')
  const [formaPago, setFormaPago] = useState('')
  const [monto, setMonto] = useState()
  const [fecha, setFecha] = useState()
  let history = useHistory()
  const [alert, setAlert] = useState(false)
  const [alertMessage, setalertMessage] = useState('')
  //console.log(open)
  const reembolsoPrice = [
    { value: 'Total', label: 'Total' },
    { value: 'Parcial', label: 'Parcial' },
  ]

  const valuesFormaPago = [
    { value: 'efectivo', label: 'efectivo' },
    { value: 'transferencia', label: 'transferencia' },
    { value: 'descuento', label: 'descuento' },
    { value: 'reembolso con izipay', label: 'reembolso con izipay' },
  ]
  const onCancelar = () => {
    setOpenModal(false)
    settipoReembolso('')
    setFormaPago('')
    setMonto('')
    //console.log('aquiii')
    setAlert(false)
    setalertMessage('')
  }
  const handleSelectChange = (e) => {
    //console.log('event', e.value)
    settipoReembolso(e.value)

    // setSelectEstadoVenta(e.value)
  }

  const handleSelectChange1 = (e) => {
    //console.log('event', e.value)
    setFormaPago(e.value)

    // setSelectEstadoVenta(e.value)
  }

  const reembolsarPedido = async () => {
    //console.log(tipoReembolso === '')
    //console.log(formaPago === '')
    if (!tipoReembolso || !formaPago) {
      //console.log('con1')
      setAlert(true)

      setalertMessage('POR FAVOR LLENA TODOS LOS CAMPOS')
      setTimeout(() => {
        setAlert(false)
      }, 3000)
      return
    }
    //console.log('fff', tipoReembolso)
    //console.log('IF VALIDATOR', monto > total)
    if (tipoReembolso == 'Parcial') {
      //console.log('FIRST IF')

      if (!monto) {
        setAlert(true)
        setalertMessage('EL MONTO DEBE TENER UN VALOR')
        setTimeout(() => {
          setAlert(false)
        }, 3000)
        return
      }

      if (parseFloat(monto) > parseFloat(total)) {
        //console.log('SEGUNDO IF')
        //console.log('monto', monto)
        //console.log('total', total)
        setAlert(true)
        setalertMessage('EL MONTO DEBE SER MENOR QUE EL TOTAL DE LA ORDEN')
        setTimeout(() => {
          setAlert(false)
        }, 3000)
        return
      }
    }

    //console.log('fff')

    let estadopdo = 'reembolso'
    let reembolsado = {}
    reembolsado.formaPago = formaPago
    reembolsado.tipoReembolso = tipoReembolso
    if (tipoReembolso == 'Parcial') {
      reembolsado.monto = monto
      estadopdo = 'reembolsoparcial'
    }
    setFecha(new Date())
    reembolsado.fecha = fecha
    let isreembolsado = true
    let body = JSON.stringify({
      reembolsado,
      estado: estadopdo,
      isreembolsado,
    })

    //console.log(body)

    try {
      const { data } = await axios.put(
        'https://ventafazil-strapi-production.up.railway.app/ventas/' +
          idPedido,
        body,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_KEY_STRAPI}`,

            'Content-Type': 'application/json',
          },
        }
      )
      setOpenModal(false)

      let datasET = {}
      datasET.description = 'Se edito un pedido'
      datasET.estado = estado
      datasET.estadopdo = estadopdo
      datasET.idpedido = idPedido
      datasET.usuario = usuario
      // //console.log('estado', estado)
      // //console.log('estadopdo', estadopdo)
      //console.log('MI DATASERRR', datasET)
      PostHistoriaAPI(datasET)

      Swal.fire('Se Edito un pedido con exito ', '', 'success')
      history.push('/ventas')
    } catch (error) {
      //console.log(error)
    }
  }

  return (
    <>
      {/* {!open && (
        <h1
          className='p-2 px-10 text-sm font-bold text-center bg-yellow-400 rounded-md cursor-pointer '
          onClick={() => setOpenModal(true)}
        >
          Reembolsar
        </h1>
      )} */}
      <h1
        className='p-2 px-10 text-sm font-bold text-center bg-yellow-400 rounded-md cursor-pointer '
        onClick={() => setOpenModal(true)}
      >
        Reembolsar
      </h1>
      {openModal && (
        <Modal onClick={reembolsarPedido} onCancelar={onCancelar}>
          <h1>Reembolso</h1>
          <div className='flex items-center justify-start w-max   '>
            <label className='w-44' htmlFor=''>
              Forma de Pago
            </label>
            <Select
              className='w-44  m-auto py-2 focus:border-yellow-500 ml-4 '
              closeMenuOnSelect={true}
              options={valuesFormaPago}
              onChange={handleSelectChange1}
            />
          </div>

          <div className='flex items-center justify-start w-max   '>
            <label className='w-44' htmlFor=''>
              Desea reembolsar
            </label>
            <Select
              className='w-44  m-auto py-2 focus:border-yellow-500  ml-4 '
              closeMenuOnSelect={true}
              options={reembolsoPrice}
              onChange={handleSelectChange}
            />
          </div>

          {tipoReembolso === 'Parcial' && (
            <>
              <label className='w-44' htmlFor=''>
                Monto de Reembolso
              </label>
              <input
                className='ml-5 outline-none border w-44'
                type='text'
                onChange={(e) => setMonto(e.target.value)}
              />
            </>
          )}

          {alert && <p className='text-center text-red-500'>{alertMessage}</p>}
        </Modal>
      )}
    </>
  )
}

export default Reembolsar
