import React from 'react'

const Indicador = ({ text, number, color, currency, decimal, textColor }) => {
  console.log('mi numbrer', parseInt(number))
  return (
    <div
      className='w-40 p-2 text-center'
      style={{ background: color, borderRadius: '20px' }}

    >
      <p className='font-bold' style={{ color: textColor }}>
        {currency && currency}
        {decimal ? number.toFixed(2) : number}
      </p>
      <p className='text-sm' style={{ color: textColor }}>
        {text}
      </p>
    </div>
  )
}

export default Indicador
